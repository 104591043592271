<template>
  <div>
    <el-upload action="" list-type="picture-card" :file-list="fileList" :on-remove="handleRemove" :http-request="uploadFile" :on-preview="handlePreview" :limit="maxCount" :on-exceed="handleExceed">
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import OSS from 'ali-oss'

export default {
  name: 'MultiUpload',
  props: {
    // 图片属性数组
    value: Array,
    // 最大上传图片数量
    maxCount: {
      type: Number,
      default: 3
    },
    name: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      dataObj: {
        policy: '',
        signature: '',
        key: '',
        ossaccessKeyId: '',
        dir: '',
        host: ''
      },
      dialogVisible: false,
      dialogImageUrl: null,
      useOss: true, // 使用oss->true;使用MinIO->false
      ossUploadUrl: 'http://macro-oss.oss-cn-shenzhen.aliyuncs.com',
      minioUploadUrl: 'http://localhost:8080/minio/upload'
    }
  },
  computed: {
    fileList() {
      const fileList = []
      for (let i = 0; i < this.value.length; i++) {
        fileList.push({ url: this.value[i] })
      }
      return fileList
    }
  },
  methods: {
    ossClient(conf) {
      return new OSS(conf)
    },
    emitInput(fileList) {
      const value = []
      for (let i = 0; i < fileList.length; i++) {
        value.push(fileList[i].url)
      }
      this.$emit('input', value)
    },
    handleRemove(file, fileList) {
      this.emitInput(fileList)
    },
    handlePreview(file) {
      this.dialogVisible = true
      this.dialogImageUrl = file.url
    },
    async uploadFile(file) {
      const config = await this.$api.common.policy()
      const region = config.endpoint.split('.')[0].slice(7)
      const uploadConf = {
        region,
        accessKeyId: config.accessKeyId,
        accessKeySecret: config.accessKeySecret,
        bucket: config.bucketName
      }
      const fileName = `${this.name}/${Date.parse(new Date())}` // 定义唯一的文件名
      this.ossClient(uploadConf)
        .put(fileName, file.file, {
          ContentType: 'image/jpeg'
        })
        .then(({ res, url }) => {
          if (res && res.status === 200) {
            this.fileList.push({ name: file.file.name, url: url })
            this.emitInput(this.fileList)
          }
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: '上传失败'
          })
        })
    },
    handleUploadSuccess(res, file) {
      let url = this.dataObj.host + '/' + this.dataObj.dir + '/' + file.name
      if (!this.useOss) {
        // 不使用oss直接获取图片路径
        url = res.data.url
      }
      this.fileList.push({ name: file.name, url: url })
      this.emitInput(this.fileList)
    },
    handleExceed() {
      this.$message({
        message: '最多只能上传' + this.maxCount + '张图片',
        type: 'warning',
        duration: 1000
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-upload--picture-card {
  width: 60px;
  height: 60px;
  line-height: 67px;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 60px;
  height: 60px;
  line-height: 67px;
}
</style>

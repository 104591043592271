<template>
  <div class="app-container">
    <el-card class="header-card">
      <div style="margin-top: 15px">
        <!-- <el-form :inline="true" :model="listQuery" size="small" label-width="110px">
          <el-form-item label="输入搜索">
            <el-input style="width: 203px" v-model="listQuery.keyword" placeholder="品牌名称/关键字" />
          </el-form-item>
        </el-form> -->
        <el-row class="search-area-btn">
          <div class="left">
            <el-button type="primary" size="small" @click="addHandle()">添加</el-button>
          </div>
          <div class="center">
            <el-button type="primary" size="small" @click="searchBrandList()">查询</el-button>
          </div>
        </el-row>
      </div>
    </el-card>
    <div class="table-container">
      <el-table ref="brandTable" :data="list" style="width: 100%" @selection-change="handleSelectionChange" v-loading="listLoading" border>
        <!-- <el-table-column type="selection" width="60" align="center"></el-table-column> -->
        <el-table-column label="编号" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column label="收货点" align="center">
          <template slot-scope="scope">{{ scope.row.addressName }}</template>
        </el-table-column>
        <el-table-column label="收货人姓名" align="center">
          <template slot-scope="scope">{{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column label="所在区域" align="center">
          <template slot-scope="scope">{{ scope.row.province + scope.row.city + scope.row.region }}</template>
        </el-table-column>
        <el-table-column label="详细地址" align="center">
          <template slot-scope="scope">{{ scope.row.detailAddress }}</template>
        </el-table-column>
        <el-table-column label="联系电话" align="center">
          <template slot-scope="scope">{{ scope.row.phone }}</template>
        </el-table-column>
        <el-table-column label="是否默认收货地址" align="center">
          <template slot-scope="scope">{{ scope.row.receiveStatus | filterStatus }}</template>
        </el-table-column>
        <el-table-column label="是否默认发货地址" align="center">
          <template slot-scope="scope">{{ scope.row.sendStatus | filterStatus }}</template>
        </el-table-column>
        <!-- <el-table-column label="是否默认" width="100" align="center">
          <template slot-scope="scope">
            <el-switch
              @change="handleShowStatusChange(scope.$index, scope.row)"
              :active-value="1"
              :inactive-value="0"
              v-model="scope.row.showStatus">
            </el-switch>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="handleUpdate(scope.$index, scope.row)">编辑 </el-button>
            <el-button type="danger" size="mini" @click="handleDelete(scope.$index, scope.row)">删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="false" class="batch-operate-container">
      <el-select size="small" v-model="operateType" placeholder="批量操作">
        <el-option v-for="item in operates" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <el-button style="margin-left: 20px" class="search-button" @click="handleBatchOperate()" type="primary" size="small"> 确定 </el-button>
    </div>
    <div class="pagination-container">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes,prev, pager, next,jumper" :page-size="listQuery.pageSize" :page-sizes="[5, 10, 20, 30, 50, 100]" :current-page.sync="listQuery.pageNumber" :total="total" />
    </div>
    <addressDetail v-if="addressDetailVisible" :obj="nowObj" @close="dialogClose()" @refreshData="refreshData()"></addressDetail>
  </div>
</template>
<script>
import addressDetail from './components/AddressDetail.vue'
export default {
  name: 'AddressList',
  components: { addressDetail },
  filters: {
    filterStatus(val) {
      if (val === 1) {
        return '是'
      } else {
        return '否'
      }
    }
  },
  data() {
    return {
      addressDetailVisible: false,
      nowObj: null,
      operates: [
        {
          label: '删除',
          value: 'delete'
        }
      ],
      operateType: null,
      listQuery: {
        pageNumber: 1,
        pageSize: 10
      },
      list: null,
      total: null,
      listLoading: true,
      multipleSelection: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    dialogClose() {
      this.addressDetailVisible = false
    },
    refreshData() {
      this.getList()
      this.dialogClose()
    },
    getList() {
      this.listLoading = true
      this.$api.order.companyAddressList(this.listQuery).then(response => {
        this.listLoading = false
        this.list = response.data.list
        this.total = response.data.total
        // this.totalPage = response.data.totalPage;
        // this.pageSize = response.data.pageSize;
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleUpdate(index, row) {
      this.nowObj = row
      this.addressDetailVisible = true
    },
    handleDelete(index, row) {
      this.$confirm('是否要删除该数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.order.deleteAddressById(row.id).then(() => {
          this.$message({
            message: '删除成功',
            type: 'success',
            duration: 1000
          })
          this.getList()
        })
      })
    },
    handleFactoryStatusChange(index, row) {
      let data = {
        ids: row.id,
        factoryStatus: row.factoryStatus
      }
      this.$api.brand
        .updateFactoryStatus(data)
        .then(() => {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000
          })
        })
        .catch(() => {
          if (row.factoryStatus === 0) {
            row.factoryStatus = 1
          } else {
            row.factoryStatus = 0
          }
        })
    },
    handleShowStatusChange(index, row) {
      let data = {
        ids: row.id,
        showStatus: row.showStatus
      }
      this.$api.brand
        .updateShowStatus(data)
        .then(() => {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000
          })
        })
        .catch(() => {
          if (row.showStatus === 0) {
            row.showStatus = 1
          } else {
            row.showStatus = 0
          }
        })
    },
    handleSizeChange(val) {
      this.listQuery.pageNumber = 1
      this.listQuery.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.pageNumber = val
      this.getList()
    },
    addHandle() {
      this.nowObj = null
      this.addressDetailVisible = true
    },
    searchBrandList() {
      this.listQuery.pageNumber = 1
      this.getList()
    },
    handleBatchOperate() {
      console.log(this.multipleSelection)
      if (this.multipleSelection < 1) {
        this.$message({
          message: '请选择一条记录',
          type: 'warning',
          duration: 1000
        })
        return
      }
      if (!this.operateType) {
        this.$message({
          message: '请选择批量操作类型',
          type: 'warning',
          duration: 1000
        })
        return
      }
      let ids = []
      for (let i = 0; i < this.multipleSelection.length; i++) {
        ids.push(this.multipleSelection[i].id)
      }
      let data = {
        ids: ids
      }
      this.$api.brand.updateShowStatus(data).then(() => {
        this.getList()
        this.$message({
          message: '修改成功',
          type: 'success',
          duration: 1000
        })
      })
    },
    addAddress() {
      this.$router.push({ path: '/oms/addressAdd' })
    }
  }
}
</script>
<style lang="scss" scoped></style>

<template>
  <div class="app-container">
    <el-card class="header-card" shadow="never">
      <div style="margin-top: 15px">
        <el-form :inline="true" :model="listQuery" size="small" label-width="110px">
          <el-row>
            <el-col :span="6">
              <el-form-item label="输入搜索">
                <el-input v-model="listQuery.keyword" style="width: 203px" placeholder="商品名称" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="店铺搜索">
                <el-select v-model="listQuery.shopIds" placeholder="请选择店铺" clearable>
                  <el-option v-for="item in ShopNameOptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="商品货号">
                <el-input v-model="listQuery.productSn" style="width: 203px" placeholder="商品货号" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="商品分类">
                <el-cascader v-model="selectProductCateValue" clearable :options="productCateOptions" filterable />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-row class="search-area-btn">
          <div class="left">&nbsp;</div>
          <div class="center">
            <el-button type="primary" size="small" @click="handleSearchList()"> 查询 </el-button>
            <el-button size="small" @click="handleResetSearch()"> 重置 </el-button>
          </div>
        </el-row>
      </div>
    </el-card>
    <div class="table-container">
      <el-table ref="productTable" v-loading="listLoading" :data="list" style="width: 100%" border @selection-change="handleSelectionChange">
        <el-table-column label="序号" type="index" width="50" align="center"> </el-table-column>
        <el-table-column label="编号" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column label="商品图片" width="120" align="center">
          <template slot-scope="scope">
            <div class="image__preview">
              <el-image style="height: 100px; width: 100px" :src="scope.row.pic" :preview-src-list="srcList" @click="showViewer(scope.row.pic)" />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商品名称" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.name }}</p>
            <p>品牌：{{ scope.row.brandName }}</p>
          </template>
        </el-table-column>
        <el-table-column label="所属店铺" align="center" width="100">
          <template slot-scope="scope">
            <p>{{ scope.row.shopName }}</p>
          </template>
        </el-table-column>
        <el-table-column label="价格/货号" width="120" align="center">
          <template slot-scope="scope">
            <p>价格：￥{{ scope.row.price }}</p>
            <p>货号：{{ scope.row.productSn }}</p>
          </template>
        </el-table-column>
        <el-table-column label="申请时间" width="180" align="center">
          <template slot-scope="scope">{{ parseDate(scope.row.createTime) }}</template>
        </el-table-column>
        <el-table-column label="操作" width="300" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="openViews(scope.row)">预览 </el-button>
            <el-button size="mini" @click="handleUpdateProduct(scope.$index, scope.row, false)">详情</el-button>
            <el-button type="primary" size="mini" @click="handleProductVerifyStatus(scope.row.id, 1)">通过</el-button>
            <el-button type="danger" size="mini" @click="handleProductVerifyStatus(scope.row.id, 2)">不通过</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination background layout="total, sizes,prev, pager, next,jumper" :page-size="listQuery.pageSize" :page-sizes="[5, 10, 15]" :current-page.sync="listQuery.pageNum" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
    <el-dialog title="货品信息" append-to-body :visible.sync="editSkuInfo.dialogVisible" width="800px">
      <span>商品货号：</span>
      <span>{{ editSkuInfo.productSn }}</span>
      <el-table style="width: 100%; margin-top: 20px" :data="editSkuInfo.stockList" border>
        <el-table-column v-for="(item, index) in editSkuInfo.productAttr" :key="item.id" :label="item.name" align="center">
          <template slot-scope="scope">
            {{ getProductSkuSp(scope.row, index, item.name) }}
          </template>
        </el-table-column>
        <el-table-column label="销售价格" width="120" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.price" :disabled="true" />
          </template>
        </el-table-column>
        <el-table-column label="商品库存" width="120" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.stock" min="1" :disabled="true" />
          </template>
        </el-table-column>
        <el-table-column label="库存预警值" width="100" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.lowStock" :disabled="true" />
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog v-if="shopDetailVisible" title="商品详情预览" class="shop-dialog" :visible.sync="shopDetailVisible" :before-close="shopDialogClose">
      <iframe width="100%" frameborder="0" class="shop-iframe" :src="shopDetailUrl"></iframe>
    </el-dialog>
    <productDetail v-if="productDetailShow" :id="productDetailId" :copy="productDetailCopy" :audit="productDetailAudit" :is-edit="productDetailEdit" @close="productDetailClose" />
  </div>
</template>
<script>
import productDetail from '../product/components/ProductDetail'

const defaultListQuery = {
  keyword: null,
  pageNum: 1,
  pageSize: 5,
  publishStatus: null,
  shopIds: null,
  verifyStatus: null,
  productSn: null,
  productCategoryId: null,
  brandId: null,
  flag: null,
  isExemption: null
}
export default {
  name: 'ProductList',
  components: {
    productDetail
  },
  filters: {
    verifyStatusFilter(value) {
      if (value === 1) {
        return '审核通过'
      } else {
        return '未审核'
      }
    }
  },
  data() {
    return {
      shopDetailVisible: false,
      shopDetailUrl: '',
      editSkuInfo: {
        dialogVisible: false,
        productId: null,
        productSn: '',
        productAttributeCategoryId: null,
        stockList: [],
        productAttr: [],
        keyword: null
      },
      ShopNameOptions: [],
      operates: [
        {
          label: '商品上架',
          value: 'publishOn'
        },
        {
          label: '商品下架',
          value: 'publishOff'
        },
        {
          label: '移入回收站',
          value: 'recycle'
        }
      ],
      operateType: null,
      listQuery: Object.assign({}, defaultListQuery),
      list: null,
      total: null,
      listLoading: true,
      selectProductCateValue: null,
      multipleSelection: [],
      productCateOptions: [],
      brandOptions: [],
      publishStatusOptions: [
        {
          value: 1,
          label: '上架'
        },
        {
          value: 0,
          label: '下架'
        }
      ],
      verifyStatusOptions: [
        {
          value: 1,
          label: '审核通过'
        },
        {
          value: 0,
          label: '未审核'
        }
      ],
      areaOptions: [
        {
          value: 0,
          label: '否'
        },
        {
          value: 1,
          label: '是'
        }
      ],
      currentSelectdProductId: null, // 当前选中的商品id
      productDetailShow: false,
      productDetailId: null,
      productDetailCopy: false,
      productDetailAudit: false,
      productDetailEdit: false,
      srcList: []
    }
  },
  computed: {
    shopProductTypeOptions() {
      if (this.$store.state.dictionary.shopProductType.length === 0) {
        this.$store.dispatch('change_shopProductType')
      }
      return this.$store.state.dictionary.shopProductType
    }
  },
  watch: {
    selectProductCateValue(newValue) {
      if (newValue != null && newValue.length === 2) {
        this.listQuery.productCategoryId = newValue[1]
      } else {
        this.listQuery.productCategoryId = null
      }
    }
  },
  created() {
    this.getList()
    this.getShopNameList()
    this.getBrandList()
    this.getProductCateList()
  },
  methods: {
    parseDate(date) {
      return this.$utils.parseDate(new Date(date))
    },
    showViewer(val) {
      this.srcList = []
      this.srcList.push(val)
    },
    productDetailClose() {
      this.productDetailId = null
      this.productDetailCopy = false
      this.productDetailAudit = false
      this.productDetailEdit = false
      this.productDetailShow = false
      this.getList()
    },
    shopDialogClose() {
      this.shopDetailVisible = false
    },
    openViews(row) {
      this.$api.product.productPerView(row.id).then(res => {
        if (res.data) {
          if (row.beBuy) {
            this.shopDetailUrl = process.env.VUE_APP_WEBSITE_URL + `productDetail.html#/goodsdetail?id=${row.id}&shopId=${row.shopId}`
          } else {
            this.shopDetailUrl = process.env.VUE_APP_WEBSITE_URL + `productDetail.html#/?id=${row.id}&shopId=${row.shopId}`
          }
          this.shopDetailVisible = true
        } else {
          this.$message({
            message: '该商品暂不允许预览',
            type: 'warning',
            duration: 1000
          })
        }
      })
    },
    // 审核通过/不通过
    handleProductVerifyStatus(id, status) {
      if (status === 1) {
        this.$confirm('确定要审核通过吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(() => {
          const params = {
            id,
            status,
            detail: ' '
          }
          this.$api.product.productVerifyStatus(params).then(res => {
            if (res.code === 200) {
              this.$message.success('审核成功')
              this.getList()
            }
          })
        })
      }
      if (status === 2) {
        this.$prompt('请输入审核不通过原因', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(({ value }) => {
          if (!value) {
            this.$message.error('请填写拒绝原因！')
            return
          }
          const params = {
            id,
            status,
            detail: value
          }
          this.$api.product.productVerifyStatus(params).then(res => {
            if (res.code === 200) {
              this.$message.success('操作成功')
              this.getList()
            }
          })
        })
      }
    },
    getProductSkuSp(row, index, name) {
      const arr = JSON.parse(row.spData)
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].key === name) {
          return arr[i].value
        }
      }
      // return arr[index].value
    },
    getList() {
      this.listLoading = true
      // fetchList
      this.$api.goodsAudit.getAuditList(this.listQuery).then(response => {
        this.listLoading = false
        this.list = response.data.list
        this.total = response.data.total
        this.list.forEach(el => {
          el.isExpand = false
        })
        // this.list = response.listData
        // this.total = response.count
      })
    },
    getShopNameList() {
      this.$api.product.getShopname({ pageNum: 1, pageSize: 100 }).then(response => {
        this.ShopNameOptions = []
        const ShopList = response.data.list
        for (let i = 0; i < ShopList.length; i++) {
          this.ShopNameOptions.push({
            label: ShopList[i].shopName,
            value: ShopList[i].id
          })
        }
      })
    },
    getBrandList() {
      this.$api.product.brandList({ pageNum: 1, pageSize: 100 }).then(response => {
        this.brandOptions = []
        const brandList = response.listData
        for (let i = 0; i < brandList.length; i++) {
          this.brandOptions.push({
            label: brandList[i].name,
            value: brandList[i].id
          })
        }
      })
    },
    getProductCateList() {
      this.$api.product.fetchListWithChildren().then(response => {
        const list = response.data
        this.productCateOptions = []
        for (let i = 0; i < list.length; i++) {
          const children = []
          if (list[i].children != null && list[i].children.length > 0) {
            for (let j = 0; j < list[i].children.length; j++) {
              children.push({
                label: list[i].children[j].name,
                value: list[i].children[j].id
              })
            }
          }
          this.productCateOptions.push({
            label: list[i].name,
            value: list[i].id,
            children: children
          })
        }
      })
    },
    handleShowSkuEditDialog(index, row) {
      this.editSkuInfo.dialogVisible = true
      this.editSkuInfo.productId = row.id
      this.editSkuInfo.productSn = row.productSn
      this.editSkuInfo.productAttributeCategoryId = row.productAttributeCategoryId
      this.editSkuInfo.keyword = null
      this.$api.product.skuList(row.id, { keyword: this.editSkuInfo.keyword }).then(response => {
        this.editSkuInfo.stockList = response.listData
      })
      if (row.productAttributeCategoryId != null) {
        this.$api.product.productAttrList(row.productAttributeCategoryId, { type: 0 }).then(response => {
          this.editSkuInfo.productAttr = response.listData
        })
      }
    },
    handleSearchEditSku() {
      this.$api.product
        .fetchSkuStockList(this.editSkuInfo.productId, {
          keyword: this.editSkuInfo.keyword
        })
        .then(response => {
          this.editSkuInfo.stockList = response.listData
        })
    },
    handleEditSkuConfirm() {
      if (this.editSkuInfo.stockList == null || this.editSkuInfo.stockList.length <= 0) {
        this.$message({
          message: '暂无sku信息',
          type: 'warning',
          duration: 1000
        })
        return
      }
      let flag = false
      const reg = /^\d+$/
      this.editSkuInfo.stockList.forEach(item => {
        if (item.stock < 0 || !reg.test(item.stock)) {
          flag = true
        }
      })
      if (flag) {
        this.$message({
          message: '商品库存不能小于0且为整数',
          type: 'warning',
          duration: 1000
        })
        return
      }
      this.$confirm('是否要进行修改', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.product.skuUpdate(this.editSkuInfo.productId, this.editSkuInfo.stockList).then(() => {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000
          })
          this.editSkuInfo.dialogVisible = false
        })
      })
    },
    handleSearchList() {
      this.listQuery.pageNum = 1
      this.getList()
    },
    handleAddProduct() {
      this.$router.push({ path: '/pms/addProduct' })
    },
    handleBatchOperate() {
      if (this.operateType == null) {
        this.$message({
          message: '请选择操作类型',
          type: 'warning',
          duration: 1000
        })
        return
      }
      if (this.multipleSelection == null || this.multipleSelection.length < 1) {
        this.$message({
          message: '请选择要操作的商品',
          type: 'warning',
          duration: 1000
        })
        return
      }
      this.$confirm('是否要进行该批量操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const ids = []
        for (let i = 0; i < this.multipleSelection.length; i++) {
          ids.push(this.multipleSelection[i].id)
        }
        switch (this.operateType) {
          case this.operates[0].value:
            this.updatePublishStatus(1, ids)
            break
          case this.operates[1].value:
            this.updatePublishStatus(0, ids)
            break
          case this.operates[2].value:
            this.updateDeleteStatus(1, ids)
            break
          default:
            break
        }
        // this.getList();
      })
    },
    handleSizeChange(val) {
      this.listQuery.pageNum = 1
      this.listQuery.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.pageNum = val
      this.getList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handlePublishStatusChange(index, row) {
      const ids = []
      ids.push(row.id)
      this.updatePublishStatus(row.publishStatus, ids, index)
    },
    handleResetSearch() {
      this.selectProductCateValue = []
      this.listQuery = Object.assign({}, defaultListQuery)
    },
    handleDelete(index, row) {
      console.log('index:', index)
      this.$confirm('是否要进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const ids = []
        ids.push(row.id)
        this.updateDeleteStatus(1, ids)
      })
    },
    handleUpdateProduct(index, row, type) {
      this.productDetailId = parseInt(row.id)
      this.productDetailCopy = type
      this.productDetailAudit = true
      this.productDetailEdit = true
      this.productDetailShow = true
    },
    handleShowProduct(index, row) {
      console.log('handleShowProduct', row)
    },
    handleShowVerifyDetail(index, row) {
      console.log('handleShowVerifyDetail', row)
    },
    handleShowLog(index, row) {
      console.log('handleShowLog', row)
    },
    getAuditStatus(ids, auditStatus) {
      const params = {
        ids: ids,
        auditStatus: auditStatus
      }
      this.$api.product.getAuditStatus(params).then(response => {
        console.log(response)
        this.getList()
        this.$message({
          message: '修改成功',
          type: 'success',
          duration: 1000
        })
      })
    },
    updatePublishStatus(publishStatus, ids, index) {
      let data = {
        ids: ids,
        publishStatus: publishStatus
      }
      this.$api.product
        .updatePublishStatus(data)
        .then(() => {
          this.getList()
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000
          })
        })
        .catch(() => {
          this.list[index].publishStatus = publishStatus === 1 ? 0 : 1
        })
    },
    updateDeleteStatus(deleteStatus, ids) {
      let data = {
        ids: ids,
        deleteStatus: deleteStatus
      }
      this.$api.product.updateDeleteStatus(data).then(() => {
        this.$message({
          message: '删除成功',
          type: 'success',
          duration: 1000
        })
        this.getList()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.title-area {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pagination-container {
  padding-bottom: 20px;
}
.shop-dialog {
  ::v-deep.el-dialog {
    height: 80vh;
    width: 80vw;
    margin-top: 10vh !important;
  }

  iframe {
    width: 100%;
    height: 90%;
    position: absolute;
    left: 0;
  }
}
</style>

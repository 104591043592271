<template>
  <comments-detail :is-edit="true" />
</template>
<script>
import CommentsDetail from './components/CommentsDetail'
export default {
  name: 'EditComment',
  components: { CommentsDetail }
}
</script>
<style></style>

<template>
  <el-dialog title="选择虚拟用户" append-to-body :visible="selectUserDialogVisible" width="50%" @close="closeDialog">
    <el-table ref="Table" :data="dialogData.list" row-key="id" @selection-change="handleDialogSelectionChange" border>
      <el-table-column type="selection" width="60" align="center" :reserve-selection="true" />
      <el-table-column prop="id" label="ID" width="100" />
      <el-table-column label="用户名称" align="center">
        <template slot-scope="scope">{{ scope.row.name }}</template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination background layout="prev, pager, next" :current-page.sync="dialogData.listQuery.page" :page-size="dialogData.listQuery.count" :page-sizes="[5, 10, 20, 30, 50, 100]" :total="dialogData.total" @size-change="handleDialogSizeChange" @current-change="handleDialogCurrentChange" />
    </div>
    <div style="clear: both"></div>
    <div slot="footer">
      <el-button size="small" @click="closeDialog">取 消</el-button>
      <el-button size="small" type="primary" @click="handleSelectDialogConfirm()">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    selectUserDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogData: {
        list: [],
        total: null,
        multipleSelection: null,
        listQuery: {
          publishStatus: 1,
          keyword: null,
          page: 1,
          count: 5
        }
      }
    }
  },
  created() {
    this.getDialogList()
  },
  methods: {
    closeDialog() {
      this.$emit('closeProductDialog')
    },
    handleSelectDialogConfirm() {
      this.$emit('addUserList', this.dialogData.multipleSelection)
      this.closeDialog()
    },
    handleSelectSearch() {
      this.getDialogList()
    },
    handleDialogSizeChange(val) {
      this.dialogData.listQuery.page = 1
      this.dialogData.listQuery.count = val
      this.getDialogList()
    },
    handleDialogCurrentChange(val) {
      this.dialogData.listQuery.page = val
      this.getDialogList()
    },
    handleDialogSelectionChange(val) {
      if (val.length > 1) {
        this.$refs.Table.clearSelection()
        this.$refs.Table.toggleRowSelection(val.pop())
      }
      this.dialogData.multipleSelection = val
    },
    getDialogList() {
      this.$api.comment.fetchRobotList(this.dialogData.listQuery).then(response => {
        this.dialogData.list = response.listData
        this.dialogData.total = response.count
      })
    }
  }
}
</script>

<style lang="sass" scoped></style>

import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
 import app from "./modules/app";
 import user from "./modules/user";
import permission from "./modules/permission";
import dictionary from "./modules/dictionary";
import settings from "./modules/settings";
import tagsView from "./modules/tagsView";

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
     app,
    user,
    permission,
    dictionary,
    settings,
    tagsView,
  },
  getters
})

export default store

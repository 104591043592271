<template>
  <el-dialog title="选择商品" append-to-body :visible="visible" width="50%" @close="closeDialog">
    <el-input v-model="dialogData.listQuery.keyword" style="width: 250px; margin-bottom: 20px" size="small" placeholder="商品名称搜索">
      <el-button slot="append" icon="el-icon-search" @click="handleSelectSearch()" />
    </el-input>
    <el-table :data="dialogData.list" row-key="id" border @selection-change="handleDialogSelectionChange">
      <el-table-column type="selection" width="60" align="center" :selectable="checkboxTable" :reserve-selection="true" />
      <el-table-column prop="id" label="ID" width="100" />
      <el-table-column label="商品名称" align="center">
        <template slot-scope="scope">{{ scope.row.name }}</template>
      </el-table-column>
      <el-table-column label="价格" width="120" align="center">
        <template slot-scope="scope">￥{{ scope.row.price }}</template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination background layout="prev, pager, next" :current-page.sync="dialogData.listQuery.pageNum" :page-size="dialogData.listQuery.pageSize" :page-sizes="[5, 10, 20, 30, 50, 100]" :total="dialogData.total" @size-change="handleDialogSizeChange" @current-change="handleDialogCurrentChange" />
    </div>
    <div style="clear: both"></div>
    <div slot="footer">
      <el-button size="small" @click="closeDialog">取 消</el-button>
      <el-button size="small" type="primary" @click="handleSelectDialogConfirm()">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    selectedGoodsIds: {
      type: Array,
      default: () => []
    },
    selectedGoodsSkuIds: {
      type: Array,
      default: () => []
    },
    activeName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: true,
      dialogData: {
        list: [],
        total: null,
        multipleSelection: null,
        listQuery: {
          auditStatus: 1,
          publishStatus: 1,
          keyword: null,
          beBuy: 1,
          pageNum: 1,
          pageSize: 5
        }
      }
    }
  },
  created() {
    console.log(222, this.selectedGoodsSkuIds)
    this.getDialogList()
  },
  methods: {
    closeDialog() {
      this.$emit('closeProductDialog')
    },
    handleSelectDialogConfirm() {
      this.$emit('addProductList', this.dialogData.multipleSelection, this.activeName)
      this.closeDialog()
    },
    handleSelectSearch() {
      this.getDialogList()
    },
    handleDialogSizeChange(val) {
      this.dialogData.listQuery.pageNum = 1
      this.dialogData.listQuery.pageSize = val
      this.getDialogList()
    },
    handleDialogCurrentChange(val) {
      this.dialogData.listQuery.pageNum = val
      this.getDialogList()
    },
    handleDialogSelectionChange(val) {
      this.dialogData.multipleSelection = val
    },
    getDialogList() {
      this.$api.product.productList(this.dialogData.listQuery).then(response => {
        this.dialogData.list = response.listData
        this.dialogData.total = response.count
      })
    },
    // 禁用某一行
    checkboxTable(row) {
      if (this.activeName === 'second') {
        if (this.selectedGoodsIds.includes(row.id)) {
          return false
        } else {
          return true
        }
      } else if (this.activeName === 'third') {
        if (this.selectedGoodsSkuIds.includes(row.id)) {
          return false
        } else {
          return true
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped></style>

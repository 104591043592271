<template>
  <div class="app-container">
    <el-card class="header-card" shadow="never">
      <div>
        <el-form :inline="true" :model="listQuery" size="small" label-width="110px">
          <el-row>
            <el-col :span="6">
              <el-form-item label="输入搜索">
                <el-input v-model="listQuery.keyword" placeholder="商品名称" clearable />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="商品分类">
                <el-cascader v-model="selectProductCateValue" clearable :options="productCateOptions" filterable />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="商品品牌">
                <el-select v-model="listQuery.brandId" placeholder="请选择品牌" clearable>
                  <el-option v-for="item in brandOptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="关联专题" prop="type">
                <el-select v-model="listQuery.subjectId" placeholder="请选择关联专题" clearable>
                  <el-option v-for="item in subjectList" :key="item.id" :label="item.title" :value="item.id" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="上架状态">
                <el-select v-model="listQuery.publishStatus" placeholder="全部" clearable>
                  <el-option v-for="item in publishStatusOptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-if="isAdmin" :span="6">
              <el-form-item label="店铺搜索">
                <el-select v-model="listQuery.shopIds" placeholder="请选择店铺" filterable clearable>
                  <el-option v-for="item in ShopNameOptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-if="isAdmin" :span="6">
              <el-form-item label="是否售卖">
                <el-select v-model="listQuery.beBuy" placeholder="请选择" filterable clearable>
                  <el-option v-for="item in beBuyOptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <el-row class="search-area-btn">
          <div class="left">
            <el-button class="btn-add" type="primary" size="mini" @click="handleAddProduct()"> 添加 </el-button>
            <el-button v-if="isManageMentRole" type="primary" size="mini" @click="exportShopAction">批量导出</el-button>

            <el-select v-model="operateType" size="small" placeholder="批量操作">
              <el-option v-for="item in operates" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <el-button style="margin-left: 20px" class="search-button" type="primary" size="small" @click="handleBatchOperate()"> 确定 </el-button>
          </div>
          <div class="center">
            <el-button type="primary" size="small" @click="handleSearchList()">查询</el-button>
            <el-button size="small" @click="handleResetSearch()"> 重置 </el-button>
          </div>
        </el-row>
      </div>
    </el-card>
    <div class="table-container">
      <el-table v-loading="listLoading" ref="productTable" :data="tableData" :row-class-name="rowClass" @row-click="clickRow" style="width: 100%" border @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="60" align="center" />
        <el-table-column label="编号" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column label="商品图片" width="120" align="center">
          <template slot-scope="scope">
            <div class="image__preview">
              <el-image style="height: 100px; width: 100px" :src="scope.row.pic" :preview-src-list="srcList" @click="showViewer(scope.row.pic)" />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商品名称" align="center" width="240">
          <template slot-scope="scope">
            <p>{{ scope.row.name }}</p>
            <p>品牌：{{ scope.row.brandName }}</p>
          </template>
        </el-table-column>
        <el-table-column label="所属店铺" align="center" width="120">
          <template slot-scope="scope">
            <p>{{ scope.row.shopName }}</p>
          </template>
        </el-table-column>
        <el-table-column label="价格" width="120" align="center">
          <template slot-scope="scope">
            <p>价格：￥{{ scope.row.price }}</p>
          </template>
        </el-table-column>
        <el-table-column label="标签" width="160" align="center">
          <template slot-scope="scope">
            <p>
              <span style="display: inline-block; text-align: left; width: 85px"> 上架商城： </span>
              <el-switch v-model="scope.row.publishStatus" :active-value="1" :inactive-value="0" :disabled="scope.row.auditStatus === 0 || scope.row.auditStatus === 2" @change="handlePublishStatusChange(scope.$index, scope.row)" />
            </p>
            <p>
              <span style="display: inline-block; text-align: left; width: 85px"> 新品： </span>
              <el-switch v-model="scope.row.newStatus" :active-value="1" :inactive-value="0" :disabled="scope.row.publishStatus == 1 || scope.row.auditStatus === 0" @change="handleNewStatusChange(scope.$index, scope.row)" />
            </p>
            <p>
              <span style="display: inline-block; text-align: left; width: 85px"> 推荐： </span>
              <el-switch v-model="scope.row.recommandStatus" :active-value="1" :inactive-value="0" :disabled="scope.row.publishStatus == 1 || scope.row.auditStatus === 0" @change="handleRecommendStatusChange(scope.$index, scope.row)" />
            </p>
          </template>
        </el-table-column>
        <el-table-column label="设置运费" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.tmpName }}</template>
        </el-table-column>
        <!-- publishStatus 0下架1上架 -->
        <!-- <el-table-column label="商品状态" width="160" align="center">
          <template slot-scope="scope">
            <p v-if="String(scope.row.auditStatus) === '1'">
              <span style="display: inline-block; text-align: left; width: 85px">商品上架：</span>
              <el-switch v-model="scope.row.publishStatus" :active-value="1" :inactive-value="0" @change="handlePublishStatusChange(scope.$index, scope.row)" />
            </p>
            <p v-else>审核中</p>
          </template>
        </el-table-column> -->
        <el-table-column label="商品审核状态" width="250" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.auditStatus === -1">
              <span class="status-dots" style="background-color: #bfbfbf"></span>
              未申请
            </span>
            <span v-if="scope.row.auditStatus === 0">
              <span class="status-dots" style="background-color: #1890ff"></span>
              商品审核中
            </span>
            <span v-if="scope.row.auditStatus === 1">
              <span class="status-dots" style="background-color: #52c41a"></span>
              通过审核
              <div style="font-size: 13px">审核时间：{{ $utils.parseDate(new Date(scope.row.auditTime)) }}</div>
            </span>
            <span v-if="scope.row.auditStatus === 2">
              <span class="status-dots" style="background-color: #ff4949"></span>
              审核拒绝
            </span>
          </template>
        </el-table-column>

        <el-table-column label="商品拒绝原因" width="200" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.auditRejectedReason || '无' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="排序" width="100" align="center">
          <template slot-scope="scope">
            <div v-if="$_has(['mall:list:online:sort'])">
              <span v-if="!isShowEditSortArr[scope.$index]" style="display: block; cursor: pointer" @click="handleEditSort(scope.row.sort, scope.$index)">{{ scope.row.sort }}</span>
              <el-input v-else v-model="scope.row.sort" placeholder="请输入内容" @blur="confirmEditSort(scope.row.sort, scope.row.id, scope.$index)" />
            </div>
            <span v-else style="display: block; cursor: pointer">{{ scope.row.sort }}</span>
          </template>
        </el-table-column>
        <el-table-column label="SKU库存" width="100" align="center">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" :disabled="scope.row.publishStatus == 1" circle @click="handleShowSkuEditDialog(scope.$index, scope.row)" />
          </template>
        </el-table-column>
        <el-table-column label="销量" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.sale }}</template>
        </el-table-column>
        <el-table-column label="不限购区域" width="200" align="center">
          <template slot-scope="scope">
            <div v-if="!scope.row.isExpand" class="title-area">
              {{ scope.row.areaName }}
            </div>
            <div v-if="scope.row.isExpand">
              {{ scope.row.areaName }}
            </div>
            <el-button v-if="scope.row.areaName && !scope.row.isExpand" type="primary" size="mini" @click="expandCity(scope.$index, scope.row)"> 展开 </el-button>
            <el-button v-if="scope.row.isExpand" type="primary" size="mini" @click="retractCity(scope.$index, scope.row)"> 收起 </el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="320" align="center">
          <template slot-scope="scope">
            <p>
              <el-button size="mini" @click="openViews(scope.row)">预览 </el-button>
              <el-button v-if="scope.row.publishStatus !== 1 && scope.row.auditStatus !== 0" size="mini" @click="handleUpdateProduct(scope.$index, scope.row, false)">编辑 </el-button>
              <el-button v-if="scope.row.publishStatus == 1 || scope.row.auditStatus === 0" size="mini" @click="handleUpdateProduct(scope.$index, scope.row, false)">查看 </el-button>
              <el-button size="mini" type="danger" :disabled="scope.row.publishStatus == 1 || scope.row.auditStatus === 0" @click="handleDelete(scope.$index, scope.row)">删除 </el-button>
              <el-button :disabled="scope.row.auditStatus === 0 || scope.row.auditStatus === 2" size="mini" type="primary" @click="handleUpdateProduct(scope.$index, scope.row, true)">复制 </el-button>
            </p>
            <p>
              <el-button size="mini" type="primary" :disabled="scope.row.publishStatus == 0 || scope.row.auditStatus === 2" @click="handleSynch(scope.row)"> 同步es </el-button>
              <el-button :disabled="scope.row.auditStatus === 0 || scope.row.auditStatus === 2" size="mini" type="primary" @click="handleSetFreight(scope.$index, scope.row)"> 设置运费 </el-button>
              <el-button :disabled="scope.row.auditStatus === 0 || scope.row.auditStatus === 2" size="mini" type="primary" @click="handleSetArea(scope.$index, scope.row)"> 设置不限购区域 </el-button>
            </p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination background layout="total, sizes,prev, pager, next,jumper" :page-size="listQuery.pageSize" :page-sizes="[5, 10, 15]" :current-page.sync="listQuery.pageNum" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
    <el-dialog title="编辑货品信息" append-to-body :visible.sync="editSkuInfo.dialogVisible" width="800px">
      <span>商品货号：</span>
      <span>{{ editSkuInfo.productSn }}</span>
      <el-table style="width: 100%; margin-top: 20px" :data="editSkuInfo.stockList" border>
        <el-table-column v-for="(item, index) in editSkuInfo.productAttr" :key="item.id" :label="item.name" align="center">
          <template slot-scope="scope">
            {{ getProductSkuSp(scope.row, index, item.name) }}
          </template>
        </el-table-column>
        <el-table-column label="销售价格" width="120" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.price" :disabled="true" />
          </template>
        </el-table-column>
        <el-table-column label="商品库存" width="120" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.stock" min="1" />
          </template>
        </el-table-column>
        <el-table-column label="库存预警值" width="100" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.lowStock" />
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editSkuInfo.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleEditSkuConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <AreaDialog v-if="selectAreaDialogVisible" :user-status="userStatus" :select-area-dialog-visible="selectAreaDialogVisible" @closeAreaDialog="closeAreaDialog" @addAreaInfo="addAreaInfo" />
    <FreightDialog v-if="selectFreightDialogVisible" :user-status="userStatus" :select-freight-dialog-visible="selectFreightDialogVisible" :freight-id="freightId" @closeFreightDialog="closeFreightDialog" @addFreightInfo="addFreightInfo" />
    <el-dialog v-if="shopDetailVisible" title="商品详情预览" class="shop-dialog" :visible.sync="shopDetailVisible" :before-close="shopDialogClose">
      <iframe width="100%" frameborder="0" class="shop-iframe" :src="shopDetailUrl"></iframe>
    </el-dialog>
    <productDetail v-if="productDetailShow" :id="productDetailId" :copy="productDetailCopy" :is-edit="productDetailEdit" :is-audit-status="isAuditStatus" :user-status="userStatus" @close="productDetailClose" />
  </div>
</template>
<script>
import tableWrapper from '@/mixin/tableWrapper'
import AreaDialog from './components/AreaDialog'
import FreightDialog from './components/FreightDialog'
import productDetail from './components/ProductDetail'
import { getStorage } from '@/utils/storage'
import { mapState } from 'vuex'
import store from '@/store'

const defaultListQuery = {
  keyword: null,
  pageNum: 1,
  pageSize: 5,
  publishStatus: null,
  platform: null,
  verifyStatus: null,
  productSn: null,
  productCategoryId: null,
  brandId: null,
  shopIds: null,
  flag: null,
  isExemption: null,
  expositionStatus: 2,
  subjectId: '',
  sort: '1',
  beBuy: ''
}
var that = null
export default {
  name: 'ProductList',
  components: {
    AreaDialog,
    FreightDialog,
    productDetail
  },
  mixins: [tableWrapper],
  data() {
    return {
      isAdmin: false,
      shopDetailVisible: false,
      productDetailShow: false,
      productDetailId: null,
      productDetailCopy: false,
      productDetailEdit: false,
      shopDetailUrl: '',
      selectRow: [],
      editSkuInfo: {
        dialogVisible: false,
        productId: null,
        productSn: '',
        productAttributeCategoryId: null,
        stockList: [],
        productAttr: [],
        keyword: null
      },
      operates: [
        {
          label: '商品上架',
          value: 'publishOn'
        },
        {
          label: '商品下架',
          value: 'publishOff'
        },
        {
          label: '移入回收站',
          value: 'recycle'
        }
      ],
      operateType: null,
      listQuery: Object.assign({}, defaultListQuery),
      tableData: [],
      userStatus: 0,
      total: null,
      listLoading: true,
      selectProductCateValue: null,
      multipleSelection: [],
      productCateOptions: [],
      brandOptions: [],
      ShopNameOptions: [],
      publishStatusOptions: [
        {
          value: 1,
          label: '上架'
        },
        {
          value: 0,
          label: '下架'
        }
      ],
      verifyStatusOptions: [
        {
          value: 1,
          label: '审核通过'
        },
        {
          value: 0,
          label: '未审核'
        }
      ],
      beBuyOptions: [
        {
          value: '1',
          label: '售卖商品'
        },
        {
          value: '0',
          label: '展示商品'
        }
      ],
      currentSelectdProductId: null, // 当前选中的商品id
      selectAreaDialogVisible: false, // 是否展示不限购区域弹框
      selectFreightDialogVisible: false, // 是否展示运费弹框
      currentSelectedAreaInfo: null, // 当前选中的不限购地区信息
      currentSelectedFreightInfo: null, // 当前选中的运费模板
      showPayVisible: false, // 是否展示缴纳保证金弹框
      shopInfo: null, // 商户基础信息
      freightId: null,
      srcList: [],
      subjectList: [],
      isAuditStatus: true,
      isShowEditSortArr: []
    }
  },
  computed: {
    ...mapState({
      shopProductTypeOptions: state => state.dictionary.shopProductType,
      isManageMentRole: state => state.user.userRole
    })
  },
  watch: {
    selectProductCateValue(newValue) {
      if (newValue != null && newValue.length === 2) {
        this.listQuery.productCategoryId = newValue[1]
      } else {
        this.listQuery.productCategoryId = null
      }
    },
    multipleSelection(data) {
      this.selectRow = []
      if (data.length > 0) {
        data.forEach(item => {
          this.selectRow.push(this.$refs.productTable.data.indexOf(item))
        })
      }
    }
  },
  created() {
    that = this
    this.isAdmin = getStorage('admin') === '1' ? true : false
    this.getList()
    this.getShopNameList()
    this.getBrandList()
    this.getProductCateList()
    this.getDictionaryList()
    this.getsubjectList()
  },
  // eslint-disable-next-line vue/order-in-components
  filters: {
    verifyStatusFilter(value) {
      if (value === 1) {
        return '审核通过'
      } else {
        return '未审核'
      }
    }
  },
  methods: {
    // 设置不限购区域
    handleSetArea(ndex, row) {
      this.currentSelectdProductId = row.id
      this.selectAreaDialogVisible = true
    },
    // 设置不限购区域
    handleSetFreight(index, row) {
      this.currentSelectdProductId = row.id
      this.selectFreightDialogVisible = true
    },
    closeAreaDialog() {
      this.selectAreaDialogVisible = false
    },
    closeFreightDialog() {
      this.selectFreightDialogVisible = false
    },
    addAreaInfo(value) {
      this.currentSelectedAreaInfo = value
      this.updateGoodsArea()
    },
    addFreightInfo(value) {
      this.currentSelectedFreightInfo = value
      this.updateGoodsFreight()
    },
    // 批量
    handleBatchArea() {
      if (this.multipleSelection == null || this.multipleSelection.length < 1) {
        this.$message({
          message: '请选择要操作的商品',
          type: 'warning',
          duration: 1000
        })
        return
      }
      this.selectAreaDialogVisible = true
    },
    // 批量
    handleBatchFreight() {
      if (this.multipleSelection == null || this.multipleSelection.length < 1) {
        this.$message({
          message: '请选择要操作的商品',
          type: 'warning',
          duration: 1000
        })
        return
      }
      this.selectFreightDialogVisible = true
    },
    // 批量设置不限购区域
    updateGoodsArea() {
      let data = {}
      if (this.currentSelectdProductId) {
        data = {
          flashAreaId: this.currentSelectedAreaInfo.tmpName,
          flashAreaName: this.currentSelectedAreaInfo.areaList,
          ids: this.currentSelectdProductId
        }
      } else {
        const ids = []
        if (this.multipleSelection && this.multipleSelection.length) {
          this.multipleSelection.forEach(el => {
            ids.push(el.id)
          })
        }
        data = {
          flashAreaId: this.currentSelectedAreaInfo.tmpName,
          flashAreaName: this.currentSelectedAreaInfo.areaList,
          ids: ids.join(',')
        }
      }
      this.$api.product.setLimitArea(data).then(res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '设置成功'
          })
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    // 批量设置运费模板
    updateGoodsFreight() {
      let data = {}
      if (this.currentSelectdProductId) {
        data = {
          freightId: this.currentSelectedFreightInfo.freightId,
          freightName: this.currentSelectedFreightInfo.freightName,
          ids: this.currentSelectdProductId,
          isExemption: 1
        }
      } else {
        const ids = []
        if (this.multipleSelection && this.multipleSelection.length) {
          this.multipleSelection.forEach(el => {
            ids.push(el.id)
          })
        }
        data = {
          freightId: this.currentSelectedFreightInfo.freightId,
          freightName: this.currentSelectedFreightInfo.freightName,
          ids: ids.join(','),
          isExemption: 1
        }
      }
      this.$api.product.setFreight(data).then(res => {
        if (res.code === 200) {
          this.selectFreightDialogVisible = false
          this.$message({
            type: 'success',
            message: '设置成功'
          })
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    // 解除不限购区域模板
    removeBatchArea() {
      if (this.multipleSelection == null || this.multipleSelection.length < 1) {
        this.$message({
          message: '请选择要操作的商品',
          type: 'warning',
          duration: 1000
        })
        return
      }
      const ids = []
      if (this.multipleSelection && this.multipleSelection.length) {
        this.multipleSelection.forEach(el => {
          ids.push(el.id)
        })
      }
      const data = {
        ids: ids.join(',')
      }
      this.$api.product.relieveFlashArea(data).then(res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '解除不限购区域成功'
          })
          this.getList()
        }
      })
    },
    // 解除运费模板
    removeBatchFreight() {
      if (this.multipleSelection == null || this.multipleSelection.length < 1) {
        this.$message({
          message: '请选择要操作的商品',
          type: 'warning',
          duration: 1000
        })
        return
      }
      const ids = []
      if (this.multipleSelection && this.multipleSelection.length) {
        this.multipleSelection.forEach(el => {
          ids.push(el.id)
        })
      }
      const data = {
        ids: ids.join(',')
      }
      this.$api.product.relieveFreight(data).then(res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '解除运费区域成功'
          })
          this.getList()
        }
      })
    },
    // 收起
    retractCity(index, row) {
      this.list.forEach(el => {
        if (el.id === row.id) {
          el.isExpand = false
        }
      })
      this.list = JSON.parse(JSON.stringify(this.list))
    },
    // 展开
    expandCity(index, row) {
      this.list.forEach(el => {
        if (el.id === row.id) {
          el.isExpand = true
        }
      })
      this.list = JSON.parse(JSON.stringify(this.list))
    },
    getsubjectList() {
      const params = {
        pageNum: 1,
        pageSize: 9999,
        use: 2
      }
      this.$api.product.subjectListAll(params).then(res => {
        if (res.code === 200) {
          this.subjectList = res.listData
        }
      })
    },
    handleEditSort(val, index) {
      if (this.isShowEditSortArr.indexOf(true) !== -1) {
        return false
      }
      this.isShowEditSortArr.splice(index, 1, true)
      console.log(this.isShowEditSortArr, '3224435')
    },
    confirmEditSort(sort, id, index) {
      this.$confirm('是否要进行修改', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const params = {
            sort,
            id,
            detail: ' '
          }
          this.$api.product.mustUpdateProduct(params).then(response => {
            if (response.code === 200) {
              this.$message({
                type: 'success',
                message: '修改成功',
                duration: 1000
              })
              this.getList()
              this.isShowEditSortArr.splice(index, 1, false)
            }
          })
        })
        .catch(() => {
          this.isShowEditSortArr.splice(index, 1, false)
          this.getList()
        })
    },
    handleboLanHuiStatusChange(expositionStatus, row) {
      return new Promise(resolve => {
        let data = {
          id: row.id,
          expositionStatus: expositionStatus
        }
        that.$api.product.updateExpositionStatus(data).then(() => {
          if (expositionStatus === 1) {
            that.$message({
              message: '博览会下架成功！',
              type: 'success',
              duration: 1000
            })
          } else {
            that.$message({
              message: '博览会上架成功！',
              type: 'success',
              duration: 1000
            })
          }
          //  else {
          //   that.$message({
          //     message: '博览会审核通过成功！',
          //     type: 'success',
          //     duration: 1000
          //   })
          // }
          resolve()
        })
        // if (row.publishStatus === 0) {
        //   this.$message({
        //     message: '请先上架商品'
        //   })
        //   return
        // } else {
        //   this.updateExpositionStatus(index, row.id)
        // }
      })
    },
    updateExpositionStatus(expositionStatus, ids) {
      let data = {
        id: ids,
        expositionStatus: expositionStatus
      }
      this.$api.product.updateExpositionStatus(data).then(() => {
        this.getList()
        if (expositionStatus === 1) {
          this.$message({
            message: '等待审核通过即可上架博览会！',
            type: 'success'
          })
        } else if (expositionStatus === 0) {
          this.$message({
            message: '博览会下架成功！',
            type: 'success'
          })
        } else {
          this.$message({
            message: '博览会审核通过成功！',
            type: 'success'
          })
        }
      })
    },
    rowClass({ rowIndex }) {
      if (this.selectRow.includes(rowIndex)) {
        return 'selecleRowColor'
      }
    },
    clickRow(row) {
      this.$refs.productTable.toggleRowSelection(row)
    },
    showViewer(val) {
      this.srcList = []
      this.srcList.push(val)
    },
    productDetailClose() {
      this.productDetailId = null
      this.productDetailCopy = false
      this.productDetailEdit = false
      this.productDetailShow = false
      this.getList()
    },
    shopDialogClose() {
      this.shopDetailVisible = false
    },
    openViews(row) {
      this.$api.product.productPerView(row.id).then(res => {
        if (res.data) {
          if (row.beBuy) {
            this.shopDetailUrl = process.env.VUE_APP_WEBSITE_URL + `productDetail.html#/goodsdetail?id=${row.id}&shopId=${row.shopId}`
          } else {
            this.shopDetailUrl = process.env.VUE_APP_WEBSITE_URL + `productDetail.html#/?id=${row.id}&shopId=${row.shopId}`
          }
          this.shopDetailVisible = true
        } else {
          this.$message({
            message: '该商品暂不允许预览',
            type: 'warning',
            duration: 1000
          })
        }
      })
    },
    // 批量导出
    exportShopAction() {
      this.listLoading = true
      var paramsValue = this.listQuery
      delete paramsValue.pageNum
      delete paramsValue.pageSize
      this.$api.product
        .exportShop(this.listQuery)
        .then(() => {
          this.listLoading = false
          this.$confirm('导出文件正在生成中，是否前去下载页面查看？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'success'
          }).then(() => {
            this.$router.push('/reportcen/exportdown')
          })
          // const blob = new Blob([res], {
          //   type: 'application/vnd.ms-excel, charset=utf-8'
          // })
          // const fileName = '新印商品列表'
          // // const objectUrl = URL.createObjectURL(blob)
          // // window.location.href = objectUrl
          // if (window.navigator.msSaveOrOpenBlob) {
          //   // 兼容ie
          //   window.navigator.msSaveBlob(blob, fileName)
          // } else {
          //   const downloadElement = document.createElement('a')
          //   const href = window.URL.createObjectURL(blob) // 创建下载的链接
          //   downloadElement.href = href

          //   downloadElement.download = fileName + '.xls' // 下载后文件名

          //   document.body.appendChild(downloadElement)
          //   // 此写法兼容火狐
          //   const evt = document.createEvent('MouseEvents')
          //   evt.initEvent('click', false, false)
          //   downloadElement.dispatchEvent(evt)

          //   document.body.removeChild(downloadElement)
          //   this.exportloading = false
          // }
          // this.listLoading = false
        })
        .catch(() => {
          this.listLoading = false
        })
    },

    getProductSkuSp(row, index, name) {
      // if (index === 0) {
      //   return row.sp1;
      // } else if (index === 1) {
      //   return row.sp2;
      // } else {
      //   return row.sp3;
      // }
      const arr = JSON.parse(row.spData)
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].key === name) {
          return arr[i].value
        }
      }
      // return arr[index].value
    },
    async getDictionaryList() {
      await store.dispatch('change_shopProductType')
    },
    getList() {
      this.listLoading = true
      // fetchList
      this.isShowEditSortArr = []
      this.$api.product.getProductList(this.listQuery).then(response => {
        this.listLoading = false
        this.tableData = response.data ? response.data.list : []
        this.UserStatus = this.tableData && this.tableData.length ? this.tableData[0].status : ''
        this.total = response.data.total
        this.tableData.forEach(el => {
          el.isExpand = false
          this.isShowEditSortArr.push(false)
        })
      })
    },
    getBrandList() {
      this.$api.product.brandList({ pageNum: 1, pageSize: 100 }).then(response => {
        this.brandOptions = []
        const brandList = response.listData
        for (let i = 0; i < brandList.length; i++) {
          this.brandOptions.push({
            label: brandList[i].name,
            value: brandList[i].id
          })
        }
      })
    },
    getShopNameList() {
      this.$api.product.getShopname({ pageNum: 1, pageSize: 100 }).then(response => {
        this.ShopNameOptions = []
        const ShopList = response.data.list
        for (let i = 0; i < ShopList.length; i++) {
          this.ShopNameOptions.push({
            label: ShopList[i].shopName,
            value: ShopList[i].id
          })
        }
      })
    },
    getProductCateList() {
      this.$api.product.fetchListWithChildren().then(response => {
        const list = response.data
        this.productCateOptions = []
        for (let i = 0; i < list.length; i++) {
          const children = []
          if (list[i].children != null && list[i].children.length > 0) {
            for (let j = 0; j < list[i].children.length; j++) {
              children.push({
                label: list[i].children[j].name,
                value: list[i].children[j].id
              })
            }
          }
          this.productCateOptions.push({
            label: list[i].name,
            value: list[i].id,
            children: children
          })
        }
      })
    },
    handleSearchEditSku() {
      this.$api.product
        .skuList(this.editSkuInfo.productId, {
          keyword: this.editSkuInfo.keyword
        })
        .then(response => {
          this.editSkuInfo.stockList = response.listData
        })
    },
    handleEditSkuConfirm() {
      if (this.editSkuInfo.stockList == null || this.editSkuInfo.stockList.length <= 0) {
        this.$message({
          message: '暂无sku信息',
          type: 'warning',
          duration: 1000
        })
        return
      }
      let flag = false
      const reg = /^\d+$/
      this.editSkuInfo.stockList.forEach(item => {
        if (item.stock < 0 || !reg.test(item.stock)) {
          flag = true
        }
      })
      if (flag) {
        this.$message({
          message: '商品库存不能小于0且为整数',
          type: 'warning',
          duration: 1000
        })
        return
      }
      this.$confirm('是否要进行修改', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.product.skuUpdate(this.editSkuInfo.productId, this.editSkuInfo.stockList).then(() => {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000
          })
          this.editSkuInfo.dialogVisible = false
        })
      })
    },
    handleSynch(row) {
      this.$confirm('是否同步es', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.product.syncInfoProduct(row.id).then(() => {
          this.$message({
            message: '同步成功',
            type: 'success',
            duration: 1000
          })
          // this.editSkuInfo.dialogVisible = false
        })
      })
    },
    handleSearchList() {
      this.listQuery.pageNum = 1
      this.getList()
    },
    handleAddProduct() {
      this.isAuditStatus = true
      this.productDetailId = null
      this.productDetailCopy = false
      this.productDetailEdit = false
      this.productDetailShow = true
    },
    handleBatchOperate() {
      if (this.operateType == null) {
        this.$message({
          message: '请选择操作类型',
          type: 'warning'
        })
        return
      }
      if (this.multipleSelection == null || this.multipleSelection.length < 1) {
        this.$message({
          message: '请选择要操作的商品',
          type: 'warning'
        })
        return
      }

      this.$confirm('是否要进行该批量操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const ids = []
        for (let i = 0; i < this.multipleSelection.length; i++) {
          ids.push(this.multipleSelection[i].id)
        }
        switch (this.operateType) {
          case this.operates[0].value:
            this.updatePublishStatus(1, ids)
            break
          case this.operates[1].value:
            this.updatePublishStatus(0, ids)
            break
          case this.operates[2].value:
            this.updateDeleteStatus(1, ids)
            break
          default:
            break
        }
        // this.getList();
      })
    },
    handleSizeChange(val) {
      this.listQuery.pageNum = 1
      this.listQuery.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.pageNum = val
      this.getList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handlePublishStatusChange(index, row) {
      const ids = []
      ids.push(row.id)
      if (String(row.publishStatus) === '0') {
        this.handleboLanHuiStatusChange(1, row).then(() => {
          this.updatePublishStatus(row.publishStatus, ids, index, row).then(() => {
            that.getList()
          })
        })
        // Promise.all(this.updatePublishStatus(row.publishStatus, ids, index, row), this.handleboLanHuiStatusChange(1, row))
      } else {
        this.updatePublishStatus(row.publishStatus, ids, index, row).then(() => {
          this.handleboLanHuiStatusChange(2, row).then(() => {
            that.getList()
          })
        })
        // Promise.all(this.handleboLanHuiStatusChange(1, row), this.updatePublishStatus(row.publishStatus, ids, index, row))
      }
    },
    handleResetSearch() {
      this.selectProductCateValue = []
      this.listQuery = Object.assign({}, defaultListQuery)
      this.getList()
    },
    handleDelete(index, row) {
      console.log('index:', index)
      this.$confirm('是否要进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const ids = []
        ids.push(row.id)
        this.updateDeleteStatus(1, ids)
      })
    },
    handleUpdateProduct(index, row, type) {
      if (row.auditStatus === 0) {
        this.isAuditStatus = false
      } else if (row.auditStatus === 1) {
        this.isAuditStatus = true
      } else if (row.auditStatus === 2) {
        this.isAuditStatus = true
      }
      console.log(row, 'jsdoiafjsofrgi驾驶技术')
      this.productDetailId = parseInt(row.id)
      this.productDetailCopy = type
      this.productDetailEdit = true
      this.productDetailShow = true
    },
    handleShowProduct(index, row) {
      console.log('handleShowProduct', row)
    },
    handleShowVerifyDetail(index, row) {
      console.log('handleShowVerifyDetail', row)
    },
    handleShowLog(index, row) {
      console.log('handleShowLog', row)
    },
    updatePublishStatus(publishStatus, ids, index) {
      return new Promise((resolve, reject) => {
        let data = {
          ids: ids,
          publishStatus: publishStatus
        }
        that.$api.product
          .updatePublishStatus(data)
          .then(() => {
            resolve()
            // this.handleboLanHuiStatusChange(1, row)
          })
          .catch(() => {
            that.tableData[index].publishStatus = publishStatus === 1 ? 0 : 1
            reject()
          })
      })
    },
    updateDeleteStatus(deleteStatus, ids) {
      let data = {
        ids: ids,
        deleteStatus: deleteStatus
      }
      this.$api.product.updateDeleteStatus(data).then(() => {
        this.$message({
          message: '删除成功',
          type: 'success',
          duration: 1000
        })
        this.getList()
      })
    },
    getByIdAndList() {
      // getUserINfo({ userId: this.$store.state.user.userId }).then(res => {
      //   console.log(res, '点击添加商品查看')
      //   if (res.data) {
      //     if (res.data.umsShop) {
      //       this.shopInfo = res.data.umsShop
      //       this.showPayVisible = true // 展示缴纳保证金弹框
      //     } else {
      //       this.productDetailId = null
      //       this.productDetailCopy = null
      //       this.productDetailEdit = false
      //       this.productDetailShow = true
      //     }
      //   } else {
      //     this.productDetailId = null
      //     this.productDetailCopy = null
      //     this.productDetailEdit = false
      //     this.productDetailShow = true
      //   }
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.header-card {
  .el-form {
    .el-input {
      width: 213px;
    }
  }
}

.title-area {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.status-dots {
  margin-right: 8px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
}

.shop-dialog {
  ::v-deep.el-dialog {
    height: 80vh;
    width: 80vw;
    margin-top: 10vh !important;
  }

  iframe {
    width: 100%;
    height: 90%;
    position: absolute;
    left: 0;
  }
}
</style>

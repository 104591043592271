export default {
  data() {
    return {
      pages: {
        page: 1,
        size: 10,
        total: 0,
        pageSize: [10, 20, 30, 40, 50]
      }
    }
  },
  created() {
    this.initKeyDown()
  },
  activated() {
    this.initKeyDown()
  },
  methods: {
    initKeyDown() {
      // 计时器
      let timeout
      // 防抖
      let tmpIndex = 0
      document.onkeydown = e => {
        if (!(e.target instanceof HTMLBodyElement)) return
        // 翻页
        const maxPager = parseInt(this.pages.total / this.pages.size) + 1
        switch (e.key) {
          case 'ArrowLeft':
            if (this.pages.page <= 1) {
              this.pages.page = 1
              return
            }
            this.pages.page -= 1

            this.getList()
            break
          case 'ArrowRight':
            if (this.pages.page >= maxPager) {
              this.pages.page = maxPager
              return
            }
            this.pages.page += 1
            this.getList()
            break
          default:
            if (!isNaN(parseInt(e.key))) {
              // 第一页到第十页的快速翻页
              tmpIndex = tmpIndex * 10 + parseInt(e.key)
              clearTimeout(timeout)
              timeout = setTimeout(() => {
                if (tmpIndex === 0) {
                  this.pages.page = 10
                } else {
                  if (tmpIndex >= maxPager) {
                    if (this.pages.page === maxPager) {
                      tmpIndex = 0
                      return
                    }
                    tmpIndex = maxPager
                  }
                  this.pages.page = tmpIndex
                }
                tmpIndex = 0
                this.getList()
              }, 500)
            }
        }
        e.stopPropagation()
      }
    },
    pageSizeChange(val) {
      this.pages.size = val
      this.getList()
    },
    pageNumberChange(val) {
      this.pages.page = val
      this.getList()
    }
  }
}

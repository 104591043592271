import axios from 'axios'
import { message } from '@/utils/resetMessage'
import store from '../store'
import { getToken, getPlatform } from '@/utils/storage'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    config.headers['usertype'] = 0
    if (getToken()) {
      config.headers['token'] = getToken()
      if (getPlatform()) {
        config.headers['platform'] = getPlatform()
      }
      if (config.noUse) {
        return config
      }
      if (config.method.toLocaleUpperCase() === 'GET' && config.params && !config.params.use) {
        config.params['use'] = 2
      }
      if (config.method.toLocaleUpperCase() === 'POST' && config.data) {
        if (typeof config.data == 'string') {
          config.data += '&use=2'
        } else {
          config.data = {
            ...config.data,
            use: 2
          }
        }
      }
    }
    return config
  },
  error => {
    console.log(error) // for debug
  }
)

service.interceptors.response.use(
  response => {
    if (response.data.type === 'application/vnd.ms-excel') {
      return response.data
    }
    const res = response.data
    if (res.code !== 200) {
      message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 3 * 1000
      })
      if (res.code === 1001 || res.code === 1002 || res.code === 1003 || res.code === 403 || res.code === 401) {
        store.dispatch('user/resetToken')
        window.location.href = process.env.VUE_APP_WEBSITE_URL
      }
      return Promise.reject('error')
    } else {
      return response.data
    }
  },
  error => {
    if (error.response) {
      const status = error.response.status
      if (status === 401 && window.location.hash.indexOf('#/login') !== 0) {
        store.dispatch('user/resetToken')
        window.location.href = process.env.VUE_APP_WEBSITE_URL
      } else if (status === 500) {
        message({
          message: '服务繁忙，请稍后再试',
          type: 'error',
          duration: 5 * 1000
        })
      } else {
        message({
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
      }
    } else {
      message({
        message: error,
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service

import request from '@/api/request'
const exportdown = {
  searchDownList(params) {
    return request({
      url: '/export/download/list',
      method: 'get',
      params,
    })
  },
}

export default exportdown

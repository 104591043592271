import request from '@/api/request'
import qs from 'qs'

const productCate = {
  fetchListWithAttr() {
    return request({
      url: '/expositionShop/productAttribute/category/list/withAttr',
      method: 'get'
    })
  },
  getProductAttrInfo(productCategoryId) {
    return request({
      url: '/expositionShop/productAttribute/attrInfo/' + productCategoryId,
      method: 'get'
    })
  },
  productCategoryList(parentId, params) {
    return request({
      url: '/expositionShop/productCategory/list/' + parentId,
      method: 'get',
      params: params
    })
  },
  createProductCate(data) {
    return request({
      url: '/expositionShop/productCategory/create',
      method: 'post',
      data: data
    })
  },
  updateProductCate(id, data) {
    return request({
      url: '/expositionShop/productCategory/update/' + id,
      method: 'post',
      data: data
    })
  },
  getProductCate(id) {
    return request({
      url: '/expositionShop/productCategory/' + id,
      method: 'get'
    })
  },
  getBrandByProductcateId(params) {
    return request({
      url: '/expositionShop/productCategory/list/withBrand',
      method: 'POST',
      params: params
    })
  },
  deleteProductCate(id) {
    return request({
      url: '/expositionShop/productCategory/delete/' + id,
      method: 'post'
    })
  },
  updateShowStatus(data) {
    return request({
      url: '/expositionShop/productCategory/update/showStatus',
      method: 'post',
      data
    })
  },
  updateNavStatus(data) {
    return request({
      url: '/expositionShop/productCategory/update/navStatus',
      method: 'post',
      data: qs.stringify(data)
    })
  }
}

export default productCate

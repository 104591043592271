<template>
  <div>
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible="true" :title="'消息' + (isEdit ? '编辑' : '新增')" top="10vh" width="60%" height="50%" @close="close">
      <el-card class="form-container" shadow="never">
        <el-form ref="modeFrom" :model="mode" :rules="rules" label-width="150px">
          <el-form-item label="消息内容：" prop="news">
            <el-input v-model="mode.news" type="textarea" rows="10" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit('modeFrom')">提交</el-button>
            <el-button v-if="!isEdit" @click="resetForm('modeFrom')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-dialog>
  </div>
</template>
<script>
const defaultMode = {
  news: '',
  type: 1
}
export default {
  name: 'ModeDetail',
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fileName: 'static',
      mode: Object.assign({}, defaultMode),
      rules: {
        news: [{ required: true, message: '请输入消息内容', trigger: 'blur' }]
      }
    }
  },
  created() {},
  methods: {
    close() {
      this.$emit('close')
    },
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if (this.isEdit) {
              //
            } else {
              this.$api.notice.addNoticeInfo(this.mode).then(() => {
                this.$message({
                  message: '提交成功',
                  type: 'success',
                  duration: 1000
                })
              })
              this.$emit('refreshData')
            }
          })
        } else {
          this.$message({
            message: '验证失败',
            type: 'error',
            duration: 1000
          })
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.mode = Object.assign({}, defaultMode)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  height: 500px;
}
</style>

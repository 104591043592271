<template>
  <div class="app-container">
    <el-card class="header-card">
      <el-row class="search-area-btn">
        <div class="left">
          <el-button type="primary" size="mini" class="btn-add" @click="synchronization()">一键同步</el-button>
        </div>
      </el-row>
    </el-card>
    <div class="table-container">
      <el-table ref="homeBrandTable" v-loading="listLoading" :data="list" style="width: 100%" border>
        <el-table-column label="编号" width="120" align="center">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column label="操作备注" align="center">
          <template slot-scope="scope">{{ scope.row.operationLog }}</template>
        </el-table-column>
        <el-table-column label="操作人" align="center">
          <template slot-scope="scope">{{ scope.row.operationName }}</template>
        </el-table-column>
        <el-table-column label="操作时间" align="center">
          <template slot-scope="scope">{{ formatDate(scope.row.operationTime) }}</template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes,prev, pager, next,jumper" :page-size="listQuery.pageSize" :page-sizes="[5, 10, 20, 30, 50, 100]" :total="total"> </el-pagination>
    </div>
    <el-dialog v-if="dialogVisible" title="一键同步" :visible.sync="dialogVisible" width="40%" :close-on-click-modal="closeOnClickModal">
      <el-form :model="dialogData" :rules="rules" ref="objFrom" label-width="150px">
        <el-form-item label="操作备注：" prop="operationLog">
          <el-input v-model="dialogData.operationLog" style="width: 200px"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" v-loading="loading" element-loading-text="正在同步中" element-loading-spinner="el-icon-loading" element-loading-background="none">
        <el-button @click="closeDialog('objFrom')" size="small">取 消</el-button>
        <el-button type="primary" @click="sureDialog('objFrom')" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
const defaultListQuery = {
  pageNumber: 1,
  pageSize: 5
}
export default {
  name: 'homeBrandList',
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      list: null,
      total: null,
      listLoading: false,
      dialogVisible: false,
      dialogData: {
        operationLog: ''
      },
      rules: {
        operationLog: [{ required: true, message: '请输入操作备注', trigger: 'blur' }]
      },
      loading: false,
      closeOnClickModal: false
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  methods: {
    formatDate(val) {
      if (val) {
        return this.$utils.parseDate(new Date(val))
      }
    },
    synchronization() {
      this.dialogVisible = true
    },
    closeDialog(formName) {
      this.$refs[formName].resetFields()
      this.dialogVisible = false
    },
    sureDialog(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const data = {
            operationLog: this.dialogData.operationLog
          }
          this.loading = true
          this.$api.sync.esAndRedisSync(data).then(() => {})
          setTimeout(() => {
            this.loading = false
            this.$refs[formName].resetFields()
            this.dialogVisible = false
            this.getList()
          }, 1000)
        } else {
          this.$message({
            message: '验证失败',
            type: 'error',
            duration: 1000
          })
          return false
        }
      })
    },
    handleSizeChange(val) {
      this.listQuery.pageNumber = 1
      this.listQuery.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.pageNumber = val
      this.getList()
    },
    getList() {
      this.listLoading = true
      this.$api.sync.getEsAndRedisSyncList(this.listQuery).then(response => {
        this.listLoading = false
        this.list = response.data.list
        this.total = response.data.total
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-loading-spinner {
  background: rgba(255, 255, 255, 0.9);
}
</style>

import request from '@/api/request'
import qs from 'qs'

const home = {
  getShopById(id) {
    return request({
      url: `expositionShop/shop/get/${id}`,
      method: 'GET'
    })
  },
  shopUpdate(params) {
    return request({
      url: `/expositionShop/shop/update/ybh`,
      method: 'POST',
      data: params
    })
  },
  followProductReq(params) {
    return request({
      url: `/expositionShop/ybh/home/statistics/productCollectList`,
      method: 'POST',
      data: qs.stringify(params)
    })
  },
  followEnterReq(params) {
    return request({
      url: `/expositionShop/ybh/home/statistics/shopCollectList`,
      method: 'POST',
      data: qs.stringify(params)
    })
  },
  browserProductReq(params) {
    return request({
      url: `/expositionShop/ybh/home/statistics/readHistoryList`,
      method: 'POST',
      data: qs.stringify(params)
    })
  },
  browserEnterReq(data) {
    return request({
      url: `/expositionShop/ybh/home/statistics/readShopHistoryList`,
      method: 'POST',
      data: qs.stringify(data)
    })
  },
  shopCountReq(params) {
    return request({
      url: `/expositionShop/ybh/home/statistics/shopCount`,
      method: 'POST',
      data: qs.stringify(params)
    })
  },
  shopProportionReq(params) {
    return request({
      url: `/expositionShop/ybh/home/statistics/shopProportion`,
      method: 'POST',
      data: qs.stringify(params)
    })
  },
  exhibitionNumberReq(params) {
    return request({
      url: `/expositionShop/ybh/home/statistics/exhibitionNumber`,
      method: 'POST',
      data: qs.stringify(params)
    })
  },
  flowStatisticsReq(params) {
    return request({
      url: `/expositionShop/ybh/home/statistics/ybhFlowStatisticsList`,
      method: 'POST',
      data: qs.stringify(params)
    })
  },
  pointsSummaryList(params) {
    return request({
      url: `/expositionShop/ybh/home/statistics/pointsSummaryList`,
      method: 'POST',
      data: qs.stringify(params)
    })
  }
}

export default home

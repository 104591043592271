<template>
  <div class="flowBox">
    <quantityLine></quantityLine>
    <quanProportionPie></quanProportionPie>
    <followRanking></followRanking>
    <browseRanking></browseRanking>
  </div>
</template>

<script>
import quantityLine from "./childComp/quantityLine";
import quanProportionPie from './childComp/quanProportionPie'
import followRanking from './childComp/followRanking'
import browseRanking from './childComp/browseRanking'
export default {
  name: 'App',
  components: {
    quantityLine,
    quanProportionPie,
    followRanking,
    browseRanking
  },
  data() {
    return {
    }
  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
.flowBox{
  width: 100%;
  background-color: #F9F8F8;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
}
</style>
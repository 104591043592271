<template>
  <el-card class="form-container" shadow="never">
    <el-form :model="address" :rules="rules" ref="addressFrom" label-width="150px">
      <el-form-item label="收货点" prop="addressName">
        <el-input v-model="address.addressName"></el-input>
      </el-form-item>
      <el-form-item label="收货人姓名" prop="name">
        <el-input v-model="address.name"></el-input>
      </el-form-item>
      <el-form-item label="所在区域">
        <v-distpicker :province="address.province" :city="address.city" :area="address.region" @selected="onSelectRegion" />
      </el-form-item>
      <el-form-item label="详细地址" prop="detailAddress">
        <el-input v-model="address.detailAddress"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <el-input v-model="address.phone"></el-input>
      </el-form-item>
      <el-form-item label="是否默认收货地址：">
        <el-radio-group v-model="address.receiveStatus">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否默认发货地址：">
        <el-radio-group v-model="address.sendStatus">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit('addressFrom')">提交</el-button>
        <el-button v-if="!isEdit" @click="resetForm('addressFrom')">重置</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import VDistpicker from 'v-distpicker'
const defaultAddress = {
  addressName: '',
  name: '',
  province: '',
  city: '',
  region: '',
  detailAddress: '',
  phone: '',
  receiveStatus: 0,
  sendStatus: 0
}
export default {
  name: 'AddressDetail',
  components: { VDistpicker },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    var validatorPhone = (rule, value, callback) => {
      const reg1 = /^1[3456789]\d{9}$/
      const reg2 = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/
      if (!value) {
        callback(new Error('请输入联系电话'))
      } else if (!reg1.test(value) && !reg2.test(value)) {
        callback(new Error('请输入正确的电话格式'))
      } else {
        callback()
      }
    }
    return {
      fileName: 'static',
      address: Object.assign({}, defaultAddress),
      rules: {
        addressName: [
          { required: true, message: '请输入收货点', trigger: 'blur' },
          {
            min: 2,
            max: 140,
            message: '长度在 2 到 140 个字符',
            trigger: 'blur'
          }
        ],
        province: [{ required: true, message: '请输入选择省份', trigger: 'blur' }],
        city: [{ required: true, message: '请输入选择市县', trigger: 'blur' }],
        region: [{ required: true, message: '请输入选择区域', trigger: 'blur' }],
        name: [
          { required: true, message: '请输入收货人姓名', trigger: 'blur' },
          {
            min: 2,
            max: 140,
            message: '长度在 2 到 140 个字符',
            trigger: 'blur'
          }
        ],
        detailAddress: [
          { required: true, message: '请输入详细地址', trigger: 'blur' },
          {
            min: 2,
            max: 140,
            message: '长度在 2 到 140 个字符',
            trigger: 'blur'
          }
        ],
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { validator: validatorPhone, trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    if (this.isEdit) {
      this.$api.order.getAddressById(this.$route.query.id).then(response => {
        this.address = response.data
      })
    } else {
      this.address = Object.assign({}, defaultAddress)
    }
  },
  methods: {
    onSelectRegion(data) {
      this.address.province = data.province.value
      this.address.city = data.city.value
      this.address.region = data.area.value
    },
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if (this.isEdit) {
              this.$api.order.updateAddressById(this.$route.query.id, this.address).then(() => {
                this.$refs[formName].resetFields()
                this.$message({
                  message: '修改成功',
                  type: 'success',
                  duration: 1000
                })
                this.$router.back()
              })
            } else {
              this.$api.order.createAddress(this.address).then(() => {
                this.$refs[formName].resetFields()
                this.address = Object.assign({}, defaultAddress)
                this.$message({
                  message: '提交成功',
                  type: 'success',
                  duration: 1000
                })
              })
            }
          })
        } else {
          this.$message({
            message: '验证失败',
            type: 'error',
            duration: 1000
          })
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.address = Object.assign({}, defaultAddress)
    }
  }
}
</script>
<style></style>

<template>
  <el-dialog title="微信支付" :visible.sync="dialogWxPayVisible" center append-to-body width="700px" :before-close="handleClose" :close-on-click-modal="closeOnClickModal">
    <div class="codepaybox">
      <div class="codepay">
        <div class="codepay-title">
          <p class="t1">扫码支付</p>
          <p class="t2">订单提交成功,快快支付吧,请于<span>30分钟内</span>完成支付</p>
        </div>
        <div class="codepay-content">
          <div class="qrcodebox">
            <div id="qrcode" ref="codePay" class="qrcode" />
          </div>
          <div class="detail">
            <p class="t1">
              应付金额：<span>￥</span><span class="total">{{ wxListInfo.totalAmount }}</span>
            </p>
            <p class="t2">请使用微信扫码付款</p>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import QRCode from 'qrcodejs2'
export default {
  props: {
    wxListInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    dialogWxPayVisible: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      closeOnClickModal: false,
      timerCode: null,
      qrcode: '',
      code: ''
    }
  },
  mounted() {
    this.code = this.wxListInfo.orderCode
    if (this.source === 'chargeMoney') {
      this.wxcharge()
    } else {
      this.wxpay()
    }
  },
  methods: {
    handleClose() {
      clearInterval(this.timerCode)
      this.$emit('changeWxDialog', false)
    },
    // 轮询接口
    _timerCode() {
      var params = {
        code: this.code
      }
      this.timerCode = setInterval(() => {
        this.$api.pay.wxIfPay(params).then(res => {
          if (res.code === 200 && res.data === true) {
            clearInterval(this.timerCode)
            this.$message({
              type: 'success',
              message: '支付成功'
            })
            this.$emit('changeWxDialog', false)
            this.$router.push({
              path: '/success/1'
            })
          }
        })
      }, 5000)
    },
    // 调取微信支付
    wxpay() {
      this.$api.pay
        .getWxCode(this.wxListInfo)
        .then(res => {
          if (this.qrcode) {
            this.qrcode.clear()
            this.qrcode = null
          }
          if (res.code === 200) {
            this.$nextTick(() => {
              this._qrcode(res.data)
              if (res.data) {
                this._timerCode()
              }
            })
          }
        })
        .catch(err => {
          throw new Error(err)
        })
    },
    // 微信充值
    wxcharge() {
      this.$api.pay
        .wxRechargePay(this.wxListInfo)
        .then(res => {
          if (this.qrcode) {
            this.qrcode.clear()
            this.qrcode = null
          }
          if (res.code === 200) {
            this.$nextTick(() => {
              this.code = res.data.code
              this._qrcode(res.data.wxUrl)
              if (res.data.wxUrl || res.data.isPay) {
                this.chargeTime()
              }
            })
          }
        })
        .catch(err => {
          this.close()
          throw new Error(err)
        })
    },
    // 微信充值轮询
    chargeTime() {
      var params = {
        code: this.code
      }
      this.timerCode = setInterval(() => {
        this.$api.pay
          .wxRechargeIfPay(params)
          .then(res => {
            if (res.code === 200 && res.data === true) {
              clearInterval(this.timerCode)
              this.$message({
                type: 'success',
                message: '支付成功'
              })
              this.close()
              // window.location.href = '/submitorder.html#/success/2'
            }
          })
          .catch(() => {
            this.close()
          })
      }, 5000)
    },
    close() {
      this.$emit('changeWxDialog', false)
      this.$emit('closePayDialog')
    },
    // 生成支付二维码
    _qrcode(text) {
      this.qrcode = new QRCode('qrcode', {
        width: 95,
        height: 95,
        text: text,
        colorDark: '#000',
        colorLight: '#fff'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.codepaybox {
  padding: 10px 0 60px;
}
.codepay {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  background: #fff;
  .codepay-title {
    margin-bottom: 80px;
    .t1 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 15px;
    }
    .t2 {
      font-size: 14px;
      span {
        color: #047be4;
      }
    }
  }
  .codepay-content {
    display: flex;
    justify-content: center;
    .qrcodebox {
      width: 130px;
      height: 130px;
      background: url(images/codeback.png) center no-repeat;
      background-size: cover;
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .detail {
      .t1 {
        span {
          color: #ff4800;
          &.total {
            font-size: 32px;
            font-weight: 500;
          }
        }
      }
      .t2 {
        color: #ababab;
        font-size: 12px;
        text-align: left;
      }
    }
  }
}
</style>

<template>
  <el-dialog v-dialogDrag :close-on-click-modal="false" :visible="true" :title="(isEdit ? '编辑' : '新增') + '专题'" top="10vh" width="40%" @close="close">
    <el-card class="container" shadow="never">
      <el-form ref="homeAdvertiseFrom" :model="homeAdvertise" :rules="rules" label-width="150px" size="small">
        <el-form-item label="专题名称：" prop="title">
          <el-input v-model="homeAdvertise.title" class="input-width" />
        </el-form-item>
        <el-form-item label="专题简介：" prop="description">
          <el-input v-model="homeAdvertise.description" class="input-width" />
        </el-form-item>
        <el-form-item label="是否推荐：">
          <el-select v-model="homeAdvertise.recommendStatus">
            <el-option v-for="type in recommendStatusOptions" :key="type.value" :label="type.label" :value="type.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="是否显示：">
          <el-select v-model="homeAdvertise.showStatus">
            <el-option v-for="type in showStatusOptions" :key="type.value" :label="type.label" :value="type.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="展示平台：" prop="use">
          <el-radio-group v-model="homeAdvertise.use">
            <el-radio :label="1">商城</el-radio>
            <el-radio :label="2">博览会</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="专题图片：" prop="pic">
          <single-upload v-model="homeAdvertise.pic" :name="fileName" />
        </el-form-item>
      </el-form>
      <div class="solt-footer" align="center">
        <el-button type="primary" @click="onSubmit('homeAdvertiseFrom')">提交</el-button>
        <el-button v-if="!isEdit" @click="resetForm('homeAdvertiseFrom')">重置</el-button>
      </div>
    </el-card>
  </el-dialog>
</template>
<script>
const defaultHomeAdvertise = {
  id: '',
  title: '',
  description: '',
  pic: '',
  showStatus: 1,
  recommendStatus: 1,
  use: 2
}
export default {
  name: 'SubjectDetail',
  components: {},
  props: {
    id: {
      type: [Number, String],
      default: ''
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fileName: 'static',
      dialogShow: true,
      homeAdvertise: {},
      rules: {
        title: [
          { required: true, message: '请输入专题名称', trigger: 'blur' },
          {
            min: 2,
            max: 140,
            message: '长度在 2 到 140 个字符',
            trigger: 'blur'
          }
        ],
        description: [
          { required: true, message: '请输入专题简介', trigger: 'blur' },
          {
            min: 2,
            max: 140,
            message: '长度在 2 到 140 个字符',
            trigger: 'blur'
          }
        ],
        pic: [{ required: true, message: '请上传专题图片', trigger: 'blur' }],
        use: [{ required: true, message: '请选择展示平台', trigger: 'change' }]
      },
      showStatusOptions: [
        {
          label: '显示',
          value: 1
        },
        {
          label: '不显示',
          value: 0
        }
      ],
      recommendStatusOptions: [
        {
          label: '推荐',
          value: 1
        },
        {
          label: '不推荐',
          value: 0
        }
      ]
    }
  },
  created() {
    if (this.isEdit) {
      this.$api.subject.getSubjectDetail(this.id).then(response => {
        this.homeAdvertise = response.data
      })
    } else {
      this.homeAdvertise = Object.assign({}, defaultHomeAdvertise)
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.homeAdvertise.createTime = ''
            // const params = JSON.stringify(this.homeAdvertise)
            if (this.isEdit) {
              this.$api.subject
                .updateSubject(
                  this.homeAdvertise
                  // params
                )
                .then(() => {
                  this.$refs[formName].resetFields()
                  this.$message({
                    message: '修改成功',
                    type: 'success',
                    duration: 1000
                  })
                  this.$emit('refreshData')
                })
            } else {
              this.$api.subject.createSubject(this.homeAdvertise).then(() => {
                this.$refs[formName].resetFields()
                this.homeAdvertise = Object.assign({}, defaultHomeAdvertise)
                this.$message({
                  message: '提交成功',
                  type: 'success',
                  duration: 1000
                })
                this.$emit('refreshData')
              })
            }
          })
        } else {
          this.$message({
            message: '验证失败',
            type: 'error',
            duration: 1000
          })
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.homeAdvertise = Object.assign({}, defaultHomeAdvertise)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep.el-form {
  width: 100%;
}
.input-width {
  width: 70%;
}
</style>

<template>
  <div class="app-container">
    <el-card class="header-card">
      <el-row class="search-area-btn">
        <div class="left">
          <el-button type="primary" size="mini" class="btn-add" @click="handleAdd">添加 </el-button>
          <!--  <el-select v-model="operateType" size="small" placeholder="批量操作">
            <el-option v-for="item in operateOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
          <el-button style="margin-left: 20px" class="search-button" type="primary" size="small" @click="handleBatchOperate"> 确定 </el-button> -->
        </div>
        <div class="center">
          <el-button type="primary" @click="handleBatchOperate">查询 </el-button>
        </div>
      </el-row>
    </el-card>
    <div class="table-container">
      <el-table ref="returnReasonTable" v-loading="listLoading" :data="list" style="width: 100%" border @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="60" align="center" />
        <el-table-column label="编号" width="80" align="center">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column label="服务类型" width="150" align="center">
          <template slot-scope="scope">{{ scope.row.returnType | handleServeType }}</template>
        </el-table-column>
        <el-table-column label="原因类型" align="center">
          <template slot-scope="scope">{{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column label="排序" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.sort }}</template>
        </el-table-column>
        <el-table-column label="是否可用" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" :active-value="1" @change="handleStatusChange(scope.$index, scope.row)" :inactive-value="0" />
          </template>
        </el-table-column>
        <el-table-column label="添加时间" width="180" align="center">
          <template slot-scope="scope">{{ formatCreateTime(scope.row.createTime) }}</template>
        </el-table-column>
        <el-table-column label="操作" width="160" align="center">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="handleUpdate(scope.$index, scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination background layout="total, sizes,prev, pager, next,jumper" :current-page.sync="listQuery.pageNum" :page-size="listQuery.pageSize" :page-sizes="[5, 10, 20, 30, 50, 100]" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
    <el-dialog title="添加退货原因" :visible.sync="dialogVisible" width="30%">
      <el-form ref="reasonForm" :model="returnReason" label-width="150px">
        <el-form-item label="服务类型：">
          <!-- <el-input v-model="returnReason.type" class="input-width"></el-input> -->
          <el-select v-model="returnReason.returnType" class="input-width">
            <el-option v-for="(item, index) in serviceTypeList" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="原因类型：">
          <el-input v-model="returnReason.name" class="input-width" />
        </el-form-item>
        <el-form-item label="排序：">
          <el-input v-model="returnReason.sort" class="input-width" />
        </el-form-item>
        <el-form-item label="是否启用：">
          <el-switch v-model="returnReason.status" :active-value="1" :inactive-value="0" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
const defaultListQuery = {
  pageNum: 1,
  pageSize: 5
}
const defaultReturnReason = {
  name: null,
  sort: 0,
  returnType: null,
  status: 1,
  createTime: null
}
export default {
  name: 'returnReasonList',
  data() {
    return {
      list: null,
      total: null,
      multipleSelection: [],
      listLoading: true,
      listQuery: Object.assign({}, defaultListQuery),
      operateType: null,
      operateOptions: [
        {
          label: '删除',
          value: 1
        }
      ],
      dialogVisible: false,
      returnReason: Object.assign({}, defaultReturnReason),
      operateReasonId: null,
      serviceTypeList: [
        {
          label: '退货原因',
          value: 0
        },
        {
          label: '退款原因',
          value: 1
        },
        {
          label: '换货原因',
          value: 2
        }
      ]
    }
  },
  created() {
    this.getList()
  },
  filters: {
    handleServeType(val) {
      let str = ''
      switch (val) {
        case 0:
          str = '退货原因'
          break
        case 1:
          str = '退款原因'
          break
        case 2:
          str = '换货原因'
          break
      }
      return str
    }
  },
  methods: {
    formatCreateTime(time) {
      let date = new Date(time)
      return this.$utils.parseDate(date)
    },
    handleAdd() {
      this.dialogVisible = true
      this.operateReasonId = null
      this.returnReason = Object.assign({}, defaultReturnReason)
    },
    handleConfirm() {
      if (this.operateReasonId == null) {
        //添加操作
        this.$api.order.addReason(this.returnReason).then(() => {
          this.dialogVisible = false
          this.operateReasonId = null
          this.$message({
            message: '添加成功！',
            type: 'success',
            duration: 1000
          })
          this.getList()
        })
      } else {
        //编辑操作
        this.$api.order.updateReason(this.operateReasonId, this.returnReason).then(() => {
          this.dialogVisible = false
          this.operateReasonId = null
          this.$message({
            message: '修改成功！',
            type: 'success',
            duration: 1000
          })
          this.getList()
        })
      }
    },
    handleUpdate(index, row) {
      this.dialogVisible = true
      this.operateReasonId = row.id
      this.$api.order.getReasonDetail(row.id).then(response => {
        this.returnReason = response.data
      })
    },
    handleDelete(index, row) {
      let ids = []
      ids.push(row.id)
      this.deleteReason(ids)
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleStatusChange(index, row) {
      let ids = []
      ids.push(row.id)
      let data = {
        status: row.status,
        ids: ids
      }
      this.$api.order.updateStatus(data).then(() => {
        this.$message({
          message: '状态修改成功',
          type: 'success'
        })
      })
    },
    handleBatchOperate() {
      if (this.multipleSelection == null || this.multipleSelection.length < 1) {
        this.$message({
          message: '请选择要操作的条目',
          type: 'warning',
          duration: 1000
        })
        return
      }
      if (this.operateType === 1) {
        let ids = []
        for (let i = 0; i < this.multipleSelection.length; i++) {
          ids.push(this.multipleSelection[i].id)
        }
        this.deleteReason(ids)
      }
    },
    handleSizeChange(val) {
      this.listQuery.pageNum = 1
      this.listQuery.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.pageNum = val
      this.getList()
    },
    getList() {
      this.listLoading = true
      this.$api.order.returnReasonList(this.listQuery).then(response => {
        this.listLoading = false
        this.list = response.listData
        this.total = response.count
      })
    },
    deleteReason(ids) {
      this.$confirm('是否要进行该删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          ids: ids.join(',')
        }
        this.$api.order.deleteReason(data).then(() => {
          this.$message({
            message: '删除成功！',
            type: 'success',
            duration: 1000
          })
          this.listQuery.pageNum = 1
          this.getList()
        })
      })
    }
  }
}
</script>
<style scoped>
.input-width {
  width: 80%;
}
</style>

<template>
  <div class="app-container">
    <el-card class="header-card">
      <el-form :inline="true" :model="formData" class="form-inline">
        <el-row>
          <el-col :span="6">
            <el-form-item label="创建时间">
              <el-date-picker
                v-model="timeValue"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="selectTime"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="search-area-btn">
        <div class="left"></div>
        <div class="center">
          <el-button :loading="loading" type="primary" @click="getListData">
            查询
          </el-button>
        </div>
      </div>
    </el-card>

    <el-card class="table-card">
      <el-table
        :data="listArr"
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
        border
        style="width: 100%;"
        height="600px"
      >
        <el-table-column type="index" align="center" width="100" label="序号" />
        <el-table-column prop="downloadName" align="center" label="文件名称" />
        <el-table-column align="center" label="文件状态">
          <template slot-scope="scope">
            <span>{{ switchStatus(scope.row.handlerStatus) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createdBy" align="center" label="创建人" />
        <el-table-column prop="createdTime" align="center" label="创建时间" />
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              :disabled="scope.row.handlerStatus !== 1"
              type="primary"
              size="mini"
              @click="download(scope.row)"
            >
              下载
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page.pageNmuber"
        :page-sizes="[20, 50]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.pageTool"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
  </div>
</template>

<script>
import { saveAs } from 'file-saver'
export default {
  data() {
    return {
      // 文件下载列表
      loading: false,
      listArr: [],
      timeValue: '',
      startTime: '',
      endTime: '',
      formData: {},
      page: {
        pageNmuber: 1,
        pageSize: 20,
        pageTool: 0,
      },
    }
  },
  computed: {
    downloadListOptions() {
      if (this.$store.state.dictionary.downloadList.length === 0) {
        this.$store.dispatch('DOWNLOAD_LIST')
      }
      return this.$store.state.dictionary.downloadList
    },
  },
  mounted() {
    this.timeValue = []
    this.timeValue[0] = this.$utils.parseDate(
      new Date().getTime() - 3600 * 1000 * 24 * 7,
      '{y}-{m}-{d}',
    )
    this.timeValue[1] = this.$utils.parseDate(new Date(), '{y}-{m}-{d}')
    this.getListData()
  },

  methods: {
    // 转换下载状态
    switchStatus(code) {
      var returnText = ''
      for (var i = 0; i < this.downloadListOptions.length; i++) {
        if (code + '' === this.downloadListOptions[i].dictValue) {
          returnText = this.downloadListOptions[i].dictLabel
        }
      }
      console.log(returnText)
      return returnText
    },
    // 选择时间执行
    selectTime(res) {
      if (res === null) {
        this.startTime = ''
        this.endTime = ''
      }
      console.log('时间', res)
      this.startTime = this.getTime(res[0])
      this.endTime = this.getTime(res[1])
    },
    // 查询生成的文件进行下载
    getListData() {
      var _this = this
      var params = {
        createdTime: this.startTime,
        endTime: this.endTime,
        pageNumber: this.page.pageNmuber,
        pageSize: this.page.pageSize,
      }
      this.$api.exportdown.searchDownList(params).then((res) => {
        console.log(res)
        _this.listArr = res.data.list
        _this.page.pageTool = res.data.total
      })
    },
    // 下载定稿文件修改文件名
    download(obj) {
      //url, filename
      // scope.row.downloadUrl, scope.row.downloadName + '.xls'
      let url = obj.downloadUrl,
        filename = obj.downloadName

      console.log('url:' + url)
      if (url.indexOf('https') === -1) {
        url = url.replace('http', 'https')
      }
      this.downloading = true
      this.getBlob(url, (blob) => {
        const _this = this
        try {
          saveAs(blob, filename)
          _this.downloading = false
        } catch (error) {
          console.log(error)
          _this.$message.error('下载失败，请重新下载')
          _this.downloading = false
        }
      })
    },
    getBlob(url, cb) {
      console.log('url:' + url)
      const _this = this
      var xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.responseType = 'blob'
      xhr.onload = function () {
        if (xhr.status === 200) {
          cb(xhr.response)
        } else {
          _this.$message.error('下载失败，请重新下载')
          _this.downloading = false
        }
      }
      xhr.send()
    },
    // 获取时间
    getTime(time) {
      var timeObj = new Date(time)
      var year = timeObj.getFullYear()
      var month = timeObj.getMonth() + 1
      month = month > 10 ? month : '0' + month
      var day =
        timeObj.getDate() >= 10 ? timeObj.getDate() : '0' + timeObj.getDate()
      return year + '-' + month + '-' + day
    },
    // pageSize切换
    handleSizeChange(res) {
      console.log(res)
      this.page.pageSize = res
      this.getListData()
    },
    // pageNumber切换
    handleCurrentChange(res) {
      console.log(res)
      this.page.pageNmuber = res
      this.getListData()
    },
  },
}
</script>

<style>
.eportDownBox {
  padding: 20px;
}
.selectBox {
  display: flex;
  justify-content: flex-start;
}
.selectBox > .but {
  margin-left: 20px;
}
.eportDownBox > .tableBox {
  margin-top: 40px;
}
</style>

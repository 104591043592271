<template>
  <div class="flowBox">
    <browserLine></browserLine>
    <div class="tableBox">
      <flowStatic></flowStatic>
      <pageViews></pageViews>
    </div>
  </div>
</template>

<script>
import browserLine from "./components/browserLine";
import flowStatic from './components/flowStatic'
import pageViews from './components/pageViews'
export default {
  name: 'App',
  components: {
    browserLine,
    flowStatic,
    pageViews,
  },
  data() {
    return {
    }
  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
.flowBox{
  width: 100%;
  background-color: #F9F8F8;
  padding: 20px 15px 20px 0;
}
.tableBox{
  display: flex;
}
</style>
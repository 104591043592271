<template>
  <freight-detail :is-edit="true"></freight-detail>
</template>
<script>
import FreightDetail from './components/FreightDetail'
export default {
  name: 'updateFreight',
  components: { FreightDetail }
}
</script>
<style></style>

<template>
  <suit-detail :is-edit="true"></suit-detail>
</template>
<script>
import SuitDetail from './components/SuitDetail'
export default {
  name: 'updateSuit',
  components: { SuitDetail }
}
</script>
<style></style>

<template>
  <div class="app-container">
    <div class="table-layout">
      <el-row>
        <el-col :span="4" class="table-cell-title">名称</el-col>
        <el-col :span="4" class="table-cell-title">优惠券类型</el-col>
        <el-col :span="4" class="table-cell-title">可使用商品</el-col>
        <el-col :span="4" class="table-cell-title">使用门槛</el-col>
        <el-col :span="4" class="table-cell-title">面值</el-col>
        <el-col :span="4" class="table-cell-title">状态</el-col>
      </el-row>
      <el-row>
        <el-col :span="4" class="table-cell">{{ coupon.name }}</el-col>
        <el-col :span="4" class="table-cell">{{ $utils.handleOptions(coupon.type, couponTypeOptions) }}</el-col>
        <el-col :span="4" class="table-cell">{{ coupon.useType | formatUseType }}</el-col>
        <el-col :span="4" class="table-cell">满{{ coupon.minPoint }}元可用</el-col>
        <el-col :span="4" class="table-cell">{{ coupon.amount }}元</el-col>
        <el-col :span="4" class="table-cell">{{ coupon.endTime | formatStatus }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="4" class="table-cell-title">有效期</el-col>
        <el-col :span="4" class="table-cell-title">总发行量</el-col>
        <el-col :span="4" class="table-cell-title">已领取</el-col>
        <el-col :span="4" class="table-cell-title">待领取</el-col>
        <el-col :span="4" class="table-cell-title">已使用</el-col>
        <el-col :span="4" class="table-cell-title">未使用</el-col>
      </el-row>
      <el-row>
        <el-col :span="4" class="table-cell" style="font-size: 13px"> {{ formatDate(coupon.startTime) }}至{{ formatDate(coupon.endTime) }} </el-col>
        <el-col :span="4" class="table-cell">{{ coupon.publishCount }}</el-col>
        <el-col :span="4" class="table-cell">{{ coupon.receiveCount }}</el-col>
        <el-col :span="4" class="table-cell">{{ coupon.publishCount - coupon.receiveCount }}</el-col>
        <el-col :span="4" class="table-cell">{{ coupon.useCount }}</el-col>
        <el-col :span="4" class="table-cell">{{ coupon.publishCount - coupon.useCount }}</el-col>
      </el-row>
    </div>
    <el-card class="filter-container" shadow="never">
      <div>
        <i class="el-icon-search"></i>
        <span>筛选搜索</span>
        <el-button style="float: right" type="primary" size="small" @click="handleSearchList()"> 查询搜索 </el-button>
        <el-button style="float: right; margin-right: 15px" size="small" @click="handleResetSearch()"> 重置 </el-button>
      </div>
      <div style="margin-top: 15px">
        <el-form :inline="true" :model="listQuery" size="small" label-width="140px">
          <el-form-item label="使用状态：">
            <el-select v-model="listQuery.useStatus" placeholder="全部" clearable class="input-width">
              <el-option v-for="item in useTypeOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="订单编号：">
            <el-input v-model="listQuery.orderSn" class="input-width" placeholder="订单编号" />
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <div class="table-container">
      <el-table ref="couponHistoryTable" v-loading="listLoading" :data="list" style="width: 100%" border>
        <!--        <el-table-column label="优惠码" width="160" align="center">-->
        <!--          <template slot-scope="scope">{{ scope.row.couponCode }}</template>-->
        <!--        </el-table-column>-->
        <el-table-column label="领取会员" width="140" align="center">
          <template slot-scope="scope">{{ scope.row.memberNickname }}</template>
        </el-table-column>
        <el-table-column label="领取方式" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.getType | formatGetType }}</template>
        </el-table-column>
        <el-table-column label="领取时间" width="160" align="center">
          <template slot-scope="scope">{{ scope.row.createTime | formatTime }}</template>
        </el-table-column>
        <el-table-column label="当前状态" width="140" align="center">
          <template slot-scope="scope">{{ scope.row.useStatus | formatCouponHistoryUseType }}</template>
        </el-table-column>
        <el-table-column label="使用时间" width="160" align="center">
          <template slot-scope="scope">{{ scope.row.useTime | formatTime }}</template>
        </el-table-column>
        <el-table-column label="订单编号" align="center">
          <template slot-scope="scope">{{ scope.row.orderSn === null ? '' : scope.row.orderSn }}</template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes,prev, pager, next,jumper" :current-page.sync="listQuery.pageNum" :page-size="listQuery.pageSize" :page-sizes="[5, 10, 20, 30, 50, 100]" :total="total" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import store from '@/store'

const defaultTypeOptions = [
  {
    label: '全场赠券',
    value: 0
  },
  {
    label: '会员赠券',
    value: 1
  },
  {
    label: '购物赠券',
    value: 2
  },
  {
    label: '注册赠券',
    value: 3
  }
]
const defaultListQuery = {
  pageNum: 1,
  pageSize: 10,
  useStatus: null,
  orderSn: null,
  couponId: null
}
const defaultUseTypeOptions = [
  {
    label: '未使用',
    value: 0
  },
  {
    label: '已使用',
    value: 1
  },
  {
    label: '已过期',
    value: 2
  }
]
export default {
  name: 'CouponHistoryList',
  data() {
    return {
      coupon: {},
      listQuery: Object.assign({}, defaultListQuery),
      useTypeOptions: Object.assign({}, defaultUseTypeOptions),
      list: null,
      total: null,
      listLoading: false
    }
  },
  filters: {
    formatType(type) {
      for (let i = 0; i < defaultTypeOptions.length; i++) {
        if (type === defaultTypeOptions[i].value) {
          return defaultTypeOptions[i].label
        }
      }
      return ''
    },
    formatUseType(useType) {
      if (useType === 0) {
        return '全场通用'
      } else if (useType === 1) {
        return '指定分类'
      } else {
        return '指定商品'
      }
    },
    formatPlatform(platform) {
      if (platform === 1) {
        return '移动平台'
      } else if (platform === 2) {
        return 'PC平台'
      } else {
        return '全平台'
      }
    },

    formatStatus(endTime) {
      const now = new Date().getTime()
      endTime = new Date(endTime).getTime()
      if (endTime > now) {
        return '未过期'
      } else {
        return '已过期'
      }
    },
    formatGetType(type) {
      if (type === 1) {
        return '主动获取'
      } else {
        return '后台赠送'
      }
    },
    formatCouponHistoryUseType(useType) {
      if (useType === 0) {
        return '未使用'
      } else if (useType === 1) {
        return '已使用'
      } else {
        return '已过期'
      }
    }
  },
  computed: {
    ...mapState({
      couponTypeOptions: state => state.dictionary.couponType
    })
  },
  created() {
    this.getCounponList()
    this.$api.coupon.getCoupon(this.$route.query.id).then(response => {
      this.coupon = response.data
    })
    this.listQuery.couponId = this.$route.query.id
    this.getList()
  },
  methods: {
    formatTime(time) {
      if (time == null || time === '') {
        return ''
      }
      const date = new Date(time)
      return this.$utils.parseDate(date)
    },
    formatDate(time) {
      if (time == null || time === '') {
        return 'N/A'
      }
      const date = new Date(time)
      return this.$utils.parseDate(date, '{y}-{m}-{d}')
    },
    async getCounponList() {
      await store.dispatch('change_coupontype')
    },
    getList() {
      this.listLoading = true
      this.$api.coupon.couponHistoryList(this.listQuery).then(response => {
        this.listLoading = false
        this.list = response.listData
        this.total = response.count
      })
    },
    handleResetSearch() {
      this.listQuery = Object.assign({}, defaultListQuery)
      this.listQuery.couponId = this.$route.query.id
    },
    handleSearchList() {
      this.listQuery.pageNum = 1
      this.getList()
    },
    handleSizeChange(val) {
      this.listQuery.pageNum = 1
      this.listQuery.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.pageNum = val
      this.getList()
    }
  }
}
</script>
<style scoped>
.app-container {
  width: 80%;
  margin: 20px auto;
}

.filter-container {
  margin-top: 20px;
}

.table-layout {
  margin-top: 20px;
  border-left: 1px solid #dcdfe6;
  border-top: 1px solid #dcdfe6;
}

.table-cell {
  height: 60px;
  line-height: 20px;
  border-right: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  padding: 10px;
  font-size: 14px;
  color: #606266;
  text-align: center;
  overflow: hidden;
}

.table-cell-title {
  border-right: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  padding: 10px;
  background: #f2f6fc;
  text-align: center;
  font-size: 14px;
  color: #303133;
}
</style>

import request from '@/api/request'
import qs from 'qs'

const brand = {
  brandList(params) {
    return request({
      url: '/expositionShop/brand/list',
      method: 'get',
      params: params
    })
  },
  createBrand(data) {
    return request({
      url: '/expositionShop/brand/create',
      method: 'post',
      data: data
    })
  },
  updateShowStatus(data) {
    return request({
      url: '/expositionShop/brand/update/showStatus',
      method: 'post',
      data: qs.stringify(data)
    })
  },
  updateFactoryStatus(data) {
    return request({
      url: '/expositionShop/brand/update/factoryStatus',
      method: 'post',
      data: qs.stringify(data)
    })
  },
  deleteBrand(id) {
    return request({
      url: '/expositionShop/brand/delete/' + id,
      method: 'get'
    })
  },
  getBrand(id) {
    return request({
      url: '/expositionShop/brand/' + id,
      method: 'get'
    })
  },
  updateBrand(id, data) {
    return request({
      url: '/expositionShop/brand/update/' + id,
      method: 'post',
      data: data
    })
  }
}

export default brand

import request from '@/api/request'
import qs from 'qs'

const pay = {
  //////////////订单支付
  // 支付宝订单下单
  aliPayFromOrder(params) {
    return request({
      url: '/expositionShopweb/web/ali/aliPay',
      method: 'get',
      params
    })
  },
  // 支付宝异步回调
  aliPayCallback() {
    return request({
      url: '/expositionShopweb/web/ali/aliNotify',
      method: 'post'
    })
  },
  // 微信支付生成二维码
  getWxCode(params) {
    return request({
      url: '/expositionShopweb/web/wx/wxPay',
      method: 'get',
      params
    })
  },
  // 微信支付异步回调
  wxNotify() {
    return request({
      url: '/expositionShopweb/web/wx/wxNotify',
      method: 'post'
    })
  },
  // 微信支付是否成功
  wxIfPay(params) {
    return request({
      url: '/expositionShopweb/web/wx/wxIfPay',
      method: 'get',
      params
    })
  },
  // 余额支付
  balancePay(data) {
    return request({
      url: '/expositionShopweb/balance/balancePay',
      method: 'post',
      data: qs.stringify(data)
    })
  },
  //////////////充值下单
  // 支付宝充值
  aliRechargePay(params) {
    return request({
      url: '/expositionShopweb/web/ali/aliRechargePay',
      method: 'get',
      params
    })
  },
  // 微信生成二维码
  wxRechargePay(params) {
    return request({
      url: '/expositionShopweb/web/wx/wxRechargePay',
      method: 'get',
      params
    })
  },
  // 微信充值支付是否成功
  wxRechargeIfPay(params) {
    return request({
      url: '/expositionShopweb/web/wx/wxRechargeIfPay',
      method: 'get',
      params,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  aliTransfer(businessType, params) {
    return request({
      url: `/pay/api/ali/pay/wxstore/transfer/${businessType}`,
      method: 'POST',
      data: params
    })
  },
  wxTransfer(businessType, params) {
    return request({
      url: `/pay/api/wx/pay/wxstore/transfer/${businessType}`,
      method: 'POST',
      data: params
    })
  }
}
export default pay

import Vue from 'vue'
import store from '@/store'

Vue.directive('has', {
  inserted(el, binding, vnode) {
    const value = binding.value
    const btnPermitted = vnode.context.$store.state.permission.btnPermitted
    let path = vnode.context.$route.path
    let isHas = false
    for (let key in btnPermitted) {
      if(path === '/home'){
        path += '/#'
      }
      if (key === path) {
        btnPermitted[path].forEach(item => {
          if (value === item.menuPermitted) {
            isHas = true
          }
        })
      }
    }
    if (!isHas) {
      el.parentNode.removeChild(el)
    }
  }
})

const hasPermission = userPermissionList => {
  const btnPermitted = store.state.permission.btnPermitted
  const isHas = userPermissionList.some(item => {
    let ret = false
    for (const key in btnPermitted) {
      btnPermitted[key].forEach(tm => {
        if (item === tm.menuPermitted) {
          ret = true
        }
      })
    }
    return ret
  })
  return isHas
}

Vue.prototype.$_has = hasPermission

Vue.directive('enterMoney', {
  inserted: function (el) {
    el.addEventListener('keypress', function (e) {
      e = e || window.event
      const charcode = typeof e.charCode === 'number' ? e.charCode : e.keyCode
      const re = /\d/
      const val = String(el.children[0].value)

      if (val.includes('.') && val.length - val.indexOf('.') > 2) {
        e.preventDefault()
        return
      }
      if (charcode === 46) {
        if (val.includes('.')) {
          e.preventDefault()
        }

        return
      } else if (!re.test(String.fromCharCode(charcode)) && charcode > 9 && !e.ctrlKey) {
        if (e.preventDefault) {
          e.preventDefault()
        } else {
          e.returnValue = false
        }
      }
    })
  }
})

Vue.directive('enterNumber', {
  inserted: function (el) {
    el.addEventListener('keypress', function (e) {
      e = e || window.event
      const charcode = typeof e.charCode === 'number' ? e.charCode : e.keyCode
      const re = /\d/
      if (charcode === 46) {
        e.preventDefault()
        return
      } else if (!re.test(String.fromCharCode(charcode)) && charcode > 9 && !e.ctrlKey) {
        if (e.preventDefault) {
          e.preventDefault()
        } else {
          e.returnValue = false
        }
      }
    })
  }
})

<template>
  <areasuit-detail :is-edit="true" />
</template>
<script>
import AreasuitDetail from './components/AreasuitDetail'
export default {
  name: 'UpdateAreasuit',
  components: { AreasuitDetail }
}
</script>
<style></style>

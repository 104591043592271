<template>
  <div class="app-container">
    <el-card class="header-card">
      <el-form :inline="true" :model="formData" class="demo-form-inline">
        <el-form-item label="店铺名称">
          <el-input v-model="formData.shopName" placeholder="请填写店铺名称" size="mini" />
        </el-form-item>
        <el-form-item label="审核状态">
          <el-select v-model="formData.statusIds" placeholder="请选择" size="mini" clearable>
            <el-option v-for="item in audits" :key="item.val" :label="item.label" :value="item.val" />
          </el-select>
        </el-form-item>
      </el-form>
      <el-row class="search-area-btn">
        <div class="left">
          <button-import v-if="isManageMentRole" :loading="loading" :action="excelUploadAction" :title="'企业导入'" :size="'mini'" @success="excelUploadSuccess" />
          <el-button :loading="loading" type="primary" size="mini" @click="templateDown">导入模板下载</el-button>
        </div>
        <div class="center">
          <el-button :loading="loading" type="primary" size="mini" @click="handleSearch">查询</el-button>
          <el-button :loading="loading" size="mini" @click="handleReset">重置</el-button>
        </div>
      </el-row>
    </el-card>

    <el-card class="table-card">
      <template>
        <el-table v-loading="loading" :data="tableData" style="width: 100%" class="m-t-12" border :header-cell-style="{ background: '#eef1f6', color: '#606266', textAlign: 'center' }">
          <el-table-column label="序号" type="index" align="center" width="50" />
          <el-table-column prop="shopName" label="店铺名称" align="center" width="200" />
          <el-table-column prop="userName" label="用户名" align="center" width="180" />
          <el-table-column prop="contact" label="店铺负责人" align="center" width="180" />
          <el-table-column prop="mobile" label="店铺联系方式" align="center" width="180" />
          <el-table-column prop="createdTime" label="申请时间" align="center" width="180" />
          <el-table-column prop="updatedTime" label="审核时间" align="center" width="180">
            <template slot-scope="scope">
              {{ scope.row.status === 2 ? '' : scope.row.updatedTime }}
            </template>
          </el-table-column>
          <el-table-column v-if="isAdmin" label="店铺启用" width="80" align="center">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.isEnable" :active-value="0" :inactive-value="1" @change="handleUpdateStatus(scope.$index, scope.row)" />
            </template>
          </el-table-column>
          <el-table-column prop="status" label="审核状态" align="center" width="120">
            <template slot-scope="scope">
              {{ scope.row.status | handleStatus }}
            </template>
          </el-table-column>
          <el-table-column prop="sort" label="排序" align="center">
            <template slot-scope="scope">
              <span v-if="!isShowEditSortArr[scope.$index]" style="display: block; cursor: pointer" @click="handleEditSort(scope.row.sort, scope.$index)">{{ scope.row.sort || 0 }}</span>
              <el-input v-else v-model="scope.row.sort" placeholder="请输入内容" @blur="confirmEditSort(scope.row.sort, scope.row.id, scope.$index)" />
            </template>
          </el-table-column>
          <el-table-column prop="auditOpinion" label="审批意见" align="center" />
          <el-table-column fixed="right" label="操作" align="left" width="180">
            <template slot-scope="scope">
              <el-button size="mini" @click="auditClick(scope.row.id, false)">查看</el-button>
              <el-button v-if="scope.row.status === 2" type="warning" size="mini" @click="auditClick(scope.row.id, 'verify')">审核</el-button>
              <el-button v-else type="primary" size="mini" @click="auditClick(scope.row.id, 'edit')">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <div class="m-w cen-box m-t-12">
        <el-pagination :current-page="page" :page-sizes="pageSize" :page-size="size" :total="count" :layout="'total, sizes, prev, pager, next, jumper'" @size-change="pageSizeChange" @current-change="pageNumberChange" />
      </div>
    </el-card>
    <component :is="storeAuditDetail" v-if="storeAuditDetailVisible" :id="editId" :edit="itemEdit" :verify="itemVerify" :categoryList="categoryList" @close="close" />
  </div>
</template>

<script>
import { getStorage } from '@/utils/storage'
import { mapState } from 'vuex'

import { saveAs } from 'file-saver'

export default {
  name: 'StoreAudit',
  components: {
    storeAuditDetail: () => import('./components/storeAuditDetail')
  },
  filters: {
    handleStatus(val) {
      switch (val) {
        case 0:
          return '审核通过'
        case 1:
          return '审核拒绝'
        case 2:
          return '待审核'
        case 99:
          return '店铺已禁用'
      }
    }
  },
  data() {
    return {
      storeAuditDetail: 'storeAuditDetail',
      storeAuditDetailVisible: false,
      editId: '',
      isAdmin: false,
      itemEdit: false,
      itemVerify: false,
      formData: {
        shopName: '',
        statusIds: 2,
        companyType: 0
      },
      audits: [
        { val: 2, label: '待审核' },
        { val: 0, label: '审核通过' },
        { val: 1, label: '审核拒绝' },
        { val: 99, label: '店铺已禁用' }
      ],
      tableData: [],
      loading: false,
      page: 1,
      size: 10,
      count: 0,
      pageSize: [10, 20, 30, 40, 50],
      categoryList: [],
      isShowEditSortArr: [],
      excelUploadAction: this.$api.storeAudit.importUser()
    }
  },
  computed: {
    ...mapState({
      isManageMentRole: state => state.user.userRole
    })
  },
  created() {
    this.isAdmin = getStorage('admin') === '1' ? true : false
    this.getList()
    this.getCompanyCategory()
  },
  methods: {
    excelUploadSuccess(res) {
      console.log('导入执行成功', res)
      this.$confirm('文件导入执行成功，前去查看导入结果', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success'
      }).then(() => {
        this.$router.push('/reportcen/exportdown')
      })
    },
    getList() {
      const params = {
        pageNumber: this.page,
        pageSize: this.size,
        shopName: this.formData.shopName,
        companyType: this.formData.companyType,
        statusIds: String(this.formData.statusIds) ? String(this.formData.statusIds) : '1,2'
      }
      this.$api.storeAudit.shopList(params).then(res => {
        if (res.code === 200) {
          this.tableData = res.data.list
          this.count = res.data.total
        }
      })
    },
    handleEditSort(val, index) {
      this.isShowEditSortArr.splice(index, 1, true)
    },
    confirmEditSort(sort, id, index) {
      this.$confirm('是否要进行修改', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const params = {
            sort,
            id
          }
          this.$api.storeAudit.mustUpdateProduct(params).then(response => {
            if (response.code === 200) {
              this.$message({
                type: 'success',
                message: '修改成功',
                duration: 1000
              })
              this.getList()
              this.isShowEditSortArr.splice(index, 1, false)
            }
          })
        })
        .catch(() => {
          this.isShowEditSortArr.splice(index, 1, false)
          this.getList()
        })
    },
    // 获取经营范围
    getCompanyCategory() {
      this.$api.storeAudit.getCompanyCategory().then(res => {
        if (res.code === 200) {
          this.categoryList = res.listData
        }
      })
    },
    handleUpdateStatus(index, row) {
      this.$confirm('是否要修改启用状态?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const params = {
            shopId: row.id,
            isEnable: row.isEnable
          }
          this.updateStatus(params)
        })
        .catch(() => {
          this.getList()
        })
    },
    updateStatus(params) {
      this.$api.storeAudit.disabledShop(params).then(res => {
        if (res.code === 200) {
          this.$message.success('修改成功')
          this.getList()
        }
      })
    },
    auditClick(id, type) {
      if (type === 'edit') {
        this.itemEdit = true
      } else {
        this.itemEdit = false
      }
      if (type === 'verify') {
        this.itemVerify = true
      } else {
        this.itemVerify = false
      }
      this.editId = id
      this.storeAuditDetailVisible = true
    },
    templateDown() {
      const filename = '企业导入模板'
      const url = 'https://xinyinnetwork.oss-cn-hangzhou.aliyuncs.com/static/365tt/template/shop_template.xlsx'
      this.downloading = true
      this.getBlob(url, blob => {
        const _this = this
        try {
          saveAs(blob, filename)
          _this.downloading = false
        } catch (error) {
          console.log(error)
          _this.$message.error('下载失败，请重新下载')
          _this.downloading = false
        }
      })
    },
    getBlob(url, cb) {
      console.log('url:' + url)
      const _this = this
      var xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.responseType = 'blob'
      xhr.onload = function () {
        if (xhr.status === 200) {
          cb(xhr.response)
        } else {
          _this.$message.error('下载失败，请重新下载')
          _this.downloading = false
        }
      }
      xhr.send()
    },
    close() {
      this.storeAuditDetailVisible = false
      this.getList()
    },
    handleSearch() {
      this.page = 1
      this.getList()
    },
    handleReset() {},
    pageSizeChange(val) {
      this.size = val
      this.getList()
    },
    pageNumberChange(val) {
      this.page = val
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.table-card {
  width: 100%;
  margin-bottom: 40px;
  .search-area-btn {
    margin-bottom: 8px;
  }
  ::v-deep.el-card__body {
    padding-bottom: 0;
  }
}
::v-deep.el-pagination {
  text-align: right;
}
</style>

<template>
  <el-dialog v-dialogDrag :close-on-click-modal="false" :visible="true" title="属性列表" top="10vh" width="80%" @close="close">
    <div class="app-container">
      <el-card class="header-card">
        <el-row class="search-area-btn">
          <div class="left">
            <el-button type="primary" class="btn-add" size="mini" @click="addProductAttr()">添加</el-button>
          </div>
        </el-row>
      </el-card>
      <div class="table-container">
        <el-table ref="productAttrTable" v-loading="listLoading" :data="list" style="width: 100%" border @selection-change="handleSelectionChange">
          <el-table-column label="编号" width="100" align="center">
            <template slot-scope="scope">{{ scope.row.id }}</template>
          </el-table-column>
          <el-table-column label="属性名称" width="140" align="center">
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>
          <el-table-column label="商品类型" width="140" align="center">
            <template>{{ obj.cname }}</template>
          </el-table-column>
          <el-table-column label="属性是否可选" width="120" align="center">
            <template slot-scope="scope">{{ scope.row.selectType | selectTypeFilter }}</template>
          </el-table-column>
          <el-table-column label="属性值的录入方式" width="150" align="center">
            <template slot-scope="scope">{{ scope.row.inputType | inputTypeFilter }}</template>
          </el-table-column>
          <el-table-column label="可选值列表" align="center">
            <template slot-scope="scope">{{ scope.row.inputList }}</template>
          </el-table-column>
          <el-table-column label="排序" width="100" align="center">
            <template slot-scope="scope">{{ scope.row.sort }}</template>
          </el-table-column>
          <el-table-column label="操作" width="200" align="center">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="handleUpdate(scope.$index, scope.row)">编辑 </el-button>
              <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除 </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-container">
        <el-pagination background layout="total, sizes,prev, pager, next,jumper" :page-size="listQuery.pageSize" :page-sizes="[5, 10, 20, 30, 50, 100]" :current-page.sync="listQuery.pageNum" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </div>

    <productAttrDetail v-if="productAttrDetailShow" :obj="productAttrDetailObj" :is-edit="productAttrDetailEdit" @close="dialogClose" />
  </el-dialog>
</template>
<script>
import productAttrDetail from './components/ProductAttrDetail'

export default {
  name: 'ProductAttrList',
  components: {
    productAttrDetail
  },
  props: {
    obj: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 5,
        type: this.obj.type
      },
      operateType: null,
      multipleSelection: [],
      operates: [
        {
          label: '删除',
          value: 'deleteProductAttr'
        }
      ],
      productAttrDetailShow: false,
      productAttrDetailObj: {},
      productAttrDetailEdit: false
    }
  },
  created() {
    this.getList()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    dialogClose() {
      this.productAttrDetailShow = false
      this.productAttrDetailObj = {}
      this.productAttrDetailEdit = false
      this.getList()
    },
    getList() {
      this.listLoading = true
      this.$api.product.productAttrList(this.obj.cid, this.listQuery).then(response => {
        this.listLoading = false
        this.list = response.listData
        this.total = response.count
      })
    },
    addProductAttr() {
      this.productAttrDetailObj = { cid: this.obj.cid, type: this.obj.type }
      this.productAttrDetailEdit = false
      this.productAttrDetailShow = true
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleBatchOperate() {
      if (this.multipleSelection < 1) {
        this.$message({
          message: '请选择一条记录',
          type: 'warning',
          duration: 1000
        })
        return
      }
      if (this.operateType !== 'deleteProductAttr') {
        this.$message({
          message: '请选择批量操作类型',
          type: 'warning',
          duration: 1000
        })
        return
      }
      const ids = []
      for (let i = 0; i < this.multipleSelection.length; i++) {
        ids.push(this.multipleSelection[i].id)
      }
      this.handleDeleteProductAttr(ids)
    },
    handleSizeChange(val) {
      this.listQuery.pageNum = 1
      this.listQuery.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.pageNum = val
      this.getList()
    },
    handleUpdate(index, row) {
      this.productAttrDetailObj = { id: row.id }
      this.productAttrDetailEdit = true
      this.productAttrDetailShow = true
    },
    handleDeleteProductAttr(ids) {
      this.$confirm('是否要删除该属性', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          ids: ids
        }
        this.$api.product.deleteProductAttr(data).then(() => {
          this.$message({
            message: '删除成功',
            type: 'success',
            duration: 1000
          })
          this.getList()
        })
      })
    },
    handleDelete(index, row) {
      this.$api.product.cannotRepetition({ attributeId: row.id }).then(res => {
        if (res.code === 200) {
          const ids = []
          ids.push(row.id)
          this.handleDeleteProductAttr(ids)
        }
      })
    }
  },
  filters: {
    inputTypeFilter(value) {
      if (value === 1) {
        return '从列表中选取'
      } else {
        return '手工录入'
      }
    },
    selectTypeFilter(value) {
      if (value === 1) {
        return '单选'
      } else if (value === 2) {
        return '多选'
      } else {
        return '唯一'
      }
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.app-container {
  padding-bottom: 60px;
}
</style>

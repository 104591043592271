<template>
  <el-dialog v-dialogDrag :close-on-click-modal="false" :visible="true" title="新闻预览" top="10vh" width="60%" append-to-body @close="close">
    <div class="container">
      <div class="artHeader">
        <div class="dateBox">
          <p>{{ day }}</p>
          <span>{{ date }}</span>
        </div>
        <div class="leftBox">
          <h1>{{ newsData.title }}</h1>
          <div class="leftDesc">
            <p>发布者：新印科技</p>
            <p>浏览次数：{{ newsData.clicks }}</p>
          </div>
          <div class="imgList">
            <img src="@/assets/img/infoDetail/weibo.png" alt="" />
            <img src="@/assets/img/infoDetail/QQ.png" alt="" />
            <img src="@/assets/img/infoDetail/weixin.png" alt="" />
          </div>
        </div>
      </div>
      <div class="artBody" v-html="newsData.content"></div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">关闭</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  components: {},
  props: {
    newsData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      descObj: '',
      title: '',
      titleTab: '',
      day: '',
      date: '',
      prev: '',
      next: '',
      infoListArray: [],
      numTypeId: '',
      numId: '',
      count: 0
    }
  },
  watch: {},
  mounted() {
    let date = this.$utils.parseDate(this.newsData.publishDate, '{y}-{m}-{d}')
    this.day = date.split('-')[2]
    this.date = date.split('-')[0] + '-' + date.split('-')[1]
    console.log(date, 'jaiiodofeok')
  },
  methods: {
    close() {
      this.$emit('handlePreviewColse')
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  overflow: hidden;
  margin: 0 auto;
  width: 600px;
  p {
    margin: 0;
  }
  h1 {
    margin-top: 0;
    margin-bottom: 0;
  }
  p.titleTab {
    width: 100%;
    margin: 50px 0 40px;
    text-align: left;
    font-size: 28px;
    font-weight: bold;
    line-height: 37px;
    color: #888;
    span {
      color: #3376b5;
    }
  }
}
.artHeader {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .dateBox {
    margin-right: 54px;
    text-align: center;
    width: 130px;
    height: 130px;
    background: #dbdbdb;
    opacity: 1;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      font-size: 59px;
      font-weight: bold;
      color: #3376b5;
    }
    span {
      font-size: 26px;
      font-weight: 400;
      color: #000;
    }
  }
  .leftBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    h1 {
      font-size: 28px;
      font-weight: bold;
      line-height: 37px;
      color: #000000;
    }
    .leftDesc {
      margin: 15px 0 20px;
      display: flex;
      p {
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        color: #606060;
        margin-right: 50px;
      }
    }
    .imgList {
      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
  }
}
.artBody {
  text-align: center;
  margin-bottom: 30px;
  img {
    width: 1175px;
    height: 700px;
  }
  ::v-deep p {
    margin: 20px 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: #606060;
    text-align: left;
  }
  ::v-deep h1 {
    margin: 20px 0 30px;
    font-size: 28px;
    font-weight: bold;
    line-height: 37px;
    color: #000;
    text-align: center;
  }
  ::v-deep h6 {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: #959595;
  }
  ::v-deep h5 {
    box-sizing: border-box;
    padding: 2px 12px;
    margin: 20px auto 50px;
    width: 1200px;
    height: 180px;
    background: #7eabd5;
    p {
      text-indent: 20px;
      text-align: left;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      color: #ffffff;
    }
  }
}
.artFooter {
  width: 100%;
  margin-bottom: 84px;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  p {
    font-size: 14px;
    cursor: pointer;
  }
  span {
    color: #707070;
  }
}
</style>

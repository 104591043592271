<template>
  <div class="LineBox">
    <div class="headerBox">
      <div class="titleBox">
        <h1>参展企业数量</h1>
        <p>
          累计<span>{{ totalNumber }}</span
          >家企业入驻印博会
        </p>
      </div>
      <div class="dropBox">
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link"> {{ timeInit }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in timeSelectArry" :key="index" :command="item.command">{{ item.title }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div ref="chart" class="lineContent" style="width: 800px; height: 600px; margin: 0 auto"></div>
  </div>
</template>

<script>
let Echarts = require('echarts/lib/echarts')
export default {
  name: 'App',
  components: {},
  data() {
    return {
      timeSelectArry: [
        {
          title: '近7日',
          command: 'a'
        },
        {
          title: '近1个月',
          command: 'b'
        },
        {
          title: '近6个月',
          command: 'c'
        },
        {
          title: '近1年',
          command: 'd'
        }
      ],
      timeInit: '近7日',
      startTime: '',
      endTime: '',
      xAxisArry: [],
      seriesArry: [],
      totalNumber: 0
    }
  },
  mounted() {
    const now = new Date()
    this.startTime = this.$utils.parseDate(now.getTime() - 3600 * 1000 * 24 * 6, '{y}-{m}-{d}')
    this.endTime = this.$utils.parseDate(now, '{y}-{m}-{d}')
    this.lineDataReq()
  },
  methods: {
    lineEcharts(xAxisArry, seriesArry) {
      this.chart = Echarts.init(this.$refs.chart)
      var option = {
        tooltip: {
          trigger: 'axis',
          formatter(params) {
            for (let x in params) {
              return '日期：' + params[x].name + ' ' + '数量：' + params[x].data
            }
          }
        },
        xAxis: {
          type: 'category',
          data: xAxisArry
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: seriesArry,
            type: 'line'
          }
        ]
      }
      this.chart.setOption(option)
    },
    handleCommand(commond) {
      switch (commond) {
        case 'a':
          this.timeInit = this.timeSelectArry[0].title
          var now = new Date()
          this.startTime = this.$utils.parseDate(now.getTime() - 3600 * 1000 * 24 * 6, '{y}-{m}-{d}')
          this.endTime = this.$utils.parseDate(now, '{y}-{m}-{d}')
          break
        case 'b':
          this.timeInit = this.timeSelectArry[1].title
          var now2 = new Date()
          this.startTime = this.$utils.parseDate(new Date().setMonth(now2.getMonth() - 1), '{y}-{m}-{d}')
          this.endTime = this.$utils.parseDate(now2, '{y}-{m}-{d}')
          break
        case 'c':
          this.timeInit = this.timeSelectArry[2].title
          var now3 = new Date()
          this.startTime = this.$utils.parseDate(new Date().setMonth(now3.getMonth() - 6), '{y}-{m}-{d}')
          this.endTime = this.$utils.parseDate(now3, '{y}-{m}-{d}')
          break
        case 'd':
          this.timeInit = this.timeSelectArry[3].title
          var now4 = new Date()
          this.startTime = this.$utils.parseDate(new Date().setMonth(now4.getMonth() - 12), '{y}-{m}-{d}')
          this.endTime = this.$utils.parseDate(now4, '{y}-{m}-{d}')
          break
      }
      this.lineDataReq()
    },
    lineDataReq() {
      let params = {
        startTime: this.startTime,
        endTime: this.endTime
      }
      this.$api.home.shopCountReq(params).then(res => {
        if (res.code === 200) {
          this.totalNumber = res.data.totalNumber
          let data = res.data.list
          this.xAxisArry = []
          this.seriesArry = []
          for (let i = 0; i < data.length; i++) {
            if (data.length === 7) {
              this.xAxisArry.push(data[i].date.split('-').splice(1, 2).join('-'))
            } else {
              this.xAxisArry.push(data[i].date)
            }
            this.seriesArry.push(data[i].shopCount)
          }
          this.lineEcharts(this.xAxisArry, this.seriesArry)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.LineBox {
  box-sizing: border-box;
  padding: 18px 15px;
  background-color: #fff;
  border-radius: 10px;
  width: 49.5%;
  margin: 0 16px 13px 0;
}
.headerBox {
  display: flex;
  justify-content: space-between;
}
.titleBox {
  display: flex;
  h1 {
    margin: 0 30px 0 0;
    color: #333;
    font-size: 16px;
  }
  p {
    margin: 0;
    font-size: 14px;
    color: #666;
  }
  span {
    color: #ff0000;
  }
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>

<template>
  <div class="app-container">
    <el-card class="header-card">
      <el-form :inline="true" :model="formData" class="demo-form-inline">
        <el-row>
          <el-col :span="5">
            <el-form-item label="分类名称">
              <el-input v-model="formData.categoryName" placeholder="请输入关键词" clearable maxlength="30" />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="5">
            <el-form-item label="状态">
              <el-select v-model="formData.disabled" placeholder="请选择" clearable>
                <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="生效时间">
              <el-date-picker v-model="formData.date" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['12:00:00']" format="yyyy-MM-dd" />
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
      <el-row class="search-area-btn">
        <div class="left">
          <el-button type="primary" size="mini" @click="handleAdd">新增</el-button>
        </div>
        <div class="center">
          <el-button :loading="loading" type="primary" size="mini" @click="handleSearch">查询</el-button>
          <el-button :loading="loading" size="mini" @click="handleReset">重置</el-button>
        </div>
      </el-row>
    </el-card>
    <el-card class="table-card">
      <div>
        <el-table v-loading="loading" row-key="id" :data="tableData" style="width: 100%" highlight-current-row class="m-t-12" border :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
          <el-table-column prop="categoryName" label="分类名称" align="center" />
          <el-table-column prop="description" label="分类描述" align="center" />
          <el-table-column prop="sort" label="排序" align="center" />
          <el-table-column label="操作" align="center" fixed="right" width="180">
            <template slot-scope="scope">
              <el-button type="primary" @click="handleEditBtn(scope.row)">编辑</el-button>
              <el-button type="danger" @click="handleDeleteBtn(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="center">
          <el-pagination :current-page="pageData.page" :page-sizes="pageData.pageSize" :page-size="pageData.size" :total="pageData.count" :layout="'total, sizes, prev, pager, next, jumper'" @size-change="pageSizeChange" @current-change="pageNumberChange" />
        </div>
      </div>
    </el-card>
    <component :is="addCategoryDialog" v-if="addCategoryDialogVisible" :obj="nowItem" @refreshData="refreshData" @close="close" />
  </div>
</template>

<script>
export default {
  components: {
    addCategoryDialog: () => import('./components/addCategoryDialog.vue')
  },
  props: {},
  watch: {},
  data() {
    return {
      loading: false,
      addCategoryDialog: 'addCategoryDialog',
      addCategoryDialogVisible: false,
      nowItem: {},
      formData: {
        categoryName: '',
        disabled: '',
        date: ''
      },
      statusList: [
        { label: '全部', value: '' },
        { label: '展示', value: '1' },
        { label: '关闭', value: '2' }
      ],
      tableData: [],
      pageData: {
        page: 1,
        size: 10,
        count: 0,
        pageSize: [10, 20, 30, 40, 50]
      }
    }
  },
  computed: {},
  created() {},
  mounted() {
    this.getList()
  },
  methods: {
    handleEditBtn(item) {
      this.nowItem = item
      this.addCategoryDialogVisible = true
    },
    handleDeleteBtn(item) {
      this.$confirm('确定删除该数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.newsClass.categoryRemove({ id: item.id }).then(res => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.getList()
          }
        })
      })
    },
    getList() {
      const parmas = {
        pageNumber: this.pageData.page,
        pageSize: this.pageData.size,
        ...this.formData
      }
      this.$api.newsClass.categoryList(parmas).then(res => {
        if (res.code === 200) {
          this.tableData = res.data.list
          this.pageData.count = res.data.total
        }
      })
    },
    close() {
      this.addCategoryDialogVisible = false
    },
    refreshData() {
      this.getList()
      this.close()
    },
    handleAdd() {
      this.nowItem = {}
      this.addCategoryDialogVisible = true
    },
    pageSizeChange(val) {
      this.pageData.size = val
      this.getList()
    },
    pageNumberChange(val) {
      this.pageData.page = val
      this.getList()
    },
    handleSearch() {
      this.pageData.page = 1
      this.getList()
    },
    handleReset() {
      this.formData = {
        categoryName: '',
        status: '',
        date: ''
      }
      this.pageData.page = 1
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.el-pagination {
  margin-top: 10px;
  text-align: center;
}
</style>

import request from '@/api/request'
import qs from 'qs'

const notice = {
  addNoticeInfo(params) {
    return request({
      url: '/expositionShop/news/create',
      method: 'post',
      data: params
    })
  },
  getNoticeList(params) {
    return request({
      url: '/expositionShop/news/list',
      method: 'get',
      params: params
    })
  },
  removeNotic(params) {
    return request({
      url: '/expositionShop/news/remove',
      method: 'post',
      data: params
    })
  },
  getYzxinstructions(id) {
    return request({
      url: `/shopweb/web/instructions/get/${id}`,
      method: 'get'
    })
  },
  saveYzxinstructions(params) {
    return request({
      url: '/expositionShopweb/web/instructions/save',
      method: 'POST',
      data: qs.stringify(params)
    })
  },
  updateYzxinstructions(params) {
    return request({
      url: '/expositionShopweb/web/instructions/update',
      method: 'POST',
      data: qs.stringify(params)
    })
  }
}

export default notice

<template>
  <div class="app-container">
    <el-card class="header-card">
      <el-row class="search-area-btn">
        <div class="left">
          <el-button type="primary" class="btn-add" size="mini" @click="handleAddProductCate()">添加</el-button>
          <el-button v-if="parentId" size="mini" @click="handleShowprevLevel">返回上级 </el-button>
        </div>
      </el-row>
    </el-card>
    <div class="table-container">
      <el-table ref="productCateTable" v-loading="listLoading" style="width: 100%" :data="list" border>
        <el-table-column label="编号" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column label="分类名称" align="center">
          <template slot-scope="scope">{{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column label="级别" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.level | levelFilter }}</template>
        </el-table-column>
        <el-table-column label="商品数量" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.productCount }}</template>
        </el-table-column>
        <el-table-column label="数量单位" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.productUnit }}</template>
        </el-table-column>
        <!-- <el-table-column label="导航栏" width="100" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.navStatus" :active-value="1" :inactive-value="0" @change="handleNavStatusChange(scope.$index, scope.row)" />
          </template>
        </el-table-column>
        <el-table-column label="显示平台" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.use === 2 ? '博览会' : scope.row.use === 1 ? '商城' : '' }}</template>
        </el-table-column> -->
        <el-table-column label="是否显示" width="100" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.showStatus" :active-value="1" :inactive-value="0" @change="handleShowStatusChange(scope.$index, scope.row)" />
          </template>
        </el-table-column>
        <el-table-column label="排序" width="80" align="center">
          <template slot-scope="scope">{{ scope.row.sort }}</template>
        </el-table-column>
        <el-table-column label="操作" width="340" align="center">
          <template slot-scope="scope">
            <el-button v-if="!scope.row.level" size="mini" @click="handleShowNextLevel(scope.$index, scope.row)">查看下级 </el-button>
            <el-button type="primary" size="mini" @click="handleUpdate(scope.$index, scope.row)">编辑 </el-button>
            <el-button type="danger" size="mini" @click="handleDelete(scope.$index, scope.row)">删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination background layout="total, sizes,prev, pager, next,jumper" :page-size="listQuery.pageSize" :page-sizes="[5, 10, 15]" :current-page.sync="listQuery.pageNum" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
    <productCateDetail v-if="productCateDetailShow" :id="productCateDetailId" :is-edit="productCateDetailEdit" @close="close" />
  </div>
</template>

<script>
import productCateDetail from './components/ProductCateDetail'

export default {
  name: 'ProductCateList',
  components: {
    productCateDetail
  },
  filters: {
    levelFilter(value) {
      if (value === 0) {
        return '一级'
      } else if (value === 1) {
        return '二级'
      }
    },
    disableNextLevel(value) {
      if (value === 0) {
        return false
      } else {
        return true
      }
    }
  },
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 10,
        use: 2
      },
      parentId: 0,
      productCateDetailShow: false,
      productCateDetailEdit: false,
      productCateDetailId: null
    }
  },
  watch: {
    $route() {
      this.resetParentId()
      this.getList()
    }
  },
  created() {
    this.resetParentId()
    this.getList()
  },
  methods: {
    close() {
      this.productCateDetailShow = false
      this.productCateDetailId = null
      this.productCateDetailEdit = false
      this.resetParentId()
      this.getList()
    },
    handleShowprevLevel() {
      this.$router.push({ path: this.$route.path })
    },
    resetParentId() {
      this.listQuery.pageNum = 1
      if (this.$route.query.parentId != null) {
        this.parentId = this.$route.query.parentId
      } else {
        this.parentId = 0
      }
    },
    handleAddProductCate() {
      this.productCateDetailId = null
      this.productCateDetailEdit = false
      this.productCateDetailShow = true
    },
    getList() {
      this.listLoading = true
      this.$api.productCate.productCategoryList(this.parentId, this.listQuery).then(response => {
        this.listLoading = false
        this.list = response.listData
        this.total = response.count
      })
    },
    handleSizeChange(val) {
      this.listQuery.pageNum = 1
      this.listQuery.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.pageNum = val
      this.getList()
    },
    handleNavStatusChange(index, row) {
      const ids = []
      ids.push(row.id)
      let data = {
        ids: ids,
        navStatus: row.navStatus
      }
      this.$api.productCate.updateNavStatus(data).then(() => {
        this.$message({
          message: '修改成功',
          type: 'success',
          duration: 1000
        })
      })
    },
    handleShowStatusChange(index, row) {
      console.log(row.showStatus)
      if (this.parentId !== 0 && row.productCount <= 0 && row.showStatus === 1) {
        this.list[index].showStatus = 0
        this.$message({
          type: 'warning',
          message: '当前分类下的商品数量为0，请添加此类商品后重试'
        })
        return
      }

      const ids = []
      ids.push(row.id)
      let data = {
        ids: ids,
        showStatus: row.showStatus
      }
      this.$api.productCate
        .updateShowStatus(data)
        .then(response => {
          if (response.code === 200) {
            this.$message({
              message: '修改成功',
              type: 'success',
              duration: 1000
            })
          }
        })
        .catch(() => {
          this.list[index].showStatus = 1
          this.$forceUpdate()
        })
    },
    handleShowNextLevel(index, row) {
      this.$router.push({
        path: '/pms/productCate',
        query: { parentId: row.id }
      })
    },
    handleTransferProduct() {
      console.log('handleAddProductCate')
    },
    handleUpdate(index, row) {
      this.productCateDetailId = parseInt(row.id)
      this.productCateDetailEdit = true
      this.productCateDetailShow = true
    },
    handleDelete(index, row) {
      this.$confirm('是否要删除该分类', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.productCate.deleteProductCate(row.id).then(() => {
          this.$message({
            message: '删除成功',
            type: 'success',
            duration: 1000
          })
          this.getList()
        })
      })
    }
  }
}
</script>

<style scoped></style>

<template>
  <el-dialog v-dialogDrag append-to-body :close-on-click-modal="false" :visible="true" :title="(isEdit ? '编辑' : '新增') + '属性'" top="10vh" width="60%" @close="close">
    <el-card class="container" shadow="never">
      <el-form ref="productAttrFrom" :model="productAttr" :rules="rules" label-width="150px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="属性名称：" prop="name">
              <el-input v-model="productAttr.name" :disabled="isEdit && isRelevance" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品类型：" prop="productAttributeCategoryId">
              <el-select v-model="productAttr.productAttributeCategoryId" placeholder="请选择" :disabled="isEdit && isRelevance">
                <el-option v-for="item in productAttrCateList" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="分类筛选样式:">
              <el-radio-group v-model="productAttr.filterType">
                <el-radio :label="0">普通</el-radio>
                <el-radio :label="1">颜色</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="能否进行检索:">
              <el-radio-group v-model="productAttr.searchType">
                <el-radio :label="0">不需要检索</el-radio>
                <el-radio :label="1">关键字检索</el-radio>
                <el-radio :label="2">范围检索</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="商品属性关联:">
              <el-radio-group v-model="productAttr.relatedStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="属性是否可选:">
              <el-radio-group v-model="productAttr.selectType" :disabled="isEdit && isRelevance">
                <el-radio :label="0">唯一</el-radio>
                <el-radio :label="1">单选</el-radio>
                <el-radio :label="2">复选</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="属性值的录入方式:">
              <el-radio-group v-model="productAttr.inputType" :disabled="isEdit && isRelevance">
                <el-radio :label="0">手工录入</el-radio>
                <el-radio :label="1">从下面列表中选择</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="属性值可选值列表:">
              <el-input v-model="inputListFormat" :autosize="true" type="textarea" :disabled="isEdit && isRelevance" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="是否支持手动新增:">
              <el-radio-group v-model="productAttr.handAddStatus" :disabled="isEdit && isRelevance">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排序属性：">
              <el-input v-model="productAttr.sort" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex" justify="center">
        <el-button type="primary" @click="onSubmit('productAttrFrom')">提交</el-button>
        <el-button @click="close">取消</el-button>
      </el-row>
    </el-card>
  </el-dialog>
</template>

<script>
const defaultProductAttr = {
  filterType: 0,
  handAddStatus: 0,
  inputList: '',
  inputType: 0,
  name: '',
  productAttributeCategoryId: null,
  relatedStatus: 0,
  searchType: 0,
  selectType: 0,
  sort: 0,
  type: 0
}
export default {
  name: 'ProductAttrDetail',
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    obj: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      productAttr: Object.assign({}, defaultProductAttr),
      rules: {
        name: [
          { required: true, message: '请输入属性名称', trigger: 'blur' },
          {
            min: 2,
            max: 140,
            message: '长度在 2 到 140 个字符',
            trigger: 'blur'
          }
        ],
        productAttributeCategoryId: [{ required: true, message: '请选择商品类型', trigger: 'blur' }]
      },
      productAttrCateList: null,
      inputListFormat: null,
      isRelevance: true
    }
  },
  watch: {
    inputListFormat(newValue) {
      newValue = newValue.replace(/\n/g, ',')
      this.productAttr.inputList = newValue
    }
  },
  created() {
    if (this.isEdit) {
      this.$api.product.getProductAttr(this.obj.id).then(response => {
        this.productAttr = response.data
        this.inputListFormat = this.productAttr.inputList.replace(/,/g, '\n')
        this.cannotRepetition(response.data.id)
      })
    } else {
      this.resetProductAttr()
    }
    this.getCateList()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    cannotRepetition(id) {
      this.$api.product.cannotRepetition({ attributeId: id }).then(res => {
        if (res.code === 200) {
          this.isRelevance = false
        }
      })
    },
    getCateList() {
      const listQuery = { pageNum: 1, pageSize: 100 }
      this.$api.product.categoryList(listQuery).then(response => {
        this.productAttrCateList = response.listData
      })
    },
    resetProductAttr() {
      this.productAttr = Object.assign({}, defaultProductAttr)
      if (this.obj.cid) {
        this.productAttr.productAttributeCategoryId = Number(this.obj.cid)
      }
      if (this.obj.type) {
        this.productAttr.type = Number(this.obj.type)
      }
    },
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.productAttr.sort = Number(this.productAttr.sort)
            if (this.isEdit) {
              this.$api.product.updateProductAttr(this.obj.id, this.productAttr).then(() => {
                this.$message({
                  message: '修改成功',
                  type: 'success',
                  duration: 1000
                })
                this.close()
              })
            } else {
              this.$api.product.createProductAttr(this.productAttr).then(() => {
                this.$message({
                  message: '提交成功',
                  type: 'success',
                  duration: 1000
                })
                this.close()
              })
            }
          })
        } else {
          this.$message({
            message: '验证失败',
            type: 'error',
            duration: 1000
          })
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.inputListFormat = ''
      this.resetProductAttr()
    }
  }
}
</script>

<style scoped></style>

<template>
  <div class="content">
    <el-row class="account-area">
      <el-row :gutter="20">
        <el-col :span="8" class="left-area">
          <div>
            <div class="header-area">
              <span>账户信息</span>
            </div>
            <el-row class="main-content">
              <!-- <el-col :span="12" class="left">
                <img :src="shop.mainUrl" alt="" v-if="shop.mainUrl" />
                <img :src="img1" alt="" v-else />
                <div class="left-r">
                  <p v-if="shop.status === 0">{{ shop.companyName }}<span>已认证</span></p>
                  <p v-if="shop.status !== 0">{{ shop.companyName }}<span>未认证</span></p> 
                   <p>
                    企业编码:<span>{{ shop.companyCode }}</span>
                  </p>
                  <p>
                    认证期限:<span>{{ shop.validityFinish | handTime }} 到期</span>
                  </p> 
                </div>
              </el-col> -->
              <el-col :span="24" class="right">
                <div class="left-t">
                  <img v-if="shop.mainUrl" :src="shop.mainUrl" alt="" />
                  <img v-else :src="img1" alt="" />
                  <div class="left-t-r">
                    {{ shop.shopName }}
                    <!-- <span class="payMoney" @click="payMoney" v-if="bondStatus === 0">待缴费</span>
                    <span class="payMoney" v-else>已缴费</span> -->
                  </div>
                </div>
                <div class="left-b">
                  <div class="left-text">
                    <span>{{ listData3.openProduct ? listData3.openProduct : 0 }}</span>
                    <span>已上架商品</span>
                  </div>
                  <div class="left-text">
                    <span>{{ listData3.closeProduct ? listData3.closeProduct : 0 }}</span>
                    <span>已下架商品</span>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="16" class="right-area">
          <div>
            <div class="header-area">
              <span>我的账户钱包</span>
            </div>
            <div class="main-content">
              <el-row>
                <el-col :span="6">
                  <p>我的余额</p>
                  <p>{{ user.amount | moneyFilter }}<span class="unit">元</span></p>
                </el-col>
                <el-col :span="6">
                  <p>可提现金额</p>
                  <p>{{ user.amountValid | moneyFilter }}<span class="unit">元</span></p>
                  <el-button @click="widthdrawMoney">立即提现</el-button>
                </el-col>
                <el-col :span="6">
                  <p>已提现</p>
                  <p>{{ amountCount | moneyFilter }}<span class="unit">元</span></p>
                  <a href="#" @click="accountDetailVisible = true">查看明细 ></a>
                </el-col>
                <el-col :span="6">
                  <p>冻结金额</p>
                  <p>{{ user.amountFreeze | moneyFilter }}<span class="unit">元</span></p>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-row>
    <el-row class="main-area">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="结算记录" name="first">
          <template>
            <el-table ref="multipleTable" v-loading="loading" :data="tableData" border stripe style="width: 100%" height="530" highlight-current-row :header-cell-style="{ background: '#EEF1F6', color: '#646A73' }">
              <el-table-column prop="orderSn" label="订单编号" width="250" align="center" />
              <el-table-column prop="businessType" label="业务类型" width="200" align="center">
                <template slot-scope="scope">
                  <span v-for="(item, index) in businessTypeLists" :key="index">
                    <span v-if="scope.row.businessType === item.dictValue">{{ item.dictLabel }}</span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="productName" label="商品名称" width="200" align="center">
                <template slot-scope="scope">
                  <div v-if="myIndexOf(scope.row.productName) !== -1">
                    <div v-for="(item, index) in returnArray(scope.row.productName)" :key="index">
                      <span>{{ item }}</span>
                    </div>
                  </div>
                  <div v-else>
                    <span>{{ scope.row.productName }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="totalAmount" label="订单金额" width="150" align="center" />
              <el-table-column prop="createTime" label="订单时间" width="200px" align="center">
                <template slot-scope="scope">
                  {{ scope.row.createTime }}
                </template>
              </el-table-column>
              <el-table-column prop="status" label="订单状态" width="150" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.status | statusFilter }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="settlementStatus" label="结算状态" width="150" align="center">
                <template slot-scope="scope">
                  <el-button size="small" :type="buttonClass(scope.row.settlementStatus)">{{ scope.row.settlementStatus | settlementStatusFilter }}</el-button>
                </template>
              </el-table-column>
              <el-table-column prop="lstUpdTime" label="结算时间" width="200px" align="center">
                <template slot-scope="scope">
                  {{ scope.row.lstUpdTime }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" fixed="right">
                <template slot-scope="scope">
                  <!-- 0301：商城退款 -->
                  <el-button @click="handleViewOrder(scope.$index, scope.row)" size="mini" v-if="scope.row.businessType !== '0301'">查看详情</el-button>
                  <el-button @click="handleViewDetail(scope.$index, scope.row)" size="mini" v-else>退款详情</el-button>
                </template>
              </el-table-column>
            </el-table>
          </template>
          <!-- 分页 -->
          <div class="pagination">
            <el-pagination :current-page="page" :page-sizes="pageSize" :page-size="size" :total="count" :layout="'total, sizes, prev, pager, next, jumper'" @size-change="pageSizeChange" @current-change="pageNumberChange" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="提现账户" name="second">
          <div class="tips-area" v-if="false">
            <div class="top">
              <div class="left"><img :src="warningIcon" alt="" />商户提现规则</div>
              <div class="right">
                <img :src="closeIcon" alt="" @click="promptIf" />
              </div>
            </div>
            <div class="tips-content">
              <ul>
                <li>我是商户提现规则，我是商户提现规则，我是商户提现规则</li>
                <li>提交提现申请后，工作人员将在1~3个工作日内打款</li>
              </ul>
            </div>
          </div>
          <div v-if="!showAddAccount" class="account-main">
            <el-row class="account-info">
              <span class="activePay">{{ user.acountType | accountListName }}</span>
            </el-row>
            <el-row class="account-list">
              <!-- <div v-if="user.acountType === '0'">
                <div class="list-item" v-if="aliAccountList.length">
                  <el-row>
                    <el-image :src="aliIcon" alt="" class="image"></el-image>
                    <span class="span">提现到支付宝</span>
                  </el-row>
                  <div class="text">
                    <el-row>
                      <p>
                        <span class="span2">支付宝姓名：</span><span class="value">{{ user.acountName }}</span>
                      </p>
                      <p>
                        <span class="span2">支付宝账户：</span><span class="value">{{ user.acountNo }}</span>
                      </p>
                    </el-row>
                  </div>
                  <div class="bu">
                    <p>
                      <button class="button" @click="widthdrawMoney">
                        <span class="te">立即提现</span>
                      </button>
                    </p>
                    <p>
                      <button class="button2" @click="addAccountInfo">
                        <span class="xt">修改账户信息</span>
                      </button>
                    </p>
                  </div>
                </div>
                <div class="account-img" v-else>
                  <img :src="accountIcon" alt="" />
                  <span>暂无任何提现账户信息，快去添加吧~</span>
                </div>
              </div> -->
              <!-- <div v-if="user.acountType === '1'">
                <div class="list-item" v-if="wxAccountList.length">
                  <el-row>
                    <el-image :src="wxIcon" alt="" class="image"></el-image>
                    <span class="span">提现到微信</span>
                  </el-row>
                  <div class="text">
                    <el-row>
                      <p>
                        <span class="span2">微信商户号：</span><span class="value">{{ user.acountName }}</span>
                      </p>
                      <p>
                        <span class="span2">微信商户ID：</span><span class="value">{{ user.acountNo }}</span>
                      </p>
                    </el-row>
                  </div>
                  <div class="bu">
                    <p>
                      <button class="button" @click="widthdrawMoney">
                        <span class="te">立即提现</span>
                      </button>
                    </p>
                    <p>
                      <button class="button2" @click="addAccountInfo">
                        <span class="xt">修改账户信息</span>
                      </button>
                    </p>
                  </div>
                </div>
                <div class="account-img" v-else>
                  <img :src="accountIcon" alt="" />
                  <span>暂无任何提现账户信息，快去添加吧~</span>
                </div>
              </div> -->
              <!--  -->
              <div v-if="user.acountType === '2'">
                <div class="list-item" v-if="cardAccountList.length">
                  <el-row>
                    <el-image :src="cardIcon" alt="" class="image"></el-image>
                    <span class="span">提现到银行卡</span>
                  </el-row>
                  <div class="text">
                    <el-row>
                      <p>
                        <span class="span2">持卡人姓名：</span><span class="value">{{ user.acountName }}</span>
                      </p>
                      <p>
                        <span class="span2">银行名称：</span><span class="value">{{ user.bank }}</span>
                      </p>
                      <p>
                        <span class="span2">银行卡号：</span><span class="value">{{ user.acountNo }}</span>
                      </p>
                    </el-row>
                  </div>
                  <div class="bu">
                    <p>
                      <button class="button" @click="widthdrawMoney">
                        <span class="te">立即提现</span>
                      </button>
                    </p>
                    <p>
                      <button class="button2" @click="addAccountInfo">
                        <span class="xt">修改账户信息</span>
                      </button>
                    </p>
                  </div>
                </div>
                <div class="account-img" v-else>
                  <img :src="accountIcon" alt="" />
                  <span>暂无任何提现账户信息，快去添加吧~</span>
                </div>
              </div>
            </el-row>
            <el-row class="account-btn" v-if="user.acountType === null || user.acountType === ''">
              <el-button @click="addAccountInfo" class="insertButton">新增提现账户</el-button>
            </el-row>
          </div>
          <div v-if="showAddAccount" class="main-content">
            <div class="steps-area">
              <div v-for="(item, index) in stepsList" :key="index">
                <img :src="active <= item.id ? item.icon1 : item.selected" alt="" />
                <span>{{ item.tips }}</span>
              </div>
            </div>
            <div class="inner-area">
              <el-row v-if="active === 1" class="step1">
                <!--  第一步 -->
                <el-row class="style-area">
                  <el-col :span="8" v-for="(item, index) in payList" :key="index">
                    <div @click="changePay(item.id)">
                      <img :src="item.img" alt="" />
                      <span :class="activePay === item.id ? 'active' : ''">{{ item.desc }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="btn-area">
                  <el-button type="primary" @click="nextStep(1)">下一步</el-button>
                  <!--                  <el-button type="primary" @click="onStep(0)">上一步</el-button>-->
                </el-row>
              </el-row>
              <el-row v-if="active === 2" class="step2">
                <!--  第二步 -->
                <div v-if="activePay === 1">
                  <!-- 支付宝 -->
                  <el-row class="step2-title">
                    <img :src="aliIcon" alt="" />
                    <span>提现到支付宝</span>
                  </el-row>
                  <el-row class="step2-form">
                    <el-form :model="aliForm" :rules="aliRules" ref="aliForm">
                      <span class="tips-text">实名账户姓名 <i>*</i></span>
                      <el-form-item prop="name">
                        <el-input v-model="aliForm.name" placeholder="请输入真实实名姓名"></el-input>
                      </el-form-item>
                      <span class="tips-text">支付宝账户 <i>*</i></span>
                      <el-form-item prop="account">
                        <el-input v-model="aliForm.account" maxlength="100" placeholder="请输入要转入的支付宝账户/手机号码"></el-input>
                      </el-form-item>
                    </el-form>
                  </el-row>
                  <el-row class="step2-btn">
                    <el-button type="primary" @click="sureAccoutInfo('aliForm')">下一步</el-button><br />
                    <el-button type="primary" @click="onStep(1)">上一步</el-button>
                  </el-row>
                </div>
                <div v-if="activePay === 2">
                  <!-- 微信 -->
                  <el-row class="step2-title">
                    <img :src="wxIcon" alt="" />
                    <span>提现到微信</span>
                  </el-row>
                  <el-row class="step2-form">
                    <el-form :model="wxForm" :rules="wxRules" ref="wxForm">
                      <span class="tips-text">微信商户名称/用户姓名 <i>*</i></span>
                      <el-form-item prop="name">
                        <el-input v-model="wxForm.name" placeholder="请输入真实姓名"></el-input>
                      </el-form-item>
                      <span class="tips-text">微信支付商户ID/手机号 <i>*</i></span>
                      <el-form-item prop="account">
                        <el-input v-model="wxForm.account" maxlength="100" placeholder="请输入要转入的微信支付商户ID/手机号"></el-input>
                      </el-form-item>
                    </el-form>
                  </el-row>
                  <el-row class="step2-btn">
                    <el-button type="primary" @click="sureAccoutInfo('wxForm')">下一步</el-button><br />
                    <el-button type="primary" @click="onStep(1)">上一步</el-button>
                  </el-row>
                </div>
                <div v-if="activePay === 3">
                  <!-- 银行卡 -->
                  <el-row class="step2-title">
                    <img :src="cardIcon" alt="" />
                    <span>提现到银行卡</span>
                  </el-row>
                  <el-row class="step2-form">
                    <el-form :model="cardForm" :rules="cardRules" ref="cardForm">
                      <span class="tips-text">持卡人姓名 <i>*</i></span>
                      <el-form-item prop="name">
                        <el-input v-model="cardForm.name" placeholder="请输入持卡人姓名"></el-input>
                      </el-form-item>
                      <span class="tips-text">开户银行 <i>*</i></span>
                      <el-form-item prop="bank">
                        <el-input v-model="cardForm.bank" placeholder="请输入要转入的开户银行"></el-input>
                        <!-- <p>具体到详细支行名</p> -->
                      </el-form-item>
                      <span class="tips-text">银行账号 <i>*</i></span>
                      <el-form-item prop="account">
                        <el-input v-model="cardForm.account" maxlength="19" placeholder="请输入要转入的银行账号"></el-input>
                      </el-form-item>
                    </el-form>
                  </el-row>
                  <el-row class="step2-btn">
                    <el-button type="primary" @click="sureAccoutInfo('cardForm')">下一步</el-button><br />
                    <el-button type="primary" @click="onStep(1)">上一步</el-button>
                  </el-row>
                </div>
              </el-row>
              <el-row v-if="active === 3" class="step3">
                <!-- 第三步 -->
                <div v-if="activePay === 1">
                  <el-row class="step3-title">
                    <img :src="aliIcon" alt="" />
                    <span>提现到支付宝</span>
                  </el-row>
                  <el-row class="step-main">
                    <p>
                      实名账户姓名 : <span>{{ aliForm.name }}</span>
                    </p>
                    <p>
                      支付宝账户 : <span>{{ aliForm.account }}</span>
                    </p>
                  </el-row>
                </div>
                <div v-if="activePay === 2">
                  <el-row class="step3-title">
                    <img :src="wxIcon" alt="" />
                    <span>提现到微信商户</span>
                  </el-row>
                  <el-row class="step-main">
                    <p>
                      微信商户名称/用户姓名 : <span>{{ wxForm.name }}</span>
                    </p>
                    <p>
                      微信支付商户ID/手机号 : <span>{{ wxForm.account }}</span>
                    </p>
                  </el-row>
                </div>
                <div v-if="activePay === 3">
                  <el-row class="step3-title">
                    <img :src="cardIcon" alt="" />
                    <span>提现到银行卡</span>
                  </el-row>
                  <el-row class="step-main">
                    <p>
                      持卡人姓名 : <span>{{ cardForm.name }}</span>
                    </p>
                    <p>
                      银行名称 : <span>{{ cardForm.bank }}</span>
                    </p>
                    <p>
                      银行卡号 : <span>{{ cardForm.account }}</span>
                    </p>
                    <!--                    <p>开户银行 : <span>{{ aliForm.bank }}</span></p>-->
                  </el-row>
                </div>
                <div class="step3-btn">
                  <el-button type="primary" @click="onStep(2)">上一步</el-button>
                  <el-button type="primary" @click="submitAccount(1)">确定</el-button>
                  <!--                  <el-button @click="submitAccount(2)">完善其他提现方式信息</el-button>-->
                </div>
              </el-row>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <accountDetailDialog v-if="accountDetailVisible" :account-detail-visible="accountDetailVisible" @closeDialog="closeDialog" />
    <payDialog v-if="showPayVisible" :show-pay-visible="showPayVisible" @closePayDialog="closePayDialog" :shop="shop" @updateBondStatus="updateBondStatus" />
    <withdrawDialog v-if="withdrawVisible" :withdraw-visible="withdrawVisible" @closeWithdrawDialog="closeWithdrawDialog" :user="user" :shop="shop" />
  </div>
</template>

<script>
import img1 from '@/assets/images/merchants/icon_logo.png'
import closeIcon from '@/assets/images/merchants/close-icon.png'
import warningIcon from '@/assets/images/merchants/warning-icon.png'
import selectIcon1 from '@/assets/images/merchants/select-icon1.png'
import selectIcon2 from '@/assets/images/merchants/select-icon2.png'
import selectedIcon from '@/assets/images/merchants/seleted-icon.png'
import rightArrow from '@/assets/images/merchants/right-arrow.png'
import imgIcon from '@/assets/images/merchants/img-icon.png'
import aliIcon from '@/assets/images/merchants/ali-pay.png'
import wxIcon from '@/assets/images/merchants/wx-pay.png'
import cardIcon from '@/assets/images/merchants/card-pay.png'
import accountIcon from '@/assets/images/merchants/account-icon.png'

import accountDetailDialog from './components/accountDetailDialog.vue'
import payDialog from './components/payDialog.vue'
import withdrawDialog from './components/withdrawDialog.vue'
import { mapState } from 'vuex'
import store from '@/store'
import { getPlatform } from '@/utils/storage'
let aliForm = {
  name: '',
  account: ''
}
let wxForm = {
  name: '',
  account: ''
}
let cardForm = {
  name: '',
  account: '',
  bank: ''
}
const initAliForm = {
  ...aliForm
}
const initWxForm = {
  ...wxForm
}
const initCardForm = {
  ...cardForm
}

export default {
  components: {
    accountDetailDialog,
    payDialog,
    withdrawDialog
  },
  data() {
    return {
      activeName: 'first',
      img1: img1,
      closeIcon,
      warningIcon,
      rightArrow,
      aliIcon,
      wxIcon,
      cardIcon,
      accountIcon,
      loading: false,
      accountDetailVisible: false, // 账户明细
      showPayVisible: false, // 缴纳保证金弹框
      withdrawVisible: false, // 提现弹框
      tableData: [],
      page: 1,
      size: 10,
      count: 0,
      pageSize: [5, 10, 20, 30, 50, 100],
      active: 1, // 当前所在步骤
      activePay: 3, // 当前选择的支付方式
      showAddAccount: false, // 是否选择添加账户
      stepsList: [
        {
          id: 1,
          tips: '选择提现方式',
          icon1: selectIcon1,
          selected: selectedIcon
        },
        {
          id: 2,
          tips: '完善账号信息',
          icon1: selectIcon2,
          selected: selectedIcon
        },
        {
          id: 3,
          tips: '确认信息',
          icon1: imgIcon,
          selected: imgIcon
        }
      ],
      accountList: [
        // {
        //   id: 1,
        //   name: '支付宝账户'
        // },
        // {
        //   id: 2,
        //   name: '微信账户'
        // },
        {
          id: 3,
          name: '银行账户'
        }
      ],
      payList: [
        // {
        //   id: 1,
        //   img: aliIcon,
        //   desc: '提现到支付宝'
        // },
        // {
        //   id: 2,
        //   img: wxIcon,
        //   desc: '提现到微信'
        // },
        {
          id: 3,
          img: cardIcon,
          desc: '提现到银行卡'
        }
      ],
      aliForm: {
        ...initAliForm
      },
      aliRules: {
        name: [{ required: true, message: '请输入实名账户姓名', trigger: 'blur' }],
        account: [{ required: true, message: '请输入支付宝账户', trigger: 'blur' }]
      },
      wxForm: {
        ...initWxForm
      },
      wxRules: {
        name: [{ required: true, message: '请输入真实姓名', trigger: 'blur' }],
        account: [
          {
            required: true,
            message: '请输入要转入的微信支付商户ID',
            trigger: 'blur'
          }
        ]
      },
      cardForm: {
        ...initCardForm
      },
      cardRules: {
        name: [{ required: true, message: '请输入持卡人姓名', trigger: 'blur' }],
        account: [
          {
            required: true,
            message: '请输入要转入的银行账号',
            trigger: 'blur'
          }
        ],
        bank: [
          {
            required: true,
            message: '请输入要转入的开户银行',
            trigger: 'blur'
          }
        ]
      },
      aliAccountList: [
        {
          id: 1,
          name: '赖桂英',
          account: '8632511852110'
        }
      ], // 支付宝账户列表
      wxAccountList: [
        {
          id: 1,
          name: '赖桂英',
          account: '8632511852110'
        }
      ], // 微信账户列表
      cardAccountList: [
        // 银行卡账户列表
        {
          id: 1,
          name: '赖桂英',
          account: '86325118521106124X',
          bank: '招商银行世界之窗支行'
        },
        {
          id: 1,
          name: '赖桂英',
          account: '86325118521106124X',
          bank: '招商银行世界之窗支行'
        }
      ],
      platformId: 2,
      user: '',
      prompt: true,
      listData3: {
        openProduct: null, // 已上架
        closeProduct: null, // 已下架
        tightInventoryProduct: null, // 库存紧张
        allProduct: null // 全部商品
      },
      amountCount: '',
      shop: '',
      bondStatus: ''
    }
  },
  computed: {
    //businessTypeList
    ...mapState({
      businessTypeLists: state => state.dictionary.businessTypeList
    })
  },
  created() {
    this.getBusinessType()
    this.getPlatformId()
    this.getUser()
    this.getPartThreeData()
    this.$api.dynamicAccount.selectAmount({ platformId: getPlatform() }).then(res => {
      this.amountCount = res.data
    })
    this.getShopByUserId()
    // this.getWithdrawalsRecordList()
  },
  filters: {
    moneyFilter(val) {
      if (val === null || val === '') {
        return 0
      }
      return val
    },
    accountListName(val) {
      if (val === '0') {
        return '支付宝账户'
      } else if (val === '1') {
        return '微信账户'
      } else if (val === '2') {
        return '银行账户'
      }
      return ''
    },
    statusFilter(val) {
      //   订单状态：0->待付款；1->待发货；2->已发货；
      // * * 3->已完成；4->已关闭；5->无效订单 6->已取消
      //   * * 7->待分享 8拼团失败 9->售后关闭 10->部分发货
      switch (val) {
        case 0:
          return '代付款'
        case 1:
          return '待发货'
        case 2:
          return '已发货'
        case 3:
          return '已完成'
        case 4:
          return '已关闭'
        case 5:
          return '无效订单'
        case 6:
          return '已取消'
        case 7:
          return '待分享'
        case 8:
          return '拼团失败'
        case 9:
          return '售后关闭'
        case 10:
          return '部分发货'
        default:
          return ''
      }
    },
    settlementStatusFilter(val) {
      // 1,待支付 2已支付 3支付失败 4已完成
      if (val === 1) {
        return '待支付'
      } else if (val === 2) {
        return '已支付'
      } else if (val === 3) {
        return '支付失败'
      } else if (val === 4) {
        return '已完成'
      }
      return ''
    },
    handTime(val) {
      let date = String(val).split(' ')
      return date[0]
    }
  },
  methods: {
    async getPartThreeData() {
      const dataList = await this.$api.common.partThree()
      this.listData3 = {
        ...dataList.data
      }
    },
    handleViewOrder(index, row) {
      this.$router.push({
        path: '/oms/orderDetail',
        query: { id: row.orderId }
      })
    },
    handleViewDetail(index, row) {
      this.$router.push({
        path: '/oms/returnApplyDetail',
        query: { id: row.orderId }
      })
    },
    myIndexOf(name) {
      return String(name).indexOf(',')
    },
    returnArray(name) {
      return String(name).split(',')
    },
    buttonClass(val) {
      if (val === 1) {
        return 'warning'
      } else if (val === 2) {
        return 'primary'
      } else if (val === 3) {
        return 'danger'
      } else if (val === 4) {
        return 'success'
      }
      return ''
    },
    async getBusinessType() {
      await store.dispatch('BUSINESS_TYPE')
    },
    async getPlatformId() {
      this.platformId = await this.$api.common.getConfigList('PLATFORM_SHOP')
    },
    getShopByUserId() {
      const param = {
        page: this.page,
        size: this.size,
        userId: this.$store.state.user.userId,
        businessType: '0501',
        platformId: 2
      }
      this.$api.dynamicAccount.getByIdAndList(param).then(res => {
        if (res.code === 200) {
          this.tableData = res.data.list.list
          this.shop = res.data.umsShop || {}
          this.count = res.data.list.total
          this.bondStatus = res.data.umsShop.bondStatus
        }
      })

      // getShopByUserId(this.$store.state.user.userId).then(res => {
      //   if (res.code === 200) {
      //     this.shop=res.data
      //   }
      // })
    },
    updateBondStatus(status) {
      this.bondStatus = status
    },
    getWithdrawalsRecordList() {
      let param = {
        page: this.page,
        size: this.size,
        userId: this.$store.state.user.userId
      }
      this.$api.dynamicAccount.getWithdrawalsRecordList(param).then(res => {
        // console.log(res);
        this.tableData = res.data.list
        this.total = res.data.total
      })
      this.recordLoading = false
    },
    getUser() {
      this.$api.common
        .getAcount({
          platformId: this.platformId,
          userId: this.$store.state.user.userId
        })
        .then(res => {
          this.user = res.data
        })
    },
    handleClick() {
      // console.log(tab, event)
    },
    promptIf() {
      this.prompt = false
    },
    pageSizeChange(val) {
      this.size = val
      this.getShopByUserId()
    },
    pageNumberChange(val) {
      this.page = val
      this.getShopByUserId()
    },
    getTransList() {},
    // 改变交易方式，微信支付宝银行卡
    changePay(id) {
      this.activePay = id
    },
    onStep(flag) {
      if (flag === 0) {
        this.showAddAccount = false
      } else if (flag === 1) {
        this.active = 1
      } else if (flag === 2) {
        this.active = 2
      }
    },
    nextStep(flag) {
      // 第一步
      if (flag === 1) {
        this.active = 2
      }
    },
    // 新增提现账户
    addAccountInfo() {
      this.showAddAccount = true
    },
    // 第二步提交
    sureAccoutInfo(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.active = 3
        }
      })
    },
    // 获取平台id
    getCurrentPlatformInfo() {
      // getCurrentPlatformInfo().then(res => {
      //   if (res.code === 200) {
      //     this.platformId = res.data.platformId
      //   }
      // })
    },
    // 提交账户信息
    submitAccount(flag) {
      let param = {}
      if (this.activePay === 1) {
        param = {
          acountName: this.aliForm.name,
          acountNo: this.aliForm.account,
          acountType: 0
        }
      } else if (this.activePay === 2) {
        param = {
          acountName: this.wxForm.name,
          acountNo: this.wxForm.account,
          acountType: 1
        }
      } else if (this.activePay === 3) {
        param = {
          acountName: this.cardForm.name,
          acountNo: this.cardForm.account,
          acountBank: this.cardForm.bank,
          acountType: 2
        }
      }
      param = {
        ...param,
        platformId: this.platformId,
        userId: this.$store.state.user.userId
      }
      this.$api.common.updateAccountInfo(param).then(res => {
        if (res.code === 200) {
          this.$message.success('添加成功')
          this.getUser()
        }
      })
      if (flag === 1) {
        this.showAddAccount = false
        this.active = 1
      } else {
        this.showAddAccount = true
        this.active = 1
      }
      // 调用接口
      // 接口调用成功后执行下面代码--清除文本框信息
      this.aliForm = {
        ...initAliForm
      }
      this.wxForm = {
        ...initWxForm
      }
      this.cardForm = {
        ...initCardForm
      }
    },
    closeDialog() {
      this.accountDetailVisible = false
    },
    // 保证金弹框
    payMoney() {
      this.showPayVisible = true
    },
    closePayDialog(status) {
      // console.log(status);
      this.showPayVisible = false
      this.bondStatus = status
    },
    // 提现-展示弹框
    widthdrawMoney() {
      if (this.user.acountType === null || this.user.acountType === '') {
        this.$message.warning('还没有绑定提现账户，赶快去绑定吧')
        this.activeName = 'second'
        return
      }
      this.withdrawVisible = true
    },
    closeWithdrawDialog() {
      this.getUser()
      this.withdrawVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 12px;
  background: #ececec;
  .account-area {
    .left-area {
      height: 184px;
      > div {
        background: #ffffff;
        padding: 0 24px;
        .header-area {
          width: 100%;
          height: 45px;
          line-height: 45px;
          color: #333333;
          border-bottom: 1px solid #eeeeee;
        }
        .main-content {
          padding: 24px 0;
          .left {
            border-right: 1px solid #cccccc;
            display: flex;
            font-size: 14px;
            img {
              width: 48px;
              height: 48px;
              display: flex;
              margin-right: 12px;
            }
            .left-r {
              p {
                color: #646a73;
                margin: 0;
                &:nth-child(1) {
                  color: #333333;
                  span {
                    margin-left: 6px;
                    border: 1px solid #2f88ff;
                    color: #2f88ff;
                    padding: 1px 4px;
                    font-size: 12px;
                  }
                }
                &:nth-child(2) {
                  margin: 14px 0;
                  span {
                    color: #333333;
                  }
                }
                &:nth-child(3) {
                  span {
                    color: #2f88ff;
                  }
                }
              }
            }
          }
          .right {
            padding-left: 20px;
            .left-t {
              display: flex;
              text-align: left;
              img {
                width: 48px;
                height: 48px;
                display: block;
                margin-right: 12px;
              }
              .left-t-r {
                flex: 1;
                color: #333333;
                font-size: 14px;
                display: flex;
                display: -webkit-flex;
                align-items: center;
                span {
                  color: #ffffff;
                  background: #2f88ff;
                  padding: 1px 4px;
                  font-size: 12px;
                  cursor: pointer;
                }
              }
            }
            .left-b {
              width: calc(100% - 60px);
              padding-left: 60px;
              justify-content: flex-start;
              display: flex;
              .left-text {
                text-align: center;
                margin-right: 100px;
                span {
                  display: block;
                  &:first-child {
                    font-size: 24px;
                    color: #2f88ff;
                  }
                  &:last-child {
                    font-size: 14px;
                    color: #333333;
                  }
                }
              }
            }
          }
        }
      }
    }
    .right-area {
      height: 184px;
      > div {
        background: #ffffff;
        padding: 0 24px;
        height: 184px;
        .header-area {
          width: 100%;
          height: 45px;
          line-height: 45px;
          color: #333333;
          border-bottom: 1px solid #eeeeee;
        }
        .main-content {
          padding: 25px 0 21px;
          > .el-row {
            .el-col {
              text-align: center;
              border-right: 1px solid #cccccc;
              height: 90px;
              p {
                margin: 0;
                &:nth-child(1) {
                  font-size: 14px;
                  color: #333333;
                  margin: 5px 0;
                }
              }
              &:nth-child(1) {
                p {
                  &:nth-child(2) {
                    color: #ff1a00;
                    font-size: 24px;
                  }
                }
              }
              &:nth-child(2) {
                p {
                  &:nth-child(2) {
                    color: #ff8800;
                    font-size: 24px;
                  }
                }
                .el-button {
                  color: #2f88ff;
                  border: 1px solid #2f88ff;
                  font-size: 12px;
                  padding: 4px 6px;
                  border-radius: 0;
                  margin-top: 16px;
                }
              }
              &:nth-child(3) {
                p {
                  &:nth-child(2) {
                    color: #2f88ff;
                    font-size: 24px;
                  }
                }
                a {
                  color: #2f88ff;
                  font-size: 14px;
                  margin-top: 5px;
                }
              }
              &:nth-child(4) {
                border: none;
                p {
                  &:nth-child(2) {
                    color: #646a73;
                    font-size: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .main-area {
    margin-top: 12px;
    background: #ffffff;
    padding: 13px 24px;
    .tips-area {
      width: 768px;
      height: 108px;
      margin: 0 auto;
      background: #fff5eb;
      color: #333333;
      font-size: 14px;
      .top {
        display: flex;
        justify-content: space-between;
        padding-left: 22px;
        .left {
          padding-top: 15px;
          img {
            width: 14px;
            height: 14px;
            margin-right: 6px;
          }
        }
        .right {
          padding: 7px 7px 0 0;
          img {
            width: 14px;
            height: 14px;
            cursor: pointer;
          }
        }
      }
      .tips-content {
        li {
          padding: 5px 5px 5px 10px;
          list-style: none;
          position: relative;
          &::before {
            content: '';
            display: block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #ff8800;
            position: absolute;
            top: 12px;
            left: 0;
          }
        }
      }
    }
    .account-main {
      width: 768px;
      margin: 30px auto 156px;
      .account-info {
        span {
          position: relative;
          color: #c0c4cc;
          padding: 0 20px 0 0px;
          cursor: pointer;
          &::after {
            content: '/';
            position: absolute;
            right: 8px;
            color: #c0c4cc;
          }
          &:last-child {
            &::after {
              content: '';
            }
          }
          &.active {
            color: #333333;
          }
        }
      }
      .account-list {
        .list-item {
          margin: 40px 0;
          .inner-item {
            width: 300px;
            height: 140px;
            margin: 0 auto;
            border: 1px solid #2f88ff;
            background: #f8faff;
            border-radius: 4px;
            > span {
              box-sizing: border-box;
              font-size: 14px;
              background: #2f88ff;
              color: #ffffff;
              border-radius: 4px 0 4px 0;
              padding: 2px 10px;
            }
            p {
              font-size: 14px;
              margin-left: 20px;
              span {
                &:first-child {
                  color: #333333;
                  display: inline-block;
                  width: 80px;
                  text-align: right;
                  margin-right: 10px;
                }
                &:last-child {
                  color: #2f88ff;
                }
              }
            }
          }
        }
        .account-img {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 56px 0 50px;
          img {
            width: 200px;
            height: 130px;
            display: block;
            margin-bottom: 20px;
          }
          span {
            color: #333333;
            font-size: 14px;
          }
        }
      }
      .account-btn {
        text-align: center;
        margin-top: 50px;
        .el-button {
          width: 300px;
          height: 50px;
        }
      }
    }
    .main-content {
      margin: 60px 0 100px;
      .steps-area {
        width: 784px;
        height: 54px;
        margin: 0 auto;
        display: flex;
        background: #f5f7fa;
        > div {
          display: flex;
          width: 272px;
          position: relative;
          justify-content: center;
          align-items: center;
          line-height: 24px;
          color: #646a73;
          img {
            width: 24px;
            height: 24px;
            display: block;
            margin-right: 12px;
          }
          &::after {
            content: '';
            width: 12px;
            height: 14px;
            position: absolute;
            right: 0px;
            top: 20px;
            background: url('../../../assets/images/merchants/right-arrow.png') no-repeat;
            background-size: 12px 14px;
          }
          &:last-child {
            width: 240px;
            &::after {
              width: 0;
            }
          }
        }
      }
      .inner-area {
        width: 784px;
        margin: 0 auto;
        .step1 {
          .style-area {
            text-align: center;
            margin: 90px 0 200px;
            > .el-col {
              cursor: pointer;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              img {
                width: 88px;
                height: 88px;
                display: block;
                margin-bottom: 15px;
                border: 1px solid #dcdfe6;
                border-radius: 8px;
              }
              span {
                text-align: center;
                display: block;
                position: relative;
                font-size: 14px;
                color: #333333;
                &.active {
                  padding-left: 16px;
                  &::before {
                    content: '';
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    display: block;
                    border-radius: 50%;
                    background: #52c41a;
                    top: 4px;
                    left: 0;
                  }
                }
              }
            }
          }
          .btn-area {
            text-align: center;
            .el-button {
              width: 300px;
              height: 50px;
            }
          }
        }
        .step2 {
          margin-top: 49px;
          .step2-title {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 32px;
            img {
              width: 88px;
              height: 88px;
              display: block;
              margin-bottom: 15px;
              border: 1px solid #dcdfe6;
              border-radius: 8px;
            }
            span {
              color: #333333;
              font-size: 14px;
            }
          }
          .step2-form {
            width: 300px;
            margin: 0 auto;
            .tips-text {
              font-size: 14px;
              color: #333333;
              i {
                color: #f2383e;
              }
            }
            .el-input {
              margin-top: 8px;
            }
            p {
              text-align: right;
              color: #cbcbcb;
              font-size: 12px;
              margin: 0;
            }
          }
          .step2-btn {
            text-align: center;
            margin-top: 60px;
            .el-button {
              width: 300px;
              height: 50px;
            }
          }
        }
        .step3 {
          margin-top: 49px;
          .step3-title {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 32px;
            img {
              width: 88px;
              height: 88px;
              display: block;
              margin-bottom: 15px;
              border: 1px solid #dcdfe6;
              border-radius: 8px;
            }
            span {
              color: #333333;
              font-size: 14px;
            }
          }
          .step-main {
            text-align: center;
            p {
              font-size: 14px;
              color: #333333;
              span {
                color: #2f88ff;
              }
            }
          }
          .step3-btn {
            margin-top: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .el-button {
              width: 300px;
              height: 50px;
              &:nth-child(1) {
                margin-bottom: 15px;
              }
              &:nth-child(2) {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
  .insertButton {
    border: #0086b3 1px solid;
    color: #0086b3;
  }
  .step2-btn button {
    margin-top: 8px;
    position: relative;
    top: -40px;
  }
}
.image {
  width: 88px;
  height: 88px;
  background: rgba(0, 0, 0, 0);
  border: 1px solid #dcdfe6;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
  opacity: 1;
  border-radius: 8px;
  position: relative;
  left: 45%;
}
.span {
  width: 70px;
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 20px;
  color: #333333;
  opacity: 1;
  position: relative;
  left: 33%;
  top: 20px;
}
.span2 {
  width: 64px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 19px;
  color: #333333;
  opacity: 1;
}
.value {
  width: 140px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 19px;
  color: #2f88ff;
  opacity: 1;
}
.text {
  position: relative;
  left: 40%;
  top: 50px;
}
.button {
  width: 300px;
  height: 50px;
  background: #1a90fb;
  opacity: 1;
  border-radius: 4px;
  border: 0px;
}
.button2 {
  width: 300px;
  height: 50px;
  border: 1px solid #e5e5e5;
  opacity: 1;
  border-radius: 4px;
  border: 0px;
}
.bu {
  position: relative;
  left: 30%;
  top: 50px;
}
.te {
  width: 64px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 22px;
  color: #ffffff;
  opacity: 1;
}
.xt {
  width: 96px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 22px;
  color: #333333;
  opacity: 1;
}
.unit {
  font-size: 12px;
}
</style>

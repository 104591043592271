<template>
  <el-dialog title="运送城市设置" append-to-body :visible="selectDialogVisible" width="50%" @close="closeDialog">
    <el-row>
      <el-row class="checked-area">
        <div>
          <el-checkbox v-model="checkedLevel1" @change="changeLevel1">一级全选</el-checkbox>
          <span @click="invertSelectionLevel1">反选</span>
        </div>
        <div>
          <el-checkbox v-model="checkedLevel2" @change="changeLevel2">二级全选</el-checkbox>
          <span @click="invertSelectionLevel2">反选</span>
        </div>
      </el-row>
      <el-cascader-panel ref="cascaderArea" v-model="areaValue" :options="areaOptions" @expand-change="getNodes" @change="clickNodes" :props="props" size="mini" />
    </el-row>
    <div style="clear: both"></div>
    <div slot="footer">
      <el-button size="small" @click="closeDialog">取 消</el-button>
      <el-button size="small" type="primary" @click="handleSelectDialogConfirm()">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    selectDialogVisible: {
      type: Boolean,
      default: false
    },
    selectedGoodsIds: {
      type: Array,
      default: () => []
    },
    selectedGoodsSkuIds: {
      type: Array,
      default: () => []
    },
    currentResv1: {
      type: String,
      default: null
    },
    currentResv2: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      areaOptions: [],
      areaValue: [],
      props: {
        value: 'id',
        label: 'name',
        children: 'children',
        multiple: true
      },
      checkedLevel1: false, // 一级全选/反选
      checkedLevel2: false, // 二级全选/反选
      provinceList: [], // 全部省份
      selectedProvinceList: [], // 获取选中的省份
      cityList: [], // 当前展示的全部城市
      selectedCityList: [], // 获取当前展示的城市
      currentProvinceId: null // 当前省份
    }
  },
  created() {
    // this.getProviceList()
    this.getProvinceAreaList()
    // 回显
    if (this.currentResv2) {
      this.areaValue = JSON.parse(this.currentResv2)
    } else {
      this.areaValue = []
    }

    // let selectedList = JSON.parse(this.currentResv2)
    // let selectedIds = []
    // selectedList.forEach(el => {
    //   selectedIds.push(el[0])
    // })
    // selectedIds = [...new Set(selectedIds)]
    // if (selectedIds && selectedIds.length) {
    //   selectedIds.forEach(el => {
    //     setTimeout(() => {
    //       this.getCityList(el)
    //     }, 100)
    //   })
    // }
  },
  methods: {
    closeDialog() {
      this.$emit('closeAreaDialog')
    },
    handleSelectDialogConfirm() {
      if (this.areaValue.length) {
        const selectedAllData = this.$refs.cascaderArea.getCheckedNodes()
        const selectedChildrenIds = []
        const selectedChildrenNames = []
        if (selectedAllData && selectedAllData.length) {
          selectedAllData.forEach(el => {
            // 只保存子节点--即市级
            if (el.level === 2) {
              selectedChildrenIds.push(el.value)
              selectedChildrenNames.push(el.parent.label + '-' + el.label)
            }
          })
        }
        const params = {
          selectedChildrenIds,
          selectedChildrenNames,
          selectedInfo: this.areaValue
        }
        this.$emit('addAreaList', params)
        this.closeDialog()
      } else {
        this.$message({
          message: '请选择地区',
          type: 'warning',
          duration: 1000
        })
        return false
      }
    },
    // 获取省份
    async getProviceList() {
      const params = {
        pageNumber: '1',
        pageSize: '10000'
      }
      const { data } = await this.$api.suit.getProvinceList(params)
      this.areaOptions = data.list.map(item => {
        this.$set(item, 'id', item.provinceId)
        this.$set(item, 'name', item.provinceName)
        this.$set(item, 'children', [])
      })
      this.areaOptions = data.list
    },
    // 获取城市
    async getCityList(provinceId) {
      const { data } = await this.$api.suit.getCityList({ provinceId })
      if (data.length) {
        const list = data[0].sysChnCityList
        list.map(item => {
          this.$set(item, 'id', item.cityId)
          this.$set(item, 'name', item.cityName)
          this.$set(item, 'provinceId', item.provinceId)
          this.$set(item, 'children', undefined)
        })
        this.areaOptions.map(item => {
          if (item.provinceId === provinceId) {
            item.children = list
          }
        })
      }
    },
    getProvinceAreaList() {
      this.$api.suit.getProvinceAreaList().then(res => {
        const data = res.data
        data.forEach(el => {
          el.id = el.provinceId
          el.name = el.provinceName
          el.children = el.sysChnCityList
          if (el.sysChnCityList && el.sysChnCityList.length) {
            el.sysChnCityList.forEach(item => {
              item.id = item.cityId
              item.name = item.cityName
              item.children = undefined
            })
          }
        })
        this.areaOptions = data
        this.areaOptions.forEach(el => {
          this.provinceList.push(el.id)
        })
      })
    },
    getNodes(val) {
      console.log('val22:', val)
      this.currentProvinceId = []
      if (val.length === 1) {
        this.currentProvinceId = val[0]
      }
      // if (val.length === 1) {
      //   this.getCityList(val[0])
      //   // 获取地区 （三级）
      // }
    },
    clickNodes(val) {
      console.log('val11:', val)
      if (val && val.length) {
        this.currentProvinceId = []
        let provinceList = []
        val.forEach(el => {
          provinceList.push(el[0])
        })
        provinceList = [...new Set(provinceList)]
        this.currentProvinceId = provinceList.pop()
      }
    },
    // 一级全选
    changeLevel1(val) {
      // const selectedAllData = this.$refs.cascaderArea.getCheckedNodes()
      if (val) {
        // 全选
        this.areaValue = []
        this.areaOptions.forEach(el => {
          el.children.forEach(item => {
            this.areaValue.push([el.id, item.id])
          })
        })
      } else {
        // 全不选
        this.areaValue = []
        this.areaOptions.forEach(el => {
          el.children.forEach(() => {
            this.areaValue.push([])
          })
        })
      }
    },
    // 一级反选
    invertSelectionLevel1() {
      const selectedAllData = this.$refs.cascaderArea.getCheckedNodes()
      const selectedProvinceId = []
      if (selectedAllData && selectedAllData.length) {
        // 有选中的
        selectedAllData.forEach(el => {
          if (el.level === 1) {
            selectedProvinceId.push(el.value)
          }
        })
        const restProvinceIds = this.provinceList
          .filter(v => {
            return !(selectedProvinceId.indexOf(v) > -1)
          })
          .concat(
            selectedProvinceId.filter(i => {
              return !(this.provinceList.indexOf(i) > -1)
            })
          )
        if (restProvinceIds && restProvinceIds.length) {
          // 有未选中的
          this.areaValue = []
          this.areaOptions.forEach(el => {
            if (restProvinceIds.findIndex(e => e === el.id) >= 0) {
              el.children.forEach(item => {
                this.areaValue.push([el.id, item.id])
              })
            }
          })
        } else {
          // 没有未选中的，全选了
          this.areaValue = []
          this.areaOptions.forEach(el => {
            el.children.forEach(() => {
              this.areaValue.push([])
            })
          })
        }
      } else {
        // 没有选中的
        this.areaValue = []
        this.areaOptions.forEach(el => {
          el.children.forEach(item => {
            this.areaValue.push([el.id, item.id])
          })
        })
      }
    },
    // 二级全选
    changeLevel2(val) {
      if (val) {
        // 全选
        const allAreaValue = []
        if (this.areaValue && this.areaValue.length) {
          this.areaValue.forEach(el => {
            allAreaValue.push(el)
          })
        }
        for (var i = allAreaValue.length - 1; i >= 0; i--) {
          if (allAreaValue[i][0] === this.currentProvinceId) {
            allAreaValue.splice(i, 1)
          }
        }
        this.areaValue = []
        if (allAreaValue && allAreaValue.length) {
          allAreaValue.forEach(el => {
            this.areaValue.push(el)
          })
        }
        this.areaOptions.forEach(el => {
          if (el.id === this.currentProvinceId) {
            el.children.forEach(item => {
              this.areaValue.push([el.id, item.id])
            })
          }
        })
      } else {
        // 全不选
        const allAreaValue = []
        if (this.areaValue && this.areaValue.length) {
          this.areaValue.forEach(el => {
            allAreaValue.push(el)
          })
        }
        for (let i = allAreaValue.length - 1; i >= 0; i--) {
          if (allAreaValue[i][0] === this.currentProvinceId) {
            allAreaValue.splice(i, 1)
          }
        }
        this.areaValue = []
        if (allAreaValue && allAreaValue.length) {
          allAreaValue.forEach(el => {
            this.areaValue.push(el)
          })
        }
      }
    },
    // 二级反选
    async invertSelectionLevel2() {
      console.log('this.currentProvinceId:', this.currentProvinceId)
      const params = {
        pageNumber: 1,
        pageSize: 999999,
        provinceId: this.currentProvinceId
      }
      const cityList = [] // 当前选中的省份对应的全部城市
      await this.$api.suit.getCityListByProvinceId(params).then(res => {
        res.data.list.forEach(el => {
          cityList.push(el.cityId)
        })
      })
      // 当前选中省份重已勾选的城市
      const currentSelectedCityIdList = []
      if (this.areaValue && this.areaValue.length) {
        this.areaValue.forEach(el => {
          if (el[0] === this.currentProvinceId) {
            currentSelectedCityIdList.push(el[1])
          }
        })
      }
      // 城市比较---查出当前省份下未选中的城市（补集）
      let restCityIds = []
      if (currentSelectedCityIdList && currentSelectedCityIdList.length) {
        restCityIds = cityList
          .filter(v => {
            return !(currentSelectedCityIdList.indexOf(v) > -1)
          })
          .concat(
            currentSelectedCityIdList.filter(i => {
              return !(cityList.indexOf(i) > -1)
            })
          )
      } else {
        cityList.forEach(el => {
          restCityIds.push(el)
        })
      }
      // 除了当前省份外其他的省份所选中的城市
      const allAreaValue = []
      if (this.areaValue && this.areaValue.length) {
        this.areaValue.forEach(el => {
          allAreaValue.push(el)
        })
      }
      for (var i = allAreaValue.length - 1; i >= 0; i--) {
        if (allAreaValue[i][0] === this.currentProvinceId) {
          allAreaValue.splice(i, 1)
        }
      }
      // 将城市回显
      this.areaValue = []
      if (allAreaValue && allAreaValue.length) {
        allAreaValue.forEach(el => {
          this.areaValue.push(el)
        })
      }
      if (restCityIds && restCityIds.length) {
        // 有未选中的
        restCityIds.forEach(el => {
          this.areaValue.push([this.currentProvinceId, el])
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.el-dialog {
  z-index: 2200;
}
.checked-area {
  display: flex;
  > div {
    width: 180px;
    display: flex;
    span {
      display: block;
      margin-left: 10px;
      color: #606266;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
</style>

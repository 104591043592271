<template>
  <el-dialog v-dialogDrag :close-on-click-modal="false" :visible="true" :title="(isEdit ? '编辑' : '新增') + '商品分类'" top="10vh" width="60%" @close="close">
    <el-card class="container" shadow="never">
      <el-form ref="productCateFrom" :model="productCate" :rules="rules" label-width="150px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="分类名称：" prop="name">
              <el-input v-model="productCate.name" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否显示：">
              <el-radio-group v-model="productCate.showStatus">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <!-- <el-col :span="12">
            <el-form-item label="展示平台：" prop="use">
              <el-radio-group v-model="productCate.use" :disabled="!isShowPlatform">
                <el-radio :label="1">商城</el-radio>
                <el-radio :label="2">博览会</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="排序：">
              <el-input v-model="productCate.sort" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="productCate.parentId == 0 ? '分类导航图标：' : '分类图标：'" prop="icon">
              <single-upload v-model="productCate.icon" :name="fileName" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="分类图片：">
              <single-upload v-model="productCate.keywords" :name="fileName" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="数量单位：">
              <el-input v-model="productCate.productUnit" />
            </el-form-item>
          </el-col>
        </el-row>

        <!-- <el-form-item v-if="false" label="是否显示在导航栏：">
          <el-radio-group v-model="productCate.navStatus">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item> -->

        <el-row>
          <el-col :span="12">
            <el-form-item label="上级分类：">
              <el-select v-model="productCate.parentId" placeholder="请选择分类" :disabled="productCate.parentId == 0 && isEdit" @change="productCateChange">
                <el-option v-for="item in selectProductCateList" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="分类描述：">
              <el-input v-model="productCate.description" type="textarea" :autosize="true" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <!-- <el-form-item v-show="productCate.parentId" label="商品分类规则：" :prop="productCate.parentId != 0 ? 'productCateRuleId' : 'productCateRuleId2'">
              <el-select v-model="productCate.productCateRuleId" placeholder="请选择分类规则" :disabled="isEdit">
                <el-option v-for="item in productCateRules" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item> -->
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="productCate.parentId !== 0" label="是否为印刷商品：">
              <el-radio-group v-model="productCate.isPrint">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item v-show="productCate.parentId == 0" label="税收分类编码：" :prop="productCate.parentId == 0 ? 'spbm' : 'spbm2'">
              <el-input v-model="productCate.spbm" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-show="productCate.parentId == 0" label="税率：" :prop="productCate.parentId == 0 ? 'taxrate' : 'taxrate2'">
              <el-input v-model="productCate.taxrate" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item v-if="productCate.parentId" label="关联品牌：">
              <el-select v-model="selectBrand" placeholder="请选择商品品牌">
                <el-option v-for="item in selectBrandOptions" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
              <el-button @click="handleAddCategoryBrand()">添加</el-button>
              <el-table ref="productRelationTable" :data="categoryBrandList" style="width: 100%; margin-top: 20px" border>
                <el-table-column label="品牌名称" align="center">
                  <template slot-scope="scope">{{ scope.row.name }}</template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="100">
                  <template slot-scope="scope">
                    <el-button size="mini" type="text" @click="handleDeleteCategoryBrand(scope.$index, scope.row)">删除 </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-row type="flex" justify="center" style="margin-top: 30px">
        <el-button type="primary" @click="onSubmit('productCateFrom')">提交</el-button>
        <el-button @click="close">取消</el-button>
      </el-row>
    </el-card>
  </el-dialog>
</template>

<script>
const defaultProductCate = {
  description: '',
  icon: '',
  keywords: '',
  name: '',
  navStatus: 0,
  parentId: 0,
  productUnit: '',
  showStatus: 0,
  sort: 0,
  productAttributeIdList: [],
  brandIds: '',
  spbm: '',
  taxrate: '',
  isPrint: 0,
  use: 2,
  productCateRuleId: ''
}
export default {
  name: 'ProductCateDetail',
  components: {},
  filters: {
    filterLabelFilter(index) {
      if (index === 0) {
        return '筛选属性：'
      } else {
        return ''
      }
    }
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      fileName: 'static',
      productCate: Object.assign({}, defaultProductCate),
      selectProductCateList: [],
      productCateRules: [
        { label: '品牌', value: 1 },
        { label: '商品分类', value: 2 }
      ],
      rules: {
        name: [
          { required: true, message: '请输入分类名称', trigger: 'blur' },
          {
            min: 2,
            max: 140,
            message: '长度在 2 到 140 个字符',
            trigger: 'blur'
          }
        ],
        // productCateRuleId: [{ required: true, message: '请选择商品分类规则', trigger: 'blur' }],
        // productCateRuleId2: [{ required: false, trigger: 'blur' }],

        icon: [{ required: true, message: '请上传分类图标', trigger: 'blur' }],
        keywords: [{ required: true, message: '请上传分类图片', trigger: 'blur' }],
        spbm: [
          { required: true, message: '请输入税收分类编码', trigger: 'blur' },
          {
            pattern: /^[A-Za-z0-9]+$/,
            message: '请输入正确的编码',
            trigger: 'blur'
          }
        ],
        spbm2: [{ required: false, message: '请输入税收分类编码', trigger: 'blur' }],
        taxrate: [
          { required: true, message: '请输入税率', trigger: 'blur' },
          {
            pattern: /^([0-9]*)+(.[0-9]{1,4})?$/,
            message: '请输入正确的税率',
            trigger: 'blur'
          }
        ],
        taxrate2: [{ required: false, message: '请输入税率', trigger: 'blur' }]
      },
      filterAttrs: [],
      filterProductAttrList: [
        {
          value: []
        }
      ],
      selectBrandOptions: [],
      selectBrand: '',
      categoryBrandList: [],
      isShowPlatform: true
    }
  },
  watch: {},
  async created() {
    if (this.isEdit) {
      this.getSelectProductCateList().then(() => {
        this.initData()
      })
      this.$api.productCate.getProductAttrInfo(this.id).then(response => {
        if (response.data != null && response.data.length > 0) {
          this.filterProductAttrList = []
          for (let i = 0; i < response.data.length; i++) {
            this.filterProductAttrList.push({
              key: Date.now() + i,
              value: [response.data[i].attributeCategoryId, response.data[i].attributeId]
            })
          }
        }
      })
    } else {
      this.getSelectProductCateList()
      this.productCate = Object.assign({}, defaultProductCate)
    }
    this.getProductAttrCateList()
  },
  methods: {
    initData() {
      const that = this
      return new Promise(function (resolve) {
        that.$api.productCate.getProductCate(that.id).then(response => {
          that.productCate = response.data
          if (response.data.parentId) {
            that.productCateChange(response.data.parentId, response.data.brandIds)
            resolve()
          }
        })
      })
    },
    close() {
      this.$emit('close')
    },
    // 添加品牌
    handleAddCategoryBrand() {
      if (!this.productCate.parentId) {
        this.$message({
          message: '请先选择上级分类',
          type: 'warning'
        })
        return
      }
      if (!this.selectBrand) return
      let flag = false
      this.categoryBrandList.forEach(item => {
        if (this.selectBrand === item.id) {
          flag = true
        }
      })
      if (flag) {
        this.$message({
          message: '已添加当前品牌'
        })
        return
      }
      const brand = this.getBrandById(this.selectBrand)
      this.categoryBrandList.push(brand)
      this.selectBrand = null
    },
    getBrandById(id) {
      for (let i = 0; i < this.selectBrandOptions.length; i++) {
        if (id === this.selectBrandOptions[i].id) {
          return this.selectBrandOptions[i]
        }
      }
      return null
    },
    // 删除品牌
    handleDeleteCategoryBrand(index) {
      this.categoryBrandList.splice(index, 1)
    },
    // 所选分类&&数据回显所选分类
    productCateChange(val, brandIds) {
      console.log(val, this.selectProductCateList)
      const currentCate = this.selectProductCateList.find(el => {
        return el.id === val
      })

      if (currentCate && currentCate.id === 0) {
        // 一级分类
        this.isShowPlatform = true
        this.productCate.use = 1
        // 重置分类规则
        this.productCate.productCateRuleId = ''
      } else {
        // 二级分类-展示平台跟着一级分类变动
        this.isShowPlatform = false
        this.productCate.use = currentCate.use
      }
      this.$api.productCate.getBrandByProductcateId({ categoryId: val }).then(res => {
        this.selectBrandOptions = res.data
        if (brandIds && brandIds.length) {
          this.selectBrandOptions.forEach(brand => {
            brandIds.forEach(item => {
              if (item.brandId === brand.id) {
                this.categoryBrandList.push(brand)
              }
            })
          })
        }
      })
    },
    getSelectProductCateList() {
      const that = this
      return new Promise(function (resolve) {
        that.$api.productCate.productCategoryList(0, { pageSize: 100, pageNum: 1, use: 2 }).then(response => {
          that.selectProductCateList = response.listData
          that.selectProductCateList.unshift({ id: 0, name: '无上级分类' })
          resolve()
        })
      })
    },
    getProductAttrCateList() {
      this.$api.productCate.fetchListWithAttr().then(response => {
        const list = response.data
        for (let i = 0; i < list.length; i++) {
          const productAttrCate = list[i]
          const children = []
          if (productAttrCate.productAttributeList != null && productAttrCate.productAttributeList.length > 0) {
            for (let j = 0; j < productAttrCate.productAttributeList.length; j++) {
              children.push({
                label: productAttrCate.productAttributeList[j].name,
                value: productAttrCate.productAttributeList[j].id
              })
            }
          }
          this.filterAttrs.push({
            label: productAttrCate.name,
            value: productAttrCate.id,
            children: children
          })
        }
      })
    },
    getProductAttributeIdList() {
      // 获取选中的筛选商品属性
      const productAttributeIdList = []
      for (let i = 0; i < this.filterProductAttrList.length; i++) {
        const item = this.filterProductAttrList[i]
        if (item.value !== null && item.value.length === 2) {
          productAttributeIdList.push(item.value[1])
        }
      }
      return productAttributeIdList
    },
    onSubmit(formName) {
      // this.$refs.productCateFrom.validateField('productCateRuleId')
      this.$refs[formName].validate(valid => {
        if (valid) {
          const categoryBrandList = this.categoryBrandList.map(item => {
            return item.id
          })
          if (this.productCate.parentId && categoryBrandList.length <= 0) {
            this.$message({
              type: 'warning',
              message: '二级分类请添加关联品牌'
            })
            return
          }
          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.productCate.brandIds = categoryBrandList.join(',')
            this.productCate.productAttributeIdList = this.getProductAttributeIdList()
            if (this.isEdit) {
              this.$api.productCate.updateProductCate(this.id, this.productCate).then(() => {
                this.$message({
                  message: '修改成功',
                  type: 'success',
                  duration: 1000
                })
                this.close()
              })
            } else {
              this.$api.productCate.createProductCate(this.productCate).then(() => {
                this.$refs[formName].resetFields()
                this.resetForm(formName)
                this.$message({
                  message: '提交成功',
                  type: 'success',
                  duration: 1000
                })
                this.close()
              })
            }
          })
        } else {
          this.$message({
            message: '验证失败',
            type: 'error',
            duration: 1000
          })
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.productCate = Object.assign({}, defaultProductCate)
      this.getSelectProductCateList()
      this.filterProductAttrList = [
        {
          value: []
        }
      ]
    },
    removeFilterAttr(productAttributeId) {
      if (this.filterProductAttrList.length === 1) {
        this.$message({
          message: '至少要留一个',
          type: 'warning',
          duration: 1000
        })
        return
      }
      var index = this.filterProductAttrList.indexOf(productAttributeId)
      if (index !== -1) {
        this.filterProductAttrList.splice(index, 1)
      }
    },
    handleAddFilterAttr() {
      if (this.filterProductAttrList.length === 3) {
        this.$message({
          message: '最多添加三个',
          type: 'warning',
          duration: 1000
        })
        return
      }
      this.filterProductAttrList.push({
        value: null,
        key: Date.now()
      })
    }
  }
}
</script>

<style scoped></style>

import request from '@/api/request'
// import qs from 'qs'

const dynamicAccount = {
  getByIdAndList(params) {
    return request({
      url: '/system/log/account/desc/search',
      method: 'POST',
      // params:params,
      data: params
    })
  },
  // OPS店铺表-查询
  getProductionList(params) {
    return request({
      url: '/expositionShop/shop/enum',
      method: 'get',
      params
    })
  },
  accountList(params) {
    return request({
      url: '/system/log/account/search',
      method: 'POST',
      // params:params,
      data: params
    })
  },

  // 参数查找
  searchItem() {
    return request({
      url: '/system/config/key/PLATFORM_BUSINESS_ROLE',
      method: 'GET'
    })
  },

  // system/user/list
  getUserList(params) {
    return request({
      url: '/system/user/list',
      method: 'get',
      params: params
    })
  },

  // system/log/withdrawals/record
  getWithdrawalsRecordList(params) {
    return request({
      url: '/system/log/withdrawals/record',
      method: 'POST',
      // params:params,
      data: params
    })
  },

  //
  selectAmount(params) {
    return request({
      url: '/system/log/selectamount',
      method: 'GET',
      params: params
    })
  },

  transfer(accountId) {
    return request({
      url: '/system/log/transfer/by/' + accountId,
      method: 'PUT'
      // params:params,
    })
  },

  // system/log/refuse/transfer/{accountId}
  refuseTransfer(accountId) {
    return request({
      url: '/system/log/refuse/transfer/' + accountId,
      method: 'PUT'
      // params:params,
    })
  },

  /**
   * 获取平台列表
   * @returns
   */
  getPlatfromList() {
    return request({
      url: '/system/platform/platfromList',
      method: 'GET'
    })
  }
}

export default dynamicAccount

<template>
  <div>
    <!-- <el-upload
      :action="useOss?ossUploadUrl:minioUploadUrl"
      :data="useOss?dataObj:null"
      list-type="picture"
      :multiple="false" :show-file-list="showFileList"
      :file-list="fileList"
      :before-upload="beforeUpload"
      :on-remove="handleRemove"
      :on-success="handleUploadSuccess"
      :on-preview="handlePreview">
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过10MB</div>
    </el-upload> -->
    <el-upload action="" list-type="picture" :multiple="false" :show-file-list="showFileList" :file-list="fileList" :http-request="uploadFile" :on-remove="handleRemove" :disabled="disabled" :before-upload="beforeAvatarUpload">
      <el-button size="small" :disabled="disabled" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">{{ tips }}</div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="fileList[0].url" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import OSS from 'ali-oss'

export default {
  name: 'SingleUpload',
  props: {
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tips: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  data() {
    return {
      dataObj: {
        policy: '',
        signature: '',
        key: '',
        ossaccessKeyId: '',
        dir: '',
        host: ''
        // callback:'',
      },
      ossData: {
        accessKeyId: '',
        accessKeySecret: '',
        bucketName: '',
        endpoint: ''
      },
      dialogVisible: false,
      useOss: true, // 使用oss->true;使用MinIO->false
      // ossUploadUrl:'http://macro-oss.oss-cn-shenzhen.aliyuncs.com',
      ossUploadUrl: '',
      minioUploadUrl: 'http://localhost:8080/minio/upload'
    }
  },
  computed: {
    imageUrl() {
      return this.value
    },
    imageName() {
      if (this.value != null && this.value !== '') {
        return this.value.substr(this.value.lastIndexOf('/') + 1)
      } else {
        return null
      }
    },
    fileList() {
      return [
        {
          name: this.imageName,
          url: this.imageUrl
        }
      ]
    },
    showFileList: {
      get() {
        return this.value !== null && this.value !== '' && this.value !== undefined
      },
      set() {}
    }
  },
  methods: {
    ossClient(conf) {
      return new OSS(conf)
    },
    emitInput(val) {
      this.$emit('input', val)
    },
    handleRemove() {
      this.emitInput('')
    },
    handlePreview() {
      this.dialogVisible = true
    },
    beforeUpload() {
      const _self = this
      if (!this.useOss) {
        // 不使用oss不需要获取策略
        return true
      }
      return new Promise((resolve, reject) => {
        this.$api.common
          .policy()
          .then(response => {
            _self.ossData = response.data
            // http://xinyin-mall.oss-cn-hangzhou.aliyuncs.com/static/
            this.ossUploadUrl = `http://${response.data.bucketName}.${response.data.endpoint.split('//')[1]}/${this.name}`
            console.log('this.ossUploadUrl:', this.ossUploadUrl)
            // _self.dataObj.policy = response.data.policy;
            // _self.dataObj.signature = response.data.signature;
            // _self.dataObj.ossaccessKeyId = response.data.accessKeyId;
            // _self.dataObj.key = response.data.dir + '/${filename}';
            // _self.dataObj.dir = response.data.dir;
            // _self.dataObj.host = response.data.host;
            // _self.dataObj.callback = response.data.callback;
            resolve(true)
          })
          .catch(err => {
            console.log(err)
            reject(false)
          })
      })
    },
    async uploadFile(file) {
      const config = await this.$api.common.policy()
      const region = config.endpoint.split('.')[0].slice(7)
      const uploadConf = {
        region,
        accessKeyId: config.accessKeyId,
        accessKeySecret: config.accessKeySecret,
        bucket: config.bucketName
      }
      const fileName = `${this.name}/${Date.parse(new Date())}` // 定义唯一的文件名
      this.ossClient(uploadConf)
        .put(fileName, file.file, {
          ContentType: 'image/jpeg'
        })
        .then(({ res, url }) => {
          if (res && res.status === 200) {
            this.fileList.push({ name: file.file.name, url: url })
            this.emitInput(this.fileList[1].url)
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '上传失败'
          })
        })
    },
    handleUploadSuccess(res, file) {
      console.log('res:', res)
      console.log('file:', file)
      this.showFileList = true
      this.fileList.pop()
      let url = this.dataObj.host + '/' + this.dataObj.dir + '/' + file.name
      if (!this.useOss) {
        // 不使用oss直接获取图片路径
        url = res.data.url
      }
      this.fileList.push({ name: file.name, url: url })
      this.emitInput(this.fileList[0].url)
    },
    beforeAvatarUpload(file) {
      // const isGIF = file.type === 'image/gif'
      const isJPEG = file.type === 'image/gif'
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isJPG && !isJPEG && !isPNG) {
        this.$message.error('上传图片只能是 JPEG/JPG/PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('单张图片大小不能超过 10M!')
      }
      return (isJPEG || isJPG || isPNG) && isLt2M
    }
  }
}
</script>
<style></style>

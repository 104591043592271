<template>
  <el-dialog v-dialogDrag :close-on-click-modal="false" :visible="true" :title="(isEdit ? '编辑' : '新增') + '地区套餐'" top="10vh" width="50%" @close="close">
    <el-card class="container" shadow="never">
      <el-form ref="suitObjFrom" :model="suitObj" :rules="suitRules" label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="地区">
              <el-cascader ref="cascaderArea" v-model="areaValue" :options="areaOptions" :props="props" size="mini" @expand-change="getNodes" @change="handleChangeArea" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="套餐名称" prop="packageName">
              <el-input v-model="suitObj.packageName" size="mini" disabled>
                <el-button slot="append" type="primary" @click="addSuitInfo">添加</el-button>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="创建时间">
              <el-input v-model="createTime" size="mini" disabled="" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="最后修改时间">
              <el-input v-model="lstUpdTime" size="mini" disabled="" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex" justify="center" class="btn-area">
        <el-button type="primary" size="mini" @click="onSubmit('suitObjFrom')">保存</el-button>
        <el-button size="mini" @click="close">取消</el-button>
      </el-row>
      <ProductDialog :select-dialog-visible="selectDialogVisible" :selected-ids="selectedIds" @closeProductDialog="closeProductDialog" @addProductList="addProductList" />
    </el-card>
  </el-dialog>
</template>
<script>
import SingleUpload from '@/components/Upload/singleUpload'
import ProductDialog from './productDialog'
const defaultSuit = {
  province: '',
  city: '',
  packageId: '',
  packageName: ''
}
export default {
  name: 'BrandDetail',
  // eslint-disable-next-line vue/no-unused-components
  components: { SingleUpload, ProductDialog },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: null
    },
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      suitObj: Object.assign({}, defaultSuit),
      createTime: '',
      lstUpdTime: '',
      areaValue: [],
      selectDialogVisible: false,
      areaOptions: [],
      selectedIds: [],
      selectedGoodsList: {},
      currentCity: [],
      props: {
        value: 'id',
        label: 'name',
        children: 'children'
      },
      repeatArea: true,
      suitRules: {
        packageName: [{ required: true, message: '请选择套餐名称', trigger: 'blur' }]
      }
    }
  },
  watch: {
    areaValue(newValue) {
      if (newValue != null && newValue.length == 2) {
        this.suitObj.province = newValue[0]
        this.suitObj.city = newValue[1]
      } else {
        this.suitObj.province = null
        this.suitObj.city = null
      }
    }
  },
  created() {
    this.getProviceList()
    if (this.isEdit) {
      this.$api.suit.getAreaSuitListById(this.id).then(response => {
        this.suitObj = response.data
        this.$set(this.suitObj, 'packageName', this.name)
        if (response.data.province) {
          this.areaValue[0] = String(response.data.province)
        }
        if (response.data.city) {
          this.areaValue[1] = String(response.data.city)
          this.getCityList(this.areaValue[0])
        }
        this.createTime = this.formatTime(response.data.createTime)
        this.lstUpdTime = this.formatTime(response.data.lstUpdTime)
      })
    } else {
      this.suitObj = Object.assign({}, defaultSuit)
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    formatTime(time) {
      if (time == null || time === '') {
        return ''
      }
      const date = new Date(time)
      return this.$utils.parseDate(date)
    },
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            if (this.isEdit) {
              this.currentCity = this.$refs['cascaderArea'].getCheckedNodes()[0].pathLabels
              const params = {
                city: this.$refs['cascaderArea'].getCheckedNodes()[0].value
              }
              await this.$api.suit.verifySuit(params).then(res => {
                if (res.code === 200) {
                  if (res.data) {
                    this.repeatArea = true
                    this.$message({
                      type: 'warning',
                      message: '您选中的地区已存在套餐'
                    })
                  } else {
                    this.repeatArea = false
                  }
                }
              })

              if (this.repeatArea) {
                return
              }

              const data = {
                id: this.id,
                city: this.suitObj.city,
                packageId: this.suitObj.packageId,
                province: this.suitObj.province,
                resv1: this.currentCity[0] + '-' + this.currentCity[1]
              }
              this.$api.suit.updateAreaSuitInfo(data).then(res => {
                if (res.code === 200) {
                  this.$refs[formName].resetFields()
                  this.$message({
                    message: '修改成功',
                    type: 'success',
                    duration: 1000
                  })
                  this.close()
                }
              })
            } else {
              const data1 = {
                ...this.suitObj,
                resv1: this.currentCity[0] + '-' + this.currentCity[1]
              }
              if (this.repeatArea) {
                this.$message({
                  type: 'warning',
                  message: '您选中的地区已存在套餐'
                })
                return
              }
              this.$api.suit.addAreaSuitInfo(data1).then(res => {
                if (res.code === 200) {
                  this.$refs[formName].resetFields()
                  this.suitObj = Object.assign({}, defaultSuit)
                  this.$message({
                    message: '提交成功',
                    type: 'success',
                    duration: 1000
                  })
                  this.close()
                }
              })
            }
          })
        } else {
          this.$message({
            message: '验证失败',
            type: 'error',
            duration: 1000
          })
          return false
        }
      })
    },
    handleChangeArea() {
      this.currentCity = this.$refs['cascaderArea'].getCheckedNodes()[0].pathLabels
      const data = {
        city: this.$refs['cascaderArea'].getCheckedNodes()[0].value
      }
      this.$api.suit.verifySuit(data).then(res => {
        if (res.code === 200) {
          if (res.data) {
            this.repeatArea = true
            this.$message({
              type: 'warning',
              message: '您选中的地区已存在套餐'
            })
          } else {
            this.repeatArea = false
          }
        }
      })
    },
    handleClick(tab, event) {
      console.log(tab, event)
    },
    pageSizeChange(val) {
      this.size = val
    },
    pageNumberChange(val) {
      this.page = val
    },
    goodsPageSizeChange(val) {
      this.goodsSize = val
    },
    goodsPageNumberChange(val) {
      this.goodsPage = val
    },
    addSuitInfo() {
      this.selectDialogVisible = true
    },
    closeProductDialog() {
      this.selectDialogVisible = false
    },
    addProductList(val) {
      this.selectedGoodsList = val[0]
      this.$set(this.suitObj, 'packageName', val[0].packageName)
      this.$set(this.suitObj, 'packageId', val[0].packageId)
    },
    handleSkuList(skuarr = [], i, list) {
      let arr = []
      for (let j = 0; j < list[i].length; j++) {
        if (i < list.length - 1) {
          // 演示一下第一次执行函数的结果
          skuarr[i] = list[i][j] // skuarr[0] = ['黑色']  i=0, j=0
          // eslint-disable-next-line no-undef
          func(skuarr, i + 1, list) // 执行递归 skuarr[1] = ['s']  i=1, j=0
        } else {
          // 拓展运算符合并数组
          arr.push([...skuarr, list[i][j]]) // arr[0] = ['黑色','s','好']  i=2不符合if条件,执行else   j=0
        }
      }
      // eslint-disable-next-line no-undef
      return arr
    },
    // 获取省份
    async getProviceList() {
      const params = {
        pageNumber: '1',
        pageSize: '10000'
      }
      const { data } = await this.$api.suit.getProvinceList(params)
      this.areaOptions = data.list.map(item => {
        this.$set(item, 'id', item.provinceId)
        this.$set(item, 'name', item.provinceName)
        this.$set(item, 'children', [])
      })
      this.areaOptions = data.list
    },
    // 获取城市
    async getCityList(provinceId) {
      const { data } = await this.$api.suit.getCityList({ provinceId })
      if (data.length) {
        const list = data[0].sysChnCityList
        list.map(item => {
          this.$set(item, 'id', item.cityId)
          this.$set(item, 'name', item.cityName)
          this.$set(item, 'children', undefined)
        })
        this.areaOptions.map(item => {
          if (item.provinceId == provinceId) {
            item.children = list
          }
        })
      }
    },
    // 获取地区
    async getAreaList(provinceId, cityId) {
      const params = {
        cityId,
        provinceId
      }
      const { data } = await this.$api.suit.getAreaList(params)
      if (data.length) {
        const list = data[0].sysChnAreaList
        list.map(item => {
          this.$set(item, 'id', item.areaId)
          this.$set(item, 'name', item.areaName)
        })
        this.areaOptions.map(item => {
          if (item.provinceId == provinceId) {
            item.children.map(city => {
              city.children = list
            })
          }
        })
      }
    },
    getNodes(val) {
      if (val.length === 1) {
        this.getCityList(val[0])
        // 获取地区 （三级）
      }
      // else if (val.length === 2) {
      //   this.getAreaList(val[0], val[1])
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-container {
  width: 60%;
  left: 220px;
}
::v-deep.btn-area {
  margin-bottom: 20px;
  button {
    width: 100px;
  }
}
</style>

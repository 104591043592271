<template>
  <div class="detail-container" style="left: 50px">
    <el-card shadow="never">
      <span class="font-title-medium">退货商品</span>
      <el-table border class="standard-margin" ref="productTable" :data="productList">
        <el-table-column label="商品图片" width="160" align="center">
          <template slot-scope="scope">
            <img style="height: 80px" :src="scope.row.productPic" />
          </template>
        </el-table-column>
        <el-table-column label="商品名称" align="center">
          <template slot-scope="scope">
            <span class="font-small">{{ scope.row.productName }}</span
            ><br />
            <span class="font-small">品牌：{{ scope.row.productBrand }}</span>
          </template>
        </el-table-column>
        <el-table-column label="价格/商品ID" width="180" align="center">
          <template slot-scope="scope">
            <span class="font-small">价格：￥{{ scope.row.productRealPrice }}</span
            ><br />
            <span class="font-small">商品ID：NO.{{ scope.row.productId }}</span>
          </template>
        </el-table-column>
        <el-table-column label="属性" width="180" align="center">
          <template slot-scope="scope">
            <span v-for="col in scope.row.productAttr" :key="col.key">{{ col.key + ':' + col.value }}</span>
          </template>
        </el-table-column>
        <el-table-column label="数量" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.productCount }}</template>
        </el-table-column>
        <el-table-column label="小计" width="100" align="center">
          <template slot-scope="scope">￥{{ scope.row.returnAmount }}</template>
        </el-table-column>
      </el-table>
      <div style="float: right; margin-top: 15px; margin-bottom: 15px">
        <span class="font-title-medium">合计：</span>
        <span class="font-title-medium color-danger">￥{{ orderReturnApply.returnAmount }}</span>
      </div>
    </el-card>
    <el-card shadow="never" class="standard-margin">
      <span class="font-title-medium">服务单信息</span>
      <div class="form-container-border">
        <el-row>
          <el-col :span="6" class="form-border form-left-bg font-small">服务单号</el-col>
          <el-col class="form-border font-small" :span="18">{{ orderReturnApply.orderSn }}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">申请状态</el-col>
          <el-col class="form-border font-small" :span="18">{{ $utils.handleOptions(orderReturnApply.status, changeStatusOptions) }}</el-col>
        </el-row>
        <el-row>
          <el-col :span="6" class="form-border form-left-bg font-small" style="height: 50px; line-height: 30px">订单编号 </el-col>
          <el-col class="form-border font-small" :span="18" style="height: 50px">
            {{ orderReturnApply.orderSn }}
            <el-button type="text" size="small" @click="handleViewOrder">查看</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">申请时间</el-col>
          <el-col class="form-border font-small" :span="18">
            {{ formatTime(orderReturnApply.createTime) }}
          </el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">用户账号</el-col>
          <el-col class="form-border font-small" :span="18">{{ orderReturnApply.memberUsername || '暂无' }}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">联系人</el-col>
          <el-col class="form-border font-small" :span="18">{{ orderReturnApply.returnName }}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">联系电话</el-col>
          <el-col class="form-border font-small" :span="18">{{ orderReturnApply.returnPhone }}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">退货原因</el-col>
          <el-col class="form-border font-small" :span="18">{{ orderReturnApply.reason || '暂无' }}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">问题描述</el-col>
          <el-col class="form-border font-small" :span="18">
            <el-tooltip class="item" effect="dark" :content="orderReturnApply.description ? orderReturnApply.description : '暂无'" placement="top">
              <span class="col-description">{{ orderReturnApply.description || '暂无' }}</span>
            </el-tooltip>
          </el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6" style="height: 100px; line-height: 80px">凭证图片 </el-col>
          <el-col v-if="proofPics.length" class="form-border font-small" :span="18" style="height: 100px">
            <el-image v-for="(item, index) in proofPics" style="width: 80px; height: 80px" :src="item" :key="index" :preview-src-list="proofPics" />
          </el-col>
          <el-col v-if="!proofPics.length" class="form-border font-small" :span="18" style="height: 100px"> 无描述 </el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">收货地址</el-col>
          <el-col class="form-border font-small" :span="18">{{ orderReturnApply.receiveAddress || '暂无' }}</el-col>
        </el-row>
        <!-- <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6"
            >收货人电话</el-col
          >
          <el-col class="form-border font-small" :span="18">{{
            orderReturnApply.receivePhone || '暂无'
          }}</el-col>
        </el-row> -->
        <el-row v-if="orderReturnApply.status > 0">
          <el-col class="form-border form-left-bg font-small" :span="6">物流公司</el-col>
          <el-col class="form-border font-small" :span="18">{{ orderReturnApply.buyerLogisticsName || '暂无' }}</el-col>
        </el-row>
        <el-row v-if="orderReturnApply.status > 0">
          <el-col class="form-border form-left-bg font-small" :span="6">快递单号</el-col>
          <el-col class="form-border font-small" :span="18">{{ orderReturnApply.buyerLogisticsOdd || '暂无' }}</el-col>
        </el-row>
        <el-row v-if="orderReturnApply.status > 0">
          <el-col class="form-border form-left-bg font-small" :span="6">物流联系电话</el-col>
          <el-col class="form-border font-small" :span="18">{{ orderReturnApply.buyerPhone || '暂无' }}</el-col>
        </el-row>
        <el-row v-if="orderReturnApply.status > 0">
          <el-col class="form-border form-left-bg font-small" :span="6" style="height: 200px; line-height: 200px">物流信息</el-col>
          <el-col v-if="orderReturnApply.buyerLogisticalInfoVos && orderReturnApply.buyerLogisticalInfoVos.length" class="form-border font-small" :span="18" style="height: 200px; overflow-y: scroll">
            <p v-for="item in orderReturnApply.buyerLogisticalInfoVos" :key="item.id">
              {{ item.logisticalContext }}
            </p>
          </el-col>
          <el-col v-else class="form-border font-small" :span="18" style="height: 200px; overflow-y: scroll"> 暂无 </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card shadow="never" class="standard-margin">
      <span class="font-title-medium">商家换货信息</span>
      <div class="form-container-border">
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">订单金额</el-col>
          <el-col class="form-border font-small" :span="18">￥{{ orderReturnApply.returnAmount }}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">运费金额</el-col>
          <el-col class="form-border font-small" :span="18">￥{{ orderReturnApply.freightAmount || '暂无' }}</el-col>
        </el-row>
        <div v-show="orderReturnApply.status === 0">
          <el-row>
            <el-col class="form-border form-left-bg font-small" :span="6" style="height: 52px; line-height: 32px">选择收货点 </el-col>
            <el-col class="form-border font-small" style="height: 52px" :span="18">
              <el-select size="small" style="width: 200px" :disabled="orderReturnApply.status !== 0" v-model="updateStatusParam.companyAddressId">
                <el-option v-for="address in companyAddressList" :key="address.id" :value="address.id" :label="address.addressName"> </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row>
            <el-col class="form-border form-left-bg font-small" :span="6">收货人姓名</el-col>
            <el-col class="form-border font-small" :span="18">{{ currentAddress ? currentAddress.name : '' }}</el-col>
          </el-row>
          <el-row>
            <el-col class="form-border form-left-bg font-small" :span="6">所在区域</el-col>
            <el-col class="form-border font-small" :span="18">{{ currentAddress | formatRegion }}</el-col>
          </el-row>
          <el-row>
            <el-col class="form-border form-left-bg font-small" :span="6">详细地址</el-col>
            <el-col class="form-border font-small" :span="18">{{ currentAddress ? currentAddress.detailAddress : '' }}</el-col>
          </el-row>
          <el-row>
            <el-col class="form-border form-left-bg font-small" :span="6">联系电话</el-col>
            <el-col class="form-border font-small" :span="18">{{ currentAddress ? currentAddress.phone : '' }}</el-col>
          </el-row>
        </div>
      </div>
      <div class="form-container-border" v-show="orderReturnApply.status !== 0">
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">处理人员</el-col>
          <el-col class="form-border font-small" :span="18">{{ orderReturnApply.handleMan }}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">处理时间</el-col>
          <el-col class="form-border font-small" :span="18">
            {{ formatTime(orderReturnApply.handleTime) }}
          </el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">处理备注</el-col>
          <el-col class="form-border font-small" :span="18">
            <el-tooltip class="item" effect="dark" :content="orderReturnApply.handleNote ? orderReturnApply.handleNote : '暂无'" placement="top">
              <span class="col-description">{{ orderReturnApply.handleNote || '暂无' }}</span>
            </el-tooltip>
          </el-col>
        </el-row>
      </div>
      <div class="form-container-border" v-show="orderReturnApply.status === 2">
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">收货人员</el-col>
          <el-col class="form-border font-small" :span="18">{{ orderReturnApply.receiveMan }}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">收货时间</el-col>
          <el-col class="form-border font-small" :span="18">{{ formatTime(orderReturnApply.receiveTime) }}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">收货备注</el-col>
          <el-col class="form-border font-small" :span="18">{{ orderReturnApply.receiveNote }}</el-col>
        </el-row>
      </div>
      <div class="form-container-border" v-show="orderReturnApply.status === 0">
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6" style="height: 52px; line-height: 32px">处理备注</el-col>
          <el-col class="form-border font-small" :span="18">
            <el-input size="small" v-model="updateStatusParam.handleNote" style="width: 200px; margin-left: 10px"></el-input>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card shadow="never" class="standard-margin">
      <span class="font-title-medium">商家快递信息</span>
      <div class="form-container-border" v-show="orderReturnApply.status === 2">
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6" style="height: 52px; line-height: 32px">物流公司</el-col>
          <el-col class="form-border font-small" :span="18">
            <el-select v-model="updateStatusParam.logisticalCompany" filterable remote reserve-keyword clearable placeholder="请输入物流公司" :remote-method="remoteMethod" :loading="companyloading" size="small" style="width: 200px; margin-left: 10px" @clear="getCompanyList" @change="changeDeliveryCompany($event)">
              <el-option v-for="item in companyOptions" :key="item.expId" :label="item.expName" :value="item.expName"> </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6" style="height: 52px; line-height: 32px">快递单号</el-col>
          <el-col class="form-border font-small" :span="18">
            <el-input size="small" v-model="updateStatusParam.logisticalNumber" placeholder="请输入快递单号" style="width: 200px; margin-left: 10px"></el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6" style="height: 52px; line-height: 32px">卖家联系电话</el-col>
          <el-col class="form-border font-small" :span="18">
            <el-input size="small" v-model="updateStatusParam.phoneNumber" placeholder="请输入卖家联系电话" style="width: 200px; margin-left: 10px"></el-input>
          </el-col>
        </el-row>
      </div>
      <div class="form-container-border" v-show="orderReturnApply.status >= 3">
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">物流公司</el-col>
          <el-col class="form-border font-small" :span="18">{{ orderReturnApply.logisticsName || '暂无' }}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">快递单号</el-col>
          <el-col class="form-border font-small" :span="18">{{ orderReturnApply.logisticsOdd || '暂无' }}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">卖家联系电话</el-col>
          <el-col class="form-border font-small" :span="18">{{ orderReturnApply.phone || '暂无' }}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6" style="height: 200px; line-height: 200px">物流信息</el-col>
          <el-col v-if="orderReturnApply.logisticalInfoVos && orderReturnApply.logisticalInfoVos.length" class="form-border font-small" :span="18" style="height: 200px; overflow-y: scroll">
            <p v-for="item in orderReturnApply.logisticalInfoVos" :key="item.id">
              {{ item.logisticalContext }}
            </p>
          </el-col>
          <el-col v-else class="form-border font-small" :span="18" style="height: 200px; overflow-y: scroll"> 暂无 </el-col>
        </el-row>
      </div>
    </el-card>
    <!-- 操作按钮 -->
    <div style="margin-top: 15px; text-align: center" v-if="orderReturnApply.status === 0">
      <el-button type="primary" size="small" @click="handleUpdateStatus(1)">确认换货</el-button>
      <el-button type="danger" size="small" @click="handleUpdateStatus(3)">拒绝换货</el-button>
    </div>
    <div style="margin-top: 15px; text-align: center" v-if="orderReturnApply.status === 2">
      <el-button type="primary" size="small" @click="handleUpdateStatusTo()">确认发货</el-button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import store from '@/store'

const defaultUpdateStatusParam = {
  companyAddressId: null,
  handleMan: null,
  handleNote: null,
  receiveMan: '',
  receiveNote: null,
  returnAmount: 0,
  status: 0,
  logisticalCompany: '',
  logisticalNumber: '',
  phoneNumber: ''
}
const defaultOrderReturnApply = {
  id: null,
  orderId: null,
  companyAddressId: null,
  productId: null,
  orderSn: null,
  createTime: null,
  memberUsername: null,
  returnAmount: null,
  returnName: null,
  returnPhone: null,
  status: null,
  handleTime: null,
  productPic: null,
  productName: null,
  productBrand: null,
  productAttr: null,
  productCount: null,
  productPrice: null,
  productRealPrice: null,
  reason: null,
  description: null,
  proofPics: [],
  handleNote: null,
  handleMan: null,
  receiveMan: null,
  receiveTime: null,
  receiveNote: null
}
export default {
  name: 'returnApplyDetail',
  data() {
    return {
      id: null,
      orderReturnApply: Object.assign({}, defaultOrderReturnApply),
      productList: null,
      proofPics: [],
      updateStatusParam: Object.assign({}, defaultUpdateStatusParam),
      companyAddressList: null,
      companyOptions: [],
      companyloading: false
    }
  },
  created() {
    this.getDictionaryList()
    this.id = this.$route.query.id
    this.getDetail()
    this.getCompanyList()
  },
  computed: {
    // totalAmount() {
    //   if (this.orderReturnApply != null) {
    //     return (
    //       this.orderReturnApply.productRealPrice *
    //       this.orderReturnApply.productCount
    //     );
    //   } else {
    //     return 0;
    //   }
    // },
    currentAddress() {
      let id = this.updateStatusParam.companyAddressId
      if (this.companyAddressList == null) return {}
      for (let i = 0; i < this.companyAddressList.length; i++) {
        let address = this.companyAddressList[i]
        if (address.id === id) {
          return address
        }
      }
      return null
    },
    ...mapState({
      changeStatusOptions: state => state.dictionary.changeStatus
    })
  },
  filters: {
    formatRegion(address) {
      if (!address) return ''
      let str = address.province
      if (address.city != null) {
        str += '  ' + address.city
      }
      str += '  ' + address.region
      return str
    }
  },
  methods: {
    formatTime(time) {
      if (time == null || time === '') {
        return ''
      }
      let date = new Date(time)
      return this.$utils.parseDate(date)
    },
    async getDictionaryList() {
      await store.dispatch('change_changestatus')
    },
    remoteMethod(query) {
      if (query !== '') {
        this.companyloading = true
        this.$api.order.getCompanyList({ keyWord: query }).then(res => {
          this.companyloading = false
          this.companyOptions = res.data
        })
      } else {
        this.companyOptions = []
      }
    },
    getCompanyList() {
      this.companyloading = true
      this.$api.order.getCompanyList().then(res => {
        this.companyOptions = res.data.splice(0, 20)
        this.companyloading = false
      })
    },
    handleViewOrder() {
      this.$router.push({
        path: '/oms/orderDetail',
        query: { id: this.orderReturnApply.orderId }
      })
    },
    getDetail() {
      this.$api.order.getApplyDetail(this.id).then(response => {
        // console.log("getDetail");
        response.data.productAttr = response.data.productAttr && JSON.parse(response.data.productAttr)
        this.orderReturnApply = response.data
        this.productList = []
        this.productList.push(this.orderReturnApply)
        if (this.orderReturnApply.proofPics != null) {
          this.proofPics = this.orderReturnApply.proofPics.split(',')
        }
        //退货中和完成
        if (this.orderReturnApply.status === 1 || this.orderReturnApply.status === 2) {
          this.updateStatusParam.returnAmount = this.orderReturnApply.returnAmount
          this.updateStatusParam.companyAddressId = this.orderReturnApply.companyAddressId
        }
        this.getCompanyAddressList()
        console.log(this.proofPics)
      })
    },
    getCompanyAddressList() {
      this.$api.order.companyAddressList().then(response => {
        this.companyAddressList = response.data.list
        for (let i = 0; i < this.companyAddressList.length; i++) {
          if (this.companyAddressList[i].receiveStatus === 1 && this.orderReturnApply.status === 0) {
            this.updateStatusParam.companyAddressId = this.companyAddressList[i].id
          }
        }
      })
    },
    handleUpdateStatus(status) {
      if (!this.companyAddressList.length && status === 1) {
        this.$message({
          type: 'warning',
          message: '请先添加收货地址'
        })
        return
      }
      if (!this.currentAddress && status === 1) {
        this.$message({
          type: 'warning',
          message: '请选择收货点'
        })
        return
      }
      this.updateStatusParam.receiveMan = this.currentAddress ? this.currentAddress.name : ''
      this.updateStatusParam.status = status
      // this.$confirm("是否要进行此操作?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning"
      // }).then(() => {
      //   // const params = {
      //   //   returnApplyId: this.id,
      //   //   status: status
      //   // }
      //   this.$api.order.updateApplyStatus(this.id, this.updateStatusParam).then(response => {
      //     this.$message({
      //       type: "success",
      //       message: "操作成功!",
      //       duration: 1000
      //     });
      //     this.$router.back();
      //   });
      // });
      // 确认换货
      if (status === 1) {
        this.$confirm('是否要进行此操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // const params = {
          //   returnApplyId: this.id,
          //   status: status
          // }
          this.updateStatusParam.refuseState = '0'
          this.$api.order.updateApplyStatus(this.id, this.updateStatusParam).then(() => {
            this.$message({
              type: 'success',
              message: '操作成功!',
              duration: 1000
            })
            this.$router.back()
          })
        })
        // 拒绝换货
      } else if (status === 3) {
        this.$prompt('请输入拒绝说明', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(({ value }) => {
          this.updateStatusParam.refuseState = value || '卖家已拒绝'
          this.$api.order.updateApplyStatus(this.id, this.updateStatusParam).then(() => {
            this.$message({
              type: 'success',
              message: '操作成功!',
              duration: 1000
            })
            this.$router.back()
          })
        })
      }
    },
    handleUpdateStatusTo() {
      if (!this.updateStatusParam.logisticalCompany) {
        this.$message({
          type: 'warning',
          message: '请输入快递公司'
        })
        return
      }
      if (!this.updateStatusParam.logisticalNumber) {
        this.$message({
          type: 'warning',
          message: '请输入快递单号'
        })
        return
      }
      if (!this.updateStatusParam.phoneNumber) {
        this.$message({
          type: 'warning',
          message: '请输入卖家联系电话'
        })
        return
      }
      this.$confirm('是否要进行此操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          orderReturnApplyId: this.id,
          logisticalCompany: this.updateStatusParam.logisticalCompany,
          logisticalNumber: this.updateStatusParam.logisticalNumber,
          phoneNumber: this.updateStatusParam.phoneNumber,
          type: 1
        }
        this.$api.order.sendProduct(params).then(() => {
          this.$message({
            type: 'success',
            message: '操作成功!',
            duration: 1000
          })
          this.$router.back()
        })
      })
    },
    changeDeliveryCompany(event) {
      if (event === '新印配送') {
        this.$set(this.updateStatusParam, 'logisticalNumber', this.orderReturnApply.orderSn)
      }
    }
  }
}
</script>
<style scoped>
.detail-container {
  position: absolute;
  left: 0;
  right: 0;
  width: 1080px;
  padding: 35px 35px 15px 35px;
  margin: 20px auto;
}

.standard-margin {
  margin-top: 15px;
}
.form-border {
  border-right: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  padding: 10px;
}

.form-container-border {
  border-left: 1px solid #dcdfe6;
  border-top: 1px solid #dcdfe6;
  margin-top: 15px;
}

.form-left-bg {
  background: #f2f6fc;
  text-align: center;
}
.col-description {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>

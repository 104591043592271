<template>
  <el-dialog title="设置不限购区域模板" append-to-body :visible="selectAreaDialogVisible" width="50%" @close="closeDialog">
    <el-form ref="objFrom" :model="dataObj" :rules="objRules" label-width="100px">
      <el-form-item label="模板名称" prop="areaId">
        <el-select v-model="dataObj.areaId" placeholder="请选择" clearable>
          <el-option v-for="item in areaOptions" :key="'area_' + item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <div class="btn-area">
        <el-button v-if="userStatus === 0" type="primary" size="mini" @click="addAreaModlues">添加不限购区域模板</el-button>
      </div>
      <div class="btn-area">
        <el-button size="small" @click="closeDialog">取 消</el-button>
        <el-button size="small" type="primary" @click="handleSelectDialogConfirm('objFrom')">确 定</el-button>
      </div>
    </el-form>
    <LimitAreaDetail v-if="LimitAreaDetailShow" @close="close" />
  </el-dialog>
</template>

<script>
import LimitAreaDetail from '../../limitArea/components/LimitAreaDetail'
export default {
  components: {
    LimitAreaDetail
  },
  props: {
    selectAreaDialogVisible: {
      type: Boolean,
      default: false
    },
    userStatus: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      areaOptions: [],
      dataObj: {
        areaId: null
      },
      objRules: {
        areaId: [{ required: true, message: '请选择模板', trigger: 'change' }]
      },
      LimitAreaDetailShow: false
    }
  },
  created() {
    this.getList()
  },
  methods: {
    close() {
      this.LimitAreaDetailShow = false
      this.getList()
    },
    async getList() {
      await this.$api.suit.getLimitAreaAllList().then(res => {
        if (res.data && res.data.length) {
          this.areaOptions = []
          res.data.forEach(el => {
            this.areaOptions.push({
              value: el.id,
              label: el.tmpName
            })
          })
        }
      })
    },
    closeDialog() {
      this.$emit('closeAreaDialog')
    },
    handleSelectDialogConfirm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            this.$api.suit.getLimitAreaById(this.dataObj.areaId).then(res => {
              const areaList = res.data.resv1.split(',')
              const nameList = []
              if (areaList && areaList.length) {
                areaList.forEach(el => {
                  nameList.push(el.split('-')[0])
                })
              }
              const data = {
                tmpName: this.dataObj.areaId,
                areaList: [...new Set(nameList)].join(',')
              }
              this.$emit('addAreaInfo', data)
              this.closeDialog()
            })
          })
        } else {
          this.$message({
            message: '验证失败',
            type: 'error',
            duration: 1000
          })
          return false
        }
      })
    },
    addAreaModlues() {
      this.LimitAreaDetailShow = true
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-area {
  margin: 20px;
}
</style>

import request from '@/api/request'
// import qs from 'qs'

const invoice = {
  // 开票信息-查询
  invoiceList(params) {
    return request({
      url: '/system/invoice/log/list',
      method: 'get',
      params: params
    })
  },

  // 开票信息-查找
  getInvoiceByTd(id) {
    return request({
      url: `/expositionShop/sys/incoiceLogController/getDetails/${id}`,
      method: 'get'
    })
  },

  // 开票信息-删除
  removeInvoiceByTd(params) {
    return request({
      url: '/system/invoice/log/remove',
      method: 'get',
      params: params
    })
  },

  // 开票信息-新增
  saveInvoice(params) {
    return request({
      url: '/system/invoice/log/remove',
      method: 'POST',
      data: params
    })
  },

  // 开票信息-修改
  updateInvoice(params) {
    return request({
      url: '/system/invoice/log/update',
      method: 'POST',
      params: params
    })
  },

  // 根据订单编号查询订单
  getOrderInfo(params) {
    return request({
      url: '/expositionShop/order/code',
      method: 'GET',
      params: params
    })
  },

  // 发票作废
  cancellationInvoice(params) {
    return request({
      url: '/expositionShop/sys/incoiceLogController/toVoid',
      method: 'GET',
      params: params
    })
  },
  /**
   *发票信息导出
   * @param {*} params
   * @returns
   */
  invoiceListExport(params) {
    return request({
      url: '/system/invoice/log/exportList',
      method: 'GET',
      params,
      responseType: 'blob'
    })
  }
}

export default invoice

<template>
  <el-dialog v-dialogDrag :close-on-click-modal="false" :visible="true" :title="isEdit ? '编辑' : '新增' + '套餐'" top="10vh" width="80%" @close="close">
    <el-card class="container" shadow="never">
      <el-form ref="suitObjFrom" :model="suitObj" :rules="suitRules" label-width="100px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="套餐名称" prop="packageName">
              <el-input v-model="suitObj.packageName" size="mini" @blur="validateName" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="创建时间">
              <el-input v-model="createTime" size="mini" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="最后修改时间">
              <el-input v-model="lstUpdTime" size="mini" disabled />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="商品分类" name="first">
          <el-form ref="classifyObjFrom" :model="classifyObj" :rules="classifyRules" label-width="100px">
            <el-form-item label="商品分类：">
              <el-cascader v-model="selectProductCateValue" clearable :options="productCateOptions" size="mini" />
              <el-button type="primary" disabled @click="searchClassfiy">查询</el-button>
            </el-form-item>
          </el-form>
          <el-table border :data="classifyTableData">
            <el-table-column type="index" width="50" label="序号" align="center" />
            <el-table-column prop="id" label="分类编号" />
            <el-table-column prop="parentName" label="一级分类名称" />
            <el-table-column prop="name" label="二级分类名称" />
            <el-table-column label="优惠比例(100%)" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.salesRate" size="mini" disabled />
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-container">
            <el-pagination :current-page="page" :page-sizes="pageSize" :page-size="size" :total="count" :layout="'total, sizes, prev, pager, next, jumper'" @size-change="pageSizeChange" @current-change="pageNumberChange" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="商品" name="second">
          <el-row class="btn-area">
            <el-button type="primary" disabled size="mini" @click="addProduct">添加商品</el-button>
          </el-row>
          <el-table border :data="goodsTableData">
            <el-table-column prop="id" label="商品编号" />
            <el-table-column prop="name" label="商品名称" />
            <el-table-column prop="price" label="优惠售价" />
            <el-table-column label="优惠比例" align="center">
              <template slot-scope="scope">
                <el-input v-model.number="scope.row.salesProportion" size="mini" />
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-container">
            <el-pagination :current-page="goodsPage" :page-sizes="goodsPageSize" :page-size="goodsSize" :total="goodsCount" :layout="'total, sizes, prev, pager, next, jumper'" @size-change="goodsPageSizeChange" @current-change="goodsPageNumberChange" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="商品sku" name="third">
          <el-row class="btn-area">
            <el-button type="primary" size="mini" :disabled="isEdit" @click="addProductSku">添加商品sku</el-button>
          </el-row>
          <el-table border :data="goodsSkuTableData">
            <el-table-column prop="productId" label="商品编号" />
            <el-table-column prop="name" label="商品名称" />
            <el-table-column label="商品规格">
              <template slot-scope="scope">
                <span v-for="(item, index) in scope.row.spData" :key="index">{{ item.key + ':' + item.value + ';' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="price" label="售价" />
            <el-table-column label="优惠金额" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.salesAmount" size="mini" @change="changeSkuPrice(scope.row)" />
              </template>
            </el-table-column>
            <el-table-column prop="salesPrice" label="优惠售价" />
          </el-table>
          <div class="pagination-container">
            <el-pagination :current-page="goodsSkuPage" :page-sizes="goodsSkuPageSize" :page-size="goodsSkuSize" :total="goodsSkuCount" :layout="'total, sizes, prev, pager, next, jumper'" @size-change="goodsSkuPageSizeChange" @current-change="goodsSkuPageNumberChange" />
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-row type="flex" justify="center" class="btn-area">
        <el-button type="primary" size="mini" @click="onSubmit('suitObjFrom')">保存</el-button>
        <el-button size="mini" @click="close">取消</el-button>
      </el-row>
      <ProductDialog v-if="selectDialogVisible" :selected-goods-ids="selectedGoodsIds" :selected-goods-sku-ids="selectedGoodsSkuIds" :active-name="activeName" @closeProductDialog="closeProductDialog" @addProductList="addProductList" />
    </el-card>
  </el-dialog>
</template>
<script>
import SingleUpload from '@/components/Upload/singleUpload'
import ProductDialog from './productDialog'
const defaultSuit = {
  packageName: '',
  createTime: '',
  lstUpdTime: ''
}
const defaultClassify = {
  productCategoryId: ''
}
export default {
  name: 'BrandDetail',
  // eslint-disable-next-line vue/no-unused-components
  components: { SingleUpload, ProductDialog },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      // 套餐表单参数
      suitObj: Object.assign({}, defaultSuit),
      suitRules: {
        packageName: [
          { required: true, message: '请输入套餐名称', trigger: 'blur' },
          {
            min: 2,
            max: 70,
            message: '长度在 2 到 70 个字符',
            trigger: 'blur'
          }
        ]
      },

      activeName: 'first', // 当前选中的tab
      selectProductCateValue: null,
      productCateOptions: [],
      // 商品分类表单参数
      classifyObj: Object.assign({}, defaultClassify),
      classifyRules: {},
      createTime: '',
      lstUpdTime: '',
      // 商品分类分页参数
      page: 1,
      size: 5,
      count: 0,
      pageSize: [5, 10, 20, 30, 50, 100],
      // 商品列表分页参数
      goodsPage: 1,
      goodsSize: 5,
      goodsCount: 0,
      goodsPageSize: [5, 10, 20, 30, 50, 100],
      // 商品sku列表分页参数
      goodsSkuPage: 1,
      goodsSkuSize: 5,
      goodsSkuCount: 0,
      goodsSkuPageSize: [5, 10, 20, 30, 50, 100],
      classifyTableData: [], // 分类表格列表
      selectedClassfiyList: [], // 已输入的分类列
      selectDialogVisible: false, // 商品列表弹框
      selectedGoodsIds: [], // 选中的商品id
      selectedGoodsList: [], // 选中的商品列表
      selectedGoodsDataList: [], // 填写过的商品列表（切换分页时记住上一次输入的数据）
      selectedGoodsSkuIds: [], // 选中的商品sku id
      selectedGoodsSkuList: [], // 选中的商品sku列表
      selectedGoodsSkuDataList: [], // 填写过的商品sku列表（切换分页时记住上一次输入的数据）
      getGoodsSkuList: [], // 编辑调用接口时返回的数据--sku
      currentGoodsSkuDataList: [], // 编辑时存放当前页的列表数据--sku
      goodsTableData: [], // 商品表格列表
      goodsSkuTableData: [], // 商品sku表格列表
      validatePackageName: true,
      currentDiscountsType: 0 // 当前优惠类型
    }
  },
  created() {
    this.getProductCateList()
    if (this.isEdit) {
      this.getData()
    } else {
      this.getClassifyList()
      this.suitObj = Object.assign({}, defaultSuit)
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    formatTime(time) {
      if (time == null || time === '') {
        return ''
      }
      const date = new Date(time)
      return this.$utils.parseDate(date)
    },
    async getData() {
      if (this.activeName === 'first') {
        await this.getClassifyList()
      }
      const params = {
        discountsType: this.currentDiscountsType,
        packageId: this.id
      }
      this.$api.suit.getSuitListById(params).then(res => {
        this.$set(this.suitObj, 'packageName', res.data.packageName)
        this.createTime = this.formatTime(res.data.createTime)
        this.lstUpdTime = this.formatTime(res.data.lstUpdTime)
        if (this.activeName === 'first') {
          const classfiyDataList = res.data.memberPackageDiscountVoList
          if (classfiyDataList && classfiyDataList.length) {
            classfiyDataList.forEach(el => {
              this.classifyTableData.forEach(item => {
                if (el.productCategoryId === item.id) {
                  item.dataId = el.id
                  item.salesRate = el.productDiscount
                }
              })
            })
          }
          this.classifyTableData = JSON.parse(JSON.stringify(this.classifyTableData))
        }
        if (this.activeName === 'second') {
          const listData1 = res.data.memberPackageDiscountVoList
          if (listData1 && listData1.length) {
            listData1.forEach(el => {
              this.selectedGoodsIds.push(el.productId)
            })
            const goodsDataList = listData1
            this.getPrductInfoById(this.selectedGoodsIds, goodsDataList)
          }
        }
        if (this.activeName === 'third') {
          const listData2 = res.data.memberPackageDiscountVoList
          if (listData2 && listData2.length) {
            listData2.forEach(el => {
              this.selectedGoodsSkuIds.push(el.productId)
            })
            this.getGoodsSkuList = listData2
            this.getProductSkuInfoByProductId(this.selectedGoodsSkuIds)
          }
        }
      })
    },
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            const data1 = {
              packageName: this.suitObj.packageName,
              packageId: this.id ? this.id : ''
            }
            await this.$api.suit.verifyPackageName(data1).then(res => {
              if (res.code === 200) {
                if (res.data) {
                  this.validatePackageName = true
                  this.$message({
                    type: 'warning',
                    message: '您输入的套餐名称已存在'
                  })
                } else {
                  this.validatePackageName = false
                }
              }
            })
            if (this.validatePackageName) {
              return
            }
            const goodsSkuList = []
            // if (this.goodsSkuTableData && this.goodsSkuTableData.length) {
            //   this.goodsSkuTableData.forEach(el => {
            //     goodsSkuList.push({
            //       discountPrice: el.salesAmount,
            //       discountsType: 2,
            //       id: el.dataId ? el.dataId : '',
            //       productSkuId: el.id,
            //       productId: el.productId
            //     })
            //   })
            // }

            if (this.currentGoodsSkuDataList && this.currentGoodsSkuDataList.length) {
              this.currentGoodsSkuDataList.forEach(el => {
                goodsSkuList.push({
                  discountPrice: el.discountPrice,
                  discountsType: 2,
                  id: el.dataId,
                  productSkuId: el.productSkuId,
                  productId: el.productId
                })
              })
            }

            // if (this.isEdit) { // 修改
            //   if (this.currentGoodsSkuDataList && this.currentGoodsSkuDataList.length) {
            //     this.currentGoodsSkuDataList.forEach(el => {
            //       goodsSkuList.push({
            //         discountPrice: el.discountPrice,
            //         discountsType: 2,
            //         id: el.dataId,
            //         productSkuId: el.productSkuId,
            //         productId: el.productId
            //       })
            //     })
            //   }
            // } else {
            //   let currentSkuList = []
            //   if (this.selectedGoodsSkuDataList.length < this.goodsSkuCount) {
            //     if (this.selectedGoodsSkuDataList && this.selectedGoodsSkuDataList.length) {
            //       this.selectedGoodsSkuDataList.forEach(item => {
            //         currentSkuList = this.goodsSkuTableData.filter(el => item.id !== el.id)
            //       })
            //     }
            //   }

            //   if (this.selectedGoodsSkuDataList && this.selectedGoodsSkuDataList.length) {
            //     this.selectedGoodsSkuDataList.forEach(el => {
            //       goodsSkuList.push({
            //         discountPrice: el.salesAmount,
            //         discountsType: 2,
            //         id: el.dataId ? el.dataId : '',
            //         productSkuId: el.id,
            //         productId: el.productId
            //       })
            //     })
            //   }
            //   if (currentSkuList && currentSkuList.length) {
            //     currentSkuList.forEach(el => {
            //       goodsSkuList.push({
            //         discountPrice: el.salesAmount,
            //         discountsType: 2,
            //         id: el.dataId ? el.dataId : '',
            //         productSkuId: el.id,
            //         productId: el.productId
            //       })
            //     })
            //   }
            // }

            const goodsList = []
            if (this.goodsTableData && this.goodsTableData.length) {
              this.goodsTableData.forEach(el => {
                goodsList.push({
                  discountPrice: el.salesProportion,
                  discountsType: 1,
                  id: el.dataId ? el.dataId : '',
                  productId: el.id
                })
              })
            }
            const classifyList = []
            if (this.classifyTableData && this.classifyTableData.length) {
              this.classifyTableData.forEach(el => {
                if (el.salesRate) {
                  classifyList.push({
                    productCategoryId: el.id,
                    discountsType: 0,
                    id: el.dataId ? el.dataId : '',
                    productDiscount: el.salesRate
                  })
                }
              })
            }
            if (this.isEdit) {
              const data = {
                packageId: this.id,
                packageName: this.suitObj.packageName,
                memberPackageDiscountVoList: [...classifyList, ...goodsList, ...goodsSkuList]
              }
              console.log('选中:', goodsSkuList)
              this.$api.suit.updateSuitInfo(data).then(res => {
                if (res.code === 200) {
                  this.$refs[formName].resetFields()
                  this.$message({
                    message: '修改成功',
                    type: 'success',
                    duration: 1000
                  })
                  this.close()
                }
              })
            } else {
              // 新增
              if (!(goodsSkuList && goodsSkuList.length)) {
                this.$message({
                  message: '请选择商品sku',
                  type: 'warning'
                })
                return
              }
              const data2 = {
                ...this.suitObj,
                memberPackageDiscountVoList: [...classifyList, ...goodsList, ...goodsSkuList]
              }
              console.log('选中:', goodsSkuList)
              this.$api.suit.addSuitInfo(data2).then(res => {
                if (res.code === 200) {
                  this.resetForm(formName)
                  this.$message({
                    message: '提交成功',
                    type: 'success',
                    duration: 1000
                  })
                  this.close()
                }
              })
            }
          })
        } else {
          this.$message({
            message: '验证失败',
            type: 'error',
            duration: 1000
          })
          return false
        }
      })
    },
    validateName() {
      if (this.suitObj.packageName) {
        const data = {
          packageName: this.suitObj.packageName,
          packageId: this.id ? this.id : ''
        }
        this.$api.suit.verifyPackageName(data).then(res => {
          if (res.code === 200) {
            if (res.data) {
              this.validatePackageName = true
              this.$message({
                type: 'warning',
                message: '您输入的套餐名称已存在'
              })
            } else {
              this.validatePackageName = false
            }
          }
        })
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.suitObj = Object.assign({}, defaultSuit)
      this.classifyTableData = [] // 分类表格列表
      this.selectedClassfiyList = [] // 已输入的分类列
      this.selectedGoodsIds = [] // 选中的商品id
      this.selectedGoodsList = [] // 选中的商品列表
      this.selectedGoodsDataList = [] // 填写过的商品列表（切换分页时记住上一次输入的数据）
      this.selectedGoodsSkuIds = [] // 选中的商品sku id
      this.selectedGoodsSkuList = [] // 选中的商品sku列表
      this.selectedGoodsSkuDataList = [] // 填写过的商品sku列表（切换分页时记住上一次输入的数据）
      this.goodsTableData = [] // 商品表格列表
      this.goodsSkuTableData = [] // 商品sku表格列表
      this.$router.push({ path: '/pms/suit' })
    },
    handleClick(tab) {
      this.activeName = tab.name
      if (this.isEdit) {
        switch (this.activeName) {
          case 'first':
            this.currentDiscountsType = 0
            this.getData()
            break
          case 'second':
            this.currentDiscountsType = 1
            this.getData()
            break
          case 'third':
            this.currentDiscountsType = 2
            this.getData()
            break
        }
      }
    },
    async getClassifyList() {
      const params = {
        pageSize: this.size,
        pageNumber: this.page,
        seedId: this.classifyObj.productCategoryId
      }
      const listData = await this.$api.suit.getCategoryInfo(params)
      this.classifyTableData = listData.data.list
      this.count = listData.data.total
      if (this.selectedClassfiyList && this.selectedClassfiyList.length) {
        this.selectedClassfiyList.forEach(el => {
          this.classifyTableData.forEach(item => {
            if (el.productCategoryId === item.id) {
              item.salesRate = el.productDiscount
            }
          })
        })
        this.classifyTableData = JSON.parse(JSON.stringify(this.classifyTableData))
      }
    },
    searchClassfiy() {
      this.getClassifyList()
    },
    // 获取分类--级联
    getProductCateList() {
      this.$api.product.fetchListWithChildren().then(response => {
        const list = response.data
        this.productCateOptions = []
        for (let i = 0; i < list.length; i++) {
          const children = []
          if (list[i].children != null && list[i].children.length > 0) {
            for (let j = 0; j < list[i].children.length; j++) {
              children.push({
                label: list[i].children[j].name,
                value: list[i].children[j].id
              })
            }
          }
          this.productCateOptions.push({
            label: list[i].name,
            value: list[i].id,
            children: children
          })
        }
      })
    },
    handleClassifyData() {
      this.classifyTableData.forEach(el => {
        if (el.salesRate) {
          this.selectedClassfiyList.push({
            productCategoryId: el.id,
            productDiscount: el.salesRate
          })
        }
      })
    },
    handleGoodsData() {
      this.goodsTableData.forEach(el => {
        if (el.salesProportion) {
          this.selectedGoodsDataList.push({
            id: el.id,
            salesProportion: el.salesProportion
          })
        }
      })
    },
    handleGoodsSkuData() {
      this.goodsSkuTableData.forEach(el => {
        if (el.salesAmount) {
          this.selectedGoodsSkuDataList.push({
            id: el.id,
            salesAmount: el.salesAmount,
            productId: el.productId
          })
        }
      })
    },
    pageSizeChange(val) {
      this.size = val
      this.handleClassifyData()
      if (this.isEdit) {
        this.getData()
      } else {
        this.getClassifyList()
      }
    },
    pageNumberChange(val) {
      this.page = val
      this.handleClassifyData()
      if (this.isEdit) {
        this.getData()
      } else {
        this.getClassifyList()
      }
    },
    goodsPageSizeChange(val) {
      this.goodsSize = val
      this.handleGoodsData()
      this.getPrductInfoById(this.selectedGoodsIds)
    },
    goodsPageNumberChange(val) {
      this.goodsPage = val
      this.handleGoodsData()
      this.getPrductInfoById(this.selectedGoodsIds)
    },
    goodsSkuPageSizeChange(val) {
      this.goodsSkuSize = val
      this.handleGoodsSkuData()
      this.getProductSkuInfoByProductId(this.selectedGoodsSkuIds)
    },
    goodsSkuPageNumberChange(val) {
      this.goodsSkuPage = val
      this.handleGoodsSkuData()
      this.getProductSkuInfoByProductId(this.selectedGoodsSkuIds)
    },
    addProduct() {
      this.selectDialogVisible = true
    },
    closeProductDialog() {
      this.selectDialogVisible = false
    },
    addProductList(val, activeName) {
      this.activeName = activeName
      console.log(activeName, val)
      if (activeName === 'third') {
        this.selectedGoodsSkuList = this.selectedGoodsSkuList.concat(val)
        let ids = []
        if (this.selectedGoodsSkuList && this.selectedGoodsSkuList.length) {
          this.selectedGoodsSkuList.forEach(el => {
            ids.push(el.id)
          })
        }
        ids = [...new Set(ids)]
        this.selectedGoodsSkuIds = ids
        if (ids.length) {
          this.getProductSkuInfoByProductId(ids)
        }
      } else if (activeName === 'second') {
        this.selectedGoodsList = this.selectedGoodsList.concat(val)
        const ids = []
        if (this.selectedGoodsList && this.selectedGoodsList.length) {
          this.selectedGoodsList.forEach(el => {
            ids.push(el.id)
          })
        }
        this.selectedGoodsIds = ids
        if (ids.length) {
          this.getPrductInfoById(ids)
        }
      }
    },
    async getProductSkuInfoByProductId(ids) {
      const params = {
        pageNumber: this.goodsSkuPage,
        pageSize: this.goodsSkuSize,
        prductIds: ids.join(',')
      }
      const dataList = await this.$api.suit.getPrductSkuById(params)
      this.goodsSkuTableData = dataList.data.list
      this.goodsSkuCount = dataList.data.total
      if (this.goodsSkuTableData && this.goodsSkuTableData.length) {
        this.goodsSkuTableData.forEach(el => {
          el.spData = el.spData && el.spData.length ? JSON.parse(el.spData) : []
        })
      }

      if (this.getGoodsSkuList && this.getGoodsSkuList.length) {
        this.getGoodsSkuList.forEach(el => {
          this.goodsSkuTableData.forEach(item => {
            if (el.productSkuId === item.id) {
              item.dataId = el.id
              item.salesAmount = el.discountPrice
            }
          })
        })
      }

      if (this.selectedGoodsSkuDataList && this.selectedGoodsSkuDataList.length) {
        this.selectedGoodsSkuDataList.forEach(el => {
          this.goodsSkuTableData.forEach(item => {
            if (el.id === item.id) {
              // item.dataId = el.dataId
              item.salesAmount = el.salesAmount
            }
          })
        })
      }

      if (this.goodsSkuTableData && this.goodsSkuTableData.length) {
        this.goodsSkuTableData.forEach(el => {
          el.salesPrice = el.salesAmount || el.salesAmount == 0 ? (Number(el.price) - Number(el.salesAmount)).toFixed(2) : ''
        })
      }

      this.goodsSkuTableData = JSON.parse(JSON.stringify(this.goodsSkuTableData))
    },
    async getPrductInfoById(ids, goodsDataList) {
      const params = {
        pageNumber: this.goodsPage,
        pageSize: this.goodsSize,
        prductIds: ids.join(',')
      }
      const dataList = await this.$api.suit.getPrductInfoById(params)
      this.goodsTableData = dataList.data.list
      this.goodsCount = dataList.data.total
      if (goodsDataList && goodsDataList.length) {
        goodsDataList.forEach(el => {
          this.goodsTableData.forEach(item => {
            if (el.productId === item.id) {
              item.dataId = el.id
              item.salesProportion = el.discountPrice
            }
          })
        })
      }
      if (this.selectedGoodsDataList && this.selectedGoodsDataList.length) {
        this.selectedGoodsDataList.forEach(el => {
          this.goodsTableData.forEach(item => {
            if (el.id === item.id) {
              item.salesProportion = el.salesProportion
            }
          })
        })
      }

      this.goodsTableData = JSON.parse(JSON.stringify(this.goodsTableData))
    },
    changeSkuPrice(row) {
      if (row.salesAmount > row.price) {
        this.$message({
          type: 'error',
          message: '优惠金额不能大于售价'
        })
        row.salesAmount = ''
        return
      }
      this.goodsSkuTableData.forEach(el => {
        if (el.id == row.id) {
          el.salesPrice = (Number(el.price) - Number(el.salesAmount)).toFixed(2)
        }
      })
      this.goodsSkuTableData = JSON.parse(JSON.stringify(this.goodsSkuTableData))
      // if (this.isEdit) { // 编辑
      if (this.currentGoodsSkuDataList && this.currentGoodsSkuDataList.length) {
        // 如果当前选中的存在
        this.currentGoodsSkuDataList = this.currentGoodsSkuDataList.filter(el => el.productSkuId !== row.id)
        this.currentGoodsSkuDataList.push({
          discountPrice: row.salesAmount,
          dataId: row.dataId ? row.dataId : '',
          productSkuId: row.id,
          productId: row.productId
        })
      } else {
        this.currentGoodsSkuDataList.push({
          discountPrice: row.salesAmount,
          dataId: row.dataId ? row.dataId : '',
          productSkuId: row.id,
          productId: row.productId
        })
      }

      // }
    },
    addProductSku() {
      this.selectDialogVisible = true
      this.handleGoodsSkuData()
    },
    closeProductSkuDialog() {
      this.selectDialogVisible = false
    }
  },
  watch: {
    selectProductCateValue: function (newValue) {
      if (newValue != null && newValue.length == 2) {
        this.classifyObj.productCategoryId = newValue[1]
      } else {
        this.classifyObj.productCategoryId = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-container {
  width: 80%;
  left: 220px;
  padding: 10px;
}
::v-deep.btn-area {
  margin-top: 20px;
  margin-bottom: 20px;
  button {
    width: 100px;
  }
}
</style>

<template>
  <div class="app-container">
    <el-card class="header-card">
      <el-form :inline="true" :model="listQuery" size="small" label-width="110px">
        <el-form-item label="专题名称">
          <el-input v-model="listQuery.subjectName" class="input-width" placeholder="专题名称" />
        </el-form-item>
        <el-form-item label="推荐状态">
          <el-select v-model="listQuery.recommendStatus" placeholder="全部" clearable class="input-width">
            <el-option v-for="item in recommendOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
      </el-form>
      <el-row class="search-area-btn">
        <div class="left">
          <el-button type="primary" size="mini" class="btn-add" @click="handleAdd()">添加专题</el-button>
          <el-select v-model="operateType" size="small" placeholder="批量操作">
            <el-option v-for="item in operates" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
          <el-button style="margin-left: 20px" class="search-button" @click="handleBatchOperate()" type="primary" size="small"> 确定 </el-button>
        </div>
        <div class="center">
          <el-button type="primary" size="small" @click="handleSearchList()">查询</el-button>
          <el-button size="small" @click="handleResetSearch()">重置</el-button>
        </div>
      </el-row>
    </el-card>
    <div class="table-container">
      <el-table v-loading="listLoading" ref="subjectTable" :data="list" border style="width: 100%" :row-class-name="rowClass" @row-click="clickRow" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="60" align="center" />
        <el-table-column type="index" label="编号" width="120" />
        <el-table-column label="专题名称" align="center">
          <template slot-scope="scope">{{ scope.row.title }}</template>
        </el-table-column>
        <el-table-column label="专题简介" align="center">
          <template slot-scope="scope">{{ scope.row.description }}</template>
        </el-table-column>
        <el-table-column label="专题图片" width="400" align="center">
          <template slot-scope="scope"><img style="height: 80px" :src="scope.row.pic" /></template>
        </el-table-column>
        <el-table-column label="是否显示" width="160" align="center">
          <template slot-scope="scope">{{ scope.row.showStatus | formatShowStatus }}</template>
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="handleUpdate(scope.$index, scope.row)">编辑 </el-button>
            <el-button type="danger" size="mini" @click="handleDelete(scope.$index, scope.row)">删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes,prev, pager, next,jumper" :page-size="listQuery.pageSize" :page-sizes="[5, 10, 15]" :current-page.sync="listQuery.pageNum" :total="total" />
    </div>
    <el-dialog title="选择专题" :visible.sync="selectDialogVisible" width="50%">
      <el-input v-model="dialogData.listQuery.keyword" style="width: 250px; margin-bottom: 20px" size="small" placeholder="专题名称搜索">
        <el-button slot="append" icon="el-icon-search" @click="handleSelectSearch()" />
      </el-input>
      <el-table :data="dialogData.list" @selection-change="handleDialogSelectionChange" border>
        <el-table-column type="selection" width="60" align="center" />
        <el-table-column label="专题名称" align="center">
          <template slot-scope="scope">{{ scope.row.title }}</template>
        </el-table-column>
        <el-table-column label="所属分类" width="160" align="center">
          <template slot-scope="scope">{{ scope.row.categoryName }}</template>
        </el-table-column>
        <el-table-column label="添加时间" width="160" align="center">
          <template slot-scope="scope">{{ formatTime(scope.row.createTime) }}</template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination background @size-change="handleDialogSizeChange" @current-change="handleDialogCurrentChange" layout="prev, pager, next" :current-page.sync="dialogData.listQuery.pageNum" :page-size="dialogData.listQuery.pageSize" :page-sizes="[5, 10, 15]" :total="dialogData.total" />
      </div>
      <div style="clear: both"></div>
      <div slot="footer">
        <el-button size="small" @click="selectDialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleSelectDialogConfirm()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="设置排序" :visible.sync="sortDialogVisible" width="40%">
      <el-form :model="sortDialogData" label-width="150px">
        <el-form-item label="排序：">
          <el-input v-model="sortDialogData.sort" style="width: 200px" />
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="sortDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleUpdateSort" size="small">确 定</el-button>
      </span>
    </el-dialog>
    <component :is="subjectDetail" v-if="subjectDetailShow" :id="subjectDetailId" :is-edit="subjectDetailEdit" @close="close" @refreshData="refreshData" />
  </div>
</template>
<script>
const defaultListQuery = {
  pageNum: 1,
  pageSize: 5,
  use: 2,
  subjectName: null,
  recommendStatus: null
}
const defaultRecommendOptions = [
  {
    label: '未推荐',
    value: 0
  },
  {
    label: '推荐中',
    value: 1
  }
]
export default {
  name: 'homeSubjectList',
  components: {
    subjectDetail: () => import('./components/subjectDetail')
  },
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      recommendOptions: Object.assign({}, defaultRecommendOptions),
      list: null,
      total: null,
      listLoading: false,
      multipleSelection: [],
      subjectDetail: 'subjectDetail',
      subjectDetailShow: false,
      subjectDetailEdit: false,
      subjectDetailId: '',
      selectRow: [],
      operates: [
        // {
        //   label: "设为推荐",
        //   value: 0
        // },
        // {
        //   label: "取消推荐",
        //   value: 1
        // },
        {
          label: '删除',
          value: 2
        }
      ],
      operateType: null,
      selectDialogVisible: false,
      dialogData: {
        list: null,
        total: null,
        multipleSelection: [],
        listQuery: {
          keyword: null,
          pageNum: 1,
          pageSize: 5
        }
      },
      sortDialogVisible: false,
      sortDialogData: { sort: 0, id: null }
    }
  },
  created() {
    this.getList()
  },
  filters: {
    formatRecommendStatus(status) {
      if (status === 1) {
        return '推荐中'
      } else {
        return '未推荐'
      }
    },
    formatShowStatus(val) {
      if (val === 1) {
        return '显示'
      } else {
        return '不显示'
      }
    }
  },
  watch: {
    multipleSelection(data) {
      this.selectRow = []
      if (data.length > 0) {
        data.forEach(item => {
          this.selectRow.push(this.$refs.subjectTable.data.indexOf(item))
        })
      }
    }
  },
  methods: {
    rowClass({ rowIndex }) {
      if (this.selectRow.includes(rowIndex)) {
        return 'selecleRowColor'
      }
    },
    clickRow(row) {
      this.$refs.subjectTable.toggleRowSelection(row)
    },
    close() {
      this.subjectDetailShow = false
    },
    refreshData() {
      this.close()
      this.getList()
    },
    formatTime(time) {
      if (time == null || time === '') {
        return 'N/A'
      }
      let date = new Date(time)
      return this.$utils.parseDate(date)
    },
    handleUpdate(index, row) {
      this.subjectDetailShow = true
      this.subjectDetailId = row.id
      this.subjectDetailEdit = true
    },
    handleAdd() {
      this.subjectDetailShow = true
      this.subjectDetailId = ''
      this.subjectDetailEdit = false
    },
    handleResetSearch() {
      this.listQuery = Object.assign({}, defaultListQuery)
    },
    handleSearchList() {
      this.listQuery.pageNum = 1
      this.getList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleSizeChange(val) {
      this.listQuery.pageNum = 1
      this.listQuery.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.pageNum = val
      this.getList()
    },
    handleRecommendStatusStatusChange(index, row) {
      this.updateRecommendStatusStatus(row.id, row.recommendStatus)
    },
    handleDelete(index, row) {
      this.deleteSubject(row.id)
    },
    handleBatchOperate() {
      if (this.multipleSelection < 1) {
        this.$message({
          message: '请选择一条记录',
          type: 'warning',
          duration: 1000
        })
        return
      }
      let ids = []
      for (let i = 0; i < this.multipleSelection.length; i++) {
        ids.push(this.multipleSelection[i].id)
      }
      if (this.operateType === 0) {
        //设为推荐
        this.updateRecommendStatusStatus(ids, 1)
      } else if (this.operateType === 1) {
        //取消推荐
        this.updateRecommendStatusStatus(ids, 0)
      } else if (this.operateType === 2) {
        //删除
        this.deleteSubject(ids)
      } else {
        this.$message({
          message: '请选择批量操作类型',
          type: 'warning',
          duration: 1000
        })
      }
    },
    handleSelectSubject() {
      this.selectDialogVisible = true
      this.dialogData.listQuery.keyword = null
      this.getDialogList()
    },
    handleSelectSearch() {
      this.getDialogList()
    },
    handleDialogSizeChange(val) {
      this.dialogData.listQuery.pageNum = 1
      this.dialogData.listQuery.pageSize = val
      this.getDialogList()
    },
    handleDialogCurrentChange(val) {
      this.dialogData.listQuery.pageNum = val
      this.getDialogList()
    },
    handleDialogSelectionChange(val) {
      this.dialogData.multipleSelection = val
    },
    handleSelectDialogConfirm() {
      if (this.dialogData.multipleSelection < 1) {
        this.$message({
          message: '请选择一条记录',
          type: 'warning',
          duration: 1000
        })
        return
      }
      let selectSubjects = []
      for (let i = 0; i < this.dialogData.multipleSelection.length; i++) {
        selectSubjects.push({
          subjectId: this.dialogData.multipleSelection[i].id,
          subjectName: this.dialogData.multipleSelection[i].title
        })
      }
      this.$confirm('使用要进行添加操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.subject.createHomeSubject(selectSubjects).then(() => {
          this.selectDialogVisible = false
          this.dialogData.multipleSelection = []
          this.getList()
          this.$message({
            type: 'success',
            message: '添加成功!'
          })
        })
      })
    },
    handleEditSort(index, row) {
      this.sortDialogVisible = true
      this.sortDialogData.sort = row.sort
      this.sortDialogData.id = row.id
    },
    handleUpdateSort() {
      this.$confirm('是否要修改排序?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.subject.updateHomeSubjectSort(this.sortDialogData).then(() => {
          this.sortDialogVisible = false
          this.getList()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
      })
    },
    getList() {
      this.listLoading = true
      this.$api.subject.subjectList(this.listQuery).then(response => {
        this.listLoading = false
        this.list = response.listData
        this.total = response.count
      })
    },
    updateRecommendStatusStatus(ids, status) {
      this.$confirm('是否要修改推荐状态?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let data = {
            ids: ids,
            recommendStatus: status
          }
          this.$api.subject.updateRecommendStatus(data).then(() => {
            this.getList()
            this.$message({
              type: 'success',
              message: '修改成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'success',
            message: '已取消操作!'
          })
          this.getList()
        })
    },
    deleteSubject(ids) {
      this.$confirm('是否要删除该专题?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          ids: ids
        }
        this.$api.subject.deleteSubject(data).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.listQuery.pageNum = 1
          this.getList()
        })
      })
    },
    getDialogList() {
      this.$api.product.subjectListAll(this.dialogData.listQuery).then(response => {
        this.dialogData.list = response.listData
        this.dialogData.total = response.count
      })
    }
  }
}
</script>
<style></style>

<template>
  <section class="app-main">
    <transition name="fade" mode="out-in">
      <!-- <keep-alive v-if="isRouterAlive"> -->
      <router-view :key="key"></router-view>
      <!-- </keep-alive> -->
    </transition>
  </section>
</template>

<script>
export default {
  name: "AppMain",
  props: {
    isRouterAlive: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>

<template>
  <subject-detail :isEdit="false" />
</template>
<script>
import SubjectDetail from './components/subjectDetail'
export default {
  name: 'addSubject',
  components: { SubjectDetail }
}
</script>
<style></style>

<template>
  <div class="app-container">
    <el-card class="header-card">
      <el-form :inline="true" :model="listQuery" size="small" label-width="110px">
        <el-form-item label="优惠券名称">
          <el-input v-model="listQuery.name" class="input-width" placeholder="优惠券名称" />
        </el-form-item>
        <el-form-item label="优惠券类型">
          <el-select v-model="listQuery.type" placeholder="全部" clearable class="input-width">
            <el-option v-for="item in couponTypeOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item v-if="isManageMentRole" label="店铺搜索：">
          <el-select v-model="listQuery.shopId" placeholder="请选择店铺" clearable>
            <el-option v-for="item in ShopNameOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item> -->
      </el-form>
      <el-row class="search-area-btn">
        <div class="left">
          <el-button type="primary" size="mini" class="btn-add" @click="handleAdd()">添加</el-button>
        </div>
        <div class="center">
          <el-button type="primary" size="small" @click="handleSearchList()"> 查询 </el-button>
          <el-button size="small" @click="handleResetSearch()"> 重置 </el-button>
        </div>
      </el-row>
    </el-card>
    <div class="table-container">
      <el-table ref="couponTable" v-loading="listLoading" :data="list" style="width: 100%" border @selection-change="handleSelectionChange">
        <!-- <el-table-column type="selection" width="60" align="center"></el-table-column> -->
        <el-table-column label="编号" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column label="优惠劵名称" align="center">
          <template slot-scope="scope">{{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column label="优惠券类型" width="100" align="center">
          <template slot-scope="scope">{{ $utils.handleOptions(scope.row.type, couponTypeOptions) }}</template>
        </el-table-column>
        <el-table-column label="所属店铺" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.shopName }}</template>
        </el-table-column>
        <el-table-column label="可使用商品" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.useType | formatUseType }}</template>
        </el-table-column>
        <el-table-column label="使用门槛" width="140" align="center">
          <template slot-scope="scope">满{{ scope.row.minPoint }}元可用</template>
        </el-table-column>
        <el-table-column label="面值" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.amount }}元</template>
        </el-table-column>
        <el-table-column label="适用平台" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.platform | formatPlatform }}</template>
        </el-table-column>
        <el-table-column label="有效期" width="170" align="center">
          <template slot-scope="scope">{{ formatDate(scope.row.startTime) }}至{{ formatDate(scope.row.endTime) }}</template>
        </el-table-column>
        <el-table-column label="状态" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.endTime | formatStatus }}</template>
        </el-table-column>
        <el-table-column label="操作" width="320" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleView(scope.$index, scope.row)">查看</el-button>
            <el-button type="primary" size="mini" @click="handleUpdate(scope.$index, scope.row)">编辑</el-button>
            <el-button v-if="scope.row.type === 6" type="primary" size="mini" @click="openUserDialog(scope.row.id)">赠券</el-button>
            <el-button type="danger" size="mini" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination background layout="total, sizes,prev, pager, next,jumper" :current-page.sync="listQuery.pageNumber" :page-size="listQuery.pageSize" :page-sizes="[5, 10, 20, 30, 50, 100]" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
    <userDialog v-if="userDialogVisible" :user-dialog-visible="userDialogVisible" @closeUserDialog="closeUserDialog" @addUserInfo="addUserInfo" />
    <CouponDetail v-if="couponDialogVisible" :id="couponId" :is-edit="isEditCoupon" @closeDialog="closeDialog" @refreshData="refreshData" />
  </div>
</template>
<script>
import userDialog from './components/userDialog'
import CouponDetail from './components/CouponDetail'

import { mapState } from 'vuex'
import store from '@/store'
const defaultListQuery = {
  pageNumber: 1,
  pageSize: 10,
  name: null,
  type: null,
  shopId: null,
  use: 2
}

export default {
  name: 'CouponList',
  components: {
    userDialog,
    CouponDetail
  },
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      list: null,
      total: null,
      listLoading: false,
      multipleSelection: [],
      ShopNameOptions: [],
      userDialogVisible: false, // 用户弹框
      currentId: null, // 当前点击的id
      couponDialogVisible: false,
      isEditCoupon: false,
      couponId: ''
    }
  },
  computed: {
    ...mapState({
      couponTypeOptions: state => {
        return state.dictionary.couponType.map(item => {
          item.value = String(item.value)
          return item
        })
      },
      isManageMentRole: state => state.user.userRole
    })
  },
  created() {
    this.getCounponList()
    this.getList()
    // this.getShopNameList()
  },
  // eslint-disable-next-line vue/order-in-components
  filters: {
    formatUseType(useType) {
      if (useType === 0) {
        return '全场通用'
      } else if (useType === 1) {
        return '指定分类'
      } else {
        return '指定商品'
      }
    },
    formatPlatform(platform) {
      if (platform === 1) {
        return '移动平台'
      } else if (platform === 2) {
        return 'PC平台'
      } else {
        return '全平台'
      }
    },

    formatStatus(endTime) {
      const now = new Date().getTime()
      const endDate = new Date(endTime).getTime()
      if (endDate > now) {
        return '未过期'
      } else {
        return '已过期'
      }
    }
  },
  methods: {
    formatDate(time) {
      if (time == null || time === '') {
        return 'N/A'
      }
      const date = new Date(time)
      return this.$utils.parseDate(date)
    },
    refreshData() {
      this.closeDialog()
      this.getList()
    },
    async getCounponList() {
      await store.dispatch('change_coupontype')
    },
    closeDialog(isEditCoupon) {
      this.couponDialogVisible = false
      if (isEditCoupon) {
        this.getCounponList()
        this.getList()
        this.getShopNameList()
      }
    },
    handleResetSearch() {
      this.listQuery = Object.assign({}, defaultListQuery)
    },
    handleSearchList() {
      this.listQuery.pageNumber = 1
      this.getList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleSizeChange(val) {
      this.listQuery.pageNumber = 1
      this.listQuery.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.pageNumber = val
      this.getList()
    },
    handleAdd() {
      this.couponId = ''
      this.isEditCoupon = false
      this.couponDialogVisible = true
      // this.$router.push({ path: '/sms/addCoupon' })
    },
    handleView(index, row) {
      this.$router.push({ path: '/sms/couponHistory', query: { id: row.id } })
    },
    handleUpdate(index, row) {
      this.isEditCoupon = true
      this.couponId = row.id
      this.couponDialogVisible = true
      // this.$router.push({ path: '/sms/updateCoupon', query: { id: row.id }})
    },
    handleDelete(index, row) {
      this.$confirm('是否进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.coupon.deleteCoupon(row.id).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.listQuery.pageNumber = 1
          this.getList()
        })
      })
    },
    getList() {
      this.listLoading = true
      this.$api.coupon.couponList(this.listQuery).then(response => {
        this.listLoading = false
        this.list = response.listData
        this.total = response.count
      })
    },
    openUserDialog(id) {
      this.currentId = id
      this.userDialogVisible = true
    },
    closeUserDialog() {
      this.userDialogVisible = false
    },
    addUserInfo(val) {
      const data = {
        couponId: this.currentId,
        userId: val.userId,
        memberNickname: val.userName,
        presenterCount: val.presenterCount
      }
      this.$api.coupon.addUserCoupon(data).then(res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '操作成功'
          })
          this.closeUserDialog()
        }
      })
    }
  }
}
</script>
<style scoped>
.input-width {
  width: 203px;
}
</style>

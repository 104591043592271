<template>
  <el-dialog v-dialogDrag append-to-body :close-on-click-modal="false" :visible="true" title="店铺审核" top="10vh" width="75%" @close="close">
    <div class="main-content">
      <el-form ref="formData" :inline="true" :rules="rules" :model="formData" label-width="150px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="单位名称">
              <el-input v-model="formData.shopName" style="width: 250px" size="mini" :disabled="!edit" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="单位名称（英文）">
              <el-input v-model="formData.companyNameEn" style="width: 250px" size="mini" :disabled="!edit" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="法人代表">
              <el-input v-model="formData.companyLegal" style="width: 250px" size="mini" :disabled="!edit" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="详细地址">
              <el-input v-if="!edit || verify" style="width: 250px" size="mini" :value="formData.companyProvince + formData.companyCity + formData.companyRegion + formData.companyAddress" :disabled="!edit" />
              <el-cascader v-if="edit" style="width: 180px" v-model="formData.areaList" :props="areaOptionProps" :options="areaOptions" size="mini" filterable />
              <el-input v-if="edit" style="width: 120px" size="mini" v-model="formData.companyAddress" :disabled="!edit" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="公司网站">
              <el-input v-model="formData.companyWww" style="width: 250px" size="mini" :disabled="!edit" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系人" prop="name">
              <el-input style="width: 250px" size="mini" v-model="formData.contact" :disabled="!edit" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="性别">
              <el-input v-if="!edit || verify" style="width: 250px" size="mini" v-model="formData.sex" :disabled="!edit" />
              <el-radio-group v-else v-model="formData.sex">
                <el-radio label="男士">男士</el-radio>
                <el-radio label="女士">女士</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="部门">
              <el-input style="width: 250px" size="mini" v-model="formData.companyDepartment" :disabled="!edit" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="职务">
              <el-input style="width: 250px" size="mini" v-model="formData.companyPosition" :disabled="!edit" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="通讯地址">
              <el-input style="width: 250px" size="mini" v-model="formData.address" :disabled="!edit" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="邮政编码">
              <el-input style="width: 250px" size="mini" v-model="formData.companyZip" :disabled="!edit" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话">
              <el-input style="width: 250px" size="mini" v-model="formData.companyPhone" :disabled="!edit" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="邮箱">
              <el-input style="width: 250px" size="mini" v-model="formData.email" :disabled="!edit" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="手机">
              <el-input style="width: 250px" size="mini" v-model="formData.mobile" :disabled="!edit" maxlength="11" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="展示范围">
              <div v-if="!edit || verify" class="wrap">
                {{ getCategory(formData.companyCategory) }}
              </div>
              <el-checkbox-group v-else v-model="companyCategoryLabs" :min="1">
                <el-checkbox v-for="(item, index) in categoryList" :key="'cat_' + index" :label="item.name"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="公司介绍（中文）">
              <tinymce v-model="formData.companyProfile" :width="600" :height="300" :disabled="!edit" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公司介绍（英文）">
              <tinymce v-model="formData.companyProfileEn" :width="600" :height="300" :disabled="!edit" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item v-if="formData.businessLicense" label="营业执照">
              <div class="demo-image__preview">
                <el-image style="width: 100px; height: 100px" :src="formData.businessLicense" :preview-src-list="srcList" :z-index="2200" @click="showViewer(formData.businessLicense)" />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="企业主图" class="upload-wrap" prop="fileList">
              <div v-if="!edit || verify" class="demo-image__preview">
                <el-image style="width: 100px; height: 100px" :src="formData.mainUrl" :preview-src-list="srcList" :z-index="2200" @click="showViewer(formData.personalLicense)" />
              </div>
              <uploadFile v-else :limit="1" :file-size="fileSizePicture2" :file-type="fileTypePicture2" :btn-text="btnTextPicture2" :list-type="listTypePicture2" :init-file-list="formData.fileList" :loading-text="loadingTextPicture" :name="'file-name'" @removeFile="removeFile" @uploadSuccess="uploadSuccess" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Logo" prop="logoList" class="upload-wrap">
              <div v-if="!edit || verify" class="demo-image__preview">
                <el-image style="width: 100px; height: 100px" :src="formData.companyLogo" :preview-src-list="srcList" :z-index="2200" @click="showViewer(formData.cardFront)" />
              </div>
              <uploadFile v-else :limit="1" :file-size="fileSizePicture2" :file-type="fileTypePicture2" :btn-text="btnTextPicture2" :list-type="listTypePicture2" :init-file-list="formData.logoList" :loading-text="loadingTextPicture" :name="'file-name'" @removeFile="removeLogoFile" @uploadSuccess="uploadLogoSuccess" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="宣传资料" prop="shopList">
              <div v-if="formData.shopPics && (!edit || verify)" class="data-list">
                <div v-for="(item, index) in formData.shopPics.split(',')" :key="'zl' + index" class="list-item">
                  <video v-if="item.indexOf('.mp4') > 0" controls="controls" :src="item"></video>
                  <el-image v-else style="width: 100px; height: 100px" :src="item" :preview-src-list="srcList" :z-index="2200" @click="showViewer(formData.cardFront)" />
                </div>
              </div>
              <upload-file v-else :limit="5" :file-size="fileSizePicture" :file-type="fileTypePicture" :btn-text="btnTextPicture" :list-type="listTypePicture" :init-file-list="formData.shopList" :loading-text="loadingTextPicture" :name="'file-name'" @removeFile="removeShopFile" @uploadSuccess="uploadShopSuccess" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="企业荣誉" prop="honorList">
              <div v-if="!edit || verify" class="pic-list">
                <template v-if="formData.honorList && formData.honorList.length > 0">
                  <div v-for="(item, index) in formData.honorList" :key="index" class="list-item">
                    <img :src="item.url" />
                  </div>
                </template>
                <template v-else> 无企业荣誉数据 </template>
              </div>
              <upload-file v-else :limit="6" :file-size="fileSizePicture2" :file-type="fileTypePicture2" :btn-text="btnTextPicture2" :list-type="listTypePicture2" :init-file-list="formData.honorList" :loading-text="loadingTextPicture" :name="'file-name'" @removeFile="removePicFile" @uploadSuccess="uploadPicSuccess" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="企业展示" prop="companyShow">
              <tinymce v-model="formData.companyShow" :width="800" :height="300" :disabled="!edit" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注">
              <el-input v-model="formData.accessOpinion" type="textarea" style="width: 500px" maxlength="200" show-word-limit size="mini" :rows="4" :disabled="!edit" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <div class="line" />
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="店铺状态">
              <el-select v-model="formData.status" placeholder="请选择" size="mini" :disabled="true" style="width: 250px">
                <el-option v-for="item in audits" :key="item.val" :label="item.label" :value="item.val" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请时间">
              <el-input v-model="formData.agreementTime" style="width: 250px" size="mini" :disabled="true" />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
              <el-form-item label="提现日" prop="withdrawalDay">
                <el-input v-model="formData.withdrawalDay" size="mini" style="width: 190px" clearable @focus="dayTimeDialogVisible = true">
                  <template slot="append">日</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="提现手续费" prop="withdrawalFee">
                <el-input v-model="formData.withdrawalFee" size="mini" style="width: 190px" clearable @blur="withdrawalFeeBlur()"><template slot="append">%</template></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="日提款额度" prop="withdrawalLimit">
                <el-input v-model="formData.withdrawalLimit" size="mini" style="width: 190px" clearable @blur="withdrawalLimitBlur()"><template slot="append">元</template></el-input>
              </el-form-item>
            </el-col> -->
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="审核人员">
              <el-input v-model="userName" style="width: 250px" size="mini" :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="审核时间">
              <el-input v-model="formData.updatedTime" style="width: 250px" size="mini" :disabled="true" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="商户审批原因" prop="auditOpinion">
              <el-input v-model="formData.auditOpinion" type="textarea" style="width: 500px" maxlength="200" show-word-limit size="mini" clearable :rows="4" :disabled="!verify" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
        <el-button v-if="verify" size="mini" type="primary" @click="auditClick(true)">审核通过</el-button>
        <el-button v-if="verify" size="mini" type="danger" @click="auditClick(false)">审核拒绝</el-button>
        <el-button v-if="edit" size="mini" type="primary" @click="save()">保存</el-button>
      </div>
  </el-dialog>
</template>

<script>
import Tinymce from '@/components/Tinymce'

export default {
  components: { Tinymce },
  props: {
    id: {
      type: Number,
      default: null
    },
    edit: {
      type: Boolean,
      default: false
    },
    verify: {
      type: Boolean,
      default: false
    },
    categoryList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      widthInput: '700px',
      loading: false,
      srcList: [],
      formData: {
        auditOpinion: ''
      },
      companyCategoryLabs: [],
      fileSizePicture: [100, 'M'],
      fileSizePicture2: [500, 'kb'],
      btnTextPicture: ['选取文件', '上传'],
      btnTextPicture2: ['选取图片', '上传'],
      fileTypePicture: ['mp4', 'jpg', 'png', 'jpeg'],
      fileTypePicture2: ['jpg', 'png', 'jpeg'],
      listTypePicture: 'text',
      listTypePicture2: 'picture-card',
      loadingTextPicture: '上传文件中...',
      areaList: [],
      areaOptionProps: {
        value: 'label',
        label: 'label',
        children: 'children'
      },
      audits: [
        { val: 2, label: '待审核' },
        { val: 0, label: '审核通过' },
        { val: 1, label: '审核拒绝' },
        { val: 99, label: '店铺已禁用' }
      ],
      areaOptions: [], // 省市区
      rules: {
        auditOpinion: [{ required: true, message: '请输入商户审批原因', trigger: 'blur' }],
        bond: [{ required: true, message: '请输入保证金', trigger: 'blur' }],
        companyProfile: [{ required: true, message: '请填写公司简介', trigger: 'blur' }],
        logoList: [{ required: true, message: '请选择公司LOGO', trigger: 'blur' }],
        fileList: [{ required: true, message: '请选择企业主图', trigger: 'blur' }]
        // shopList: [{ required: true, message: '请选择企业宣传资料', trigger: 'blur' }]
        // companyShow: [{ required: true, message: '请输入企业展示', trigger: 'blur' }]
      }
    }
  },
  computed: {
    userName() {
      return this.$store.state.user.name
    }
  },
  created() {
    this.initData()
    this.getProvinceAreaList()
  },
  methods: {
    async initData() {
      await this.$api.storeAudit.getShopDetail(this.id).then(res => {
        if (res.code === 200) {
          this.formData = res.data
          this.formData.areaList = [res.data.companyProvince, res.data.companyCity, res.data.companyRegion]
          this.companyCategoryLabs = []
          if (res.data.companyCategory) {
            const ctys = JSON.parse(res.data.companyCategory)
            ctys.map(item => {
              this.companyCategoryLabs.push(item.categoryName)
            })
            console.log(1111, this.companyCategoryLabs)
          }

          const fileType = res.data.mainUrl.substring(res.data.mainUrl.lastIndexOf('.') + 1).toLowerCase()
          const LogofileType = res.data.mainUrl.substring(res.data.companyLogo.lastIndexOf('.') + 1).toLowerCase()
          this.formData.fileList = []
          this.formData.logoList = []
          this.formData.honorList = []
          this.formData.shopList = []

          this.formData.fileList.push({
            name: 'file.' + fileType,
            url: res.data.mainUrl,
            type: fileType
          })
          this.formData.logoList.push({
            name: 'file.' + LogofileType,
            url: res.data.companyLogo,
            type: LogofileType
          })

          if (res.data.companyHonor) {
            this.formData.honorList = res.data.companyHonor.split(',')
            this.formData.honorList = this.formData.honorList.map((item, index) => {
              const temp = {
                uid: index,
                name: '荣誉证书' + (index + 1),
                url: item
              }
              return temp
            })
          }
          if (res.data.shopPics) {
            this.formData.shopList = res.data.shopPics.split(',')
            this.formData.shopList = this.formData.shopList.map((item, index) => {
              const type = item.indexOf('mp4') > 0 ? 'mp4' : 'png'

              const temp = {
                uid: index,
                name: '宣传资料' + (index + 1) + '.' + type,
                url: item,
                type: type
              }
              return temp
            })
          }
        }
      })
    },

    removeFile(index) {
      this.formData.fileList.splice(index, 1)
    },
    removePicFile(index) {
      this.formData.honorList.splice(index, 1)
    },
    removeLogoFile(index) {
      this.formData.logoList.splice(index, 1)
    },
    removeShopFile(index) {
      this.formData.shopList.splice(index, 1)
    },
    uploadSuccess(list) {
      this.formData.fileList = list
    },
    uploadPicSuccess(list) {
      this.formData.honorList = list
    },
    uploadLogoSuccess(list) {
      this.formData.logoList = list
    },
    uploadShopSuccess(list) {
      this.formData.shopList = list
      console.log(555, this.formData.shopList)
    },
    getCategory(arr) {
      if (!arr) {
        return ''
      }
      arr = JSON.parse(arr).map(item => item.categoryName)
      return arr.join(' | ')
    },
    showViewer(val) {
      this.srcList = []
      this.srcList.push(val)
    },
    updateWithDrawal() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          let status = 0
          if (this.selectEditStatus === 99) {
            status = this.edit.status
          }
          if (status === 1) {
            if (this.edit.auditOpinion === null || this.edit.auditOpinion === '') {
              this.$message.warning('审批原因不能为空')
              return
            }
          }
          this.statusUpdateEdit(status)
        }
      })
    },
    getProvinceAreaList() {
      if (localStorage.getItem('address_area')) {
        this.areaOptions = JSON.parse(localStorage.getItem('address_area'))
      } else {
        this.$api.storeAudit.getAreaInfoList().then(res => {
          if (res.data && res.data.length) {
            res.data.forEach(el => {
              el.value = el.provinceId
              el.label = el.provinceName
              el.children = el.sysChnCityList
              if (el.sysChnCityList && el.sysChnCityList.length) {
                el.sysChnCityList.forEach(item => {
                  item.value = item.cityId
                  item.label = item.cityName
                  item.children = item.sysChnAreaList
                  if (item.sysChnAreaList && item.sysChnAreaList.length) {
                    item.sysChnAreaList.forEach(i => {
                      i.value = i.areaId
                      i.label = i.areaName
                    })
                  }
                })
              }
            })
            this.areaOptions = res.data
            localStorage.setItem('address_area', JSON.stringify(res.data))
          }
        })
      }
    },
    close() {
      this.$emit('close')
    },
    withdrawalLimitBlur() {
      if (this.formData.withdrawalLimit !== null && this.formData.withdrawalLimit !== '') {
        if (!String(this.formData.withdrawalLimit).match('^\\d+$') && !String(this.formData.withdrawalLimit).match('^\\d+.\\d+$')) {
          this.formData.withdrawalLimit = 0
        }
        if (String(this.formData.withdrawalLimit).indexOf('.') !== -1) {
          const strings = String(this.formData.withdrawalLimit).split('.')
          if (strings[1] && String(strings[1]).length !== 2) {
            if (strings[1] && String(strings[1]).length === 1) {
              this.formData.withdrawalLimit = strings[0] + '.' + strings[1] + 0
            } else {
              const s = String(strings[1])
              this.formData.withdrawalLimit = strings[0] + '.' + s.substring(0, 2)
            }
          }
        }
      }
    },
    auditClick(type) {
      if (type) {
        this.rules.auditOpinion[0].required = false
      }
      this.$refs.formData.validate(valid => {
        if (valid) {
          let status
          // 审核通过
          if (type) {
            status = 0
            if (this.formData.status === 99) {
              status = this.formData.status
            }
          } else {
            // 审核拒绝
            status = 1
          }
          const params = {
            bond: this.formData.bond,
            id: this.formData.id,
            withdrawalDay: this.formData.withdrawalDay,
            withdrawalFee: this.formData.withdrawalFee,
            withdrawalLimit: this.formData.withdrawalLimit,
            auditOpinion: this.formData.auditOpinion,
            status: status,
            type: 0
          }
          this.$api.storeAudit.updateWithDrawal(params).then(res => {
            if (res.code === 200) {
              this.$message.success('保存成功')
              this.$emit('close')
            }
          })
        }
      })
    },
    handleUpdateWithDrawal() {
      const params = {
        bond: this.formData.bond,
        id: this.formData.id,
        withdrawalDay: this.formData.withdrawalDay,
        withdrawalFee: this.formData.withdrawalFee,
        withdrawalLimit: this.formData.withdrawalLimit,
        auditOpinion: this.formData.auditOpinion,
        status: status
      }
      this.$api.storeAudit.updateWithDrawal(params).then(res => {
        if (res.code === 200) {
          this.$message.success('保存成功')
          this.$emit('close')
        }
      })
    },
    // 审核通过情况下进行修改
    updateContent() {
      if (this.formData.status !== 0) {
        this.$message.error('只有审核通过的状态下才能修改')
        return
      }
      this.$refs.formData.validate(valid => {
        if (valid) {
          const param = {
            bond: this.formData.bond,
            shopId: this.formData.id,
            id: this.formData.id,
            withdrawalDay: this.formData.withdrawalDay,
            withdrawalFee: this.formData.withdrawalFee,
            withdrawalLimit: this.formData.withdrawalLimit,
            auditOpinion: this.formData.auditOpinion,
            categorys: this.formData.categorys
          }
          this.$api.storeAudit.updateContent(param).then(res => {
            if (res.code === 200) {
              this.$message.success('保存成功')
              this.$emit('close')
            }
          })
        }
      })
    },
    save() {
      this.rules.auditOpinion[0].required = false
      this.$refs.formData.validate(valid => {
        if (valid) {
          this.formData.companyProvince = this.formData.areaList[0]
          this.formData.companyCity = this.formData.areaList[1]
          this.formData.companyRegion = this.formData.areaList[2]
          this.formData.mainUrl = this.formData.fileList[0].url

          let honorStr = []
          this.formData.honorList = this.formData.honorList.map(item => {
            honorStr.push(item.url)
          })

          this.formData.companyLogo = this.formData.logoList[0].url

          let shopStr = []
          this.formData.shopList = this.formData.shopList.map(item => {
            shopStr.push(item.url)
          })
          this.formData.shopPics = shopStr ? shopStr.join(',') : ''

          this.formData.companyHonor = honorStr ? honorStr.join(',') : ''
          let ctys = []
          this.companyCategoryLabs.map(item => {
            this.categoryList.map(tm => {
              if (item === tm.name) {
                ctys.push({ categoryId: tm.id, categoryName: tm.name })
              }
            })
          })

          this.formData.companyCategory = JSON.stringify(ctys)
          delete this.formData.fileList
          delete this.formData.shopList
          delete this.formData.honorList
          delete this.formData.logoList
          delete this.formData.updatedTime

          this.$api.storeAudit.updateShop(this.formData).then(res => {
            if (res.code === 200) {
              this.$message.success('保存成功')
              this.$emit('close')
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.line {
  height: 1px;
  width: 100%;
  background: #dddada;
  margin-bottom: 40px;
  margin-top: 10px;
}
::v-deepepepepep.dialog-footer {
  text-align: right;
}
.wrap {
  max-width: 280px;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}
video {
  width: 200px;
  height: 200px;
  object-fit: fill;
}

.data-list {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  .list-item {
    margin-right: 20px;
  }
}
::v-deep.upload-wrap {
  .el-form-item__content {
    width: 400px;
    text-align: center;
  }
}
.pic-list {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 80px;
  .list-item {
    position: relative;
    margin-right: 50px;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
      width: 150px;
      height: 200px;
    }
    video {
      width: 220px;
      height: 200px;
      object-fit: fill;
    }
  }
}
</style>

import common from '@/api/common/api'
import home from '@/api/home/api'
import dynamicAccount from '@/api/dynamicAccount/api'
import product from '@/api/basicData/product'
import brand from '@/api/basicData/brand'
import suit from '@/api/basicData/suit'
import comment from '@/api/basicData/comment'
import productCate from '@/api/basicData/productCate'
import goodsAudit from '@/api/basicData/goodsAudit'
import excellent from '@/api/sms/excellent'
import subject from '@/api/sms/subject'
import storeAudit from '@/api/merchants/storeAudit'
import callMe from '@/api/callMe/callMe'
import newsClass from '@/api/information/newsClass'
import news from '@/api/information/news'
import notice from '@/api/information/notice'
import exportdown from '@/api/exportdown/api'
import order from '@/api/order/api'
import invoice from '@/api/order/invoice'
import pay from '@/api/pay/index'
import sync from '@/api/sync/api'
import coupon from '@/api/coupon/index'
import shopweb from '@/api//shopweb'

export default {
  common,
  home,
  dynamicAccount,
  product,
  productCate,
  excellent,
  goodsAudit,
  subject,
  storeAudit,
  brand,
  suit,
  comment,
  callMe,
  newsClass,
  news,
  notice,
  exportdown,
  order,
  invoice,
  pay,
  sync,
  coupon,
  shopweb
}

<template>
  <el-dialog title="优惠券详情" append-to-body :visible="true" width="50%" @close="closeDialog">
    <el-form ref="couponFrom" :model="coupon" :rules="rules" label-width="150px" size="small">
      <el-form-item label="适用范围：">
        <el-select v-model="coupon.couponType">
          <el-option v-for="(item, index) in couponTypeColumns" :key="index" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="优惠券类型：">
        <el-select v-model="coupon.type">
          <el-option v-for="(type, index) in couponTypeOptions" :key="index" :label="type.label" :value="type.value"></el-option>
        </el-select>
      </el-form-item>
      <div class="remarkSty">
        <el-form-item label="会员类型：">
          <el-select v-model="coupon.memberLevel" placeholder="选择用户类型则为批量用户赠券，不选择为创建优惠券" style="width: 360px">
            <el-option v-for="(type, index) in shopProductTypeOptions" :key="index" :label="type.label" :value="type.value"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item label="优惠券名称：" prop="name">
        <el-input v-model="coupon.name" class="input-width" />
      </el-form-item>
      <el-form-item label="适用平台：">
        <el-select v-model="coupon.platform">
          <el-option v-for="item in platformOptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="总发行量：" prop="publishCount">
        <el-input v-model.number="coupon.publishCount" placeholder="只能输入正整数" class="input-width" />
      </el-form-item>
      <el-form-item label="面额：" prop="amount">
        <el-input v-model="coupon.amount" placeholder="面值只能是数值，限2位小数" class="input-width">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="每人限领：" prop="perLimit">
        <el-input v-model.number="coupon.perLimit" placeholder="只能输入正整数" class="input-width">
          <template slot="append">张</template>
        </el-input>
      </el-form-item>
      <el-form-item label="使用门槛：" prop="minPoint">
        <el-input v-model.number="coupon.minPoint" placeholder="只能输入正整数" class="input-width">
          <template slot="prepend">满</template>
          <template slot="append">元可用</template>
        </el-input>
      </el-form-item>
      <el-form-item v-if="coupon.type === 4" label="积分价格：" prop="integral">
        <el-input v-model.number="coupon.integral" placeholder="请输入积分价格" class="input-width" />
      </el-form-item>
      <el-form-item label="有效期：" prop="timeValue">
        <el-date-picker v-model="coupon.timeValue" type="datetimerange" range-separator="至" :picker-options="expireTimeOption" start-placeholder="开始日期" end-placeholder="结束日期" class="input-width" />
      </el-form-item>
      <el-form-item label="可使用商品：">
        <el-radio-group v-model="coupon.useType">
          <el-radio-button :label="0">全场通用</el-radio-button>
          <el-radio-button :label="1">指定分类</el-radio-button>
          <el-radio-button v-if="shopInfo.id" :label="2">指定商品</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-show="coupon.useType === 1">
        <el-cascader v-model="selectProductCate" clearable placeholder="请选择分类名称" :options="productCateOptions" />
        <el-button @click="handleAddProductCategoryRelation()">添加</el-button>
        <el-table ref="productCateRelationTable" :data="coupon.productCategoryRelationList" style="width: 100%; margin-top: 20px" border>
          <el-table-column label="分类名称" align="center">
            <template slot-scope="scope"> {{ scope.row.parentCategoryName }}>{{ scope.row.productCategoryName }} </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
              <el-button size="mini" type="text" @click="handleDeleteProductCateRelation(scope.$index, scope.row)"> 删除 </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item v-show="coupon.useType === 2">
        <el-select v-model="selectProduct" filterable remote reserve-keyword :popper-append-to-body="false" placeholder="请输入商品名称/商品货号" :remote-method="searchProductMethod" :loading="selectProductLoading">
          <el-option v-for="item in selectProductOptions" :key="item.productId" :label="item.productName" :value="item.productId">
            <span style="float: left">{{ item.productName }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px"> NO.{{ item.productSn }} </span>
          </el-option>
        </el-select>
        <el-button @click="handleAddProductRelation()">添加</el-button>
        <el-table ref="productRelationTable" :data="coupon.productRelationList" style="width: 100%; margin-top: 20px" border>
          <el-table-column label="商品名称" align="center">
            <template slot-scope="scope">{{ scope.row.productName }}</template>
          </el-table-column>
          <el-table-column label="货号" align="center" width="120">
            <template slot-scope="scope">NO.{{ scope.row.productSn }}</template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
              <el-button size="mini" type="text" @click="handleDeleteProductRelation(scope.$index, scope.row)"> 删除 </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="备注：">
        <el-input v-model="coupon.note" class="input-width" type="textarea" :rows="5" placeholder="请输入内容" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit('couponFrom')"> 提交 </el-button>
        <el-button v-if="!isEdit" @click="resetForm('couponFrom')"> 重置 </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
import store from '@/store'
import { getStorage } from '@/utils/storage'

const defaultCoupon = {
  memberLevel: '',
  type: '',
  couponType: 1,
  name: null,
  platform: 0,
  amount: null,
  perLimit: 1,
  minPoint: null,
  integral: null,
  timeValue: '',
  startTime: null,
  endTime: null,
  useType: 0,
  note: null,
  publishCount: null,
  productRelationList: [],
  productCategoryRelationList: []
}
const defaultPlatformOptions = [
  {
    label: '全平台',
    value: 0
  }
]
// {
//     label: '移动平台',
//     value: 1
//   },
//   {
//     label: 'PC平台',
//     value: 2
//   }

export default {
  name: 'CouponDetail',
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [Number, String]
    }
  },
  data() {
    var validatorNum = (rule, value, callback) => {
      if (value <= 0) {
        callback(new Error('数值不能为0'))
      } else {
        callback()
      }
    }
    return {
      coupon: Object.assign({}, defaultCoupon),
      couponTypeColumns: [{ label: '普通优惠券', value: 1 }],
      platformOptions: Object.assign({}, defaultPlatformOptions),
      rules: {
        name: [
          { required: true, message: '请输入优惠券名称', trigger: 'blur' },
          {
            min: 2,
            max: 10,
            message: '长度在 2 到 10 个字符',
            trigger: 'blur'
          }
        ],
        publishCount: [
          {
            type: 'number',
            required: true,
            message: '只能输入正整数',
            trigger: 'blur'
          },
          { validator: validatorNum, trigger: 'blur' }
        ],
        amount: [
          { required: true, message: '请输入面额', trigger: 'blur' },
          {
            pattern: /^([0-9]*)+(.[0-9]{1,2})?$/,
            message: '面值只能是数值，0.01-10000，限2位小数',
            trigger: 'blur'
          },
          { validator: validatorNum, trigger: 'blur' }
        ],
        minPoint: [
          {
            type: 'number',
            required: true,
            message: '只能输入正整数',
            trigger: 'blur'
          },
          { validator: validatorNum, trigger: 'blur' }
        ],
        timeValue: [{ required: true, message: '请选择有效期', trigger: 'blur' }],
        perLimit: [
          {
            type: 'number',
            required: true,
            message: '只能输入正整数',
            trigger: 'blur'
          },
          { validator: validatorNum, trigger: 'blur' }
        ],
        integral: [
          {
            type: 'number',
            required: true,
            message: '请输入积分价格',
            trigger: 'blur'
          },
          { validator: validatorNum, trigger: 'blur' }
        ]
      },
      selectProduct: null,
      selectProductLoading: false,
      selectProductOptions: [],
      selectProductCate: null,
      productCateOptions: [],
      expireTimeOption: {
        disabledDate(date) {
          return date.getTime() <= Date.now() - 8.64e7
        }
      },
      shopInfo: {}
    }
  },
  computed: {
    ...mapState({
      couponTypeOptions: state => state.dictionary.couponType,
      shopProductTypeOptions() {
        if (this.$store.state.dictionary.memberLevelList.length === 0) {
          this.$store.dispatch('change_memberLevelList')
        }
        return this.$store.state.dictionary.memberLevelList
      }
    })
  },
  created() {
    this.getCounponList()
    if (this.isEdit) {
      this.$api.coupon.getCoupon(this.id).then(response => {
        this.coupon = response.data
        this.coupon.type = String(this.coupon.type)
        this.coupon.memberLevel = this.coupon.memberLevel ? String(this.coupon.memberLevel) : ''
        this.$set(this.coupon, 'timeValue', [this.coupon.startTime, this.coupon.endTime])
        this.getProductCateList(response.data.productCategoryRelationList)
      })
      return
    }
    this.shopInfo = getStorage('shopInfo') ? JSON.parse(getStorage('shopInfo')) : {}
    console.log(555, this.shopInfo)
    if (!this.shopInfo.id) {
      this.couponTypeColumns.push({ label: '抽奖优惠券', value: 2 })
    }
    this.getProductCateList()
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
    async getCounponList() {
      await store.dispatch('change_coupontype')
    },
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if (this.coupon.timeValue) {
              this.coupon.startTime = this.coupon.timeValue[0]
              this.coupon.endTime = this.coupon.timeValue[1]
            }
            if (this.isEdit) {
              this.$api.coupon.updateCoupon(this.id, this.coupon).then(() => {
                this.resetForm(formName)
                // this.$refs[formName].resetFields();
                this.$message({
                  message: '修改成功',
                  type: 'success',
                  duration: 1000
                })
                this.$emit('closeDialog', true)
              })
            } else {
              console.log(this.coupon)
              this.$api.coupon.createCoupon(this.coupon).then(() => {
                defaultCoupon.productCategoryRelationList = []
                defaultCoupon.productRelationList = []
                this.resetForm(formName)
                // this.$refs[formName].resetFields();
                this.$message({
                  message: '提交成功',
                  type: 'success',
                  duration: 1000
                })
                this.$emit('closeDialog', true)
                // this.coupon.productCategoryRelationList = []
                // this.coupon.productRelationList = []
                // this.$router.back();
              })
            }
          })
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.coupon = Object.assign({}, defaultCoupon)
    },
    searchProductMethod(query) {
      if (query !== '') {
        this.loading = true
        this.$api.product.fetchSimpleList({ keyword: query }).then(response => {
          this.loading = false
          const productList = response.data
          this.selectProductOptions = []
          for (let i = 0; i < productList.length; i++) {
            const item = productList[i]
            this.selectProductOptions.push({
              productId: item.id,
              productName: item.name,
              productSn: item.productSn
            })
          }
        })
      } else {
        this.selectProductOptions = []
      }
    },
    handleAddProductRelation() {
      if (this.selectProduct === null) {
        this.$message({
          message: '请先选择商品',
          type: 'warning'
        })
        return
      }
      const product = this.getProductById(this.selectProduct)
      for (let i = 0; i < this.coupon.productRelationList.length; i++) {
        if (product.productId === this.coupon.productRelationList[i].productId) {
          this.$message({
            message: '已关联了该商品',
            type: 'warning'
          })
          this.selectProduct = null
          return
        }
      }
      this.coupon.productRelationList.push(product)
      this.selectProduct = null
    },
    handleDeleteProductRelation(index) {
      this.coupon.productRelationList.splice(index, 1)
    },
    handleAddProductCategoryRelation() {
      if (this.selectProductCate === null || this.selectProductCate.length === 0) {
        this.$message({
          message: '请先选择商品分类',
          type: 'warning'
        })
        return
      }
      this.coupon.productCategoryRelationList.push(this.getProductCateByIds(this.selectProductCate))
      this.selectProductCate = []
    },
    handleDeleteProductCateRelation(index, row) {
      for (let i = 0; i < this.productCateOptions.length; i++) {
        if (this.productCateOptions[i].label === row.parentCategoryName) {
          for (let j = 0; j < this.productCateOptions[i].children.length; j++) {
            if (this.productCateOptions[i].children[j].label === row.productCategoryName) {
              this.productCateOptions[i].children[j].disabled = false
            }
          }
        }
      }
      // debugger
      this.coupon.productCategoryRelationList.splice(index, 1)
    },
    getProductById(id) {
      for (let i = 0; i < this.selectProductOptions.length; i++) {
        if (id === this.selectProductOptions[i].productId) {
          return this.selectProductOptions[i]
        }
      }
      return null
    },
    getProductCateList(productCategoryRelationList) {
      this.$api.product.fetchListWithChildren().then(response => {
        const list = response.data
        this.productCateOptions = []
        for (let i = 0; i < list.length; i++) {
          const children = []
          if (list[i].children != null && list[i].children.length > 0) {
            for (let j = 0; j < list[i].children.length; j++) {
              children.push({
                label: list[i].children[j].name,
                value: list[i].children[j].id
              })
            }
          }
          this.productCateOptions.push({
            label: list[i].name,
            value: list[i].id,
            children: children,
            disabled: false
          })
        }
        // 数据回显置灰处理
        if (productCategoryRelationList && productCategoryRelationList.length > 0) {
          productCategoryRelationList.forEach(item => {
            for (let i = 0; i < this.productCateOptions.length; i++) {
              if (this.productCateOptions[i].label === item.parentCategoryName) {
                for (let j = 0; j < this.productCateOptions[i].children.length; j++) {
                  if (this.productCateOptions[i].children[j].label === item.productCategoryName) {
                    this.productCateOptions[i].children[j].disabled = true
                  }
                }
              }
            }
          })
        }
      })
    },
    getProductCateByIds(ids) {
      let name
      let parentName
      for (let i = 0; i < this.productCateOptions.length; i++) {
        if (this.productCateOptions[i].value === ids[0]) {
          parentName = this.productCateOptions[i].label
          for (let j = 0; j < this.productCateOptions[i].children.length; j++) {
            if (this.productCateOptions[i].children[j].value === ids[1]) {
              name = this.productCateOptions[i].children[j].label
              this.productCateOptions[i].children[j].disabled = true
            }
          }
        }
      }
      return {
        productCategoryId: ids[1],
        productCategoryName: name,
        parentCategoryName: parentName
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.input-width {
  width: 80%;
}
::v-deep .el-date-editor .el-range-separator {
  width: 8%;
}
</style>

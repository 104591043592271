<template>
  <el-dialog v-dialogDrag :close-on-click-modal="false" :visible="true" :title="(isEdit ? '编辑' : '新增') + '商品'" top="10vh" width="80%" @close="close">
    <el-card class="container" shadow="never">
      <el-steps :active="active" finish-status="success" align-center>
        <el-step title="填写商品信息" />
        <el-step title="填写商品促销" />
        <el-step title="填写商品属性" />
        <el-step title="选择商品关联" />
      </el-steps>
      <product-info-detail v-show="showStatus[0]" v-model="productParam" :is-edit="isEdit" :copy="copy" :is-publish="isPublish" :is-verify="isVerify" :is-audit-status="isAuditStatus" @nextStep="nextStep" />
      <product-sale-detail v-show="showStatus[1]" v-model="productParam" :is-edit="isEdit" :copy="copy" :is-publish="isPublish" :is-verify="isVerify" @nextStep="nextStep" @prevStep="prevStep" />
      <product-attr-detail v-show="showStatus[2]" v-model="productParam" :is-edit="isEdit" :copy="copy" :is-publish="isPublish" :is-verify="isVerify" :sku-checkedlists="skuCheckedlists" :vip-level-list="vipLevelList" :user-status="userStatus" @prevStep="prevStep" @nextStep="nextStep" @finishCommit="finishCommit" />
      <product-relation-detail v-show="showStatus[3]" v-model="productParam" :is-edit="isEdit" :copy="copy" :is-publish="isPublish" :is-verify="isVerify" :is-audit-status="isAuditStatus" @prevStep="prevStep" @finishCommit="finishCommit" />
    </el-card>
  </el-dialog>
</template>
<script>
import ProductInfoDetail from './ProductInfoDetail'
import ProductSaleDetail from './ProductSaleDetail'
import ProductAttrDetail from './ProductAttrDetail'
import ProductRelationDetail from './ProductRelationDetail'

var defaultProductParam = {
  startQuantity: '1', // 起订量
  albumPics: '',
  brandId: null,
  brandName: '',
  deleteStatus: 0,
  description: '',
  detailDesc: '',
  detailHtml: '',
  detailMobileHtml: '',
  detailTitle: '',
  feightTemplateId: 0,
  flashPromotionCount: 0,
  flashPromotionId: 0,
  flashPromotionPrice: 0,
  flashPromotionSort: 0,
  giftPoint: 0,
  giftGrowth: 0,
  keywords: '',
  lowStock: 0,
  name: '',
  newStatus: 0,
  note: '',
  originalPrice: '',
  expositionStatus: 1,
  virtualSale: '', // 虚拟销量
  pic: '',
  // 会员价格{memberLevelId: 0,memberPrice: 0,memberLevelName: null}
  memberPriceList: [],
  // 商品满减
  productFullReductionList: [{ fullPrice: 0, reducePrice: 0 }],
  // 商品阶梯价格
  productLadderList: [{ count: 0, discount: 0, price: 0 }],
  previewStatus: 0,
  price: '',
  productAttributeCategoryId: null,
  // 商品属性相关{productAttributeId: 0, value: ''}
  productAttributeValueList: [],
  // 商品sku库存信息{lowStock: 0, pic: '', price: 0, sale: 0, skuCode: '', sp1: '', sp2: '', sp3: '', stock: 0}
  skuStockList: [],
  // 商品相关专题{subjectId: 0}
  subjectProductRelationList: [],
  // 商品相关优选{prefrenceAreaId: 0}
  prefrenceAreaProductRelationList: [],
  productCategoryId: null,
  productCategoryName: '',
  productSn: '',
  promotionEndTime: '',
  promotionPerLimit: 0,
  promotionPrice: null,
  promotionStartTime: '',
  promotionType: 0,
  publishStatus: 0,
  recommandStatus: 0,
  sale: 0,
  serviceIds: '',
  sort: 0,
  stock: 0,
  subTitle: '',
  unit: '',
  usePointLimit: 0,
  verifyStatus: 0,
  weight: 0,
  specDetailWeb: '',
  specDetailApp: '',
  isExemption: 0,
  freightId: null,
  freightName: '',
  initialNum: '',
  type: '',
  beBuy: 1 //是否可购买  0：否  1：是
}
export default {
  name: 'ProductDetail',
  components: {
    ProductInfoDetail,
    ProductSaleDetail,
    ProductAttrDetail,
    ProductRelationDetail
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: null
    },
    copy: {
      type: Boolean,
      default: false
    },
    audit: {
      type: Boolean,
      default: false
    },
    isAuditStatus: {
      type: Boolean,
      default: false
    },
    userStatus: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      active: 0,
      productParam: Object.assign({}, defaultProductParam),
      showStatus: [true, false, false, false],
      // copy: false,
      // 是否是上架状态
      isPublish: false,
      // 是否有上过架
      isVerify: false,
      // 编辑模式下已匹配的sku（原始sku）
      skuCheckedlists: [],
      vipLevelList: []
    }
  },
  created() {
    this.getVipList()
    if (this.isEdit) {
      this.$api.product.getProduct(this.id).then(response => {
        this.productParam = response.data
        console.log(this.productParam, 'i骄傲第三家角色及')
        this.skuCheckedlists = this.copy ? [] : response.data.skuStockList
        this.isPublish = !!(response.data.publishStatus === 1 || this.audit)
        this.isVerify = !!(response.data.verifyStatus === 1 || this.audit)
      })
    }
    if (!this.id) {
      this.productParam = Object.assign({ tempId: 1 }, defaultProductParam)
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    getVipList() {
      const params = {
        dictKey: 'SYSTEM_VIP_LEVEL'
      }
      this.$api.common.getDict(params).then(res => {
        this.vipLevelList = res.data
        res.data.forEach(el => {
          el.uniqueKey = 'price' + el.dictValue
        })
      })
    },
    hideAll() {
      for (let i = 0; i < this.showStatus.length; i++) {
        this.showStatus[i] = false
      }
    },
    prevStep() {
      if (this.active > 0 && this.active < this.showStatus.length) {
        this.active--
        this.hideAll()
        this.showStatus[this.active] = true
      }
    },
    nextStep() {
      if (this.active < this.showStatus.length - 1) {
        this.active++
        this.hideAll()
        this.showStatus[this.active] = true
      }
    },
    finishCommit(isEdit) {
      const skuStockList = this.productParam.skuStockList
      if (this.productParam.beBuy) {
        if (!skuStockList.length) {
          this.$message({
            type: 'warning',
            message: '请添加商品sku'
          })
          return
        }
        for (let i = 0; i < skuStockList.length; i++) {
          if (!skuStockList[i].price) {
            this.$message({
              type: 'warning',
              message: '请填写完商品sku价格'
            })
            return
          }
          if (!skuStockList[i].price1) {
            this.$message({
              type: 'warning',
              message: '请填写完文印店价格'
            })
            return
          }
          if (!skuStockList[i].price2) {
            this.$message({
              type: 'warning',
              message: '请填写完协会会员价格'
            })
            return
          }
          if (!skuStockList[i].price3) {
            this.$message({
              type: 'warning',
              message: '请填写完加盟店价格'
            })
            return
          }
          if (String(skuStockList[i].stock) === '') {
            this.$message({
              type: 'warning',
              message: '请填写完商品sku库存'
            })
            return
          }
          if (!skuStockList[i].number) {
            this.$message({
              type: 'warning',
              message: '请填写商品规格中的扣库数量'
            })
            return
          }
          if (skuStockList[i].number < 1) {
            this.$message({
              type: 'warning',
              message: '商品规格中的扣库数量不能小于1!'
            })
            return
          }
          if (!/^\d+$/.test(skuStockList[i].number)) {
            this.$message({
              type: 'warning',
              message: '商品规格中的扣库数量只能为正整数!'
            })
            return
          }
          if (skuStockList[i].stock < 0) {
            this.$message({
              type: 'warning',
              message: '库存不能小于0'
            })
            return
          }
          if (skuStockList[i].lowStock < 1) {
            this.$message({
              type: 'warning',
              message: '库存预警值不能小于1'
            })
            return
          }
          if (!skuStockList[i].lowStock) {
            this.$message({
              type: 'warning',
              message: '请填写完商品sku库存预警值'
            })
            return
          }
          if (!skuStockList[i].sp3) {
            this.$message({
              type: 'warning',
              message: '请填写完商品sku重量'
            })
            return
          }
        }
      }
      if (!this.productParam.pic) {
        this.$message({
          type: 'warning',
          message: '商品相册至少上传一张图片'
        })
        return
      }
      this.$confirm('是否要提交该产品', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (isEdit && !this.copy) {
          this.$api.product.updateProduct(this.id, this.productParam).then(response => {
            if (response.code === 200) {
              this.$message({
                type: 'success',
                message: '提交成功',
                duration: 1000
              })
              this.close()
            }
          })
        } else {
          this.productParam.id = ''
          this.productParam.sale = 0
          if (this.copy) {
            this.productParam.publishStatus = 0
          }
          this.$api.product.createProduct(this.productParam).then(response => {
            if (response.code === 200) {
              this.$message({
                type: 'success',
                message: '提交成功',
                duration: 1000
              })
              this.close()
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.form-container {
  position: relative;
  width: 100%;
}
</style>

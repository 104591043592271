<template>
  <div class="app-container">
    <el-card class="header-card">
      <el-form :inline="true" :model="listQuery" size="small" label-width="110px">
        <el-form-item label="订单编号">
          <el-input v-model="listQuery.platformTransId" style="width: 203px" placeholder="请输入订单编号" />
        </el-form-item>
        <el-form-item label="发票抬头">
          <el-input v-model="listQuery.invoiceName" style="width: 203px" placeholder="请输入发票抬头" />
        </el-form-item>
        <el-form-item label="申请时间">
          <el-date-picker v-model="dateValue" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']" />
        </el-form-item>
        <el-form-item label="会员账号">
          <el-input v-model="listQuery.userId" style="width: 203px" placeholder="请输入会员账号" />
        </el-form-item>
        <el-form-item label="处理状态">
          <!-- <el-input style="width: 203px" v-model="listQuery.platformTransId" placeholder="服务单号"></el-input> -->
          <el-select v-model="listQuery.status" placeholder="请选择">
            <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
      </el-form>
      <el-row class="search-area-btn">
        <div class="left">&nbsp;</div>
        <div class="center">
          <el-button type="primary" size="small" @click="searchBrandList()"> 查询 </el-button>
          <el-button type="primary" size="small" @click="exportsBrandList()"> 信息导出 </el-button>
        </div>
      </el-row>
    </el-card>
    <!-- <el-card class="operate-container" shadow="never">
      <i class="el-icon-tickets"></i>
      <span>数据列表</span>
      <el-button
        class="btn-add"
        @click="addAddress()"
        size="mini">
        添加
      </el-button>
    </el-card> -->
    <div class="table-container">
      <el-table ref="brandTable" v-loading="listLoading" :data="list" style="width: 100%" border @selection-change="handleSelectionChange">
        <!-- <el-table-column type="selection" width="60" align="center"></el-table-column> -->
        <el-table-column label="服务单号" align="center">
          <template slot-scope="scope">{{ scope.row.platformTransId }}</template>
        </el-table-column>
        <el-table-column label="申请会员账号" align="center">
          <template slot-scope="scope">{{ scope.row.userId }}</template>
        </el-table-column>
        <el-table-column label="申请时间" align="center">
          <template slot-scope="scope">{{ formatTime(scope.row.createTime) }}</template>
        </el-table-column>
        <!-- <el-table-column label="用户账户" align="center">
          <template slot-scope="scope">{{scope.row.invoiceNameType}}</template>
        </el-table-column> -->
        <el-table-column label="发票类型" align="center">
          <template slot-scope="scope">{{ scope.row.invoiceNameType | invoiceType }}</template>
        </el-table-column>
        <el-table-column label="开票金额" align="center">
          <template slot-scope="scope">{{ scope.row.invoiceAmount }}</template>
        </el-table-column>
        <el-table-column label="开票抬头" align="center">
          <template slot-scope="scope">{{ scope.row.invoiceName }}</template>
        </el-table-column>
        <el-table-column label="出票状态" align="center">
          <template slot-scope="scope">{{ scope.row.status | invoiceStatus }}</template>
        </el-table-column>
        <el-table-column label="处理时间" align="center">
          <template slot-scope="scope">{{ formatTime(scope.row.invoiceTime) }}</template>
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleUpdate(scope.$index, scope.row)">详情 </el-button>
            <!-- <el-button size="mini" v-if="scope.row.status == 0" @click="handleUpdate(scope.$index, scope.row)">审核 </el-button> -->
            <!-- <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="false" class="batch-operate-container">
      <el-select v-model="operateType" size="small" placeholder="批量操作">
        <el-option v-for="item in operates" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <el-button style="margin-left: 20px" class="search-button" type="primary" size="small" @click="handleBatchOperate()"> 确定 </el-button>
    </div>
    <div class="pagination-container">
      <el-pagination background layout="total, sizes,prev, pager, next,jumper" :page-size="listQuery.pageSize" :page-sizes="[5, 10, 20, 30, 50, 100]" :current-page.sync="listQuery.pageNumber" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
    <!--审核详情-->
    <!-- <el-dialog  title="发票审核" :visible.sync="showShFloat">
      <invoiceview></invoiceview>
    </el-dialog> -->
  </div>
</template>
<script>
// import invoiceview from './components/invoicereview.vue'

export default {
  name: 'InvoiceList',
  components: {
    // invoiceview
  },
  filters: {
    invoiceStatus(val) {
      if (val === 0) {
        return '待审核'
      } else if (val === 1) {
        return '已开票'
      } else {
        return '已废除'
      }
    },

    invoiceType(val) {
      if (val === 0 || val === null) {
        return '个人'
      } else if (val === 1) {
        return '企业'
      }
    }
  },
  data() {
    return {
      operates: [
        {
          label: '删除',
          value: 'delete'
        }
      ],
      operateType: null,
      listQuery: {
        pageNumber: 1,
        pageSize: 10,
        platformTransId: '',
        status: 0,
        platformId: 2,
        startTime: null,
        endTime: null,
        invoiceName: null,
        userId: null
      },
      statusOptions: [
        {
          label: '待开票',
          value: 0
        },
        {
          label: '已开票',
          value: 1
        },
        {
          label: '已废除',
          value: 2
        }
      ],
      list: null,
      total: null,
      listLoading: true,
      multipleSelection: [],
      dateValue: ''
      // 是否显示审核弹出层
      // showShFloat: true
    }
  },
  created() {
    this.getList()
  },
  methods: {
    formatTime(time) {
      if (time == null || time === '') {
        return ''
      }
      const date = new Date(time)
      return this.$utils.parseDate(date)
    },
    getList() {
      this.listLoading = true
      if (!this.listQuery.platformTransId) {
        delete this.listQuery.platformTransId
      }
      this.$api.invoice.invoiceList(this.listQuery).then(response => {
        this.listLoading = false
        this.list = response.data.list
        this.total = response.data.total
        // this.totalPage = response.data.totalPage;
        // this.pageSize = response.data.pageSize;
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleUpdate(index, row) {
      this.$router.push({
        path: '/oms/invoiceDetail',
        query: { id: row.id, code: row.platformTransId }
      })
    },
    // handleDelete(index, row) {
    //   this.$confirm('是否要删除该品牌', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     this.$api.invoice.removeInvoiceByTd(row.id).then(response => {
    //       this.$message({
    //         message: '删除成功',
    //         type: 'success',
    //         duration: 1000
    //       });
    //       this.getList();
    //     });
    //   });
    // },
    handleFactoryStatusChange(index, row) {
      let data = {
        ids: row.id,
        factoryStatus: row.factoryStatus
      }
      this.$api.brand
        .updateFactoryStatus(data)
        .then(() => {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000
          })
        })
        .catch(() => {
          if (row.factoryStatus === 0) {
            row.factoryStatus = 1
          } else {
            row.factoryStatus = 0
          }
        })
    },
    // handleShowStatusChange(index, row) {
    //   let data = new URLSearchParams();
    //   ;
    //   data.append("ids", row.id);
    //   data.append("showStatus", row.showStatus);
    //   this.$api.brand.updateShowStatus(data).then(response => {
    //     this.$message({
    //       message: '修改成功',
    //       type: 'success',
    //       duration: 1000
    //     });
    //   }).catch(error => {
    //     if (row.showStatus === 0) {
    //       row.showStatus = 1;
    //     } else {
    //       row.showStatus = 0;
    //     }
    //   });
    // },
    handleSizeChange(val) {
      this.listQuery.pageNumber = 1
      this.listQuery.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.pageNumber = val
      this.getList()
    },
    searchBrandList() {
      if (this.dateValue) {
        this.listQuery.startTime = this.$utils.parseDate(new Date(this.dateValue[0]))
        this.listQuery.endTime = this.$utils.parseDate(new Date(this.dateValue[1]))
      }
      this.listQuery.pageNumber = 1
      this.getList()
    },
    exportsBrandList() {
      delete this.listQuery.status
      this.$api.invoice.invoiceListExport(this.listQuery).then(res => {
        this.printExcel(res, '发票管理报表')
      })
    },
    // 打印
    printExcel(data, name = new Date()) {
      const blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      const objectUrl = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = objectUrl
      link.download = name
      link.click()
      URL.revokeObjectURL(objectUrl)
    },
    handleBatchOperate() {
      console.log(this.multipleSelection)
      if (this.multipleSelection < 1) {
        this.$message({
          message: '请选择一条记录',
          type: 'warning',
          duration: 1000
        })
        return
      }
      if (!this.operateType) {
        this.$message({
          message: '请选择批量操作类型',
          type: 'warning',
          duration: 1000
        })
        return
      }
      const ids = []
      for (let i = 0; i < this.multipleSelection.length; i++) {
        ids.push(this.multipleSelection[i].id)
      }

      let data = {
        ids: ids
      }
      this.$api.brand.updateShowStatus(data).then(() => {
        this.getList()
        this.$message({
          message: '修改成功',
          type: 'success',
          duration: 1000
        })
      })
    },
    addAddress() {
      this.$router.push({ path: '/oms/addressAdd' })
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped></style>

<template>
  <el-dialog title="提现明细" :visible="accountDetailVisible" width="50%" @close="closeDialog">
    <el-table :data="list" row-key="id" ref="Table" border max-height="500px">
      <el-table-column type="selection" width="60" align="center"></el-table-column>
      <!--      <el-table-column-->
      <!--        prop="username"-->
      <!--        label="提款账号"-->
      <!--        width="100">-->
      <!--      </el-table-column>-->
      <el-table-column label="日期" align="center">
        <template slot-scope="scope">{{ scope.row.applicationTime }}</template>
      </el-table-column>
      <el-table-column label="动账账户" align="center">
        <template slot-scope="scope">{{ scope.row.username }}</template>
      </el-table-column>
      <el-table-column label="提现账户" align="center" width="240px">
        <template slot-scope="scope">{{ scope.row.accountNo }}</template>
      </el-table-column>
      <el-table-column label="提现金额" prop="amount" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.amount }}元</span>
        </template>
      </el-table-column>
      <el-table-column label="提现状态" align="center">
        <template slot-scope="scope">{{ scope.row.transferStatus | settlementStatusFilter }}</template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <!--      <el-pagination-->
      <!--        background-->
      <!--        @size-change="handleSizeChange"-->
      <!--        @current-change="handlePageChange"-->
      <!--        layout="prev, pager, next"-->
      <!--        :current-page.sync="pageNumber"-->
      <!--        :page-size="pageSize"-->
      <!--        :page-sizes="[5,10,15]"-->
      <!--        :total="total"-->
      <!--      />-->
      <el-pagination @size-change="handleSizeChange" @current-change="handlePageChange" :current-page="pageNumber" :page-sizes="[5, 10, 20, 30, 50, 100]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" />
    </div>
    <div style="clear: both"></div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    accountDetailVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      list: []
    }
  },
  filters: {
    settlementStatusFilter(val) {
      // 1,待支付 2已支付 3支付失败 4已完成
      if (val === 1) {
        return '待支付'
      } else if (val === 2) {
        return '已支付'
      } else if (val === 3) {
        return '支付失败'
      } else if (val === 4) {
        return '已完成'
      }
      return ''
    }
  },
  created() {
    this.getWithdrawalsRecordList()
  },
  methods: {
    getWithdrawalsRecordList() {
      let param = {
        page: this.pageNumber,
        size: this.pageSize,
        userId: this.$store.state.user.userId
      }
      this.$api.dynamicAccount.getWithdrawalsRecordList(param).then(res => {
        // console.log(res);
        this.list = res.data.list
        this.total = res.data.total
      })
      this.recordLoading = false
    },
    handleSizeChange(size) {
      this.pageSize = size
      this.getWithdrawalsRecordList()
    },
    handlePageChange(page) {
      this.pageNumber = page
      this.getWithdrawalsRecordList()
    },
    closeDialog() {
      this.$emit('closeDialog')
    }
  }
}
</script>

<style lang="scss" scoped></style>

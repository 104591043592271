<template>
  <div class="app-container">
    <el-card class="operate-container" shadow="never">
      <i class="el-icon-tickets"></i>
      <span>发货列表</span>
    </el-card>
    <div class="table-container">
      <el-table ref="deliverOrderTable" style="width: 100%" :data="list" border>
        <el-table-column label="订单编号" width="180" align="center">
          <template slot-scope="scope">{{ scope.row.orderSn }}</template>
        </el-table-column>
        <el-table-column label="收货人" width="180" align="center">
          <template slot-scope="scope">{{ scope.row.receiverName }}</template>
        </el-table-column>
        <el-table-column label="手机号码" width="160" align="center">
          <template slot-scope="scope">{{ scope.row.receiverPhone }}</template>
        </el-table-column>
        <el-table-column label="邮政编码" width="160" align="center">
          <template slot-scope="scope">{{ scope.row.receiverPostCode }}</template>
        </el-table-column>
        <el-table-column label="收货地址" align="center">
          <template slot-scope="scope">{{ scope.row.address }}</template>
        </el-table-column>
        <el-table-column label="配送方式" width="200" align="center">
          <template slot-scope="scope">
            <el-select v-model="scope.row.deliveryCompany" filterable remote reserve-keyword clearable placeholder="请输入物流公司" :remote-method="remoteMethod" :loading="companyloading" size="small" @clear="getCompanyList" @change="changeDeliveryCompany($event, scope.row)">
              <el-option v-for="item in companyOptions" :key="item.expId" :label="item.expName" :value="item.expName"> </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="物流单号" width="250" align="center">
          <template slot-scope="scope">
            <el-input size="small" v-model="scope.row.deliverySn" placeholder="请输入物流单号"></el-input>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 15px; text-align: center">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="confirm" type="primary">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'deliverOrderList',
  data() {
    return {
      list: [],
      companyOptions: [],
      companyloading: false
    }
  },
  created() {
    this.list = this.$route.query.list
    this.getCompanyList()
  },
  methods: {
    remoteMethod(query) {
      if (query !== '') {
        this.companyloading = true
        this.$api.order.getCompanyList({ keyWord: query }).then(res => {
          this.companyloading = false
          this.companyOptions = res.data
        })
      } else {
        this.companyOptions = []
      }
    },
    getCompanyList() {
      this.companyloading = true
      this.$api.order.getCompanyList().then(res => {
        this.companyOptions = res.data.splice(0, 20)
        this.companyloading = false
      })
    },
    cancel() {
      this.$router.back()
    },
    confirm() {
      this.$confirm('是否要进行发货操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$api.order.deliveryOrder(this.list).then(() => {
            this.$router.back()
            this.$message({
              type: 'success',
              message: '发货成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消发货'
          })
        })
    },
    changeDeliveryCompany(event, row) {
      if (event === '新印配送') {
        this.list.forEach(el => {
          if (el.orderId === row.orderId) {
            row.deliverySn = el.orderSn
          }
        })
        this.list = JSON.parse(JSON.stringify(this.list))
      }
    }
  }
}
</script>
<style></style>

import request from '@/api/request'
import qs from 'qs'

const transParams = data => {
  let params = new URLSearchParams()
  for (let item in data) {
    params.append(item, data['' + item + ''])
    params.append('use', 2)
  }
  return params
}

const order = {
  // 确认发货
  confirmFast(params) {
    return request({
      url: '/expositionShop/order/update/deliveryStatus',
      method: 'post',
      params: params
    })
  },

  orderList(params) {
    return request({
      url: '/expositionShop/order/list',
      method: 'get',
      params: params
    })
  },

  closeOrder(data) {
    return request({
      url: '/expositionShop/order/update/close',
      method: 'post',
      data
    })
  },

  deleteOrder(data) {
    return request({
      url: '/expositionShop/order/delete',
      method: 'POST',
      data: transParams(data),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      noUse: true
    })
  },

  deliveryOrder(data) {
    return request({
      url: '/expositionShop/order/update/delivery',
      method: 'post',
      data: data
    })
  },

  // 根据code查询
  getOrderDetailByCode(params) {
    return request({
      url: '/expositionShop/order/code',
      method: 'get',
      params
    })
  },

  getOrderDetail(id) {
    return request({
      url: '/expositionShop/order/' + id,
      method: 'get'
    })
  },

  updateReceiverInfo(data) {
    return request({
      url: '/expositionShop/order/update/receiverInfo',
      method: 'post',
      data: data
    })
  },

  updateMoneyInfo(data) {
    return request({
      url: '/expositionShop/order/update/moneyInfo',
      method: 'post',
      data: data
    })
  },

  updateOrderNote(data) {
    return request({
      url: '/expositionShop/order/update/note',
      method: 'post',
      params: data
    })
  },

  // 物流追踪查询
  /* params
   *  logisticalNumber
   */
  listByFeign(params) {
    return request({
      url: '/cLogistical/cLogisticalInfo/list',
      method: 'GET',
      params: params
    })
  },

  // 确认收货
  confirmReceipt(params) {
    return request({
      url: '/expositionShop/order/confirmReceipt',
      method: 'post',
      params: params
    })
  },

  // 批量发货
  separateDelivery(data) {
    return request({
      url: '/expositionShop/order/update/separateDelivery',
      method: 'post',
      headers: {
        'content-type': 'application/json'
      },
      data,
      noUse: true
    })
  },

  // 修改物流信息
  updateDelivery(data) {
    return request({
      url: '/expositionShop/order/updateOrderDelivery',
      method: 'post',
      data: qs.stringify(data)
    })
  },

  // 批量导出订单列表
  batchExportOrderList(params) {
    return request({
      url: '/expositionShop/order/batchExportOrderList',
      method: 'get',
      params,
      responseType: 'blob'
    })
  },
  // 线下门店-查询
  getCompanyList(params) {
    return request({
      url: '/expositionShop/content/getCompany',
      method: 'GET',
      params: params
    })
  },
  getOrderSetting(id, params = {}) {
    return request({
      url: '/expositionShop/orderSetting/' + id,
      method: 'get',
      params
    })
  },
  updateOrderSetting(id, data = {}) {
    return request({
      url: '/expositionShop/orderSetting/update/' + id,
      method: 'post',
      data: data
    })
  },
  orderRefundList(params) {
    return request({
      url: '/expositionShop/orderRefund/list',
      method: 'get',
      params: params
    })
  },

  deleteApply(params) {
    return request({
      url: '/expositionShop/returnApply/delete',
      method: 'post',
      params: params
    })
  },
  updateApplyStatus(id, data) {
    return request({
      url: '/expositionShop/returnApply/update/status/' + id,
      method: 'post',
      data: data
    })
  },

  getApplyDetail(id) {
    return request({
      url: '/expositionShop/returnApply/' + id,
      method: 'get'
    })
  },

  // 状态修改-退款、换货申请管理
  updateReturnState(params) {
    return request({
      url: '/expositionShop/updateState',
      method: 'POST',
      params: params
    })
  },

  // 卖家发货（换货）
  sendProduct(params) {
    return request({
      url: '/expositionShop/exchange/sendProduct',
      method: 'POST',
      params: params
    })
  },

  // 换货订单状态修改
  updateChangeState(params) {
    return request({
      url: '/expositionShop/exchange/cancelApply',
      method: 'POST',
      params: params
    })
  },

  // 状态修改-退款、换货申请管理 (改)
  // 拒绝退款
  refuseRefund(params) {
    return request({
      url: '/expositionShop/orderRefund/updateState',
      method: 'POST',
      params: params
    })
  },

  // 逻辑删除退货换货退款
  logicDelete(params) {
    return request({
      url: '/expositionShop/orderRefund/logicDelete',
      method: 'POST',
      params: params
    })
  },
  returnReasonList(params) {
    return request({
      url: '/expositionShop/returnReason/list',
      method: 'get',
      params: params
    })
  },

  deleteReason(data) {
    return request({
      url: '/expositionShop/returnReason/delete',
      method: 'post',
      params: data
    })
  },

  updateStatus(data) {
    return request({
      url: '/expositionShop/returnReason/update/status',
      method: 'post',
      data
    })
  },

  addReason(data) {
    return request({
      url: '/expositionShop/returnReason/create',
      method: 'post',
      data: data
    })
  },

  getReasonDetail(id) {
    return request({
      url: '/expositionShop/returnReason/' + id,
      method: 'get'
    })
  },

  updateReason(id, data) {
    return request({
      url: '/expositionShop/returnReason/update/' + id,
      method: 'post',
      data: data
    })
  },
  // 获取所有收货地址
  companyAddressList(params) {
    return request({
      url: '/expositionShop/companyAddress/list',
      method: 'get',
      params
    })
  },

  // 添加收货地址
  createAddress(data) {
    return request({
      url: '/expositionShop/companyAddress/create',
      method: 'POST',
      data: data
    })
  },

  // 删除收货地址
  deleteAddressById(id) {
    return request({
      url: `/expositionShop/companyAddress/delete/${id}`,
      method: 'POST'
    })
  },

  // 获取收货地址
  getAddressById(id) {
    return request({
      url: `/expositionShop/companyAddress/detail/${id}`,
      method: 'GET'
    })
  },

  // 修改收货地址
  updateAddressById(id, data) {
    return request({
      url: `/expositionShop/companyAddress/update/${id}`,
      method: 'POST',
      data: data
    })
  },

  // 状态修改
  statusUpdate(params) {
    return request({
      url: '/expositionShop/companyAddress/statusUpdate',
      method: 'POST',
      params: params
    })
  }
}

export default order

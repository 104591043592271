import request from '@/api/request'
import qs from 'qs'

const common = {
  policy() {
    return request({
      url: '/design/oss/val/getVal',
      method: 'get',
      params: { type: 3 }
    }).then(res => {
      return res.data
    })
  },
  getShopList(params) {
    return request({
      url: '/expositionShop/shop/selectshopname',
      method: 'get',
      params: params
    })
  },
  getCompanyList(params) {
    return request({
      url: '/expositionShop/shop/selectshopnametwo',
      method: 'get',
      params
    })
  },
  getConfigList(params) {
    return request({
      url: `/system/config/key/${params}`,
      method: 'get'
    }).then(res => {
      if (res.code === 200) {
        return res.data
      }
    })
  },
  getInfo() {
    return request({
      url: '/system/user/get/user',
      method: 'get'
    })
  },
  getShopByUserId() {
    return request({
      url: '/expositionShop/shop/display',
      method: 'get'
    })
  },
  getUserRole(userId) {
    return request({
      url: `/system/user/is/shop/admin/by/${userId}`,
      method: 'get'
    })
  },
  getDict(params) {
    return request({
      url: '/system/dict/data/key',
      method: 'GET',
      params
    })
  },
  getMenuByUserId() {
    return request({
      url: '/system/menu/user',
      method: 'get'
    })
  },
  // 修改密码
  changeUserPwd(params) {
    return request({
      url: '/system/user/updatePwd',
      method: 'POST',
      data: qs.stringify(params)
    })
  },
  // 获取用户信息
  getAcount(params) {
    return request({
      url: '/system/user/get/acount',
      method: 'GET',
      params
    })
  },
  // 账户银行卡变更
  updateAccountInfo(data) {
    return request({
      url: '/system/acount/changeCard',
      method: 'POST',
      data: qs.stringify(data)
    })
  },
  partThree() {
    return request({
      url: '/expositionShop/rear/home/statistics/3',
      method: 'get'
    })
  },

  // 账户-提款（转账）申请（冻结）
  apply(params) {
    return request({
      url: '/system/acount/transfer/apply',
      method: 'POST',
      params
    })
  }
}

export default common

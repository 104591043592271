<template>
  <div class="app-container">
    <el-card class="header-card" shadow="never">
      <div>
        <el-form :inline="true" :model="listQuery" size="small" label-width="110px">
          <el-row>
            <el-col :span="6">
              <el-form-item label="输入搜索">
                <el-input v-model="listQuery.title" placeholder="请输入标题搜索" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="新闻分类">
                <el-select v-model="listQuery.categoryId" placeholder="请选择新闻分类">
                  <el-option label="全部分类" :value="''" />
                  <el-option v-for="item in shopProductTypeOptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="新闻状态" prop="type">
                <el-select v-model="listQuery.status" placeholder="请选择新闻状态">
                  <el-option v-for="item in newsStateOptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="9">
              <el-form-item label="生效时间">
                <el-date-picker v-model="timeValue" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <el-row class="search-area-btn">
          <div class="left">
            <el-button class="btn-add" type="primary" size="mini" @click="handleAddProduct()"> 添加 </el-button>
            <el-button class="btn-add" type="danger" size="mini" @click="handlemulitNews()"> 批量删除 </el-button>
          </div>
          <div class="center">
            <el-button type="primary" size="small" @click="handleSearchList()">查询</el-button>
            <el-button size="small" @click="handleResetSearch()"> 重置 </el-button>
          </div>
        </el-row>
      </div>
    </el-card>

    <div class="table-container">
      <el-table ref="multipleTable" v-loading="listLoading" :data="tableData" style="width: 100%" border @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="60" align="center" />
        <el-table-column label="新闻标题" width="240" align="center">
          <template slot-scope="scope">{{ scope.row.title }}</template>
        </el-table-column>
        <el-table-column label="新闻附图" width="180" align="center">
          <template slot-scope="scope">
            <div class="image__preview">
              <el-image v-if="scope.row.cover && scope.row.cover.indexOf('mp4') < 0" style="height: 100px; width: 100px" :src="scope.row.cover" :preview-src-list="srcList" @click="showViewer(scope.row.cover)" />
              <span v-if="!scope.row.cover || scope.row.cover.indexOf('mp4') > 0">无</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="新闻分类" width="180" align="center">
          <template slot-scope="scope">{{ scope.row.categoryId | handleCategory(shopProductTypeOptions) }}</template>
        </el-table-column>
        <el-table-column label="状态" width="160" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.status | handleLabel }}</p>
          </template>
        </el-table-column>
        <el-table-column label="置顶" width="160" align="center">
          <template slot-scope="scope">
            <p>
              <span style="display: inline-block; text-align: left; width: 85px">是否置顶：</span>
              <el-switch v-model="scope.row.overHead" :active-value="1" :inactive-value="0" @change="handleOverHeadChange(scope.row)" />
            </p>
          </template>
        </el-table-column>
        <el-table-column label="点击" width="120" align="center">
          <template slot-scope="scope">{{ scope.row.clicks }}</template>
        </el-table-column>
        <el-table-column label="排序" width="160" align="center">
          <template slot-scope="scope">
            <span v-if="!isShowEditSortArr[scope.$index]" style="display: block; cursor: pointer" @click="handleEditSort(scope.$index)">{{ scope.row.sort }}</span>
            <el-input v-else v-model="scope.row.sort" placeholder="请输入内容" @blur="confirmEditSort(scope.row.sort, scope.row.id, scope.$index)" />
          </template>
        </el-table-column>
        <el-table-column label="生效时间" align="center" width="240">
          <template slot-scope="scope">{{ handleTimeFormat(scope.row.publishDate) }}</template>
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="360" align="center">
          <template slot-scope="scope">
            <p>
              <el-button size="mini" @click="openViews(scope.row)" :disabled="scope.row.categoryId === 22">预览 </el-button>
              <el-button v-if="scope.row.publishStatus !== 1 && scope.row.auditStatus !== 0" type="primary" size="mini" @click="handleUpdateNews(scope.row, true)">编辑 </el-button>
              <el-button v-if="scope.row.publishStatus == 1 || scope.row.auditStatus === 0" size="mini" @click="handleUpdateNews(scope.row, false)">查看 </el-button>
              <el-button v-if="scope.row.status == 0" type="primary" size="mini" @click="handleReviewNews(scope.row)">提审 </el-button>
              <el-button class="btn-add" type="primary" size="mini" @click="handleUpdateNews(scope.row, true, true)"> 复制 </el-button>
              <el-button size="mini" type="danger" :disabled="scope.row.publishStatus == 1 || scope.row.auditStatus === 0" @click="handleDelete(scope.row)">删除 </el-button>
            </p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination background layout="total, sizes,prev, pager, next,jumper" :page-size="listQuery.pageSize" :page-sizes="[5, 10, 15]" :current-page.sync="listQuery.pageNumber" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
    <addNews v-if="handleShowEditNews" :isEdit="isEdit" :isCopy="isCopy" :nwesId="nwesId" :productCateOptions="shopProductTypeOptions" @closeEidtNews="closeEidtNews" @handleConfirm="handleConfirm"></addNews>
    <newsPreview v-if="handleShowNewsModal" :newsData="newsData" @handlePreviewColse="handlePreviewColse"></newsPreview>
  </div>
</template>

<script>
import addNews from './components/addNews'
import newsPreview from './components/newsPreview'
const defaultListQuery = {
  title: null,
  categoryId: '',
  status: 1,
  startTime: null,
  endTime: null,
  pageNumber: 1,
  pageSize: 5
}
export default {
  components: {
    addNews,
    newsPreview
  },
  filters: {
    handleCategory(id, shopProductTypeOptions) {
      let label = ''
      shopProductTypeOptions.forEach(val => {
        if (val.value === id) {
          label = val.label
        }
      })
      return label
    },
    handleLabel(val) {
      switch (val) {
        case 1:
          return '已发布'
          // eslint-disable-next-line no-unreachable
          break
        case 0:
          return '草稿中'
          // eslint-disable-next-line no-unreachable
          break
      }
    }
  },
  watch: {},
  computed: {},
  data() {
    return {
      timeValue: [],
      listQuery: { ...defaultListQuery },
      newsStateOptions: [
        {
          label: '已发布',
          value: 1
        },
        {
          label: '草稿中',
          value: 0
        }
      ],
      tableData: [],
      listLoading: false,
      total: 0,
      isEdit: false,
      handleShowEditNews: false,
      shopProductTypeOptions: [],
      srcList: [],
      isShowEditSortArr: [],
      nwesId: '',
      handleShowNewsModal: false,
      newsData: {},
      isCopy: false,
      multipleSelectionGoods: [],
      pageSelectArry: [[]]
    }
  },
  mounted() {
    this.getNewsCate()
    this.getNewsList()
  },
  beforeDestroy() {
    this.$refs.multipleTable.clearSelection()
  },
  methods: {
    getRowKeys(row) {
      return row.id + Math.random()
    },
    //批量删除新闻
    handlemulitNews() {
      if (!this.multipleSelectionGoods.length) {
        this.$message.warning('请选择要删除的新闻！')
        return
      }
      this.$confirm('确定要批量删除新闻吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let ids = this.multipleSelectionGoods.map(res => res.id)
        this.$api.news.mulitRemoveNews(ids).then(res => {
          if (res.code === 200) {
            this.$message.success('新闻删除成功！')
            this.getNewsList()
          }
        })
      })
    },
    isSelectedData() {
      this.tableData.forEach(item => {
        this.multipleSelectionGoods.forEach(idArry => {
          if (item.id === idArry.id) {
            this.$nextTick(() => {
              this.$refs.multipleTable.toggleRowSelection(item, true)
            })
          } else {
            this.$refs.multipleTable.toggleRowSelection(item, false)
          }
        })
      })
    },
    handleTimeFormat(startTime) {
      return this.$utils.parseDate(startTime, '{y}-{m}-{d}')
    },
    //新闻提审事件
    handleReviewNews(row) {
      this.$confirm('确定要将该新闻提交审核吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        row.status = 2
        this.handleNewUpdata(row)
      })
    },
    //新闻编辑/查看事件
    handleUpdateNews(row, isEdit, isCopy) {
      this.isEdit = isEdit
      this.isCopy = isCopy
      this.nwesId = row.id
      this.handleShowEditNews = true
    },
    //新闻预览事件
    openViews(row) {
      this.newsData = row
      this.handleShowNewsModal = true
    },

    //关闭新闻预览
    handlePreviewColse() {
      this.handleShowNewsModal = false
    },

    //新闻排序内容修改blur
    confirmEditSort() {},
    //新闻单个删除事件
    handleDelete(row) {
      this.$confirm('确定要删除该新闻吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$api.news.mulitRemoveNews([row.id]).then(res => {
            if (res.code === 200) {
              this.$message.success('新闻删除成功！')
              this.getNewsList()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    //新闻排序修改
    handleEditSort() {
      // this.isShowEditSortArr.splice(index, 1, true)
    },
    //新闻状态修改事件
    handlePublishStatusChange(row) {
      this.$confirm('确定要修改该新闻的状态吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.handleNewUpdata(row)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          })
          this.getNewsList()
        })
    },
    //新闻置顶修改事件
    handleOverHeadChange(row) {
      this.$confirm('确定要置顶该条新闻吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.handleNewsOverHead(row)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          })
          this.getNewsList()
        })
    },
    //新闻附图点击查看
    showViewer(val) {
      this.srcList = []
      this.srcList.push(val)
    },
    //新闻列表选择事件
    handleSelectionChange(val) {
      console.log(this.multipleSelectionGoods, val, '查看新闻新闻新闻哈哈哈哈哈哈哈哈哈11111')
      let obj = {}
      this.multipleSelectionGoods = val.reduce((cur, next) => {
        obj[next.id] ? '' : (obj[next.id] = true && cur.push(next))
        return cur
      }, [])
      console.log(this.multipleSelectionGoods, val, '查看新闻新闻新闻哈哈哈哈哈哈哈哈哈')
    },
    // 新闻添加按钮
    handleAddProduct() {
      this.nwesId = ''
      this.isEdit = true
      this.handleShowEditNews = true
    },

    //新闻查询按钮
    handleSearchList() {
      if (this.timeValue.length) {
        this.listQuery.startTime = this.timeValue[0]
        this.listQuery.endTime = this.timeValue[1]
      } else {
        this.listQuery.startTime = null
        this.listQuery.endTime = null
      }
      this.listQuery.pageNumber = 1
      this.getNewsList()
    },

    //搜索重置按钮
    handleResetSearch() {
      this.timeValue = []
      this.listQuery = {
        keyword: null,
        categoryId: '',
        status: 1,
        startTime: null,
        endTime: null,
        pageNumber: 1,
        pageSize: 5
      }
      this.getNewsList()
    },
    //关闭添加新闻子组件按钮事件
    closeEidtNews() {
      this.handleShowEditNews = false
    },
    //新闻添加成功按钮事件
    handleConfirm() {
      this.handleShowEditNews = false
      this.getNewsList()
    },
    //新闻编辑接口请求
    handleNewUpdata(params) {
      this.$api.news.newsContentUpadte(params).then(res => {
        if (res.code === 200) {
          this.$message.success('新闻修改成功！')
          this.listQuery.pageNumber = 1
          this.getNewsList()
        }
      })
    },
    // 新闻置顶接口请求
    handleNewsOverHead(params) {
      this.$api.news.newsOverHead(params).then(res => {
        if (res.code === 200) {
          this.$message.success('修改成功！')
          this.getNewsList()
        }
      })
    },
    //新闻列表获取事件
    getNewsList() {
      this.$api.news.newsQuery(this.listQuery).then(res => {
        if (res.code === 200) {
          this.tableData = res.data.list
          this.total = res.data.total
          this.tableData.forEach(val => {
            if (val) {
              this.isShowEditSortArr.push(false)
            }
          })
          // this.isSelectedData()
        }
      })
    },
    //新闻获取分类事件
    getNewsCate() {
      const parmas = {
        pageNumber: 1,
        pageSize: 999
      }
      this.$api.newsClass.categoryList(parmas).then(res => {
        if (res.code === 200) {
          res.data.list.forEach(val => {
            this.shopProductTypeOptions.push({
              value: val.id,
              label: val.categoryName,
              type: val.categoryType
            })
          })
        }
      })
    },
    //分页事件
    handleSizeChange(val) {
      this.listQuery.pageSize = val
      this.getNewsList()
    },
    async handleCurrentChange(val) {
      // console.log(this.pageSelectArry[val - 1], '11112')
      this.listQuery.pageNumber = val
      await this.getNewsList()
      // this.multipleSelectionGoods = this.pageSelectArry[val - 1]
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.header-card {
  .el-form {
    .el-input {
      width: 213px;
    }
  }
}

.title-area {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.status-dots {
  margin-right: 8px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
}

.shop-dialog {
  ::v-deep.el-dialog {
    height: 80vh;
    width: 80vw;
    margin-top: 10vh !important;
  }

  iframe {
    width: 100%;
    height: 90%;
    position: absolute;
    left: 0;
  }
}
</style>

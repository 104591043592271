import { getToken, removeToken, setPlatform, removePlatform } from '@/utils/storage'
import { resetRouter } from '@/router'
import { Message } from 'element-ui'
import commonApi from '@/api/common/api'


const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: '',
    userId: null,
    currentPlatFormId: '',
    bondStatus: null, // 是否缴纳了保证金
    userRole: true
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_USERID: (state, userId) => {
      state.userId = userId
    },
    SET_CURRENTPLATFORMID: (state, currentPlatFormId) => {
      state.currentPlatFormId = currentPlatFormId
    },
    SET_BONDSTATUS: (state, bondStatus) => {
      state.bondStatus = bondStatus
    },
    SET_USERROLE: (state, userRole) => {
      state.userRole = userRole
    }
  },

  actions: {
    // 获取用户信息
    GetInfo({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        commonApi
          .getInfo()
          .then(async response => {
            if (response.code === 200) {
              let platform = null
              platform = await commonApi.getConfigList('PLATFORM_SHOP')
              commit('SET_ROLES', response.data.userId)
              commit('SET_NAME', response.data.userName)
              commit('SET_USERID', response.data.userId)
              const roleList = []
              let currentPlatFormIds = null
              if (response.data.sysGroup.length) {
                response.data.sysGroup.forEach(el => {
                  if (el.platformId === Number(platform)) {
                    currentPlatFormIds = Number(platform)
                    roleList.push(el)
                  }
                })
              }

              if (roleList.length) {
                commit('SET_CURRENTPLATFORMID', currentPlatFormIds)
                setPlatform(state.currentPlatFormId)
                resolve(response.data.sysUserInfo)
              } else {
                Message.error('对不起，您没有权限')
                dispatch('resetToken')
                reject('error')
                return
              }

              commonApi.getShopByUserId(response.data.userId).then(res => {
                commit('SET_BONDSTATUS', res.data ? res.data.bondStatus : null)
              })

              commonApi.getUserRole(response.data.userId).then(res => {
                if (res.code === 200) {
                  commit('SET_USERROLE', res)
                }
              })
            } else {
              Message.error('验证失败，请重新登录')
              dispatch('resetToken')
              dispatch('tagsView/delAllViews', null, { root: true })
              reject('error')
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    resetToken({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', '')
        commit('SET_NAME', '')
        commit('SET_USERID', '')
        removeToken()
        removePlatform()
        resetRouter()
        resolve()
      })
    }
  }
}

export default user

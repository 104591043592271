<template>
  <limit-area-detail :is-edit="true" />
</template>
<script>
import LimitAreaDetail from './components/LimitAreaDetail'
export default {
  name: 'UpdateLimitArea',
  components: { LimitAreaDetail }
}
</script>
<style></style>

<template>
  <limit-area-detail :is-edit="false" />
</template>
<script>
import LimitAreaDetail from './components/LimitAreaDetail'
export default {
  name: 'AddLimitArea',
  components: { LimitAreaDetail }
}
</script>
<style></style>

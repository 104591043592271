import request from '@/api/request'
// import qs from 'qs'

const sync = {
  getEsAndRedisSyncList(params) {
    return request({
      url: '/expositionShop/esAndRedisSync/list',
      method: 'get',
      params
    })
  },

  esAndRedisSync(params) {
    return request({
      url: '/expositionShop/esAndRedisSync/syncAllInfo',
      method: 'post',
      params
    })
  }
}

export default sync

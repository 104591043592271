import request from '@/api/request'
// import qs from 'qs'

const shopweb = {
  qccList(params) {
    return request({
      url: '/shopweb/qcc/list',
      method: 'get',
      params: params
    })
  }
}

export default shopweb

<template>
  <div class="app-container">
    <el-card class="header-card">
      <el-form :inline="true" :model="listQuery" size="small" label-width="110px">
        <el-form-item label="输入搜索">
          <el-input v-model="listQuery.orderSn" class="input-width" placeholder="服务单号" />
        </el-form-item>
        <el-form-item label="处理状态">
          <el-select v-model="listQuery.status" placeholder="全部" clearable class="input-width">
            <el-option v-for="item in applyStatusOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="申请时间">
          <el-date-picker class="input-width" v-model="listQuery.createTime" value-format="yyyy-MM-dd" type="date" placeholder="请选择时间" />
        </el-form-item>
        <el-form-item label="店铺搜索" v-if="UserStatus === 0">
          <el-select v-model="listQuery.shopId" placeholder="全部" clearable class="input-width">
            <el-option v-for="item in ShopNameOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="操作人员">
          <el-input v-model="listQuery.handleMan" class="input-width" placeholder="全部" />
        </el-form-item>
        <el-form-item label="处理时间">
          <el-date-picker class="input-width" v-model="listQuery.handleTime" value-format="yyyy-MM-dd" type="date" placeholder="请选择时间" />
        </el-form-item>
      </el-form>
      <el-row class="search-area-btn">
        <div class="left">&nbsp;</div>
        <div class="center">
          <el-button type="primary" size="small" @click="handleSearchList()"> 查询</el-button>
          <el-button size="small" @click="handleResetSearch()">重置</el-button>
        </div>
      </el-row>
    </el-card>
    <div class="table-container">
      <el-table ref="returnApplyTable" :data="list" style="width: 100%" @selection-change="handleSelectionChange" v-loading="listLoading" border>
        <!-- <el-table-column type="selection" width="60" align="center"></el-table-column> -->
        <el-table-column label="服务单号" width="200" align="center">
          <template slot-scope="scope">{{ scope.row.orderSn }}</template>
        </el-table-column>
        <el-table-column label="所属店铺" width="200" align="center">
          <template slot-scope="scope">{{ scope.row.shopName }}</template>
        </el-table-column>
        <el-table-column label="申请时间" width="180" align="center">
          <template slot-scope="scope">{{ formatTime(scope.row.createTime) }}</template>
        </el-table-column>
        <el-table-column label="用户账号" align="center">
          <template slot-scope="scope">{{ scope.row.memberUsername }}</template>
        </el-table-column>
        <el-table-column label="退款金额" width="180" align="center">
          <template slot-scope="scope">￥{{ scope.row.mustPrice }}</template>
        </el-table-column>
        <el-table-column label="申请状态" width="180" align="center">
          <template slot-scope="scope">{{ $utils.handleOptions(scope.row.status, applyStatusOptions) }}</template>
        </el-table-column>
        <el-table-column label="处理时间" width="180" align="center">
          <template slot-scope="scope">{{ formatTime(scope.row.handleTime) }}</template>
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleViewDetail(scope.$index, scope.row)">查看详情</el-button>
            <el-button v-if="scope.row.status == 4" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="false" class="batch-operate-container">
      <el-select size="small" v-model="operateType" placeholder="批量操作">
        <el-option v-for="item in operateOptions" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <el-button style="margin-left: 20px" class="search-button" @click="handleBatchOperate()" type="primary" size="small"> 确定 </el-button>
    </div>
    <div class="pagination-container">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes,prev, pager, next,jumper" :current-page.sync="listQuery.pageNumber" :page-size="listQuery.pageSize" :page-sizes="[5, 10, 20, 30, 50, 100]" :total="total" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import store from '@/store'
const defaultListQuery = {
  orderSn: '',
  pageNumber: 1,
  pageSize: 10,
  returnApplyType: 1,
  shopId: null,
  // id: null,
  // receiverKeyword: null,
  status: null,
  createTime: null,
  handleMan: null,
  handleTime: null
}
// const defaultStatusOptions = [
//   {
//     label: '待处理',
//     value: 0
//   },
//   {
//     label: '退货中',
//     value: 1
//   },
//   {
//     label: '买家退货',
//     value: 2
//   },
//   {
//     label: '已完成',
//     value: 4
//   },
//   {
//     label: '已拒绝',
//     value: 5
//   }
// ]
export default {
  name: 'returnApplyList',
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      list: null,
      total: null,
      listLoading: false,
      multipleSelection: [],
      ShopNameOptions: [],
      operateType: 1,
      UserStatus: '',
      operateOptions: [
        {
          label: '批量删除',
          value: 1
        }
      ]
    }
  },
  computed: {
    ...mapState({
      applyStatusOptions: state => state.dictionary.applyStatus
    })
  },
  created() {
    this.getDictionaryList()
    this.getList()
    this.getShopNameList()
  },
  filters: {},
  methods: {
    formatTime(time) {
      if (time == null || time === '') {
        return ''
      }
      let date = new Date(time)
      return this.$utils.parseDate(date)
    },
    async getDictionaryList() {
      await store.dispatch('change_applystatus')
    },
    handleDelete(index, row) {
      this.$confirm('是否要进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          returnApplyId: row.id
        }
        this.$api.order.logicDelete(params).then(() => {
          this.getList()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
      })
    },
    getShopNameList() {
      this.$api.common.getShopList({ pageNum: 1, pageSize: 100 }).then(response => {
        console.log(response, '629')
        this.ShopNameOptions = []
        const ShopList = response.data
        for (let i = 0; i < ShopList.length; i++) {
          this.ShopNameOptions.push({
            label: ShopList[i].shopName,
            value: ShopList[i].id
          })
        }
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleResetSearch() {
      this.listQuery = Object.assign({}, defaultListQuery)
    },
    handleSearchList() {
      this.listQuery.pageNumber = 1
      this.getList()
    },
    handleViewDetail(index, row) {
      this.$router.push({
        path: '/oms/returnApplyDetail',
        query: { id: row.id }
      })
    },
    handleBatchOperate() {
      if (this.multipleSelection == null || this.multipleSelection.length < 1) {
        this.$message({
          message: '请选择要操作的申请',
          type: 'warning',
          duration: 1000
        })
        return
      }
      if (this.operateType === 1) {
        //批量删除
        this.$confirm('是否要进行删除操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids = []
          for (let i = 0; i < this.multipleSelection.length; i++) {
            ids.push(this.multipleSelection[i].id)
          }
          // let params = {
          //   ids: ids
          // }
          // deleteApply(params).then(response=>{
          //   this.getList();
          //   this.$message({
          //     type: 'success',
          //     message: '删除成功!'
          //   });
          // });
        })
      }
    },
    handleSizeChange(val) {
      this.listQuery.pageNumber = 1
      this.listQuery.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.pageNumber = val
      this.getList()
    },
    getList() {
      this.listLoading = true
      this.$api.order.orderRefundList(this.listQuery).then(response => {
        this.listLoading = false
        this.list = response.data.list
        if (this.list.length > 0) {
          this.UserStatus = this.list[0].status
        }
        this.total = response.data.total
      })
    }
  }
}
</script>
<style scoped>
.input-width {
  width: 203px;
}
</style>

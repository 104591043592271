<template>
  <div style="margin-top: 50px">
    <el-form ref="productInfoForm" :model="value" :rules="rules" label-width="120px" style="width: 100%" size="small">
      <el-row>
        <el-col :span="24">
          <el-form-item label="是否售卖：">
            <el-switch style="display: block" v-model="value.beBuy" active-color="#13ce66" inactive-color="#ff4949" inactive-text="展示商品" active-text="售卖商品" :inactive-value="0" :active-value="1"> </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="商品分类：" prop="productCategoryId">
            <el-cascader v-model="selectProductCateValue" :disabled="(isEdit && !copy && isVerify && isPublish) || !isAuditStatus" :options="productCateOptions" @change="handleCateChange" filterable />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="商品名称：" prop="name">
            <el-input v-model="value.name" :disabled="(!copy && isPublish) || !isAuditStatus" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="副标题：" prop="subTitle">
            <el-input v-model="value.subTitle" :disabled="(!copy && isPublish) || !isAuditStatus" />
          </el-form-item>
        </el-col>
        <el-col v-if="value.beBuy" :span="12">
          <el-form-item label="商品品牌：" prop="brandId">
            <el-select v-model="value.brandId" :disabled="(isEdit && !copy && isVerify && isPublish) || !isAuditStatus" placeholder="请选择品牌" @change="handleBrandChange">
              <el-option v-for="item in brandOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="商品类型：" prop="type">
            <el-select v-model="value.type" placeholder="请选择商品类型" :disabled="(!copy && isPublish) || !isAuditStatus">
              <el-option v-for="(item, index) in userShopProductTypeOptions" :key="index" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="商品售价：" prop="price">
            <el-input v-model="value.price" v-enter-money :disabled="(!copy && isPublish) || !isAuditStatus" />
          </el-form-item>
        </el-col>

        <el-col v-if="value.beBuy" :span="12">
          <el-form-item label="市场价：" prop="originalPrice">
            <el-input v-model="value.originalPrice" v-enter-money :disabled="(!copy && isPublish) || !isAuditStatus" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序">
            <el-input v-model="value.sort" v-enter-number :disabled="(isEdit && !copy && isVerify && isPublish) || !isAuditStatus" />
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
          <el-form-item label="商品介绍：">
            <el-input v-model="value.description" :auto-size="true" type="textarea" :disabled="(!copy && isPublish) || !isAuditStatus" placeholder="请输入内容" />
          </el-form-item>
        </el-col> -->
        <el-col :span="12">
          <el-form-item label="计量单位：">
            <el-input v-model="value.unit" :disabled="isEdit && !copy && isVerify" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="虚拟销量：" prop="virtualSale">
            <el-input v-model="value.virtualSale" v-enter-number :disabled="(isEdit && !copy && isVerify && isPublish) || !isAuditStatus" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="商品重量：">
            <el-input v-model="value.weight" style="width: 300px" :disabled="isEdit && !copy && isVerify" />
            <span style="margin-left: 20px">克</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="商品介绍：">
            <tinymce v-model="value.description" :width="800" :height="300" />
          </el-form-item>
        </el-col>
      </el-row>

      <div style="text-align: center">
        <el-button type="primary" size="medium" @click="handleNext('productInfoForm')">下一步，填写商品促销</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import Tinymce from '@/components/Tinymce'
import { mapState } from 'vuex'
import { getStorage } from '@/utils/storage'
export default {
  name: 'ProductInfoDetail',
  components: { Tinymce },
  props: {
    value: {
      type: Object,
      default: null
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    copy: {
      type: Boolean,
      default: false
    },
    isPublish: {
      type: Boolean,
      default: false
    },
    isVerify: {
      type: Boolean,
      default: false
    },
    isAuditStatus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    var validatorNum = (rule, value, callback) => {
      if (value <= 0) {
        callback(new Error('数值不能为0'))
      } else {
        callback()
      }
    }
    return {
      hasEditCreated: false,
      // 选中商品分类的值
      selectProductCateValue: [],
      productCateOptions: [],
      brandOptions: [],
      rules: {
        name: [
          { required: true, message: '请输入商品名称', trigger: 'blur' },
          {
            min: 2,
            max: 140,
            message: '长度在 2 到 140 个字符',
            trigger: 'blur'
          }
        ],

        // showIcon: [{ required: true, message: '请选择展示图标', trigger: 'blur' }],
        price: [
          { required: true, message: '请输入商品售价', trigger: 'blur' },
          {
            pattern: /^([0-9]*)+(.[0-9]{1,2})?$/,
            message: '请输入正确的价格格式，限2位小数',
            trigger: 'blur'
          }
          // { validator: validatorNum, trigger: 'blur' }
        ],
        originalPrice: [
          { required: true, message: '请输入市场价', trigger: 'blur' },
          {
            pattern: /^([0-9]*)+(.[0-9]{1,2})?$/,
            message: '请输入正确的价格格式，限2位小数',
            trigger: 'blur'
          },
          { validator: validatorNum, trigger: 'blur' }
        ],
        subTitle: [{ required: true, message: '请输入商品副标题', trigger: 'blur' }],
        productCategoryId: [{ required: true, message: '请选择商品分类', trigger: 'blur' }],
        brandId: [{ required: true, message: '请选择商品品牌', trigger: 'blur' }],
        description: [{ required: true, message: '请输入商品介绍', trigger: 'blur' }],
        type: [{ required: true, message: '请选择商品类型', trigger: 'blur' }]
      },
      userShopProductTypeOptions: [],
      isHasProductCate: true
    }
  },
  created() {
    if (this.value.tempId) {
      this.getProductCateList()
    }
    this.shopProductTypeOptions.forEach(el => {
      if (el.dictLabel === '普通商品') {
        this.value.type = el.value
      }
    })
    if (this.isManageMentRole) {
      this.userShopProductTypeOptions = this.shopProductTypeOptions
    } else {
      this.shopProductTypeOptions.forEach(el => {
        if (el.dictLabel === '普通商品') {
          this.userShopProductTypeOptions.push(el)
        }
      })
    }
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    // 商品的编号
    productId() {
      return this.value.id
    },
    ...mapState({
      isManageMentRole: state => state.user.userRole
    }),
    shopProductTypeOptions() {
      if (this.$store.state.dictionary.shopProductType.length === 0) {
        this.$store.dispatch('change_shopProductType')
      }
      return this.$store.state.dictionary.shopProductType
    }
  },
  // eslint-disable-next-line vue/order-in-components
  watch: {
    value(newValue) {
      // if (!this.isEdit) return
      // if (this.hasEditCreated) return
      // if (newValue.id === undefined || newValue.id == null || newValue.id === 0) return
      this.handleEditCreated()
      this.getProductCateList(newValue.productCategoryId)
      console.log(newValue.productCategoryId, '11122')
      // this.getProductCate(this.selectProductCateValue[1], this.getCateNameById(newValue.productCategoryId))
    },
    // productId(newValue) {
    //
    // },
    selectProductCateValue() {}
  },
  methods: {
    // 获取分类关联品牌
    getProductCate(id, cateNameById) {
      if (cateNameById) {
        this.isHasProductCate = true
        this.$api.product.getProductCate(id).then(res => {
          this.brandOptions = []

          if (res.data.brandIds.length > 0) {
            this.value.brandId = res.data.brandIds[0].brandId
            this.value.brandName = res.data.brandIds[0].brandName
          }
          res.data.brandIds.forEach(item => {
            this.brandOptions.push({
              label: item.brandName,
              value: item.brandId
            })
          })
        })
      } else {
        this.isHasProductCate = false
        this.value.brandId = ''
      }
    },
    // 处理编辑逻辑
    handleEditCreated() {
      if (this.value.productCategoryId != null) {
        // console.log('selectProductCateValue1:', this.selectProductCateValue)
        this.selectProductCateValue = []
        this.selectProductCateValue.push(this.value.cateParentId)
        this.selectProductCateValue.push(this.value.productCategoryId)
        console.log('selectProductCateValue2:', this.selectProductCateValue)
      }
      this.hasEditCreated = true
    },
    getProductCateList(id) {
      let params = {}
      if (getStorage('admin')) {
        //4：只查印博会的分类
        params = { use: 4 }
      } else {
        params = { use: 2 }
      }

      this.$api.product.fetchListWithChildren(params).then(response => {
        const list = response.data
        this.productCateOptions = []
        let parentId = ''
        for (let i = 0; i < list.length; i++) {
          const children = []
          if (list[i].children != null && list[i].children.length > 0) {
            for (let j = 0; j < list[i].children.length; j++) {
              if (id === list[i].children[j].id) {
                parentId = list[i].id
              }
              children.push({
                label: list[i].children[j].name,
                value: list[i].children[j].id
              })
            }
          }
          this.productCateOptions.push({
            label: list[i].name,
            value: list[i].id,
            children: children
          })
        }
        if (id) {
          this.selectProductCateValue = [parentId, id]
        }

        this.getProductCate(id, this.getCateNameById(id))
      })
    },
    getBrandList() {
      this.$api.product.brandList({ pageNum: 1, pageSize: 100 }).then(response => {
        this.brandOptions = []
        const brandList = response.listData
        for (let i = 0; i < brandList.length; i++) {
          this.brandOptions.push({
            label: brandList[i].name,
            value: brandList[i].id
          })
        }
      })
    },
    getCateNameById(id) {
      let name = ''
      console.log(this.productCateOptions, '大家萨法尔减肥')
      for (let i = 0; i < this.productCateOptions.length; i++) {
        for (let j = 0; j < this.productCateOptions[i].children.length; j++) {
          if (this.productCateOptions[i].children[j].value && this.productCateOptions[i].children[j].value === id) {
            name = this.productCateOptions[i].children[j].label
            return name
          }
        }
      }
      return name
    },
    handleNext(formName) {
      if (this.value.beBuy) {
        if (!this.isHasProductCate && this.isPublish) {
          this.$message({
            message: '该商品分类可能被删除，请下架重新编辑添加！',
            type: 'error',
            duration: 1000
          })
          return false
        }
        if (!this.isHasProductCate) {
          this.$message({
            message: '请选择商品分类！',
            type: 'error',
            duration: 1000
          })
          return false
        }
      }
      this.$refs[formName].validate(valid => {
        if (valid) {
          console.log(this.value)
          this.$emit('nextStep')
        } else {
          this.$message({
            message: '数据录入缺失或不准确',
            type: 'error',
            duration: 1000
          })
          return false
        }
      })
    },
    handleBrandChange(val) {
      this.value.brandId = val
      let brandName = ''
      for (let i = 0; i < this.brandOptions.length; i++) {
        if (this.brandOptions[i].value === val) {
          brandName = this.brandOptions[i].label
          break
        }
      }
      this.value.brandName = brandName
    },
    handleCateChange() {
      this.value.brandId = ''
      this.value.productCategoryId = this.selectProductCateValue[1]
      this.value.productCategoryName = this.getCateNameById(this.value.productCategoryId)
      this.getProductCate(this.selectProductCateValue[1], this.getCateNameById(this.selectProductCateValue[1]))
    }
  }
}
</script>

<style scoped></style>

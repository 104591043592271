<template>
  <div style="margin-top: 50px">
    <el-form ref="productRelationForm" :model="value" label-width="120px" style="width: 70%; margin-left: 15%" size="small">
      <el-form-item label="关联专题：">
        <el-transfer v-model="selectSubject" style="display: inline-block" filterable :filter-method="filterMethod" filter-placeholder="请输入专题名称" :titles="subjectTitles" :data="subjectList" />
      </el-form-item>
      <el-form-item label="关联优选：">
        <el-transfer v-model="selectPrefrenceArea" style="display: inline-block" filterable :filter-method="filterMethod" filter-placeholder="请输入优选名称" :titles="prefrenceAreaTitles" :data="prefrenceAreaList" />
      </el-form-item>
      <div style="text-align: center">
        <el-button size="medium" @click="handlePrev">上一步，填写商品属性</el-button>
        <el-button type="primary" size="medium" :disabled="(isEdit && !copy && isVerify && isPublish) || !isAuditStatus" @click="handleFinishCommit">完成，提交商品</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { getStorage } from '@/utils/storage'
export default {
  name: 'ProductRelationDetail',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    value: Object,
    isEdit: {
      type: Boolean,
      default: false
    },
    copy: {
      type: Boolean,
      default: false
    },
    isPublish: {
      type: Boolean,
      default: false
    },
    isVerify: {
      type: Boolean,
      default: false
    },
    isAuditStatus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      shopInfo: {},
      // 所有专题列表
      subjectList: [],
      // 专题左右标题
      subjectTitles: ['待选择', '已选择'],
      // 所有专题列表
      prefrenceAreaList: [],
      // 专题左右标题
      prefrenceAreaTitles: ['待选择', '已选择']
    }
  },
  computed: {
    // 选中的专题
    selectSubject: {
      get: function () {
        const subjects = []
        if (this.value.subjectProductRelationList == null || this.value.subjectProductRelationList.length <= 0) {
          return subjects
        }
        for (let i = 0; i < this.value.subjectProductRelationList.length; i++) {
          subjects.push(this.value.subjectProductRelationList[i].subjectId)
        }
        return subjects
      },
      set: function (newValue) {
        this.value.subjectProductRelationList = []
        for (let i = 0; i < newValue.length; i++) {
          this.value.subjectProductRelationList.push({
            subjectId: newValue[i]
          })
        }
      }
    },
    // 选中的优选
    selectPrefrenceArea: {
      get: function () {
        const prefrenceAreas = []
        if (this.value.prefrenceAreaProductRelationList == null || this.value.prefrenceAreaProductRelationList.length <= 0) {
          return prefrenceAreas
        }
        for (let i = 0; i < this.value.prefrenceAreaProductRelationList.length; i++) {
          prefrenceAreas.push(this.value.prefrenceAreaProductRelationList[i].prefrenceAreaId)
        }
        return prefrenceAreas
      },
      set: function (newValue) {
        this.value.prefrenceAreaProductRelationList = []
        for (let i = 0; i < newValue.length; i++) {
          this.value.prefrenceAreaProductRelationList.push({
            prefrenceAreaId: newValue[i]
          })
        }
      }
    }
  },
  created() {
    let shopInfo = getStorage('shopInfo')
    console.log(555, shopInfo)
    this.shopInfo = JSON.parse(shopInfo || '{}')
    this.getSubjectList()
    this.getPrefrenceAreaList()
  },
  methods: {
    filterMethod(query, item) {
      return item.label.indexOf(query) > -1
    },
    getSubjectList() {
      this.$api.product.subjectListAll({ use: 2 }).then(response => {
        let arr = response.listData.map(t => {
          return { label: t.title, key: t.id }
        })

        if (String(this.shopInfo.self) !== '1') {
          let idx = arr.findIndex(t => t.key === 84)

          arr.splice(idx, 1)
          console.log(idx, arr)
        }
        console.log(String(this.shopInfo.self))
        this.subjectList = [].concat(arr)
      })
    },
    getPrefrenceAreaList() {
      this.$api.product.prefrenceList().then(response => {
        const list = response.listData
        for (let i = 0; i < list.length; i++) {
          this.prefrenceAreaList.push({
            label: list[i].name,
            key: list[i].id
          })
        }
      })
    },
    handlePrev() {
      this.$emit('prevStep')
    },
    handleFinishCommit() {
      this.$emit('finishCommit', this.isEdit)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-transfer__buttons {
  padding: 0px 28px;
}
::v-deep.el-transfer-panel {
  width: 300px;
}
</style>

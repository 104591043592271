<template>
  <div class="app-container">
    <el-card class="header-card">
      <el-form :inline="true" :model="formData" class="form-inline">
        <el-row>
          <el-col :span="6">
            <el-form-item label="门店名称">
              <el-input v-model="formData.storeName" placeholder="请输入门店名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号">
              <el-input v-model="formData.phone" placeholder="请输入手机号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否联系">
              <el-select v-model="formData.isContact" placeholder="请选择" clearable>
                <el-option v-for="(item, index) in isContactOptions" :key="index" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="search-area-btn">
        <div class="left"></div>
        <div class="center">
          <el-button :loading="loading" type="primary" @click="handleSearch"> 查询 </el-button>
        </div>
      </div>
    </el-card>
    <el-card class="table-container">
      <el-table ref="table" :data="tableData" style="width: 100%" border :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
        <el-table-column type="index" fixed="left" label="序号" width="100" align="center" />
        <el-table-column label="门店名称" prop="storeName" width="200" align="center" />
        <el-table-column label="名称" prop="username" width="160" align="center" />
        <el-table-column label="联系方式" prop="phone" width="140" align="center" />
        <el-table-column label="门店属性" prop="storeProperty" width="140" align="center" />
        <el-table-column label="所在地区" prop="area" width="250" align="center" />
        <el-table-column label="到展时间" prop="arrivalTime" width="140" align="center" />
        <el-table-column label="是否联系" prop="isContact" width="140" align="center">
          <template slot-scope="scope">
            {{ scope.row.isContact === 1 ? '已联系' : '未联系' }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime" width="140" align="center" />
        <el-table-column label="操作" align="center" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button v-if="String(scope.row.isContact) === '0'" type="primary" @click.stop="handleContact(scope.row)">已联系</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination :current-page="pages.page" :page-sizes="pages.pageSize" :page-size="pages.size" :total="pages.total" :layout="'total, sizes, prev, pager, next, jumper'" @size-change="pageSizeChange" @current-change="pageNumberChange" />
      </div>
    </el-card>
  </div>
</template>
<script>
import pagesMixin from '@/mixin/pages-mixin'
const initSearchParams = {
  storeName: '',
  phone: '',
  isContact: ''
}
export default {
  name: '',
  components: {},
  mixins: [pagesMixin],
  filters: {},
  data() {
    return {
      loading: false,
      formData: {
        ...initSearchParams
      },
      dateValue: [],
      tableData: [],
      isContactOptions: [
        { label: '已联系', value: 1 },
        { label: '未联系', value: 0 }
      ]
    }
  },
  computed: {},
  created() {
    this.getList()
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    dateChange(date) {
      if (date) {
        this.formData.startTime = date[0]
        this.formData.endTime = date[1]
      } else {
        this.formData.startTime = ''
        this.formData.endTime = ''
      }
    },
    getList() {
      const param = {
        pageNumber: this.pages.page,
        pageSize: this.pages.size,
        ...this.formData
      }
      this.$api.storeAudit.registerSelect(param).then(res => {
        if (res.code === 200) {
          this.tableData = res.data.list
          this.pages.total = Number(res.data.total || 0)
        }
      })
    },
    handleContact(item) {
      this.$confirm(`确定门店：${item.storeName} 已经联系?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.storeAudit.registerContact({ id: item.id }).then(res => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '操作成功'
            })
            this.getList()
          }
        })
      })
    },
    handleSearch() {
      this.pages.page = 1
      this.getList()
    },
    handleReset() {
      this.formData = {
        ...initSearchParams
      }
      this.pages.page = 1
      this.dateValue = []
      this.getList()
    },
    handleAdd() {},
    dialogClose() {},
    refreshData() {}
  }
}
</script>
<style lang="scss"></style>

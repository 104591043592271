import Vue from 'vue'
import App from './App.vue'
import * as dialogDrag from '@/directive/dialog'
import ElementUI from 'element-ui'
import api from '@/api/index.js'
import echarts from 'echarts'

import '@/assets/styles/index.css'
import '@/styles/index.scss'
import '@/assets/menuFont/iconfont.css'
import 'element-ui/lib/theme-chalk/index.css'
import has from '@/directive/directive.js'

import router from './router'
import store from './store'
import '@/permission'

import utils from 'xinyin_library'
import xinyin_ui from 'xinyin_ui'

Vue.use(xinyin_ui)
Vue.use(ElementUI, { size: 'mini' })

Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$utils = utils
Vue.prototype.$echarts = echarts

new Vue({
  router,
  store,
  has,
  dialogDrag,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="pieBox">
    <div class="titleBox">
      <h1>印前、印中、印后企业数量</h1>
      <div class="dropBox">
        <el-dropdown  @command="handleCommand">
          <span class="el-dropdown-link">
            {{timeInit}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in timeSelectArry" :key="index" :command="item.command">{{item.title}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div ref="chart" style="width: 800px;height: 600px;margin: 0 auto;"></div>
  </div>
</template>

<script>
let Echarts = require('echarts/lib/echarts'); //基础实例 注意不要使用import
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      chart: null,
      pieList:[],
      timeSelectArry:[{
        title: '近7日',
        command: 'a'
      },{
        title: '近1月',
        command: 'b'
      },{
        title: '近6个月',
        command: 'c'
      },{
        title: '近1年',
        command: 'd'
      }],
      timeInit: '近7日',
      startTime: '',
      endTime: '',
      seriesArray: []
    }
  },
  mounted() {
    const now = new Date()
    this.startTime = this.$utils.parseDate(now.getTime() - 3600 * 1000 * 24 * 6, '{y}-{m}-{d}')
    this.endTime = this.$utils.parseDate(now, '{y}-{m}-{d}')
    this.handleQuantityReq()
  },
  methods:{
    pieEcharts(data){
      this.chart = Echarts.init(this.$refs.chart);
      var option = {
        tooltip : {
          trigger: 'axis',
          formatter(params){
            for(let x in params){
              return "数量：" + params[x].data
            }

          }
        },
        xAxis: {
          type: 'category',
          data: ['印前', '印中', '印后']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: data,
            type: 'bar',
            barWidth : 30,
            // showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          }
        ]
      }
      this.chart.setOption(option)
    },
    handleCommand(commond){
      switch (commond) {
        case 'a' :
          this.timeInit =  this.timeSelectArry[0].title
          var now = new Date()
          this.startTime = this.$utils.parseDate(now.getTime() - 3600 * 1000 * 24 * 6, '{y}-{m}-{d}')
          this.endTime = this.$utils.parseDate(now, '{y}-{m}-{d}')
          break;
        case 'b' :
          this.timeInit =  this.timeSelectArry[1].title
          var now2 = new Date()
          this.startTime = this.$utils.parseDate(new Date().setMonth(now2.getMonth() - 1), '{y}-{m}-{d}')
          this.endTime = this.$utils.parseDate(now2, '{y}-{m}-{d}')
          break;
        case 'c' :
          this.timeInit =  this.timeSelectArry[2].title
          var now3 = new Date()
          this.startTime = this.$utils.parseDate(new Date().setMonth(now3.getMonth() - 6), '{y}-{m}-{d}')
          this.endTime = this.$utils.parseDate(now3, '{y}-{m}-{d}')
          break;
        case 'd' :
          this.timeInit =  this.timeSelectArry[3].title
          var now4 = new Date()
          this.startTime = this.$utils.parseDate(new Date().setMonth(now4.getMonth() - 12), '{y}-{m}-{d}')
          this.endTime = this.$utils.parseDate(now4, '{y}-{m}-{d}')
          break;
      }
      this.handleQuantityReq()
    },
   handleQuantityReq(){
     let params = {
       startTime:this.startTime,
       endTime: this.endTime
     }
     this.$api.home.shopProportionReq(params).then(res => {
       if(res.code === 200){
         this.seriesArray[0] = res.data.yqNumber
         this.seriesArray[1] = res.data.yzNumber
         this.seriesArray[2] = res.data.yhNumber
         console.log(res,'啊方法是啊哈')
       }
       this.pieEcharts(this.seriesArray)
     })
   }
  }
}
</script>

<style lang="scss" scoped>
.pieBox{
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  width: 49.5%;
  margin-bottom: 13px;
  padding: 18px 15px;
}
.titleBox{
  display: flex;
  justify-content: space-between;
  h1{
    margin: 0 30px 0 0;
    color: #333;
    font-size: 16px;
  }
}
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
<template>
  <el-dialog title="选择用户" :visible="userDialogVisible" width="50%" @close="closeDialog">
    <el-form :inline="true" :model="keyword" class="demo-form-inline">
      <el-form-item label="用户名/手机号">
        <el-input v-model="keyword.query" size="mini" />
      </el-form-item>
      <el-form-item class="search-area-btn">
        <el-button type="primary" size="mini" @click="handleSearch">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table ref="Table" :data="dialogData.list" row-key="id" border @selection-change="handleDialogSelectionChange">
      <el-table-column type="selection" width="60" align="center" />
      <el-table-column prop="userId" label="ID" width="100" />
      <el-table-column label="用户名称" align="center">
        <template slot-scope="scope">{{ scope.row.userName }}</template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination background layout="prev, pager, next" :current-page.sync="dialogData.listQuery.pageNumber" :page-size="dialogData.listQuery.pageSize" :page-sizes="[5, 10, 20, 30, 50, 100]" :total="dialogData.total" @size-change="handleDialogSizeChange" @current-change="handleDialogCurrentChange" />
    </div>
    <div style="clear: both"></div>
    <div>
      <el-form ref="formData" :inline="true" :model="formData" :rules="formRules" class="demo-form-inline">
        <el-form-item label="赠送数量" prop="amount">
          <el-input v-model="formData.amount" size="mini" />
        </el-form-item>
        <el-form-item class="search-area-btn">
          <el-button type="primary" size="mini" @click="save('formData')">保存</el-button>
          <el-button type="primary" size="mini" @click="closeDialog">关闭</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import { getPlatform } from '@/utils/storage'

const initSearchParams = {
  query: ''
}
export default {
  props: {
    userDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogData: {
        list: [],
        total: null,
        multipleSelection: null,
        listQuery: {
          pageNumber: 1,
          pageSize: 5
        }
      },
      keyword: {
        ...initSearchParams
      },
      formData: {
        amount: ''
      },
      formRules: {
        amount: [
          { required: true, message: '不能为空', trigger: 'blur' },
          {
            pattern: /(^[1-9]\d*$)/,
            message: '输入的格式有误',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created() {
    // this.getDialogList()
  },
  methods: {
    closeDialog() {
      this.$emit('closeUserDialog')
    },
    handleSelectSearch() {
      this.getDialogList()
    },
    handleDialogSizeChange(val) {
      this.dialogData.listQuery.pageNumber = 1
      this.dialogData.listQuery.pageSize = val
      this.getDialogList()
    },
    handleDialogCurrentChange(val) {
      this.dialogData.listQuery.pageNumber = val
      this.getDialogList()
    },
    getDialogList() {
      const params = {
        ...this.dialogData.listQuery,
        platformId: getPlatform(),
        ...this.keyword
      }
      this.$api.dynamicAccount.getUserList(params).then(response => {
        this.dialogData.list = response.data.list
        this.dialogData.total = response.data.total
      })
    },
    handleSearch() {
      this.dialogData.listQuery.pageNumber = 1
      this.getDialogList()
    },
    handleDialogSelectionChange(val) {
      if (val.length > 1) {
        this.$refs.Table.clearSelection()
        this.$refs.Table.toggleRowSelection(val.pop())
      }
      this.dialogData.multipleSelection = val
    },
    save(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.dialogData.multipleSelection && this.dialogData.multipleSelection.length) {
            const data = {
              userId: this.dialogData.multipleSelection[0].userId,
              userName: this.dialogData.multipleSelection[0].userName,
              presenterCount: this.formData.amount
            }
            this.$emit('addUserInfo', data)
          } else {
            this.$message({
              type: 'warning',
              message: '请选择用户'
            })
          }
        } else {
          console.log('error submit!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>

import { render, staticRenderFns } from "./productAttrList.vue?vue&type=template&id=16e4085a&scoped=true&"
import script from "./productAttrList.vue?vue&type=script&lang=js&"
export * from "./productAttrList.vue?vue&type=script&lang=js&"
import style0 from "./productAttrList.vue?vue&type=style&index=0&id=16e4085a&rel=stylesheet%2Fscss&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16e4085a",
  null
  
)

export default component.exports
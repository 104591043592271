import request from '@/api/request'

const callMe = {
  // 获取联系我们数据的列表
  getCallMeList(params) {
    return request({
      url: '/expositionShop/mongoContact/list',
      method: 'get',
      params: params
    })
  }
}

export default callMe

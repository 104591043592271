<template>
  <div>
    <div v-if="shopInfo.id" class="container">
      <div v-if="!edit" class="info-show">
        <div v-if="shopInfo.id" class="main-intro">
          <div class="intro-info">
            <el-image class="logo-img" :src="shopInfo.companyLogo" lazy>
              <div slot="error" class="image-slot">
                <img :src="defaultIcon" />
              </div>
            </el-image>
            <div class="shop-title">{{ shopInfo.shopName }}</div>
            <div class="shop-profile" :class="showALLCoant ? 'shop-profile-all' : ''" v-html="shopInfo.companyProfile"></div>
            <div class="moreBtn" v-if="keepCoant" @click="showALLCoant ? (showALLCoant = false) : (showALLCoant = true)">
              <span>
                {{ showALLCoant ? '收起' : '查看全文' }}
              </span>
            </div>
          </div>
          <div class="intro-img">
            <div class="img">
              <video v-if="this.fileList[0] && this.fileList[0].type === 'mp4'" controls="controls" :src="shopInfo.mainUrl"></video>
              <img v-else :src="shopInfo.mainUrl" />
            </div>
          </div>
        </div>
        <div v-if="shopInfo.id" class="honor-wrap">
          <div class="title">企业宣传资料</div>
          <div class="pic-list">
            <template v-if="shopList.length > 0">
              <div v-for="(item, index) in shopList" :key="index" class="list-item">
                <video v-if="item.url.indexOf('mp4') > 0" controls="controls" :src="item.url"></video>
                <img v-else :src="item.url" />
              </div>
            </template>
            <template v-else>
              <el-empty description="暂无数据"></el-empty>
            </template>
          </div>
        </div>
        <div v-if="shopInfo.id" class="honor-wrap">
          <div class="title">企业荣誉展示</div>
          <div class="pic-list">
            <template v-if="honorList.length > 0">
              <div class="list-item list-item-honor">
                <el-carousel :interval="4000" type="card" height="360">
                  <el-carousel-item v-for="(item, index) in honorList" :key="index">
                    <img class="honorImg" :src="item.url" />
                  </el-carousel-item>
                </el-carousel>
              </div>
            </template>
            <template v-else>
              <el-empty description="暂无数据"></el-empty>
            </template>
          </div>
        </div>

        <div v-if="!shopInfo.id">欢迎访问印博会商户平台！</div>
      </div>
      <div v-else class="info-show">
        <el-form ref="formData" :inline="true" :rules="rules" :model="shopInfo" label-width="150px">
          <el-row>
            <el-form-item label="公司介绍（中文）" prop="companyProfile">
              <tinymce v-model="shopInfo.companyProfile" :width="800" :height="300" />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="公司介绍（英文）">
              <tinymce v-model="shopInfo.companyProfileEn" :width="800" :height="300" />
            </el-form-item>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="企业主图" prop="fileList">
                <upload-file :limit="1" :file-size="fileSizePicture2" :file-type="fileTypePicture2" :btn-text="btnTextPicture2" :list-type="listTypePicture2" :init-file-list="fileList" :loading-text="loadingTextPicture" :name="'file-name'" @removeFile="removeFile" @uploadSuccess="uploadSuccess" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="LOGO" prop="logoList">
                <upload-file :limit="1" :file-size="fileSizePicture2" :file-type="fileTypePicture2" :btn-text="btnTextPicture2" :list-type="listTypePicture2" :init-file-list="logoList" :loading-text="loadingTextPicture" :name="'file-name'" @removeFile="removeLogoFile" @uploadSuccess="uploadLogoSuccess" />
              </el-form-item>
            </el-col>
          </el-row>
          <br />
          <br />
          <el-row>
            <el-form-item label="企业宣传资料" prop="shopList">
              <upload-file :limit="5" :file-size="fileSizePicture" :file-type="fileTypePicture" :btn-text="btnTextPicture" :list-type="listTypePicture" :init-file-list="shopList" :loading-text="loadingTextPicture" :name="'file-name'" @removeFile="removeShopFile" @uploadSuccess="uploadShopSuccess" />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="企业荣誉" prop="honorList">
              <upload-file :limit="6" :file-size="fileSizePicture2" :file-type="fileTypePicture2" :btn-text="btnTextPicture2" :list-type="listTypePicture2" :init-file-list="honorList" :loading-text="loadingTextPicture" :name="'file-name'" @removeFile="removePicFile" @uploadSuccess="uploadPicSuccess" />
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="企业展示" prop="companyShow">
              <tinymce v-model="shopInfo.companyShow" :width="800" :height="300" />
            </el-form-item>
          </el-row>
        </el-form>
      </div>
      <div v-if="shopInfo.id" class="right-flex">
        <el-button v-if="!edit" type="primary" @click="editClick(true)">更新资料</el-button>
        <el-button v-if="edit" type="primary" :underline="false" @click="saveClick()">保存</el-button>
        <el-button v-if="edit" :underline="false" @click="editClick(false)">取消</el-button>
      </div>
    </div>
    <statisticFlow v-else></statisticFlow>
  </div>
</template>

<script>
import statisticFlow from './components/index.vue'
import Tinymce from '@/components/Tinymce'
// eslint-disable-next-line no-unused-vars
import { getStorage, setStorage } from '@/utils/storage'
import defaultIcon from '@/assets/img/common/default_icon.png'

export default {
  name: 'Home',
  components: {
    Tinymce,
    statisticFlow
  },
  data() {
    return {
      shopInfo: {},
      defaultIcon: defaultIcon,
      fileSizePicture: [500, 'M'],
      fileSizePicture2: [500, 'kb'],
      btnTextPicture: ['选取文件', '上传'],
      btnTextPicture2: ['选取图片', '上传'],
      fileTypePicture: ['mp4', 'jpg', 'png', 'jpeg'],
      fileTypePicture2: ['jpg', 'png', 'jpeg'],
      listTypePicture: 'text',
      listTypePicture2: 'picture-card',
      loadingTextPicture: '上传文件中...',
      fileList: [],
      honorList: [],
      logoList: [],
      shopList: [],
      edit: false,
      rules: {
        companyProfile: [{ required: true, message: '请填写公司简介', trigger: 'blur' }],
        logoList: [{ required: true, message: '请选择公司LOGO', trigger: 'blur' }],
        fileList: [{ required: true, message: '请选择企业主图', trigger: 'blur' }],
        shopList: [{ required: true, message: '请选择企业宣传资料', trigger: 'blur' }]
      },
      // 是否显示全部介绍
      showALLCoant: false,
      // 是否显示展开按钮
      keepCoant: true
    }
  },
  computed: {},
  created() {
    this.getShop()
  },
  methods: {
    removeFile(index) {
      this.fileList.splice(index, 1)
    },
    removePicFile(index) {
      this.honorList.splice(index, 1)
    },
    removeLogoFile(index) {
      this.logoList.splice(index, 1)
    },
    removeShopFile(index) {
      this.shopList.splice(index, 1)
    },
    editClick(edit) {
      this.edit = edit
    },
    getShop() {
      let shopInfo = getStorage('shopInfo')
      if (shopInfo) {
        this.shopInfo = JSON.parse(shopInfo)
        shopInfo = JSON.parse(shopInfo)
        this.fileList = []
        this.honorList = []
        if (shopInfo.id) {
          this.$api.home.getShopById(shopInfo.id).then(res => {
            if (res.code === 200) {
              this.shopInfo = res.data
              delete this.shopInfo.updatedTime
              const fileType = res.data.mainUrl.substring(res.data.mainUrl.lastIndexOf('.') + 1).toLowerCase()
              const LogofileType = res.data.mainUrl.substring(res.data.companyLogo.lastIndexOf('.') + 1).toLowerCase()
              this.fileList.push({
                name: 'file.' + fileType,
                url: res.data.mainUrl,
                type: fileType
              })
              this.logoList.push({
                name: 'file.' + LogofileType,
                url: res.data.companyLogo,
                type: LogofileType
              })
              if (res.data.companyHonor) {
                this.honorList = res.data.companyHonor.split(',')
                this.honorList = this.honorList.map((item, index) => {
                  const temp = {
                    uid: index,
                    name: '荣誉证书' + (index + 1),
                    url: item
                  }
                  return temp
                })
              }
              if (res.data.shopPics) {
                this.shopList = res.data.shopPics.split(',')
                this.shopList = this.shopList.map((item, index) => {
                  const type = item.indexOf('mp4') > 0 ? 'mp4' : 'png'

                  const temp = {
                    uid: index,
                    name: '宣传资料' + (index + 1) + '.' + type,
                    url: item,
                    type: type
                  }
                  return temp
                })
              }
            }
          })
        }
      }
    },
    uploadSuccess(fileList) {
      this.fileList = fileList
    },
    uploadPicSuccess(fileList) {
      this.honorList = fileList
    },
    uploadLogoSuccess(fileList) {
      this.logoList = fileList
    },
    uploadShopSuccess(fileList) {
      this.shopList = fileList
    },
    saveClick() {
      if (this.shopInfo.companyProfile === '') {
        this.$message.error('请填写公司简介')
        return
      }
      if (this.fileList.length === 0) {
        this.$message.error('请选择企业主图')
        return
      }
      if (this.logoList.length === 0) {
        this.$message.error('请选择企业LOGO')
        return
      }
      if (this.shopList.length === 0) {
        this.$message.error('请选择企业宣传资料')
        return
      }

      this.shopInfo.mainUrl = this.fileList[0].url
      let honorStr = []
      this.honorList = this.honorList.map(item => {
        honorStr.push(item.url)
      })
      this.shopInfo.companyHonor = honorStr ? honorStr.join(',') : ''
      this.shopInfo.companyLogo = this.logoList[0].url
      let shopStr = []
      this.shopList = this.shopList.map(item => {
        shopStr.push(item.url)
      })
      this.shopInfo.shopPics = shopStr.join(',')

      // this.$refs.formData.validate(valid => {
      //   if (valid) {

      this.$api.home.shopUpdate(this.shopInfo).then(res => {
        if (res.code === 200) {
          this.$message.success('资料提交成功，请等待管理员审核')
          this.edit = false
          this.getShop()
        }
      })
      //   }
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  display: -webkit-flex;
  padding: 40px 40px 0 40px;
  .info-show {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    width: 92%;
  }
  .right-flex {
    position: relative;
    min-width: 150px;
    ::v-deep.el-button {
      margin-left: 10px;
    }
  }
  .main-intro {
    text-align: center;
    width: 75%;
    margin-left: 5%;
    font-weight: 700px;
    font-size: 20px;
    color: #333;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    position: relative;

    ::v-deep.intro-info {
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      justify-content: flex-start;
      flex: 0.5;
      background: rgba(228, 232, 236, 0.2);
      padding: 20px;
      max-width: 50%;
      .logo-img {
        max-height: 80px;
        width: 100%;
        text-align: left;
        margin-bottom: 25px;

        .el-image__placeholder {
          width: auto;
        }
        img {
          object-fit: cover;
          width: auto;
          height: 100%;
        }
      }
      .shop-title {
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 30px;
        text-align: center;
      }
      .shop-profile {
        font-size: 16px;
        text-indent: 40px;
        padding: 0 30px;
        text-align: left;
        height: 90px;
        overflow: hidden;
        p {
          line-height: 2.35;
          letter-spacing: 1.3px;
          text-indent: 36px;
        }
      }
      .shop-profile-all {
        height: auto;
      }
      .moreBtn {
        width: 100%;
        text-align: right;
        color: #000;
        margin-top: 20px;
        font-size: 15px;
        span {
          display: inline-block;
          width: 80px;
          cursor: pointer;
          border: 1px solid rgba(25, 0, 0, 0.5);
          padding: 4px 10px;
          text-align: center;
          min-width: 100px;
          border-radius: 30px;
        }
      }
    }
    .intro-img {
      flex: 0.5;
      display: flex;
      display: -webkit-flex;
      justify-content: flex-start;
      align-items: center;
      .img {
        img {
          width: 520px;
          height: 343px;
          object-fit: cover;
        }
        video {
          width: 520px;
          height: 343px;
          object-fit: fill;
        }
      }
      .upload-wrap {
        width: 520px;
        height: 343px;
        padding: 40px;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #eee;
        .name {
          font-size: 14px;
        }
      }
      .btom-fun {
        align-self: flex-end;
        margin-left: 10px;
        .btns {
          display: flex;
          display: -webkit-flex;
          min-width: 110px;
        }
        ::v-deep.el-link {
          font-size: 16px;
          margin-right: 20px;
        }
      }
    }
  }

  .honor-wrap {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    .title {
      font-weight: 700;
      font-size: 22px;
      color: #0080ff;
      text-align: center;
      margin-top: 50px;
    }
    .upload-wrap {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      min-height: 300px;
    }
    .pic-list {
      display: flex;
      display: -webkit-flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 80px;
      .list-item {
        position: relative;
        margin-right: 50px;
        img {
          width: 220px;
          height: 200px;
          object-fit: fill;
        }
        video {
          width: 220px;
          height: 200px;
          object-fit: fill;
        }
      }
      .list-item-honor {
        width: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>

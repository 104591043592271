<template>
  <div class="detail-container">
    <div>
      <el-steps :active="formatStepStatus(order.status)" finish-status="success" align-center>
        <el-step title="提交订单" :description="formatTime(order.createTime)" />
        <el-step title="支付订单" :description="formatTime(order.paymentTime)" />
        <el-step title="平台发货" :description="formatTime(order.deliveryTime)" />
        <el-step title="确认收货" :description="formatTime(order.receiveTime)" />
        <el-step title="完成评价" :description="formatTime(order.commentTime)" />
      </el-steps>
    </div>
    <el-card shadow="never" style="margin-top: 15px">
      <div class="operate-container">
        <i class="el-icon-warning color-danger" style="margin-left: 20px"></i>
        <span class="color-danger"> 当前订单状态：{{ $utils.handleOptions(order.status, orderStatusOptions) }} </span>
        <div class="operate-button-container">
          <el-button size="mini" @click="issuePrinting">出库打印</el-button>
        </div>
        <div class="operate-button-container">
          <div v-show="order.status === 0">
            <el-button size="mini" @click="showUpdateReceiverDialog"> 修改收货人信息 </el-button>
            <!-- <el-button size="mini">修改商品信息</el-button> -->
            <el-button size="mini" @click="showUpdateMoneyDialog"> 修改费用信息 </el-button>
            <!-- <el-button size="mini" @click="showMessageDialog">发送站内信</el-button> -->
            <el-button size="mini" @click="showCloseOrderDialog"> 关闭订单 </el-button>
            <el-button size="mini" @click="showMarkOrderDialog"> 备注订单 </el-button>
          </div>
        </div>
        <div v-show="order.status === 1" class="operate-button-container">
          <el-button size="mini" @click="showUpdateReceiverDialog"> 修改收货人信息 </el-button>
          <el-button size="mini" @click="showMessageDialog"> 发送站内信 </el-button>
          <!-- <el-button size="mini">取消订单</el-button> -->
          <el-button size="mini" @click="showMarkOrderDialog"> 备注订单 </el-button>
        </div>
        <div v-show="order.status === 2" class="operate-button-container">
          <el-button size="mini" @click="showLogisticsDialog"> 订单跟踪 </el-button>
          <el-button size="mini" @click="showMessageDialog"> 发送站内信 </el-button>
          <el-button size="mini" @click="showMarkOrderDialog"> 备注订单 </el-button>
          <el-button size="mini" @click="confirmReceipt">确认收货</el-button>
        </div>
        <div v-show="order.status === 3" class="operate-button-container">
          <el-button size="mini" @click="showLogisticsDialog"> 订单跟踪 </el-button>
          <el-button size="mini" @click="showMessageDialog"> 发送站内信 </el-button>
          <el-button size="mini" @click="showMarkOrderDialog"> 备注订单 </el-button>
        </div>
        <div v-show="order.status === 4" class="operate-button-container">
          <el-button size="mini" @click="handleDeleteOrder">删除订单</el-button>
          <el-button size="mini" @click="showMarkOrderDialog"> 备注订单 </el-button>
        </div>
      </div>
      <div style="margin-top: 20px">
        <svg-icon icon-class="marker" style="color: #606266" />
        <span class="font-small">基本信息</span>
      </div>
      <div class="table-layout">
        <el-row>
          <el-col :span="4" class="table-cell-title">订单编号</el-col>
          <el-col :span="4" class="table-cell-title">发货单流水号</el-col>
          <el-col :span="4" class="table-cell-title">用户账号</el-col>
          <el-col :span="4" class="table-cell-title">支付方式</el-col>
          <el-col :span="4" class="table-cell-title">订单来源</el-col>
          <el-col :span="4" class="table-cell-title">订单类型</el-col>
        </el-row>
        <el-row>
          <el-col :span="4" class="table-cell">{{ order.orderSn }}</el-col>
          <el-col :span="4" class="table-cell">暂无</el-col>
          <el-col :span="4" class="table-cell">
            {{ order.memberUsername }}
          </el-col>
          <el-col :span="4" class="table-cell">
            {{ order.payType | formatPayType }}
          </el-col>
          <el-col :span="4" class="table-cell">
            {{ $utils.handleOptions(order.sourceType, orderSourceOptions) }}
          </el-col>
          <el-col :span="4" class="table-cell">
            {{ $utils.handleOptions(order.orderType, orderTypeOptions) }}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4" class="table-cell-title">物流公司</el-col>
          <el-col :span="4" class="table-cell-title">物流单号</el-col>
          <el-col :span="4" class="table-cell-title">自动确认收货时间</el-col>
          <!-- <el-col :span="4" class="table-cell-title">订单可得优币</el-col>
          <el-col :span="4" class="table-cell-title">订单可得成长值</el-col> -->
          <el-col :span="4" class="table-cell-title">活动信息</el-col>
          <el-col :span="4" class="table-cell-title">订单备注</el-col>
          <el-col :span="4" class="table-cell-title">配送方式</el-col>
        </el-row>
        <el-row>
          <el-col :span="4" class="table-cell">
            {{ order.deliveryCompany | formatNull }}
          </el-col>
          <el-col :span="4" class="table-cell">
            {{ order.deliverySn | formatNull }}
          </el-col>
          <el-col :span="4" class="table-cell"> {{ order.autoConfirmDay }}天 </el-col>
          <!-- <el-col :span="4" class="table-cell">{{order.integration}}</el-col>
          <el-col :span="4" class="table-cell">{{order.growth}}</el-col> -->
          <el-col :span="4" class="table-cell">
            <el-popover placement="top-start" title="活动信息" width="200" trigger="hover" :content="order.promotionInfo">
              <span slot="reference">
                {{ order.promotionInfo | formatLongText }}
              </span>
            </el-popover>
          </el-col>
          <el-col :span="4" class="table-cell">
            {{ order.note || '暂无' }}
          </el-col>
          <el-col :span="4" class="table-cell">
            {{ order.distributionType === 0 ? '快递' : '自提' }}
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" class="table-cell-title">客服备注</el-col>
        </el-row>
        <el-row>
          <el-col :span="24" class="table-cell table-cell-item">
            {{ order.custNote || '暂无' }}
          </el-col>
        </el-row>
        <!-- <el-row>
          <el-col :span="4" class="table-cell-title">订单备注</el-col>
        </el-row>
        <el-row>
          <el-col :span="4" class="table-cell">{{ order.note || '暂无' }}</el-col>
        </el-row> -->
      </div>
      <div style="margin-top: 20px">
        <svg-icon icon-class="marker" style="color: #606266" />
        <span class="font-small">收货人信息</span>
      </div>
      <div class="table-layout">
        <el-row>
          <el-col :span="4" class="table-cell-title">收货人</el-col>
          <el-col :span="4" class="table-cell-title">手机号码</el-col>
          <el-col :span="4" class="table-cell-title">邮政编码</el-col>
          <el-col :span="12" class="table-cell-title">收货地址</el-col>
        </el-row>
        <el-row>
          <el-col :span="4" class="table-cell">{{ order.receiverName || '' }}</el-col>
          <el-col :span="4" class="table-cell">
            {{ order.receiverPhone || '' }}
          </el-col>
          <el-col :span="4" class="table-cell">
            {{ order.receiverPostCode || '' }}
          </el-col>
          <el-col :span="12" class="table-cell">
            {{ order | formatAddress }}
          </el-col>
        </el-row>
      </div>
      <div style="margin-top: 20px">
        <svg-icon icon-class="marker" style="color: #606266" />
        <span class="font-small">商品信息</span>
      </div>
      <el-table ref="orderItemTable" :data="order.orderItemList" style="width: 100%; margin-top: 20px" border>
        <el-table-column label="商品图片" width="120" align="center">
          <template slot-scope="scope">
            <img :src="scope.row.productPic" style="height: 80px" />
          </template>
        </el-table-column>
        <el-table-column label="商品名称" width="250" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.productName }}</p>
            <p>品牌：{{ scope.row.productBrand }}</p>
          </template>
        </el-table-column>
        <el-table-column label="所属店铺" align="center">
          <template>
            <p>{{ order.shopName }}</p>
          </template>
        </el-table-column>
        <el-table-column label="价格/货号" width="120" align="center">
          <template slot-scope="scope">
            <p>价格：￥{{ scope.row.productPrice }}</p>
            <p>货号：{{ scope.row.productSn }}</p>
          </template>
        </el-table-column>
        <el-table-column label="属性" width="120" align="center">
          <template slot-scope="scope">
            {{ scope.row.productAttr }}
          </template>
        </el-table-column>
        <el-table-column label="数量" width="120" align="center">
          <template slot-scope="scope">
            {{ scope.row.productQuantity }}
          </template>
        </el-table-column>
        <el-table-column label="小计" width="120" align="center">
          <template slot-scope="scope"> ￥{{ (scope.row.productPrice * scope.row.productQuantity).toFixed(2) }} </template>
        </el-table-column>
        <el-table-column label="物流公司" width="120" align="center">
          <template slot-scope="scope">
            <el-select v-model="scope.row.deliveryCompany" filterable remote reserve-keyword clearable placeholder="物流公司" :remote-method="remoteMethod" :loading="companyloading" size="small" @clear="getCompanyList" @change="changeDeliveryCompany($event, scope.row)">
              <el-option v-for="item in companyOptions" :key="item.expId" :label="item.expName" :value="item.expName" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="物流单号" width="230" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.deliverySn" type="text" />
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="openViews(scope.row)"> 预览 </el-button>
            <el-button v-if="order.status === 2 || order.status === 3" size="mini" @click="handleViewLogistics(scope.$index, scope.row)"> 订单跟踪 </el-button>
            <el-button v-if="order.status > 1" size="mini" @click="updateDelivery(scope.$index, scope.row)"> 修改物流 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="deliver-area">
        <el-button v-if="order.status === 1 || order.status === 10" size="mini" @click="separateDelivery"> 确认发货 </el-button>
      </div>
      <!--      <div style="float: right; margin: 20px">-->
      <!--        合计：<span class="color-danger">￥{{ summation }}</span>-->
      <!--      </div>-->
      <div style="margin-top: 60px">
        <svg-icon icon-class="marker" style="color: #606266" />
        <span class="font-small">费用信息</span>
      </div>
      <div class="table-layout">
        <el-row>
          <el-col :span="6" class="table-cell-title">商品合计</el-col>
          <el-col :span="6" class="table-cell-title">运费</el-col>
          <el-col :span="6" class="table-cell-title">补差金额</el-col>
          <el-col :span="6" class="table-cell-title">优惠券</el-col>
        </el-row>
        <el-row>
          <el-col :span="6" class="table-cell"> ￥{{ summation.toFixed(2) }} </el-col>
          <!-- freightAmount -->
          <el-col :span="6" class="table-cell"> ￥{{ order.freightAmount }} </el-col>
          <el-col :span="6" class="table-cell">
            <span class="color-danger"> ￥{{ order.spreadPice ? order.spreadPice : 0 }} </span>
          </el-col>
          <el-col :span="6" class="table-cell"> -￥{{ order.couponAmount }} </el-col>
        </el-row>
        <el-row>
          <el-col :span="6" class="table-cell-title">积分抵扣</el-col>
          <el-col :span="6" class="table-cell-title">活动优惠</el-col>
          <el-col :span="6" class="table-cell-title">折扣金额</el-col>
          <el-col :span="6" class="table-cell-title">订单总金额</el-col>
        </el-row>
        <el-row>
          <el-col :span="6" class="table-cell"> -￥{{ order.integrationAmount }} </el-col>
          <el-col :span="6" class="table-cell"> -￥{{ order.promotionAmount }} </el-col>
          <el-col :span="6" class="table-cell"> -￥{{ order.discountAmount }} </el-col>
          <el-col :span="6" class="table-cell">
            <span class="color-danger">￥{{ order.totalAmount }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6" class="table-cell-title">实付款金额</el-col>
        </el-row>
        <el-row>
          <el-col :span="6" class="table-cell">
            <!-- order.payAmount+order.freightAmount-order.discountAmount -->
            <span class="color-danger">￥{{ order.payAmount }}</span>
          </el-col>
        </el-row>
      </div>
      <div style="margin-top: 20px">
        <svg-icon icon-class="marker" style="color: #606266" />
        <span class="font-small">操作信息</span>
      </div>
      <el-table ref="orderHistoryTable" style="margin-top: 20px; width: 100%" :data="order.historyList" border>
        <el-table-column label="操作者" width="120" align="center">
          <template slot-scope="scope">
            {{ scope.row.operateMan }}
          </template>
        </el-table-column>
        <el-table-column label="操作时间" width="160" align="center">
          <template slot-scope="scope">
            {{ formatTime(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column label="订单状态" width="120" align="center">
          <template slot-scope="scope">
            {{ $utils.handleOptions(scope.row.orderStatus, orderOperationStatusOptions) }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="付款状态"  width="120" align="center">
          <template slot-scope="scope">
            {{scope.row.orderStatus | formatPayStatus}}
          </template>
        </el-table-column>
        <el-table-column label="发货状态"  width="120" align="center">
          <template slot-scope="scope">
            {{scope.row.orderStatus | formatDeliverStatus}}
          </template>
        </el-table-column> -->
        <el-table-column label="备注" align="center">
          <template slot-scope="scope">
            {{ scope.row.note | formatNote }}
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="修改收货人信息" :visible.sync="receiverDialogVisible" width="40%">
      <el-form ref="receiverInfoForm" :model="receiverInfo" label-width="150px">
        <el-form-item label="收货人姓名：">
          <el-input v-model="receiverInfo.receiverName" style="width: 200px" />
        </el-form-item>
        <el-form-item label="手机号码：">
          <el-input v-model="receiverInfo.receiverPhone" style="width: 200px" />
        </el-form-item>
        <el-form-item label="邮政编码：">
          <el-input v-model="receiverInfo.receiverPostCode" style="width: 200px" />
        </el-form-item>
        <el-form-item label="所在区域：">
          <v-distpicker :province="receiverInfo.receiverProvince" :city="receiverInfo.receiverCity" :area="receiverInfo.receiverRegion" @selected="onSelectRegion" />
        </el-form-item>
        <el-form-item label="详细地址：">
          <el-input v-model="receiverInfo.receiverDetailAddress" type="textarea" rows="3" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="receiverDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleUpdateReceiverInfo"> 确 定 </el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改费用信息" :visible.sync="moneyDialogVisible" width="40%">
      <div class="table-layout">
        <el-row>
          <el-col :span="6" class="table-cell-title">商品合计</el-col>
          <el-col :span="6" class="table-cell-title">补差金额</el-col>
          <el-col :span="6" class="table-cell-title">优惠券</el-col>
          <el-col :span="6" class="table-cell-title">积分抵扣</el-col>
        </el-row>
        <el-row>
          <el-col :span="6" class="table-cell"> ￥{{ order.totalAmount }} </el-col>
          <el-col :span="6" class="table-cell">
            <el-input v-model="moneyInfo.spreadPice" size="mini" @input.native="inputhandlePrice">
              <template slot="prepend">￥</template>
            </el-input>
          </el-col>
          <el-col :span="6" class="table-cell"> -￥{{ order.couponAmount }} </el-col>
          <el-col :span="6" class="table-cell"> -￥{{ order.integrationAmount }} </el-col>
        </el-row>
        <el-row>
          <el-col :span="6" class="table-cell-title">活动优惠</el-col>
          <el-col v-if="false" :span="6" class="table-cell-title"> 折扣金额 </el-col>
          <el-col :span="6" class="table-cell-title">订单总金额</el-col>
          <!-- <el-col :span="6" class="table-cell-title">应付款金额</el-col> -->
        </el-row>
        <el-row>
          <el-col :span="6" class="table-cell"> -￥{{ order.promotionAmount }} </el-col>
          <el-col v-if="false" :span="6" class="table-cell">
            <el-input v-model="moneyInfo.discountAmount" size="mini" @input.native="inputhandle">
              <template slot="prepend">-￥</template>
            </el-input>
          </el-col>
          <el-col :span="6" class="table-cell">
            <span class="color-danger">￥{{ totalAmount }}</span>
          </el-col>
          <!-- <el-col :span="6" class="table-cell">
            <span class="color-danger">￥{{payAmount}}</span>
          </el-col> -->
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="RemoveUpdateMoneyInfo">取 消</el-button>
        <el-button type="primary" @click="handleUpdateMoneyInfo"> 确 定 </el-button>
      </span>
    </el-dialog>
    <el-dialog title="发送站内信" :visible.sync="messageDialogVisible" width="40%">
      <el-form ref="receiverInfoForm" :model="message" label-width="150px">
        <el-form-item label="标题：">
          <el-input v-model="message.title" style="width: 200px" />
        </el-form-item>
        <el-form-item label="内容：">
          <el-input v-model="message.content" type="textarea" rows="3" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="messageDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSendMessage">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="关闭订单" :visible.sync="closeDialogVisible" width="40%">
      <el-form :model="closeInfo" label-width="150px">
        <el-form-item label="操作备注：">
          <el-input v-model="closeInfo.note" type="textarea" rows="3" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleCloseOrder">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="备注订单" :visible.sync="markOrderDialogVisible" width="40%">
      <el-form :model="markInfo" label-width="150px">
        <el-form-item label="操作备注：">
          <el-input v-model="markInfo.custNote" type="textarea" rows="3" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="markOrderDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleMarkOrder">确 定</el-button>
      </span>
    </el-dialog>
    <logistics-dialog v-if="logisticsDialogVisible" :logistics-list="logisticsList" :logistics-dialog-visible="logisticsDialogVisible" @closeLogisticsDialogVisible="closeLogisticsDialogVisible" />
    <el-dialog v-if="shopDetailVisible" title="商品详情预览" class="shop-dialog" :visible.sync="shopDetailVisible" :before-close="shopDialogClose">
      <iframe width="100%" frameborder="0" class="shop-iframe" :src="shopDetailUrl"></iframe>
    </el-dialog>

    <div v-show="false" ref="printDetail" style="width: 900px; margin: 0 auto">
      <table border="1" width="650px" height="220" style="border-collapse: collapse; border: solid 1px" bordercolor="#000000">
        <tr align="center">
          <td width="360px">商品名称</td>
          <td width="240px">属性</td>
          <td width="60px">数量</td>
          <td width="90px">单价(元)</td>
          <td width="90px">总价(元)</td>
        </tr>
        <tr v-for="(item, index) in productPrintData" :key="index" align="center">
          <td width="360px">{{ item.goodsName }}</td>
          <!-- item.goodsName-->
          <td width="180px">{{ item.goodsAttribute }}</td>
          <td width="60px">{{ item.number }}</td>
          <td width="60px">{{ item.salesUnitPrice.toFixed(2) }}</td>
          <td width="60px">{{ item.salesAmount.toFixed(2) }}</td>
        </tr>
        <tr align="center">
          <td width="360px">运费(元)</td>
          <td width="120px">{{ Number(order.freightAmount).toFixed(2) }}</td>
          <td width="90px">优惠减免(元)</td>
          <td width="120px" colspan="2">
            {{ Number(order.spreadPice * -1 + order.couponAmount + order.integrationAmount + order.discountAmount).toFixed(2) }}
          </td>
        </tr>
        <tr align="center" height="60">
          <td width="360px">支付总额(元)</td>
          <td width="180px" colspan="4">
            {{ Number(order.payAmount).toFixed(2) }}
          </td>
        </tr>
        <tr height="60">
          <td colspan="6">
            <p style="color: #f00; font-size: 16px">客户签字:</p>
            <p style="color: #f00; font-size: 12px; padding-top: 28px; text-align: center">（请确认商品齐全和外观完好）</p>
          </td>
        </tr>
      </table>

      <!--      <div style="border-top:2px dashed #333;height: 2px;width: 600px;margin: 20px 0;"></div>-->
    </div>
  </div>
</template>
<script>
import LogisticsDialog from '@/views/oms/order/components/logisticsDialog'
import { getLodop } from '@/utils/LodopFuncs'
import VDistpicker from 'v-distpicker'
import { mapState } from 'vuex'
import store from '@/store'
const defaultReceiverInfo = {
  orderId: null,
  receiverName: null,
  receiverPhone: null,
  receiverPostCode: null,
  receiverDetailAddress: null,
  receiverProvince: null,
  receiverCity: null,
  receiverRegion: null,
  status: null
}
export default {
  name: 'OrderDetail',
  components: { VDistpicker, LogisticsDialog },
  data() {
    return {
      shopDetailVisible: false,
      id: null,
      order: {},
      receiverDialogVisible: false,
      receiverInfo: Object.assign({}, defaultReceiverInfo),
      moneyDialogVisible: false,
      moneyInfo: {
        orderId: null,
        freightAmount: 0,
        discountAmount: 0,
        status: null
      },
      messageDialogVisible: false,
      message: { title: null, content: null },
      closeDialogVisible: false,
      closeInfo: { note: null, id: null },
      markOrderDialogVisible: false,
      markInfo: { custNote: null },
      logisticsDialogVisible: false,
      companyOptions: [],
      companyloading: false,
      summation: 0,
      productPrintData: [],
      saleTotalMoney: 0,
      saleTotalNumber: 0
    }
  },
  computed: {
    totalAmount() {
      console.log(isNaN(Number(this.order.totalAmount) + Number(this.moneyInfo.spreadPice)), '西欧嘻嘻嘻')
      if (!this.moneyInfo.spreadPice || this.moneyInfo.spreadPice === 0) {
        return this.order.totalAmount
      }
      if (isNaN(Number(this.order.totalAmount) + Number(this.moneyInfo.spreadPice))) {
        return this.order.totalAmount
      }
      console.log('this.moneyInfo.spreadPice:', this.moneyInfo.spreadPice)
      let s = Number(this.order.totalAmount) + Number(this.moneyInfo.spreadPice)
      s = s.toFixed(2)
      return s + ''
    },
    payAmount() {
      if (!this.moneyInfo.freightAmount || this.moneyInfo.freightAmount === 0) {
        const s = Number(this.order.payAmount - this.moneyInfo.discountAmount).toFixed(2)
        return s + ''
      }
      let s = Number(this.order.payAmount) + Number(this.moneyInfo.freightAmount) - Number(this.moneyInfo.discountAmount) - Number(this.order.freightAmount)
      s = s.toFixed(2)
      return s + ''
    },
    ...mapState({
      orderStatusOptions: state => state.dictionary.orderStatus,
      orderOperationStatusOptions: state => state.dictionary.orderOperationStatus,
      orderTypeOptions: state => state.dictionary.orderType,
      orderSourceOptions: state => state.dictionary.orderSource
    })
  },
  created() {
    this.getDictionaryList()
    this.getCompanyList()
    this.id = this.list = this.$route.query.id
    this.getOrderData()
  },
  filters: {
    formatNull(value) {
      if (value === undefined || value === null || value === '') {
        return '暂无'
      } else {
        return value
      }
    },
    formatLongText(value) {
      if (value === undefined || value === null || value === '') {
        return '暂无'
      } else if (value.length > 8) {
        return value.substr(0, 8) + '...'
      } else {
        return value
      }
    },
    formatPayType(value) {
      if (value === 1) {
        return '支付宝'
      } else if (value === 2) {
        return '微信'
      } else if (value === 3) {
        return '余额'
      } else {
        return '未支付'
      }
    },
    formatAddress(order) {
      let str = order.receiverProvince || ''
      if (order.receiverCity != null) {
        str += '  ' + (order.receiverCity || '')
      }
      str += '  ' + (order.receiverRegion || '')
      str += '  ' + (order.receiverDetailAddress || '')
      return str
    },
    formatPayStatus(value) {
      if (value === 0) {
        return '未支付'
      } else if (value === 4) {
        return '已退款'
      } else {
        return '已支付'
      }
    },
    formatDeliverStatus(value) {
      if (value === 0 || value === 1) {
        return '未发货'
      } else {
        return '已发货'
      }
    },
    formatProductAttr(value) {
      if (value == null) {
        return ''
      } else {
        const attr = JSON.parse(value)
        let result = ''
        for (let i = 0; i < attr.length; i++) {
          result += attr[i].key
          result += ':'
          result += attr[i].value
          result += ';'
        }
        return result
      }
    },
    formatNote(val) {
      if (!val || val === null) return ''
      if (typeof val === 'string') {
        try {
          const v = JSON.parse(val)
          if (v.length) {
            let str = ''
            v.forEach(item => {
              str += `${item.key}:${item.value || '暂无'} `
            })
            return str
          }
        } catch (e) {
          return val
        }
      }
    }
  },
  methods: {
    shopDialogClose() {
      this.shopDetailVisible = false
    },
    openViews(row) {
      this.$api.product.skuPerView(row.productId).then(res => {
        if (res.data) {
          this.shopDetailUrl = process.env.VUE_APP_WEBSITE_URL + `productDetail.html#/goodsdetail?id=${row.productId}&shopId=${this.order.shopId}`
          this.shopDetailVisible = true
        } else {
          this.$message({
            message: '该商品暂不允许预览',
            type: 'warning',
            duration: 1000
          })
        }
      })
    },
    async getDictionaryList() {
      await store.dispatch('change_orderstatus')
      await store.dispatch('change_orderOperationStatus')
      await store.dispatch('change_ordertype')
      await store.dispatch('change_ordersource')
    },
    getOrderData() {
      this.$api.order.getOrderDetail(this.id).then(response => {
        let b
        this.productPrintData = []
        this.saleTotalNumber = 0
        this.saleTotalMoney = 0
        this.summation = 0
        this.order = response.data
        this.order.orderItemList.forEach(table => {
          this.summation += table.productPrice * table.productQuantity
          const goodsAttributeList = table.productAttr ? JSON.parse(table.productAttr) : []
          if (goodsAttributeList && goodsAttributeList.length) {
            b = ''
            goodsAttributeList.forEach(el => {
              if (el.value) {
                const a = el.value
                b = b + a + ';'
              }
            })
            table.productAttr = b
          }
          this.saleTotalMoney = Number(this.summation.toFixed(2))
          this.saleTotalNumber = Number(this.saleTotalNumber) + Number(table.productQuantity)
          this.productPrintData.push({
            goodsName: table.productName,
            goodsAttribute: table.productAttr,
            unitName: table.unitName,
            salesUnitPrice: table.productPrice,
            number: table.productQuantity,
            salesAmount: table.productPrice * table.productQuantity
          })
        })
      })
    },
    remoteMethod(query) {
      if (query !== '') {
        this.companyloading = true
        this.$api.order.getCompanyList({ keyWord: query }).then(res => {
          this.companyloading = false
          this.companyOptions = res.data
        })
      } else {
        this.companyOptions = []
      }
    },
    getCompanyList() {
      this.companyloading = true
      this.$api.order.getCompanyList().then(res => {
        this.companyOptions = res.data.splice(0, 40)
        this.companyloading = false
      })
    },
    changeDeliveryCompany(event, row) {
      if (event === '新印配送') {
        this.order.orderItemList.forEach(el => {
          if (el.id === row.id) {
            row.deliverySn = this.order.orderSn
          }
        })
        this.order.orderItemList = JSON.parse(JSON.stringify(this.order.orderItemList))
      } else {
        this.order.orderItemList.forEach(el => {
          if (el.id === row.id) {
            row.deliverySn = ''
          }
        })
        this.order.orderItemList = JSON.parse(JSON.stringify(this.order.orderItemList))
      }
    },
    inputhandle({ target }) {
      target.value = target.value.replace(/[^0-9.]/g, '')
    },
    inputhandlePrice({ target }) {
      if (Number(target.value) + this.order.totalAmount < 0) {
        this.$message('补差金额不能超过总金额！')
        target.value = ''
        this.moneyInfo.spreadPice = ''
      }
      console.log(Number(target.value) + this.order.totalAmount < 0, Number(target.value), this.order.totalAmount, 'hahah')
    },
    onSelectRegion(data) {
      this.receiverInfo.receiverProvince = data.province.value
      this.receiverInfo.receiverCity = data.city.value
      this.receiverInfo.receiverRegion = data.area.value
    },
    formatTime(time) {
      if (time == null || time === '') {
        return ''
      }
      const date = new Date(time)
      return this.$utils.parseDate(date)
    },
    formatStepStatus(value) {
      if (value === 1 || value === 10) {
        // 待发货
        return 2
      } else if (value === 2) {
        // 已发货
        return 3
      } else if (value === 3 || value === 9) {
        // 已完成
        return 4
      } else {
        // 待付款、已关闭、无限订单
        return 1
      }
    },
    showUpdateReceiverDialog() {
      this.receiverDialogVisible = true
      this.receiverInfo = {
        orderId: this.order.id,
        receiverName: this.order.receiverName,
        receiverPhone: this.order.receiverPhone,
        receiverPostCode: this.order.receiverPostCode,
        receiverDetailAddress: this.order.receiverDetailAddress,
        receiverProvince: this.order.receiverProvince,
        receiverCity: this.order.receiverCity,
        receiverRegion: this.order.receiverRegion,
        status: this.order.status
      }
    },
    handleUpdateReceiverInfo() {
      this.$confirm('是否要修改收货信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.order.updateReceiverInfo(this.receiverInfo).then(() => {
          this.receiverDialogVisible = false
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
          this.getOrderData()
        })
      })
    },
    showUpdateMoneyDialog() {
      this.moneyDialogVisible = true
      this.moneyInfo.orderId = this.order.id
      // this.moneyInfo.spreadPice =0;
      this.moneyInfo.discountAmount = this.order.discountAmount
      this.moneyInfo.status = this.order.status
    },
    RemoveUpdateMoneyInfo() {
      this.moneyDialogVisible = false
      this.moneyInfo.spreadPice = ''
    },
    handleUpdateMoneyInfo() {
      this.$confirm('是否要修改费用信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (!this.moneyInfo.spreadPice) this.moneyInfo.spreadPice = 0
        // if(!this.moneyInfo.discountAmount) this.moneyInfo.discountAmount = 0
        this.moneyInfo.discountAmount = 0
        this.$api.order.updateMoneyInfo(this.moneyInfo).then(() => {
          this.moneyDialogVisible = false
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
          this.getOrderData()
        })
      })
    },
    showMessageDialog() {
      this.messageDialogVisible = true
      this.message.title = null
      this.message.content = null
    },
    handleSendMessage() {
      this.$confirm('是否要发送站内信?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.messageDialogVisible = false
        this.$message({
          type: 'success',
          message: '发送成功!'
        })
      })
    },
    showCloseOrderDialog() {
      this.closeDialogVisible = true
      this.closeInfo.note = null
      this.closeInfo.id = this.id
    },
    handleCloseOrder() {
      this.$confirm('是否要关闭?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          ids: [this.closeInfo.id],
          note: this.closeInfo.note
        }
        this.$api.order.closeOrder(data).then(() => {
          this.closeDialogVisible = false
          this.$message({
            type: 'success',
            message: '订单关闭成功!'
          })
          this.getOrderData()
        })
      })
    },
    showMarkOrderDialog() {
      this.markOrderDialogVisible = true
      this.markInfo.id = this.id
      this.closeOrder.note = null
    },
    // 确认收货
    confirmReceipt() {
      this.$confirm('是否要确认收货?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const data = {
          id: this.id
        }
        this.$api.order.confirmReceipt(data).then(res => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '确认收货成功'
            })
            this.getOrderData()
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      })
    },
    issuePrinting() {
      const receiverCity = this.order.receiverCity ? this.order.receiverCity : ''
      const date = new Date(this.order.createTime)
      this.order.createTime = this.$utils.parseDate(date)
      var address = ''
      address = this.order.receiverProvince + receiverCity + this.order.receiverRegion + this.order.receiverDetailAddress
      const LODOP = getLodop()
      LODOP.PRINT_INITA(-1, -24, 1336, 753, '出库单')
      LODOP.ADD_PRINT_TEXT(18, 302, 199, 54, '出库单')
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 28)
      LODOP.SET_PRINT_STYLEA(0, 'FontColor', '#FF0000')
      LODOP.ADD_PRINT_TEXT(18, 458, 257, 53, '（派送返单）')
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 28)
      LODOP.SET_PRINT_STYLEA(0, 'FontColor', '#FF0000')
      LODOP.ADD_PRINT_TEXT(95, 90, 334, 28, `订单编号：${this.order.orderSn}\r\n`)
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 12)
      LODOP.ADD_PRINT_TEXT(129, 90, 326, 30, `下单日期：${this.order.createTime}\r\n`)
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 12)
      LODOP.ADD_PRINT_TEXT(164, 90, 318, 28, `收 货 人：${this.order.receiverName}\r\n`)
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 12)
      LODOP.ADD_PRINT_TEXT(197, 90, 347, 27, `手机号码：${this.order.receiverPhone}\r\n`)
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 12)
      LODOP.ADD_PRINT_TEXT(227, 90, '100%', 30, `收货地址：${address}\r\n`)
      LODOP.SET_PRINT_STYLEA(0, 'FontSize', 12)
      // LODOP.ADD_PRINT_TEXT(88, 326, 452, 55, '新印科技商品出库单')
      // LODOP.SET_PRINT_STYLEA(0, 'FontSize', 27)
      // LODOP.SET_PRINT_STYLEA(0, 'Alignment', 2)
      LODOP.ADD_PRINT_HTM(267, 78, '74.484%', '60%', this.$refs.printDetail.innerHTML)
      // LODOP.PRINT_DESIGN()
      LODOP.PREVIEW()
      // LODOP.PRINTA()
    },
    handleMarkOrder() {
      this.$confirm('是否要备注订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: this.markInfo.id,
          custNote: this.markInfo.custNote,
          status: this.order.status
        }
        this.$api.order.updateOrderNote(data).then(() => {
          this.markOrderDialogVisible = false
          this.$message({
            type: 'success',
            message: '订单备注成功!'
          })
          this.getOrderData()
        })
      })
    },
    handleDeleteOrder() {
      this.$confirm('是否要进行该删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          ids: [this.id]
        }
        this.$api.order.deleteOrder(data).then(() => {
          this.$message({
            message: '删除成功！',
            type: 'success',
            duration: 1000
          })
          this.$router.back()
        })
      })
    },
    showLogisticsDialog() {
      this.logisticsDialogVisible = true
    },
    separateDelivery() {
      const data = []
      if (this.order.orderItemList && this.order.orderItemList.length) {
        this.order.orderItemList.forEach(el => {
          data.push({
            deliveryCompany: el.deliveryCompany,
            deliverySn: el.deliverySn,
            orderItemId: el.id,
            orderId: this.id
          })
        })
        this.$api.order.separateDelivery(data).then(res => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '发货成功'
            })
            this.getOrderData()
          }
        })
      }
    },
    closeLogisticsDialogVisible(val) {
      this.logisticsDialogVisible = val
    },
    // 订单跟踪
    handleViewLogistics(index, row) {
      this.$api.order.listByFeign({ logisticalNumber: row.deliverySn }).then(res => {
        if (res.code === 200) {
          const list = res.data.list.map(item => {
            return {
              id: item.id,
              name: item.logisticalContext,
              time: item.logisticalTime ? this.$utils.parseDate(new Date(item.logisticalTime)) : ''
            }
          })
          this.logisticsList = list
          this.logisticsDialogVisible = true
        }
      })
    },
    updateDelivery(index, row) {
      const data = {
        deliveryCompany: row.deliveryCompany,
        deliverySn: row.deliverySn,
        orderItemId: row.id
      }
      this.$api.order.updateDelivery(data).then(() => {
        this.$message({
          type: 'success',
          message: '修改物流信息成功'
        })
        this.getOrderData()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.detail-container {
  width: 80%;
  padding: 20px 20px 20px 20px;
  margin: 20px auto;
}

.operate-container {
  background: #f2f6fc;
  height: 80px;
  margin: -20px -20px 0;
  line-height: 80px;
}

.operate-button-container {
  float: right;
  margin-right: 20px;
}

.table-layout {
  margin-top: 20px;
  border-left: 1px solid #dcdfe6;
  border-top: 1px solid #dcdfe6;
}

.table-cell {
  height: 60px;
  line-height: 26px;
  border-right: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  padding: 10px;
  font-size: 14px;
  color: #606266;
  text-align: center;
  overflow: hidden;
}

.table-cell-item {
  height: auto;
  overflow: visible;
}

.table-cell-title {
  border-right: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  padding: 10px;
  background: #f2f6fc;
  text-align: center;
  font-size: 14px;
  color: #303133;
}

.deliver-area {
  margin-top: 20px;
  text-align: right;
}

::v-deep.shop-dialog {
  .el-dialog {
    height: 80vh;
    width: 80vw;
    margin-top: 10vh !important;
  }

  iframe {
    width: 100%;
    height: 90%;
    position: absolute;
    left: 0;
  }
}
</style>

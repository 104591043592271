<template>
  <el-dialog title="选择套餐" append-to-body :visible="selectDialogVisible" width="50%" @close="closeDialog">
    <el-input v-model="dialogData.listQuery.packageName" style="width: 250px; margin-bottom: 20px" size="small" placeholder="套餐名称搜索">
      <el-button slot="append" icon="el-icon-search" @click="handleSelectSearch()" />
    </el-input>
    <el-table ref="Table" :data="dialogData.list" border @selection-change="handleDialogSelectionChange">
      <el-table-column type="selection" width="60" align="center" :selectable="checkboxTable" />
      <el-table-column prop="packageId" label="ID" width="100" />
      <el-table-column label="套餐名称" align="center">
        <template slot-scope="scope">{{ scope.row.packageName }}</template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination background layout="prev, pager, next" :current-page.sync="dialogData.listQuery.pageNumber" :page-size="dialogData.listQuery.pageSize" :page-sizes="[5, 10, 20, 30, 50, 100]" :total="dialogData.total" @size-change="handleDialogSizeChange" @current-change="handleDialogCurrentChange" />
    </div>
    <div style="clear: both"></div>
    <div slot="footer">
      <el-button size="small" @click="closeDialog">取 消</el-button>
      <el-button size="small" type="primary" @click="handleSelectDialogConfirm()">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    selectDialogVisible: {
      type: Boolean,
      default: false
    },
    selectedIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialogData: {
        list: [],
        total: null,
        multipleSelection: null,
        listQuery: {
          packageName: null,
          pageNumber: 1,
          pageSize: 5
        }
      }
    }
  },
  created() {
    this.getDialogList()
  },
  methods: {
    closeDialog() {
      this.$emit('closeProductDialog')
    },
    handleSelectDialogConfirm() {
      this.$emit('addProductList', this.dialogData.multipleSelection)
      this.closeDialog()
    },
    handleSelectSearch() {
      this.getDialogList()
    },
    handleDialogSizeChange(val) {
      this.dialogData.listQuery.pageNumber = 1
      this.dialogData.listQuery.pageSize = val
      this.getDialogList()
    },
    handleDialogCurrentChange(val) {
      this.dialogData.listQuery.pageNumber = val
      this.getDialogList()
    },
    handleDialogSelectionChange(val) {
      if (val.length > 1) {
        this.$refs.Table.clearSelection()
        this.$refs.Table.toggleRowSelection(val.pop())
      }
      this.multipleSelection = val
      this.dialogData.multipleSelection = val
    },
    getDialogList() {
      this.$api.suit.getSuitList(this.dialogData.listQuery).then(response => {
        this.dialogData.list = response.data.list
        this.dialogData.total = response.data.total
      })
    },
    // 禁用某一行
    checkboxTable(row) {
      if (this.selectedIds.includes(row.userId)) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="sass" scoped></style>

<template>
  <product-cate-detail :is-edit="false" />
</template>
<script>
import ProductCateDetail from './components/ProductCateDetail'
export default {
  name: 'AddProductCate',
  components: { ProductCateDetail }
}
</script>
<style></style>

import { render, staticRenderFns } from "./AreaDialog.vue?vue&type=template&id=0d7a9469&scoped=true&"
import script from "./AreaDialog.vue?vue&type=script&lang=js&"
export * from "./AreaDialog.vue?vue&type=script&lang=js&"
import style0 from "./AreaDialog.vue?vue&type=style&index=0&id=0d7a9469&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d7a9469",
  null
  
)

export default component.exports
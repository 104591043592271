<template>
  <div>
    <el-card class="header-card">
      <el-form :inline="true">
        <el-form-item label="账户名">
          <el-select v-model="select.name" clearable filterable placeholder="请选择" size="mini">
            <el-option v-for="item in userArray" :key="item.userId" :label="item.userName" :value="item.userName" />
          </el-select>
        </el-form-item>
        <el-form-item label="店铺名称">
          <el-select v-model="select.shopId" placeholder="请选择" filterable clearable remote reserve-keyword :remote-method="remoteMethod" :loading="loading">
            <el-option v-for="item in productionListOptions" :key="item.id" :label="item.shopName" :value="item.id" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="平台名称">
          <el-select v-model="searchPlatformId" size="mini" placeholder="请选择" filterable>
            <el-option v-for="item in platfromList" :key="item.platformId" :label="item.platformName" :value="item.platformId" />
          </el-select>
        </el-form-item> -->
      </el-form>
      <el-row class="search-area-btn">
        <div class="left"></div>
        <div class="center">
          <el-button type="primary" size="small" :loading="loading" @click="getList"> 查询 </el-button>
          <el-button size="small" :loading="loading" @click="reset"> 重置 </el-button>
        </div>
      </el-row>
      <div class="clearfix">
        <span style="position: relative; bottom: 5px">数据列表</span>
      </div>
      <div>
        <el-table :data="tableData" height="500" :header-cell-style="{ background: '#eef1f6', color: '#606266' }" border v-loading="loading">
          <el-table-column prop="username" label="账户名" align="center" />
          <el-table-column prop="shopName" label="店铺名称" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.shopName.indexOf(',') === -1">
                {{ scope.row.shopName }}
              </span>
              <div v-else>
                <span v-for="(item, index) in scope.row.shopName.split(',')" :key="index">
                  {{ item }}
                  <br />
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="amount" label="账号余额" align="center" />
          <el-table-column prop="settled" label="已结算金额" align="center" />
          <el-table-column prop="toBeSettled" label="待结算金额" align="center" />
          <el-table-column prop="withdraw" label="已提现金额" align="center" />
          <el-table-column prop="unWithdrawNum" label="待提现审核数" align="center" width="140px" />
          <el-table-column label="操作" fixed="right" align="center">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="dialog(scope.row)"> 详情 </el-button>
              <el-button size="mini" type="text" @click="getWithdrawalsRecordList(scope.row.userId, scope.row.username)"> 提现审核 </el-button>
              <!--            <el-button type="primary" size="mini" @click="auditEdit">提款审核</el-button>-->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-container">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[5, 10, 20, 30, 50, 100]" :page-size="currentSize" layout="total, sizes, prev, pager, next, jumper" :total="total" />
      </div>
    </el-card>

    <el-dialog title="动账详情" :visible.sync="dialogVisible" width="1150px">
      <el-card shadow="always" class="cardShow">
        <div class="clearfix">
          <span class="text">信息</span>
        </div>
        <div>
          <el-form :inline="true" :model="edit" label-width="160px" style="margin-top: -10px">
            <el-form-item label="企业/个人名称" v-if="edit.companyType === 0">
              <el-input size="mini" style="width: 170px" v-model="edit.companyName" disabled />
            </el-form-item>
            <el-form-item label="店铺名称" v-if="edit.companyType === 1">
              <el-input size="mini" style="width: 170px" v-model="edit.shopName" disabled />
            </el-form-item>
            <!--            <el-form-item label="创建时间">-->
            <!--              <el-input size="mini" style="width: 170px" v-model="edit.createdTime" disabled></el-input>-->
            <!--            </el-form-item>-->
            <el-form-item label="组织机构代码/身份证">
              <el-input size="mini" style="width: 170px" v-model="edit.companyCode" disabled />
            </el-form-item>
            <el-form-item label="商家类型">
              <el-select v-model="edit.companyType" style="width: 170px" clearable size="mini" disabled>
                <el-option v-for="item in typeArray" :key="item.key" :label="item.value" :value="item.key" />
              </el-select>
            </el-form-item>
            <el-form-item label="账户名">
              <el-input size="mini" style="width: 170px" v-model="edit.userName" disabled />
            </el-form-item>
            <el-form-item label="账户余额">
              <el-input size="mini" style="width: 170px" v-model="edit.amount" disabled>
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
            <el-form-item label="已提现金额">
              <el-input size="mini" style="width: 170px" v-model="edit.withdraw" disabled>
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
      <el-card shadow="always" class="zhong">
        <div class="clearfix">
          <span style="font-size: 16px">筛选条件</span>
          <el-button style="float: right; padding: 3px 0; width: 70px; height: 30px; font-size: 16px" size="mini" type="primary" :loading="editLoading" @click="getByIdAndList"> 查询 </el-button>
        </div>
        <el-form :inline="true">
          <el-form-item label="结算时间">
            <el-date-picker v-model="validityStart" type="daterange" size="mini" range-separator="至" start-placeholder="开始日期" value-format="yyyy-MM-ddTHH:mm:ss" end-placeholder="结束日期" />
            <!--          yyyy-MM-dd'T'HH:mm:ss.SSSZ-->
          </el-form-item>
          <el-form-item label="结算状态">
            <el-select v-model="settlementStatusValue" clearable placeholder="请选择" size="mini">
              <el-option v-for="item in settlementStatus" :key="item.key" :label="item.value" :value="item.key" />
            </el-select>
          </el-form-item>
          <el-form-item label="业务类型">
            <el-select v-model="businessType" clearable size="mini">
              <el-option v-for="item in businessTypeLists" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-form>
      </el-card>

      <el-card shadow="always" class="bottom">
        <div class="clearfix">
          <span style="font-size: 16px">数据列表</span>
        </div>
        <div style="margin-top: 10px; width: 98%">
          <el-card class="box-card">
            <div class="leftAmount">
              <span>合计金额：{{ acount.amount }}元</span>
              <span>已充值金额：{{ acount.recharge }}元</span>
              <span>已支付金额：{{ acount.pay }}元</span>
              <span>已退款金额：{{ acount.refund }}元</span>
              <span>已结算金额：{{ acount.settled }}元</span>
            </div>
            <div class="leftAmount">
              <span></span>
              <span>充值待支付：{{ acount.unRecharge }}元</span>
              <span>待支付金额：{{ acount.unPay }}元</span>
              <span>待退款金额：{{ acount.unRefund }}元</span>
              <span>待结算金额：{{ acount.toBeSettled }}元</span>
              <!--          <span>已结算金额：{{edit.settled}}</span>-->
              <!--          <span>待结算金额：{{edit.toBeSettled}}</span>-->
            </div>
          </el-card>
        </div>

        <el-table :data="editTableData" border max-height="300px" style="width: 98%" v-loading="editLoading" :span-method="arraySpanMethod" class="bottomTable">
          <el-table-column prop="orderSn" label="订单编号" align="center" width="220px" />
          <el-table-column prop="createTime" label="提交时间" align="center" width="185px" />
          <el-table-column prop="orderType" label="业务类型" align="center" width="180px">
            <template slot-scope="scope">
              <template v-for="item in businessTypeLists">
                <template v-if="scope.row.businessType === item.value">
                  <template>{{ item.label }}</template>
                </template>
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="totalAmount" label="订单金额" align="center" />
          <el-table-column prop="sourceType" label="订单来源" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.sourceType | sourceFilter }}</span>
            </template>
          </el-table-column>
          <!--          <el-table-column-->
          <!--            prop="status"-->
          <!--            label="订单状态"-->
          <!--            align="center"-->
          <!--          >-->
          <!--            <template slot-scope="scope">-->
          <!--              <span>{{scope.row.status | statusFilter}}</span>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <el-table-column prop="settlementStatus" label="动账状态" align="center">
            <template slot-scope="scope">
              <span>
                {{ scope.row.settlementStatus | settlementStatusFilter }}
              </span>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination @size-change="editHandlesSizeChange" @current-change="editHandlesCurrentChange" :current-page="editCurrentPage" :page-sizes="[5, 10, 20, 30, 50, 100]" :page-size="editCurrentSize" layout="total, sizes, prev, pager, next, jumper" :total="editTotal" />
        </div>
        <div class="count"></div>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogVisible = false">关 闭</el-button>
        <!--          <el-button size="mini" type="primary" @click="editSave()">保存</el-button>-->
      </span>
    </el-dialog>
    <el-dialog title="提现审核" :visible.sync="recordDialog" width="60%">
      <el-table :data="recordTableData" v-loading="recordLoading" height="500">
        <!--        <el-table-column-->
        <!--          prop="accountNo"-->
        <!--          label="提款账号"-->
        <!--          align="center"-->
        <!--          width="220px"-->
        <!--        />-->
        <el-table-column prop="applicationTime" label="日期" align="center" width="200px" />
        <el-table-column prop="username" label="动账账户" align="center" />
        <el-table-column prop="accountNo" label="提现账户" align="center" />
        <el-table-column prop="amount" label="提现金额" align="center" />
        <el-table-column prop="applicationTime" label="申请时间" align="center" width="220px" />
        <el-table-column prop="transferStatus" label="转账状态" align="center" width="80px">
          <template slot-scope="scope">
            <span>{{ scope.row.transferStatus | settlementStatusFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="transferTime" label="转账时间" align="center" width="220px" />
        <el-table-column fixed="right" label="操作" width="50px">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="dealWith(scope.row.accountId, scope.row.username, scope.row.amount, scope.row.platfromId, scope.row.accountNo, scope.row.accountSeqId)" :disabled="scope.row.transferStatus !== 1"> 处理 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination @size-change="recordHandlesSizeChange" @current-change="recordHandlesCurrentChange" :current-page="recordCurrentPage" :page-sizes="[5, 10, 20, 30, 50, 100]" :page-size="recordCurrentSize" layout="total, sizes, prev, pager, next, jumper" :total="recordTotal" />
      </div>
      <el-dialog title="处理" :visible.sync="dealWithDialog" width="60%" append-to-body>
        <el-form :inline="true" :model="dealWithEdit" label-width="150px">
          <el-form-item label="账户名">
            <el-input style="width: 190px" size="mini" v-model="dealWithEdit.username" disabled />
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input style="width: 190px" size="mini" v-model="dealWithEdit.createTime" disabled />
          </el-form-item>
          <!-- <el-form-item label="商家类型">
            <el-input
              style="width: 190px;"
              size="mini"
              v-model="dealWithEdit.companyType"
              disabled
            />
          </el-form-item> -->
          <el-form-item label="企业/个人名称">
            <el-input style="width: 190px" size="mini" v-model="dealWithEdit.createdBy" disabled />
          </el-form-item>
          <el-form-item label="组织机构代码/身份证">
            <el-input style="width: 190px" size="mini" v-model="dealWithEdit.companyCode" disabled />
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input style="width: 190px" size="mini" v-model="dealWithEdit.mobile" disabled />
          </el-form-item>
          <el-form-item label="账户余额">
            <el-input style="width: 190px" size="mini" v-model="dealWithEdit.amount" disabled>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="申请提现金额">
            <el-input style="width: 190px" size="mini" v-model="dealWithEdit.withdrawalAmount" disabled>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="转入账户类型">
            <el-input style="width: 190px" size="mini" v-model="dealWithEdit.acountType" disabled />
          </el-form-item>
          <el-form-item label="收款户名">
            <el-input style="width: 190px" size="mini" v-model="dealWithEdit.acountName" disabled />
          </el-form-item>
          <el-form-item label="收款账号">
            <el-input style="width: 190px" size="mini" v-model="dealWithEdit.acountNo" disabled />
          </el-form-item>
          <el-form-item v-if="dealWithEdit.acountNo && String(dealWithEdit.acountTypeVal) === '2'" label="收款银行">
            {{ getBankName(dealWithEdit.acountNo) }}
          </el-form-item>
        </el-form>
        <div class="onDialog">
          <!-- v-if="platformId === '' || platformId === 2" -->
          <div>
            <el-button @click="handleDealWithDialog">关闭</el-button>
            <el-button type="primary" @click="transfer" :disabled="buttonDisabled"> 已转账 </el-button>
            <el-button type="primary" @click="refuseTransfer" :disabled="buttonDisabled"> 已拒绝 </el-button>
            <!-- <el-button type="primary" @click="confirmTransfer" :disabled="buttonDisabled"> 确认转账 </el-button> -->
          </div>
          <!-- <div >
            <el-button @click="handleDealWithDialog">关闭</el-button>
            <el-button type="primary" @click="confirmTransfer" :disabled="buttonDisabled">确认转账</el-button>
          </div> -->
        </div>
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="recordDialog = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { removePlatform } from '@/utils/storage'
import getBankInfoByCardNo from '@/utils/banks.js'
import store from '@/store'

const editForm = {
  userName: '', //用户名
  createdTime: '', //创建时间
  companyType: '', //企业类型
  companyName: '', //企业名称
  companyCode: '', //身份证号
  withdraw: '', //已提现金额
  amount: '', //账户余额
  toBeSettled: '', //待计算金额
  settled: '', //已结算金额
  shopName: '',
  platformid: ''
}
const dealWith = {
  createdBy: '',
  companyType: '', //商户类型  需要请求
  companyName: '', // 名称 需要请求
  companyCode: '', //身份证 需要请求
  createTime: '', //创建时间 需要请求
  mobile: '', //联系电话 需要请求
  username: '', // 第二层dialog表格传递
  acountType: '', //转入账户类型 需要请求
  acountName: '', //账户姓名 需要请求
  acountNo: '', // 提款账号 需要请求
  amount: '', //账户余额 需要请求
  withdrawalAmount: '' //提现金额 通过第二次表格传递
}
const selectFrom = {
  name: '',
  shopId: ''
}
const userAcount = {
  amount: 0,
  pay: 0,
  recharge: 0,
  refund: 0,
  settled: 0,
  toBeSettled: 0,
  unPay: 0,
  unRecharge: 0,
  unRefund: 0,
  unWithdraw: 0,
  unWithdrawNum: 0,
  withdraw: 0
}
export default {
  name: 'index',
  data() {
    return {
      roleIdValue: [],
      tableData: [],
      loading: false,
      currentPage: 1,
      currentSize: 10,
      recordCurrentPage: 1,
      recordCurrentSize: 10,
      editCurrentPage: 1,
      editCurrentSize: 10,
      total: 0,
      dialogVisible: false,
      dialogTableData: [],
      validityStart: [],
      edit: {
        ...editForm
      },
      buttonDisabled: false,
      editPlatformId: '',
      dealWithEdit: {
        ...dealWith
      },
      typeArray: [
        {
          key: 0,
          value: '企业'
        },
        {
          key: 1,
          value: '个人'
        },
        {
          key: 2,
          value: '印之新'
        },
        {
          key: 3,
          value: '智慧门店'
        }
      ],
      select: {
        ...selectFrom
      },
      payArray: [
        {
          key: 0,
          value: '已结算'
        },
        {
          key: 1,
          value: '未结算'
        }
      ],
      // 1,待支付 2已支付 3支付失败 4已完成
      settlementStatus: [
        {
          key: 1,
          value: '待支付'
        },
        {
          key: 2,
          value: '已支付'
        },
        {
          key: 3,
          value: '支付失败'
        },
        {
          key: 4,
          value: '已完成'
        }
      ],
      dealWithDialog: false,
      userArray: [],
      auditDialog: false,
      userId: '', // 详情保存的ID
      editTableData: [],
      editTotal: 0,
      recordDialog: false,
      recordTableData: [],
      recordLoading: false,
      recordTotal: 0,
      recordId: '', // 第一次表格传递的userId
      totalAmount: '',
      settlementStatusValue: '',
      businessType: '',
      editLoading: false,
      accountId: '',
      platformId: 2,
      accountNo: '',
      accountSeqId: '',
      temporaryUserId: '', // 获取商户基本信息时判断ID,如果相同先不获取直接使用，不相同则再获取数据，因为这里显示的商户信息是不可更改的，所以写一层判断，减少访问次数
      switchValue: false,
      acount: {
        ...userAcount
      },
      productionListOptions: [],
      platfromList: [],
      searchPlatformId: ''
    }
  },
  computed: {
    //businessTypeList
    // ...mapState({
    //   businessTypeLists: state => state.dictionary.businessTypeList
    // })
    businessTypeLists() {
      if (this.$store.state.dictionary.businessTypeList.length === 0) {
        this.$store.dispatch('BUSINESS_TYPE')
      }
      console.log(this.$store.state.dictionary.businessTypeList)
      return this.$store.state.dictionary.businessTypeList
    }
  },
  watch: {
    searchPlatformId: function () {
      this.getList()
    }
  },
  filters: {
    orderTypeFilter(val) {
      //动账类型0消费1提款2充值3退款 4佣金
      if (val === 0) {
        return '消费'
      } else if (val === 1) {
        return '提款'
      } else if (val === 2) {
        return '充值'
      } else if (val === 3) {
        return '退款'
      } else if (val === 4) {
        return '佣金'
      }
    },
    sourceFilter(val) {
      //0->PC订单；1->app订单 2智慧门店 3印之新
      if (val === 0) {
        return 'PC订单'
      } else if (val === 1) {
        return 'app订单'
      } else if (val === 2) {
        return '智慧门店'
      } else if (val === 3) {
        return '印之新'
      }
    },
    statusFilter(val) {
      //   订单状态：0->待付款；1->待发货；2->已发货；
      // * * 3->已完成；4->已关闭；5->无效订单 6->已取消
      //   * * 7->待分享 8拼团失败 9->售后关闭 10->部分发货
      switch (val) {
        case 0:
          return '代付款'
        case 1:
          return '待发货'
        case 2:
          return '已发货'
        case 3:
          return '已完成'
        case 4:
          return '已关闭'
        case 5:
          return '无效订单'
        case 6:
          return '已取消'
        case 7:
          return '待分享'
        case 8:
          return '拼团失败'
        case 9:
          return '售后关闭'
        case 10:
          return '部分发货'
        default:
          return ''
      }
    },
    settlementStatusFilter(val) {
      // 1,待支付 2已支付 3支付失败 4已完成
      if (val === 1) {
        return '待支付'
      } else if (val === 2) {
        return '已支付'
      } else if (val === 3) {
        return '支付失败'
      } else if (val === 4) {
        return '已完成'
      }
      return ''
    }
  },
  created() {
    // this.searchRoleId()
    this.getPFList()
    this.getPlatformId()
    this.getUserList()
    // this.getBusinessType()
    this.getProductionList()
    // getCurrentPlatformInfo().then(res => {
    //   if (res.code === 200) {
    //     this.platformId=res.data.platformId
    //   }
    // })
  },
  methods: {
    getBankName(bankCode) {
      return getBankInfoByCardNo(bankCode).bankName
      // return getBankInfoByCardNo(bankCode)
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          this.getProductionList(query)
        }, 200)
      } else {
        this.getProductionList()
      }
    },
    // 获取店铺列表
    getProductionList(shopName) {
      this.$api.product.getShopname({ pageNumber: 1, pageSize: 100, shopName: shopName }).then(res => {
        this.productionListOptions = res.data.list
      })
    },
    dealWith(accountId, username, amount, platForm, accountNo, accountSeqId) {
      // setPlatform(platForm)
      this.accountId = accountId
      this.dealWithDialog = true
      this.dealWithEdit.username = username
      this.dealWithEdit.withdrawalAmount = amount
      this.buttonDisabled = false
      this.platformId = platForm
      this.accountNo = accountNo
      this.accountSeqId = accountSeqId
      this.getUser()
      this.getShopByUserId()
    },
    async getBusinessType() {
      await store.dispatch('BUSINESS_TYPE')
    },
    async getPFList() {
      this.platfromList = (await this.$api.dynamicAccount.getPlatfromList()).data
      this.searchPlatformId = this.platfromList[1].platformId
      this.getList()
    },
    async getPlatformId() {
      this.platformId = await this.$api.common.getConfigList('PLATFORM_SHOP')
    },
    transfer() {
      this.$api.dynamicAccount.transfer(this.accountId).then(res => {
        if (res.data) {
          this.buttonDisabled = true
          this.getWithdrawalsRecordList(this.recordId)
          this.$message.success('操作成功')
        } else {
          this.$message.error('操作失败')
        }
      })
    },
    refuseTransfer() {
      this.$api.dynamicAccount.refuseTransfer(this.accountId).then(res => {
        if (res.code === 200) {
          this.buttonDisabled = true
          this.getWithdrawalsRecordList(this.recordId)
          this.$message.success('操作成功')
        } else {
          this.$message.error('操作失败')
        }
      })
    },
    confirmTransfer() {
      if (String(this.dealWithEdit.acountTypeVal) === '1') {
        //微信
        this.wxTransfer()
      } else if (String(this.dealWithEdit.acountTypeVal) === '0') {
        //支付宝
        this.aliTransfer()
      }
    },
    wxTransfer() {
      const params = {
        outTradeNo: this.accountSeqId,
        subject: '微信转账',
        amount: this.dealWithEdit.withdrawalAmount,
        platformId: this.platformId,
        payeeAccount: this.dealWithEdit.acountNo,
        userId: this.recordId,
        reUserName: this.dealWithEdit.acountName
      }
      const businessType = '0101'
      this.$api.pay.wxTransfer(businessType, params).then(res => {
        if (res.code === 200) {
          this.buttonDisabled = true
          this.getWithdrawalsRecordList(this.recordId)
          this.$message.success('操作成功')
        } else {
          this.$message.error('操作失败')
        }
      })
    },
    aliTransfer() {
      const params = {
        outTradeNo: this.accountSeqId,
        remarks: '支付宝转账',
        amount: this.dealWithEdit.withdrawalAmount,
        platformId: this.platformId,
        payeeAccount: this.dealWithEdit.acountNo,
        userId: this.recordId,
        reUserName: this.dealWithEdit.acountName
      }
      const businessType = '0101'
      this.$api.pay.aliTransfer(businessType, params).then(res => {
        if (res.code === 200) {
          this.buttonDisabled = true
          this.getWithdrawalsRecordList(this.recordId)
          this.$message.success('操作成功')
        } else {
          this.$message.error('操作失败')
        }
      })
    },
    getUser() {
      this.$api.common
        .getAcount({
          platformId: this.platformId,
          userId: this.recordId
        })
        .then(res => {
          if (res.code === 200 && res.data !== null) {
            console.log('支付', res)

            this.dealWithEdit.acountType = this.getpayTypeLab(res.data.acountType)
            this.dealWithEdit.acountTypeVal = res.data.acountType
            this.dealWithEdit.acountName = res.data.acountName
            this.dealWithEdit.acountNo = res.data.acountNo
            this.dealWithEdit.amount = res.data.amount
          }
        })
    },
    getpayTypeLab(val) {
      if (val === '0') {
        return '支付宝'
      } else if (val === '1') {
        return '微信'
      } else if (val === '2') {
        return '银行卡'
      } else {
        return '微信'
      }
    },
    switchList() {
      this.getList()
    },
    getShopByUserId() {
      if (this.temporaryUserId === this.recordId) {
        return
      }
      this.$api.common.getShopByUserId(this.recordId).then(res => {
        if (res.code === 200 && res.data !== null) {
          console.log('处理详情', res)
          // this.dealWithEdit.companyType=res.data.companyType
          if (res.data.companyType === 0) {
            this.dealWithEdit.companyType = '企业'
          } else if (res.data.companyType === 1) {
            this.dealWithEdit.companyType = '个人'
          } else if (res.data.companyType === 2) {
            this.dealWithEdit.companyType = '印之新'
          } else if (res.data.companyType === 3) {
            this.dealWithEdit.companyType = '智慧门店'
          }
          this.temporaryUserId = res.data.userId
          this.dealWithEdit.companyName = res.data.companyName
          this.dealWithEdit.companyCode = res.data.companyCode
          this.dealWithEdit.mobile = res.data.mobile
          this.dealWithEdit.createdBy = res.data.createdBy
          this.dealWithEdit.createTime = res.data.createdTime
        }
      })
    },
    searchRoleId() {
      this.$api.dynamicAccount.searchItem('PLATFORM_BUSINESS_ROLE').then(res => {
        console.log('获取RoleId', res)
        var resValue = res.data
        this.roleIdValue = resValue.split(',')
      })
    },
    getUserList() {
      var _this = this
      this.$api.dynamicAccount.searchItem('PLATFORM_BUSINESS_ROLE').then(res => {
        var resValue = res.data
        _this.roleIdValue = resValue.split(',')
        let param = {
          pageNumber: 1,
          pageSize: 1000,
          // userType:1,
          roleId: _this.roleIdValue[_this.roleIdValue.length - 1],
          platformId: _this.platformId
        }
        this.$api.dynamicAccount.getUserList(param).then(res => {
          _this.userArray = res.data.list
        })
      })
    },
    dialog(row) {
      // this.edit.withdraw=row.withdraw
      this.edit.amount = row.amount
      this.edit.toBeSettled = row.toBeSettled
      this.edit.settled = row.settled
      this.edit.platformid = row.platfromId
      this.dialogVisible = true
      this.userId = row.userId
      this.validityStart = []
      // setPlatform(this.searchPlatformId)
      this.$api.common
        .getAcount({
          platformId: row.platfromId,
          userId: this.userId
        })
        .then(res => {
          if (res.code === 200) {
            // this.edit.withdraw=res.data.withdraw
            this.edit.amount = res.data.amount
            this.edit.userName = res.data.username
          }
        })
      this.$api.dynamicAccount
        .selectAmount({
          platformId: row.platfromId,
          userId: this.userId
        })
        .then(res => {
          if (res.data === null || res.data === '') {
            this.edit.withdraw = 0
          } else {
            this.edit.withdraw = res.data
          }
        })
      this.getByIdAndList()
    },
    tableRowClassName({ row }) {
      // if (rowIndex === 1) {
      //   return 'warning-row';
      // } else if (rowIndex === 3) {
      //   return 'success-row';
      // }
      // return '';
      if (row.type === 0) {
        return 'dongRow'
      } else if (row.type === 1) {
        return 'tiRow'
      } else if (row.type === 2) {
        return 'jieRow'
      }
      return 'weiRow'
    },
    auditEdit() {
      this.auditDialog = true
    },
    editHandlesSizeChange(val) {
      this.editCurrentSize = val
      this.getByIdAndList(this.userId)
    },
    editHandlesCurrentChange(val) {
      this.editCurrentPage = val
      this.getByIdAndList(this.userId)
    },
    recordHandlesSizeChange(val) {
      this.recordCurrentSize = val
      this.getWithdrawalsRecordList(this.recordId)
    },
    recordHandlesCurrentChange(val) {
      this.recordCurrentPage = val
      this.getWithdrawalsRecordList(this.recordId)
    },
    handleSizeChange(val) {
      this.currentSize = val
      this.getList()
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getList()
      // console.log(`当前页: ${val}`);
    },
    arraySpanMethod({ rowIndex, columnIndex }) {
      if (rowIndex === this.editTableData.length + 2) {
        if (columnIndex === 1) {
          return [1, 2]
        }
      }
    },
    getByIdAndList() {
      this.editLoading = true
      let param = {
        page: this.editCurrentPage,
        size: this.editCurrentSize,
        userId: this.userId,
        platformId: this.edit.platformid
      }
      if (this.validityStart != null) {
        param = {
          ...param,
          startTime: this.validityStart[0],
          endTime: this.validityStart[1]
        }
      }
      if (this.settlementStatusValue !== '') {
        param = {
          ...param,
          status: this.settlementStatusValue
        }
      }
      if (this.businessType !== null || this.businessType !== '') {
        param = {
          ...param,
          businessType: this.businessType,
          platformId: 2
        }
      }
      this.$api.dynamicAccount.getByIdAndList(param).then(res => {
        // console.log(res);
        if (res.data.umsShop !== null) {
          // this.edit.userName=res.data.umsShop.userName
          this.edit.companyCode = res.data.umsShop.companyCode
          this.edit.companyName = res.data.umsShop.companyName
          this.edit.createdTime = res.data.umsShop.createdTime
          this.edit.companyType = res.data.umsShop.companyType
          this.edit.shopName = res.data.umsShop.shopName
        }
        this.editTableData = res.data.list.list
        this.editTotal = res.data.list.total
        // this.totalAmount=res.data.totalAmount
        let accountModel = res.data.userAccountModel
        this.acount.amount = accountModel.amount
        this.acount.pay = accountModel.pay === null || accountModel.pay === '' ? 0 : accountModel.pay
        this.acount.recharge = accountModel.recharge === null || accountModel.recharge === '' ? 0 : accountModel.recharge
        this.acount.refund = accountModel.refund ? accountModel.refund : 0
        this.acount.settled = accountModel.settled ? accountModel.settled : 0
        this.acount.toBeSettled = accountModel.toBeSettled ? accountModel.toBeSettled : 0
        this.acount.unPay = accountModel.unPay ? accountModel.unPay : 0
        this.acount.unRecharge = accountModel.unRecharge ? accountModel.unRecharge : 0
        this.acount.unRefund = accountModel.unRefund ? accountModel.unRefund : 0
        this.acount.unWithdraw = accountModel.unWithdraw ? accountModel.unWithdraw : 0
        this.acount.unWithdrawNum = accountModel.unWithdrawNum ? accountModel.unWithdrawNum : 0
        this.acount.withdraw = accountModel.withdraw ? accountModel.withdraw : 0
      })
      // let params = {
      //   totalAmount: 100
      // }
      this.editLoading = false
    },
    getWithdrawalsRecordList(id) {
      this.recordLoading = true
      this.recordDialog = true
      this.recordId = id
      let param = {
        page: this.recordCurrentPage,
        size: this.recordCurrentSize,
        userId: id,
        platformId: this.searchPlatformId
      }
      this.$api.dynamicAccount.getWithdrawalsRecordList(param).then(res => {
        // console.log(res);
        this.recordTableData = res.data.list
        this.recordTotal = res.data.total
      })
      this.recordLoading = false
    },
    getList() {
      this.loading = true
      let params = {
        page: this.currentPage,
        size: this.currentSize,
        all: this.switchValue,
        name: this.select.name,
        shopId: this.select.shopId,
        platformId: this.searchPlatformId
      }
      this.$api.dynamicAccount.accountList(params).then(res => {
        // console.log(res);
        this.tableData = res.data.list
        this.total = res.data.total
      })
      this.loading = false
    },
    reset() {
      this.select.name = ''
      this.select.shopId = ''
      this.getList()
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(() => {
          done()
        })
        .catch(() => {})
    },
    handleDealWithDialog() {
      this.dealWithDialog = false
      removePlatform()
    }
  }
}
</script>

<style scoped>
.editForm {
  margin-left: -80px;
}
.table {
  padding-left: 20px;
}
.button {
  align-content: center;
  padding-left: 43%;
  padding-bottom: 30px;
}
.block {
  padding-left: 20px;
}
.color {
  display: flex;
  flex-direction: row;
}
.color div {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  margin-top: -2px;
}
.color span {
  margin-left: 5px;
}
.editButton {
  padding-left: 48%;
  padding-top: -10px;
  padding-bottom: 10px;
}
.topForm {
  padding-left: 20px;
  padding-top: 20px;
}
.qi {
  position: relative;
  left: -15px;
}
.leftAmount {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  left: -50px;
}
.bottomTable {
  margin-top: 5px;
}
.leftAmount span {
  width: 20%;
  font-size: 15px;
  padding-left: 50px;
  padding-top: 5px;
}
.leftAmount i {
  font-size: 9px;
}
/*.leftAmount span:nth-child(2){*/
/*  padding-left: 100px;*/
/*}*/
/*.leftAmount span:nth-child(3){*/
/*  padding-left: 100px;*/
/*}*/
.text {
  font-size: 18px;
}
.zhong {
  position: relative;
  top: -10px;
  bottom: 10px;
}
.bottom {
  position: relative;
  top: 10px;
}
.count {
  position: relative;
  left: 85%;
  top: 20px;
  color: #a94442;
  font-size: 16px;
}
.onDialog {
  padding-left: 40%;
}
.dong {
  background-color: #91bbe9;
}
.ti {
  background-color: darkseagreen;
}
.jie {
  background-color: sandybrown;
}
.wei {
  background-color: #d8dce5;
}
.dialogTable {
  padding-top: 5px;
}
.amount {
  padding-bottom: 10px;
}
.amount div:nth-child(2) {
  padding-top: 10px;
}
.card {
  position: relative;
  left: 20px;
  top: 20px;
}
.cardShow {
  position: relative;
  top: -25px;
}
</style>

<style>
.el-table .dongRow {
  background: #91bbe9;
}

.el-table .tiRow {
  background: darkseagreen;
}
.el-table .jieRow {
  background: sandybrown;
}
.el-table .weiRow {
  background: #d8dce5;
}
</style>

<template>
  <coupon-detail :isEdit="true" />
</template>
<script>
import CouponDetail from './components/CouponDetail'
export default {
  name: 'UpdateCoupon',
  components: { CouponDetail }
}
</script>
<style scoped></style>

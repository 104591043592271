import request from '@/api/request'
import qs from 'qs'

const product = {
  /**
   * 货品资料-查询(关键字查询)
   * @param {*} data
   * @returns
   */
  LikeSelectBase(data) {
    return request({
      url: '/wms/incoming/LikeSelectBase',
      method: 'post',
      data: qs.stringify(data)
    })
  },
  // 库存列表-查询
  getrepertoryList(params) {
    return request({
      url: '/wms/incoming/repertoryInfo',
      method: 'get',
      params
    })
  },
  memberLevelList(params) {
    return request({
      url: '/expositionShop/memberLevel/list',
      method: 'get',
      params: params
    })
  },
  brandList(params) {
    return request({
      url: '/expositionShop/brand/list',
      method: 'get',
      params: params
    })
  },
  fetchListWithChildren(params = {}) {
    return request({
      url: '/expositionShop/productCategory/list/withChildren',
      method: 'get',
      params,
      noUse: true
    })
  },
  getProductCate(id, params = {}) {
    return request({
      url: '/expositionShop/productCategory/' + id,
      method: 'get',
      params
    })
  },
  prefrenceList(params = {}) {
    return request({
      url: '/expositionShop/prefrenceArea/listAll',
      method: 'get',
      params
    })
  },
  subjectListAll(params) {
    return request({
      url: '/expositionShop/subject/listAll',
      method: 'get',
      params
    })
  },
  // 商品同步es
  syncInfoProduct(id, params = {}) {
    return request({
      url: `/expositionShop/product/syncInfo/${id}`,
      method: 'get',
      params
    })
  },
  updateDeleteStatus(data) {
    return request({
      url: '/expositionShop/product/update/deleteStatus',
      method: 'post',
      data
    })
  },
  updateNewStatus(data) {
    return request({
      url: '/expositionShop/product/update/newStatus',
      method: 'post',
      data
    })
  },
  updateRecommendStatus(data) {
    return request({
      url: '/expositionShop/product/update/recommendStatus',
      method: 'post',
      data
    })
  },
  updatePublishStatus(data) {
    return request({
      url: '/expositionShop/product/update/publishStatus',
      method: 'post',
      data
    })
  },
  updateExpositionStatus(data) {
    return request({
      url: '/expositionShop/product/update/expositionStatus',
      method: 'post',
      data
    })
  },
  getProductList(params) {
    return request({
      url: '/expositionShop/product/selectPmsProductList',
      method: 'get',
      params: params
    })
  },
  // 批量导出执行函数
  // exportShop(params) {
  //   return request({
  //     url: '/expositionShop/product/batchExportProductList',
  //     method: 'get',
  //     params: params,
  //     responseType: 'blob'
  //   })
  // },
  exportShop(params) {
    return request({
      url: '/export/api/shop/ybh/productList/export',
      method: 'get',
      params: params
    })
  },
  skuList(pid, params) {
    return request({
      url: '/expositionShop/sku/' + pid,
      method: 'get',
      params: params
    })
  },
  skuUpdate(pid, data) {
    return request({
      url: '/expositionShop/sku/update/' + pid,
      method: 'post',
      data: data
    })
  },
  productPerView(pid) {
    return request({
      url: '/expositionShop/product/preview/by/' + pid,
      method: 'get'
    })
  },
  productAttrList(cid, params) {
    return request({
      url: '/expositionShop/productAttribute/list/' + cid,
      method: 'get',
      params: params
    })
  },
  categoryList(params) {
    return request({
      url: '/expositionShop/productAttribute/category/list',
      method: 'get',
      params: params
    })
  },
  createProduct(data) {
    return request({
      url: '/expositionShop/product/create',
      method: 'post',
      data: data
    })
  },
  getProduct(id) {
    return request({
      url: '/expositionShop/product/updateInfo/' + id,
      method: 'get'
    })
  },
  updateProduct(id, data) {
    return request({
      url: '/expositionShop/product/update/' + id,
      method: 'post',
      data: data
    })
  },
  getShopname(params) {
    return request({
      url: '/expositionShop/shop/list',
      method: 'get',
      params: params
    })
  },
  productList(params) {
    return request({
      url: '/expositionShop/product/list',
      method: 'get',
      params: params
    })
  },
  productVerifyStatus(data) {
    return request({
      url: `/expositionShop/product/review/by/${data.id}/carry/${data.status}`,
      method: 'put',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: data.detail
    })
  },
  mustUpdateProduct(data) {
    return request({
      url: `/expositionShop/product/update/by/${data.id}/carry/${data.sort}`,
      method: 'put'
    })
  },
  // 解除运费
  relieveFreight(data) {
    return request({
      url: '/expositionShop/product/relieve/Freight',
      method: 'post',
      data: qs.stringify(data)
    })
  },
  // 解除不限购区域
  relieveFlashArea(data) {
    return request({
      url: '/expositionShop/product/relieve/flashArea',
      method: 'post',
      data: qs.stringify(data)
    })
  },
  getAuditStatus(params) {
    return request({
      url: '/expositionShop/product/update/auditStatus',
      method: 'post',
      params: params
    })
  },
  // 批量设置运费模板
  setFreight(data) {
    return request({
      url: '/expositionShop/product/update/updateFreight',
      method: 'post',
      data: qs.stringify(data)
    })
  },
  // 批量设置不限购区域
  setLimitArea(data) {
    return request({
      url: '/expositionShop/product/update/updateFlashArea',
      method: 'post',
      data: qs.stringify(data)
    })
  },
  // 商品还原
  productReset(data) {
    return request({
      url: '/expositionShop/product/update/deleteStatus',
      method: 'post',
      data: qs.stringify(data)
    })
  },
  // 获取shopId
  shopIdList(params) {
    return request({
      url: '/expositionShop/product/shopIdList',
      method: 'GET',
      params: params
    })
  },
  createProductAttrCate(data) {
    return request({
      url: '/expositionShop/productAttribute/category/create',
      method: 'POST',
      data: qs.stringify(data)
    })
  },
  updateProductAttrCate(id, data) {
    return request({
      url: '/expositionShop/productAttribute/category/update/' + id,
      method: 'POST',
      data: qs.stringify(data)
    })
  },
  deleteProductAttrCate(id) {
    return request({
      url: '/expositionShop/productAttribute/category/delete/' + id,
      method: 'get'
    })
  },

  // 验证类型是否与商品存在关联
  cannotRepetitionType(params) {
    return request({
      url: '/expositionShop/productAttribute/cannotRepetition',
      method: 'get',
      params: params
    })
  },
  deleteProductAttr(data) {
    return request({
      url: '/expositionShop/productAttribute/delete',
      method: 'post',
      data: qs.stringify(data)
    })
  },
  // 判断商品属性有没有关联商品sku
  cannotRepetition(params) {
    return request({
      url: '/expositionShop/product/cannotRepetition',
      method: 'get',
      params: params
    })
  },
  createProductAttr(data) {
    return request({
      url: '/expositionShop/productAttribute/create',
      method: 'post',
      data: data
    })
  },
  getProductAttr(id) {
    return request({
      url: '/expositionShop/productAttribute/' + id,
      method: 'get'
    })
  },
  updateProductAttr(id, data) {
    return request({
      url: '/expositionShop/productAttribute/update/' + id,
      method: 'post',
      data: data
    })
  },
  skuPerView(pid) {
    return request({
      url: '/expositionShop/product/preview/by/' + pid,
      method: 'get'
    })
  },
  fetchSimpleList(params) {
    return request({
      url: '/expositionShop/product/simpleListByShopId',
      method: 'get',
      params: params
    })
  }
}

export default product

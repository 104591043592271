<template>
  <div style="margin-top: 50px">
    <el-form ref="productAttrForm" :rules="rules" :model="value" label-width="120px" style="width: 100%" size="small">
      <el-form-item v-if="value.beBuy" label="起订量：">
        <el-input v-model="value.initialNum" v-enter-number :disabled="isEdit && !copy && isVerify && isPublish" style="width: 300px" />
      </el-form-item>
      <el-form-item v-if="value.beBuy" label="是否包邮：">
        <el-radio-group v-model="value.isExemption" :disabled="isEdit && !copy && isVerify && isPublish">
          <el-radio :label="0">非偏远地区包邮</el-radio>
          <el-radio :label="1">不包邮</el-radio>
        </el-radio-group>
        <div v-if="value.isExemption === 1" class="freight-set">
          <el-button type="primary" :disabled="isEdit && !copy && isVerify && isPublish" @click="handleSetFreight"> 选择运费模板 </el-button>
          <span class="lab">运费模板名称：{{ value.freightName || '无' }}</span>
        </div>
      </el-form-item>
      <el-form-item label="属性类型：">
        <el-select v-model="value.productAttributeCategoryId" placeholder="请选择属性类型" :disabled="isEdit && !copy && isVerify" @change="handleProductAttrChangeTo">
          <el-option v-for="item in productAttributeCategoryOptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item v-if="value.beBuy" label="商品规格：">
        <el-card shadow="never" class="cardBg">
          <div v-for="(productAttr, idx) in selectProductAttr" :key="idx">
            {{ productAttr.name }}：
            <el-checkbox-group v-if="productAttr.handAddStatus === 0" v-model="selectProductAttr[idx].values">
              <el-checkbox v-for="item in getInputListArr(productAttr.inputList)" :key="item" :label="item" :disabled="isEdit && !copy && isVerify && (isChoose(item) || isPublish)" class="littleMarginLeft" />
            </el-checkbox-group>
            <div v-else>
              <el-checkbox-group v-model="selectProductAttr[idx].values">
                <div v-for="(item, index) in selectProductAttr[idx].options" :key="index" style="display: inline-block" class="littleMarginLeft">
                  <el-checkbox :key="item" :label="item" :disabled="isEdit && !copy && isVerify && (isChoose(item) || isPublish)" />
                  <el-button type="text" class="littleMarginLeft" :disabled="isEdit && !copy && isVerify && (isChoose(item) || isPublish)" @click="handleRemoveProductAttrValue(idx, index)">删除 </el-button>
                </div>
              </el-checkbox-group>
              <el-input :ref="`apv${idx}`" v-model="selectProductAttr[idx].addProductAttrValue" :disabled="isEdit && !copy && isVerify && isPublish" style="width: 160px; margin-left: 10px" clearable />
              <el-button class="littleMarginLeft" :disabled="isEdit && !copy && isVerify && isPublish" @click="handleAddProductAttrValue(idx)">增加</el-button>
            </div>
          </div>
        </el-card>
        <el-table style="width: 100%; margin-top: 20px" :data="value.skuStockList" border @header-click="headEvent">
          <el-table-column v-for="(item, index) in selectProductAttr" :key="item.id" :label="item.name" align="center">
            <template slot-scope="scope">
              {{ getProductSkuSp(scope.row, index, item.name) }}
            </template>
          </el-table-column>
          <el-table-column label="市场价格" width="80" align="center">
            <template slot-scope="scope">
              <el-input v-enter-money v-model="scope.row.originalPrice" :disabled="isEdit && !copy && isVerify && isPublish && isSkuOriginalPrice(scope.row)" />
            </template>
          </el-table-column>
          <el-table-column label="销售价格" width="80" align="center">
            <template slot-scope="scope">
              <el-input v-enter-money v-model="scope.row.price" :disabled="isEdit && !copy && isVerify && isPublish && isSkuPrice(scope.row)" />
            </template>
          </el-table-column>
          <el-table-column label="文印店（企事业单位）" width="80" align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.price1" :disabled="isEdit && !copy && isVerify && isPublish" />
            </template>
          </el-table-column>
          <el-table-column label="协会会员" width="80" align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.price2" :disabled="isEdit && !copy && isVerify && isPublish" />
            </template>
          </el-table-column>
          <el-table-column label="加盟店" width="80" align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.price3" :disabled="isEdit && !copy && isVerify && isPublish" />
            </template>
          </el-table-column>
          <!-- <el-table-column label="商品编号" width="140" align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.productSn" :disabled="isEdit && !copy && isVerify && isPublish">
                <el-button slot="append" icon="el-icon-search" size="mini" @click="openGoodsDialog(scope.$index)" />
              </el-input>
            </template>
          </el-table-column> -->
          <el-table-column label="扣库数量" width="80" align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.number" :disabled="isEdit && !copy && isVerify && isPublish" />
            </template>
          </el-table-column>
          <el-table-column label="商品库存" width="80" align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.stock" :disabled="isEdit && !copy && isVerify && isPublish" />
            </template>
          </el-table-column>
          <el-table-column label="库存预警值" width="80" align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.lowStock" :disabled="isEdit && !copy && isVerify && isPublish" />
            </template>
          </el-table-column>
          <el-table-column label="重量（KG）" width="80" align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.sp3" :disabled="isEdit && !copy && isVerify && isPublish" />
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" :disabled="isEdit && !copy && isVerify" @click="handleRemoveProductSku(scope.$index, scope.row)">删除 </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button type="primary" style="margin-top: 20px" :disabled="isEdit && !copy && isVerify && isPublish" @click="handleRefreshProductSkuList">刷新列表 </el-button>
        <el-button type="primary" style="margin-top: 20px" :disabled="isEdit && !copy && isVerify" @click="handleSyncProductSkuPrice('price')">同步价格 </el-button>
        <el-button type="primary" style="margin-top: 20px" :disabled="isEdit && !copy && isVerify" @click="handleSyncProductSkuPrice('stock')">同步库存 </el-button>
        <el-button type="primary" style="margin-top: 20px" :disabled="isEdit && !copy && isVerify" @click="handleSyncProductSkuPrice('lowStock')">同步库存预警值 </el-button>
      </el-form-item>
      <el-form-item v-if="hasAttrPic" label="属性图片：">
        <el-card shadow="never" class="cardBg">
          <div v-for="(item, index) in selectProductAttrPics" :key="index">
            <span>{{ item.name }}:</span>
            <single-upload v-model="item.pic" :name="fileName" :disabled="isSingleUpload(item.name, item.pic)" style="width: 300px; display: inline-block; margin-left: 10px" />
          </div>
        </el-card>
      </el-form-item>
      <el-form-item label="产品规格：">
        <el-tabs v-model="activeParamsName" type="card">
          <el-tab-pane label="电脑端详情" name="pc">
            <tinymce v-model="value.specDetailWeb" :width="800" :height="300" />
          </el-tab-pane>
          <el-tab-pane label="移动端详情" name="mobile">
            <tinymce v-model="value.specDetailApp" :width="800" :height="300" />
          </el-tab-pane>
        </el-tabs>
      </el-form-item>
      <el-form-item v-if="false" label="商品参数：">
        <el-card shadow="never" class="cardBg">
          <div v-for="(item, index) in selectProductParam" :key="index" :class="{ littleMarginTop: index !== 0 }">
            <div class="paramInputLabel">{{ item.name }}:</div>
            <el-select v-if="item.inputType === 1" v-model="selectProductParam[index].value" class="paramInput">
              <el-option v-for="item in getParamInputList(item.inputList)" :key="item" :label="item" :value="item" />
            </el-select>
            <el-input v-else v-model="selectProductParam[index].value" class="paramInput" />
          </div>
        </el-card>
      </el-form-item>
      <el-form-item label="商品相册：">
        <!-- <multi-upload v-model="selectProductPics" :name="multiFileName" /> -->
        <upload-file :name="multiFileName" :initFileList="selectProductPics" :fileSize="[10, 'mb']" :fileType="['jpg', 'png', 'jpeg']" :limit="10" listType="picture-card" :align="'left'" @uploadSuccess="uploadSuccess" @removeFile="removeFile"></upload-file>
      </el-form-item>
      <el-form-item label="产品功能：">
        <el-tabs v-model="activeHtmlName" type="card">
          <el-tab-pane label="电脑端详情" name="pc">
            <tinymce v-model="value.detailHtml" :width="800" :height="300" />
          </el-tab-pane>
          <el-tab-pane label="移动端详情" name="mobile">
            <tinymce v-model="value.detailMobileHtml" :width="800" :height="300" />
          </el-tab-pane>
        </el-tabs>
      </el-form-item>
      <div style="text-align: center">
        <el-button size="medium" @click="handlePrev">上一步，填写商品促销</el-button>
        <el-button type="primary" size="medium" @click="handleNext">下一步，选择商品关联</el-button>
      </div>
    </el-form>
    <goodsList v-if="goodsDialog" :goods-dialog="goodsDialog" @closeGoodsDialog="closeGoodsDialog" @addGoodsInfo="addGoodsInfo" />
    <FreightDialog v-if="selectFreightDialogVisible" :freight-id="value.freightId" :user-status="userStatus" :select-freight-dialog-visible="selectFreightDialogVisible" @closeFreightDialog="closeFreightDialog" @addFreightInfo="addFreightInfo" />
  </div>
</template>

<script>
// import MultiUpload from '@/components/Upload/multiUpload'
import Tinymce from '@/components/Tinymce'
import goodsList from './goodsList'
import FreightDialog from './FreightDialog'

export default {
  name: 'ProductAttrDetail',
  components: { Tinymce, goodsList, FreightDialog },
  props: {
    value: Object,
    isEdit: {
      type: Boolean,
      default: false
    },
    copy: {
      type: Boolean,
      default: false
    },
    isPublish: {
      type: Boolean,
      default: false
    },
    isVerify: {
      type: Boolean,
      default: false
    },
    skuCheckedlists: {
      type: Array,
      default: () => []
    },
    vipLevelList: {
      type: Array,
      default: () => []
    },
    userStatus: {
      type: Number,
      default: null
    },
    isAuditStatus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectProductPics: [],
      multiFileName: 'goods',
      fileName: 'static',
      isShowColumn: false,
      // 编辑模式时是否初始化成功
      hasEditCreated: false,
      // 商品属性分类下拉选项
      productAttributeCategoryOptions: [],
      selectFreightDialogVisible: false, // 是否展示运费弹框
      currentSelectedFreightInfo: null,
      // 选中的商品属性
      selectProductAttr: [],
      // 选中的商品参数
      selectProductParam: [],
      // 选中的商品属性图片
      selectProductAttrPics: [],
      // 可手动添加的商品属性
      addProductAttrValue: '',
      // 商品富文本详情激活类型
      activeHtmlName: 'pc',
      // 规格参数富文本激活类型
      activeParamsName: 'pc',
      // 编辑模式下已匹配的sku
      // skuCheckedlists: [],
      // 与商品关联的商品类型id
      productAttributeIds: [],
      // 商品编号搜索弹框显示隐藏
      goodsDialog: false,
      // 商品编号下标
      goodNumIndex: 0,
      rules: {
        startQuantity: [{ required: true, message: '请输入起订量', trigger: 'blur' }]
      }
    }
  },
  computed: {
    // 是否有商品属性图片
    hasAttrPic() {
      if (this.selectProductAttrPics.length < 1) {
        return false
      }
      return true
    },
    // 商品的编号
    productId() {
      return this.value.id
    }
    // 商品的主图和画册图片
    // selectProductPics: {
    //   get: function () {
    //     let pics = []
    //     if (this.value.pic === undefined || this.value.pic == null || this.value.pic === '') {
    //       return pics
    //     }
    //     pics.push(this.value.pic)
    //     if (this.value.albumPics === undefined || this.value.albumPics == null || this.value.albumPics === '') {
    //       return pics
    //     }
    //     let albumPics = this.value.albumPics.split(',')
    //     for (let i = 0; i < albumPics.length; i++) {
    //       pics.push(albumPics[i])
    //     }
    //     return pics
    //   },
    //   set: function (newValue) {
    //     if (newValue == null || newValue.length === 0) {
    //       this.value.pic = null
    //       this.value.albumPics = null
    //     } else {
    //       this.value.pic = newValue[0]
    //       this.value.albumPics = ''
    //       if (newValue.length > 1) {
    //         for (let i = 1; i < newValue.length; i++) {
    //           this.value.albumPics += newValue[i]
    //           if (i !== newValue.length - 1) {
    //             this.value.albumPics += ','
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  },
  watch: {
    productId: function (newValue) {
      if (!this.isEdit) return
      if (this.hasEditCreated) return
      if (newValue === undefined || newValue == null || newValue === 0) return
      this.handleEditCreated()
    },
    value() {
      if (this.value.pic) {
        let temp = {
          uid: 0,
          url: this.value.pic
        }
        this.selectProductPics.push(temp)
      }
      if (this.value.albumPics) {
        let albumPics = this.value.albumPics.split(',')
        albumPics.map((item, index) => {
          let temp = {
            url: item,
            uid: index + 1
          }
          this.selectProductPics.push(temp)
        })
      }
    }
  },
  created() {
    this.getProductAttrCateList()
  },
  methods: {
    // 表头点击同步
    // eslint-disable-next-line no-unused-vars
    headEvent(c, e) {
      var _this = this
      if (c.label == '市场价格') {
        let value = ''
        let isValue = false
        for (let i = 0; i < this.value.skuStockList.length; i++) {
          if (this.value.skuStockList[i].originalPrice) {
            value = this.value.skuStockList[i].originalPrice
            isValue = true
            break
          }
        }
        if (isValue) {
          for (let g = 0; g < this.value.skuStockList.length; g++) {
            let chage = Object.assign({}, this.value.skuStockList[g])
            chage.originalPrice = value
            this.$set(this.value.skuStockList, g, chage)
          }
          this.showList = false
          setTimeout(() => {
            _this.showList = true
          }, 100)
        }
      }
      if (c.label == '销售价格') {
        let value = ''
        let isValue = false
        for (let i = 0; i < this.value.skuStockList.length; i++) {
          if (this.value.skuStockList[i].price) {
            value = this.value.skuStockList[i].price
            isValue = true
            break
          }
        }
        if (isValue) {
          for (let g = 0; g < this.value.skuStockList.length; g++) {
            let chage = Object.assign({}, this.value.skuStockList[g])
            chage.price = value
            this.$set(this.value.skuStockList, g, chage)
          }
          this.showList = false
          setTimeout(() => {
            _this.showList = true
          }, 100)
        }
      }
      if (c.label == '文印店（企事业单位）') {
        let value = ''
        let isValue = false
        for (let i = 0; i < this.value.skuStockList.length; i++) {
          if (this.value.skuStockList[i].price1) {
            value = this.value.skuStockList[i].price1
            isValue = true
            break
          }
        }
        if (isValue) {
          for (let g = 0; g < this.value.skuStockList.length; g++) {
            let chage = Object.assign({}, this.value.skuStockList[g])
            chage.price1 = value
            this.$set(this.value.skuStockList, g, chage)
          }
          this.showList = false
          setTimeout(() => {
            _this.showList = true
          }, 100)
        }
      }
      if (c.label == '协会会员') {
        let value = ''
        let isValue = false
        for (let i = 0; i < this.value.skuStockList.length; i++) {
          if (this.value.skuStockList[i].price2) {
            value = this.value.skuStockList[i].price2
            isValue = true
            break
          }
        }
        if (isValue) {
          for (let g = 0; g < this.value.skuStockList.length; g++) {
            let chage = Object.assign({}, this.value.skuStockList[g])
            chage.price2 = value
            this.$set(this.value.skuStockList, g, chage)
          }
          this.showList = false
          setTimeout(() => {
            _this.showList = true
          }, 100)
        }
      }
      if (c.label == '加盟店') {
        let value = ''
        let isValue = false
        for (let i = 0; i < this.value.skuStockList.length; i++) {
          if (this.value.skuStockList[i].price3) {
            value = this.value.skuStockList[i].price3
            isValue = true
            break
          }
        }
        if (isValue) {
          for (let g = 0; g < this.value.skuStockList.length; g++) {
            let chage = Object.assign({}, this.value.skuStockList[g])
            chage.price3 = value
            this.$set(this.value.skuStockList, g, chage)
          }
          this.showList = false
          setTimeout(() => {
            _this.showList = true
          }, 100)
        }
      }
      if (c.label == '扣库数量') {
        let value = ''
        let isValue = false
        for (let i = 0; i < this.value.skuStockList.length; i++) {
          if (this.value.skuStockList[i].number) {
            value = this.value.skuStockList[i].number
            isValue = true
            break
          }
        }
        if (isValue) {
          for (let g = 0; g < this.value.skuStockList.length; g++) {
            let chage = Object.assign({}, this.value.skuStockList[g])
            chage.number = value
            this.$set(this.value.skuStockList, g, chage)
          }
          this.showList = false
          setTimeout(() => {
            _this.showList = true
          }, 100)
        }
      }
      if (c.label == '商品库存') {
        let value = ''
        let isValue = false
        for (let i = 0; i < this.value.skuStockList.length; i++) {
          if (this.value.skuStockList[i].stock) {
            value = this.value.skuStockList[i].stock
            isValue = true
            break
          }
        }
        if (isValue) {
          for (let g = 0; g < this.value.skuStockList.length; g++) {
            let chage = Object.assign({}, this.value.skuStockList[g])
            chage.stock = value
            this.$set(this.value.skuStockList, g, chage)
          }
          this.showList = false
          setTimeout(() => {
            _this.showList = true
          }, 100)
        }
      }
      if (c.label == '库存预警值') {
        let value = ''
        let isValue = false
        for (let i = 0; i < this.value.skuStockList.length; i++) {
          if (this.value.skuStockList[i].lowStock) {
            value = this.value.skuStockList[i].lowStock
            isValue = true
            break
          }
        }
        if (isValue) {
          for (let g = 0; g < this.value.skuStockList.length; g++) {
            let chage = Object.assign({}, this.value.skuStockList[g])
            chage.lowStock = value
            this.$set(this.value.skuStockList, g, chage)
          }
          this.showList = false
          setTimeout(() => {
            _this.showList = true
          }, 100)
        }
      }
      if (c.label == '重量（KG）') {
        let value = ''
        let isValue = false
        for (let i = 0; i < this.value.skuStockList.length; i++) {
          if (this.value.skuStockList[i].sp3) {
            value = this.value.skuStockList[i].sp3
            isValue = true
            break
          }
        }
        if (isValue) {
          for (let g = 0; g < this.value.skuStockList.length; g++) {
            let chage = Object.assign({}, this.value.skuStockList[g])
            chage.sp3 = value
            this.$set(this.value.skuStockList, g, chage)
          }
          this.showList = false
          setTimeout(() => {
            _this.showList = true
          }, 100)
        }
      }
    },
    uploadSuccess(fileList) {
      this.selectProductPics = fileList
      this.value.pic = ''
      this.value.albumPics = ''

      this.selectProductPics.map((item, index) => {
        if (index === 0) {
          this.value.pic = String(item.url)
        } else {
          this.value.albumPics += item.url + (index + 1 !== this.selectProductPics.length ? ',' : '')
        }
      })
    },
    removeFile(index) {
      this.selectProductPics.splice(index, 1)
      this.value.pic = ''
      this.value.albumPics = ''
      this.selectProductPics.map((item, index) => {
        if (index === 0) {
          this.value.pic = String(item.url)
        } else {
          this.value.albumPics += item.url + (index + 1 !== this.selectProductPics.length ? ',' : '')
        }
      })
    },
    // 设置运费模板
    handleSetFreight() {
      this.selectFreightDialogVisible = true
    },
    addFreightInfo(data) {
      this.value.freightId = data.freightId
      this.value.freightName = data.freightName
      this.closeFreightDialog()
    },
    closeFreightDialog() {
      this.selectFreightDialogVisible = false
    },
    changeSkuGroup() {
      this.refreshProductAttrPics()
      this.refreshProductSkuList()
    },
    isSingleUpload() {
      if (this.isEdit && !this.copy && this.isVerify && this.isPublish) {
        return true
      } else {
        return false
      }
    },
    isSkuOriginalPrice(row) {
      if (row.id) return true
      return false
    },
    isSkuPrice(row) {
      if (row.id) return true
      return false
    },
    // 点击商品编号之后的搜索
    openGoodsDialog(index) {
      // console.log(this.isEdit,this.isPublish,'查看商品上下架状态')
      if (this.isEdit && !this.copy && this.isVerify && this.isPublish) return
      this.goodNumIndex = index
      console.log(this.goodNumIndex, '查看商品编号数据下标')
      this.goodsDialog = true
    },
    closeGoodsDialog() {
      this.goodsDialog = false
    },
    addGoodsInfo(val) {
      this.getrepertoryList(val.goodsCode)
      // this.value.skuStockList[this.goodNumIndex].productSn = val.goodsCode
      console.log(val, this.value.skuStockList, '查看双击之后返回的对应参数详情')
      this.closeGoodsDialog()
    },
    // 库存列表查询
    getrepertoryList(goodsCode) {
      let params = {
        pageNumber: 1,
        pageSize: 10,
        goodsCode: goodsCode,
        incomingReceipt: '',
        locationName: '',
        repertory: '',
        startTime: '',
        endTime: '',
        storeId: '',
        containerNumber: ''
      }
      this.$api.basicDataProduct.getrepertoryList(params).then(res => {
        let skuStockListObj = JSON.parse(JSON.stringify(this.value.skuStockList[this.goodNumIndex]))
        skuStockListObj.productSn = goodsCode
        skuStockListObj.stock = res.data.totalList.TotalNumber
        console.log(res.data.totalList.TotalNumber, skuStockListObj.stock)
        this.value.skuStockList.splice(this.goodNumIndex, 1, skuStockListObj)
      })
    },
    // 判断当前SKU是否已匹配
    isMatchSku() {
      for (let i = 0; i < this.skuCheckedlists.length; i++) {
        const spData = JSON.parse(this.skuCheckedlists[i].spData)
        for (let j = 0; j < this.value.skuStockList.length; j++) {
          let spData2 = JSON.parse(this.value.skuStockList[j].spData)
          if (spData[0].key !== spData2[0].key) {
            spData2 = spData2.reverse()
          }
          if (this.$utils.equalsObj(spData, spData2)) {
            this.value.skuStockList[j] = this.skuCheckedlists[i]
          }
        }
      }
    },
    // 判断当前属性是否已选
    isChoose(name) {
      for (let i = 0; i < this.skuCheckedlists.length; i++) {
        const spData = JSON.parse(this.skuCheckedlists[i].spData)
        if (spData.length) {
          for (let j = 0; j < spData.length; j++) {
            if (spData[j].value === name) {
              return true
            }
          }
        }
      }
      return false
    },
    getProductSkuSp(row, index, name) {
      // console.log(row)
      // console.log(index)
      // console.log(this.selectProductAttr)
      // debugger
      let arr = JSON.parse(row.spData)
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].key === name) {
          return arr[i].value
        }
      }
      // return arr[index].value
    },
    handleFinishCommit() {
      this.$emit('finishCommit', this.isEdit)
    },
    handleEditCreated() {
      //根据商品属性分类id获取属性和参数
      if (this.value.productAttributeCategoryId != null) {
        this.handleProductAttrChange(this.value.productAttributeCategoryId, false)
      }
      this.hasEditCreated = true
    },
    getProductAttrCateList() {
      let param = { pageNum: 1, pageSize: 100 }
      this.$api.product.categoryList(param).then(response => {
        this.productAttributeCategoryOptions = []
        let list = response.listData
        for (let i = 0; i < list.length; i++) {
          this.productAttributeCategoryOptions.push({
            label: list[i].name,
            value: list[i].id
          })
        }
      })
    },
    getProductAttrList(type, cid, isChange) {
      let param = { pageNum: 1, pageSize: 100, type: type }
      this.$api.product.productAttrList(cid, param).then(response => {
        let list = response.listData
        if (type === 0) {
          this.selectProductAttr = []
          for (let i = 0; i < list.length; i++) {
            let options = []
            let values = []
            if (this.isEdit) {
              if (list[i].handAddStatus === 1) {
                //编辑状态下获取手动添加编辑属性
                options = this.getEditAttrOptions(list[i].id)
              }
              //编辑状态下获取选中属性
              values = this.getEditAttrValues(i, list[i].name)
            }
            this.selectProductAttr.push({
              id: list[i].id,
              name: list[i].name,
              handAddStatus: list[i].handAddStatus,
              inputList: list[i].inputList,
              values: isChange ? [] : values,
              options: options,
              flag: i,
              addProductAttrValue: ''
            })
          }
          if (this.isEdit) {
            //编辑模式下刷新商品属性图片
            this.refreshProductAttrPics()
          }
          console.log(111, this.selectProductAttr)
        } else {
          this.selectProductParam = []
          for (let i = 0; i < list.length; i++) {
            let value = null
            if (this.isEdit) {
              //编辑模式下获取参数属性
              value = this.getEditParamValue(list[i].id)
            }
            this.selectProductParam.push({
              id: list[i].id,
              name: list[i].name,
              value: value,
              inputType: list[i].inputType,
              inputList: list[i].inputList
            })
          }
          console.log(222, this.selectProductParam)
        }
      })
    },
    //获取设置的可手动添加属性值
    getEditAttrOptions(id) {
      let options = []
      for (let i = 0; i < this.value.productAttributeValueList.length; i++) {
        let attrValue = this.value.productAttributeValueList[i]
        if (attrValue.productAttributeId === id) {
          let strArr = attrValue.value.split(',')
          for (let j = 0; j < strArr.length; j++) {
            options.push(strArr[j])
          }
          break
        }
      }
      return options
    },
    //获取选中的属性值
    getEditAttrValues(index, name) {
      let values = []
      for (let i = 0; i < this.value.skuStockList.length; i++) {
        let spData = JSON.parse(this.value.skuStockList[i].spData)
        // if (sku.sp1 != null && values.indexOf(sku.sp1) === -1) {
        //   values.push(sku.sp1);
        // }
        for (let j = 0; j < spData.length; j++) {
          if (spData[j].key === name && !values.includes(spData[j].value)) {
            values.push(spData[j].value)
          }
        }
        let priceList = JSON.parse(this.value.skuStockList[i].sp2)
        for (let m = 0; m < priceList.length; m++) {
          if (priceList[m].id === 3) {
            this.$set(this.value.skuStockList[i], 'price1', priceList[m].price)
          }
          if (priceList[m].id === 4) {
            this.$set(this.value.skuStockList[i], 'price2', priceList[m].price)
          }
          if (priceList[m].id === 5) {
            this.$set(this.value.skuStockList[i], 'price3', priceList[m].price)
          }
        }
      }
      return values
    },
    //获取属性的值
    getEditParamValue(id) {
      for (let i = 0; i < this.value.productAttributeValueList.length; i++) {
        if (id === this.value.productAttributeValueList[i].productAttributeId) {
          return this.value.productAttributeValueList[i].value
        }
      }
    },
    handleProductAttrChangeTo(value) {
      this.handleProductAttrChange(value, true)
    },
    handleProductAttrChange(value, isChange) {
      this.getProductAttrList(0, value, isChange)
      this.getProductAttrList(1, value, isChange)
    },
    getInputListArr(inputList) {
      return inputList.split(',')
    },
    handleAddProductAttrValue(idx) {
      // const value = this.$refs[`apv${idx}`][0].value;
      const value = this.selectProductAttr[idx].addProductAttrValue
      let options = this.selectProductAttr[idx].options
      if (value == null || value == '') {
        this.$message({
          message: '属性值不能为空',
          type: 'warning',
          duration: 1000
        })
        return
      }
      if (options.indexOf(value) !== -1) {
        this.$message({
          message: '属性值不能重复',
          type: 'warning',
          duration: 1000
        })
        return
      }
      this.selectProductAttr[idx].options.push(value)
      this.selectProductAttr[idx].addProductAttrValue = ''
      // this.addProductAttrValue = null;
    },
    handleRemoveProductAttrValue(idx, index) {
      this.selectProductAttr[idx].options.splice(index, 1)
      this.selectProductAttr[idx].values.splice(index, 1)
    },
    handleRefreshProductSkuList() {
      this.$confirm('刷新列表将导致sku信息重新生成，是否要刷新', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.refreshProductAttrPics()
        this.refreshProductSkuList()
      })
    },
    handleSyncProductSkuPrice(type) {
      switch (type) {
        case 'price':
          this.$confirm('将同步第一个sku的价格到所有sku,是否继续', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if (this.value.skuStockList !== null && this.value.skuStockList.length > 0) {
              let price = this.value.skuStockList[0].price
              for (let i = 0; i < this.value.skuStockList.length; i++) {
                // this.value.skuStockList[i].price = price;
                this.$set(this.value.skuStockList[i], 'price', price)
              }
            }
          })
          break
        case 'stock':
          this.$confirm('将同步第一个sku的库存到所有sku,是否继续', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if (this.value.skuStockList !== null && this.value.skuStockList.length > 0) {
              let stock = this.value.skuStockList[0].stock
              for (let i = 0; i < this.value.skuStockList.length; i++) {
                // this.value.skuStockList[i].price = price;
                this.$set(this.value.skuStockList[i], 'stock', stock)
              }
            }
          })
          break
        case 'lowStock':
          this.$confirm('将同步第一个sku的库存预警值到所有sku,是否继续', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if (this.value.skuStockList !== null && this.value.skuStockList.length > 0) {
              let lowStock = this.value.skuStockList[0].lowStock
              for (let i = 0; i < this.value.skuStockList.length; i++) {
                // this.value.skuStockList[i].price = price;
                this.$set(this.value.skuStockList[i], 'lowStock', lowStock)
              }
            }
          })
          break
      }
    },
    // 笛卡尔积算法，可用于商品 SKU 计算
    calcDescartes(array) {
      if (array.length < 2) return array //[0] || [];
      return [].reduce.call(array, function (col, set) {
        var res = []
        col.forEach(function (c) {
          set.forEach(function (s) {
            var t = [].concat(Array.isArray(c) ? c : [c])
            t.push(s)
            res.push(t)
          })
        })
        return res
      })
    },
    // 刷新列表
    refreshProductSkuList() {
      // this.skuCheckedlists = this.copy ? [] : [...this.value.skuStockList]
      this.value.skuStockList = []
      // debugger
      let skuList = this.value.skuStockList
      if (this.selectProductAttr.length) {
        let arr = []
        this.selectProductAttr.forEach(e => {
          let eArr = []
          if (e.values.length) {
            e.values.forEach(i => {
              eArr.push({
                key: e.name,
                value: i
              })
            })
          }
          arr.push(eArr)
        })
        // console.log(arr)
        if (this.selectProductAttr.length === 1) {
          arr.forEach(el => {
            el.forEach(i => {
              const sps = []
              sps.push(i)
              skuList.push({
                sp1: i.value,
                spData: JSON.stringify(sps)
              })
            })
          })
          // console.log(skuList)
        } else {
          // console.log(arr)
          const spArr = this.calcDescartes(arr)
          // console.log(spArr)
          // const key = spArr[0].key
          spArr.forEach(el => {
            skuList.push({
              // sp1: index === 0 && el.key,
              sp1: el[0].value,
              spData: JSON.stringify(el)
            })
          })
        }
        this.isMatchSku()
      }
    },
    refreshProductAttrPics() {
      this.selectProductAttrPics = []
      if (this.selectProductAttr.length >= 1) {
        let values = this.selectProductAttr[0].values
        for (let i = 0; i < values.length; i++) {
          let pic = null
          if (this.isEdit) {
            //编辑状态下获取图片
            pic = this.getProductSkuPic(values[i])
          }
          this.selectProductAttrPics.push({ name: values[i], pic: pic })
        }
      }
    },

    //获取商品相关属性的图片
    getProductSkuPic(name) {
      for (let i = 0; i < this.value.skuStockList.length; i++) {
        if (name === this.value.skuStockList[i].sp1) {
          return this.value.skuStockList[i].pic
        }
      }
      return null
    },
    //合并商品属性
    mergeProductAttrValue() {
      if (this.value.skuStockList && this.value.skuStockList.length) {
        this.value.skuStockList.forEach(el => {
          el.sp2 = []
          el.sp2.push({
            price: el.price1,
            id: 3
          })
          el.sp2.push({
            price: el.price2,
            id: 4
          })
          el.sp2.push({
            price: el.price3,
            id: 5
          })
          el.sp2 = JSON.stringify(el.sp2)
        })
      }
      this.value.productAttributeValueList = []
      // 未选属性不传给后台（values）
      for (let i = 0; i < this.selectProductAttr.length; i++) {
        let attr = this.selectProductAttr[i]
        if (attr.handAddStatus === 1 && attr.values != null && attr.values.length > 0) {
          this.value.productAttributeValueList.push({
            productAttributeId: attr.id,
            value: this.getOptionStr(attr.values)
          })
        }
        this.productAttributeIds.push(attr.id)
      }
      for (let i = 0; i < this.selectProductParam.length; i++) {
        let param = this.selectProductParam[i]
        this.value.productAttributeValueList.push({
          productAttributeId: param.id,
          value: param.value
        })
      }
      this.value.productAttributeIds = this.productAttributeIds
    },
    // 合并商品属性图片
    mergeProductAttrPics() {
      // console.log(this.value)
      for (let i = 0; i < this.selectProductAttrPics.length; i++) {
        for (let j = 0; j < this.value.skuStockList.length; j++) {
          if (this.value.skuStockList[j].sp1 == this.selectProductAttrPics[i].name) {
            this.value.skuStockList[j].pic = this.selectProductAttrPics[i].pic
          }
        }
      }
      // console.log(this.value.skuStockList)
    },
    getOptionStr(arr) {
      let str = ''
      for (let i = 0; i < arr.length; i++) {
        str += arr[i]
        if (i != arr.length - 1) {
          str += ','
        }
      }
      return str
    },
    handleRemoveProductSku(index) {
      let list = this.value.skuStockList
      if (list.length === 1) {
        list.pop()
      } else {
        list.splice(index, 1)
      }
    },
    getParamInputList(inputList) {
      return inputList.split(',')
    },
    handlePrev() {
      this.$emit('prevStep')
    },
    handleNext() {
      this.$refs['productAttrForm'].validate(valid => {
        if (valid) {
          this.mergeProductAttrValue()
          this.mergeProductAttrPics()
          this.$emit('nextStep')
        }
      })
    }
  }
}
</script>

<style scoped>
.littleMarginLeft {
  margin-left: 10px;
}

.littleMarginTop {
  margin-top: 10px;
}

.paramInput {
  width: 250px;
}

.paramInputLabel {
  display: inline-block;
  width: 100px;
  text-align: right;
  padding-right: 10px;
}

.cardBg {
  background: #f8f9fc;
}
</style>

import request from '@/api/request'
// import qs from 'qs'

const newsClass = {
  categoryList(params) {
    return request({
      url: `expositionShop/category/list`,
      method: 'GET',
      params
    })
  },
  categorySave(params) {
    return request({
      url: `expositionShop/category/save`,
      method: 'POST',
      data: params
    })
  },
  categoryUpdate(params) {
    return request({
      url: `expositionShop/category/update`,
      method: 'POST',
      data: params
    })
  },
  categoryRemove(params) {
    return request({
      url: `expositionShop/category/remove`,
      method: 'GET',
      params
    })
  }
}

export default newsClass

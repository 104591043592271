<template>
  <comments-detail :is-edit="false" />
</template>
<script>
export default {
  name: 'AddComment',
  components: { CommentsDetail: () => import('./components/CommentsDetail') },
  data() {
    return {
      CommentsDetail: 'CommentsDetail'
    }
  }
}
</script>
<style></style>

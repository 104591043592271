<template>
  <el-dialog v-dialogDrag append-to-body :close-on-click-modal="false" :visible="true" :title="(isEdit ? '编辑' : '新增') + '运费模板'" top="10vh" width="80%" @close="close">
    <el-card class="container" shadow="never">
      <el-form ref="freightObjFrom" :model="freightObj" :rules="freightRules" label-width="100px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="模板名称" prop="tmpName">
              <el-input v-model="freightObj.tmpName" size="mini" />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="6">
            <el-form-item>
              <el-checkbox v-model="freightObj.freeChecked">非偏远地区包邮</el-checkbox>
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="首重" prop="tmpInitialWeight">
              <el-input v-model="freightObj.tmpInitialWeight" size="mini" :disabled="freightObj.freeChecked" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="首费" prop="tmpInitialFreight">
              <el-input v-model="freightObj.tmpInitialFreight" size="mini" :disabled="freightObj.freeChecked" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="续重" prop="tmpEveryWeight">
              <el-input v-model="freightObj.tmpEveryWeight" size="mini" :disabled="freightObj.freeChecked" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="续费" prop="tmpAddFreight">
              <el-input v-model="freightObj.tmpAddFreight" size="mini" :disabled="freightObj.freeChecked" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="免运费数量" prop="tmpFullNumPostage">
              <el-input v-model="freightObj.tmpFullNumPostage" size="mini" :disabled="freightObj.freeChecked" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="免运费金额" prop="tmpFullPostage">
              <el-input v-model="freightObj.tmpFullPostage" size="mini" :disabled="freightObj.freeChecked" />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="6">
            <el-form-item label="起订量" prop="tmpInitialNum">
              <el-input v-model="freightObj.tmpInitialNum" size="mini" />
            </el-form-item>
          </el-col> -->
        </el-row>

        <el-row class="btn-area1">
          <el-button type="primary" size="mini" @click="addFreightInfo">指定地区设置运费</el-button>
          <span>除指定地区外，其他地区都采用“默认运费”设置</span>
        </el-row>

        <el-table :data="tableData" border style="width: 100%">
          <el-table-column label="运送地区" align="center">
            <template slot-scope="scope">
              <el-popover placement="top-start" title="送货" width="740" trigger="hover">
                <div v-html="scope.row.resv1"></div>
                <div slot="reference" class="drap-column overflow-hidden" v-html="scope.row.resv1"></div>
              </el-popover>
              <el-button type="primary" size="mini" @click="selectArea(scope.$index, scope.row)">选择</el-button>
            </template>
          </el-table-column>
          <el-table-column label="首重（KG）" width="80" align="center">
            <template slot-scope="scope">
              <el-input v-model.number="scope.row.assInitialWeight" size="mini" />
            </template>
          </el-table-column>
          <el-table-column label="首费（元）" width="80" align="center">
            <template slot-scope="scope">
              <el-input v-model.number="scope.row.assInitialFreight" size="mini" />
            </template>
          </el-table-column>
          <el-table-column label="续重（KG）" width="80" align="center">
            <template slot-scope="scope">
              <el-input v-model.number="scope.row.assEveryWeight" size="mini" />
            </template>
          </el-table-column>
          <el-table-column label="续费（元）" width="80" align="center">
            <template slot-scope="scope">
              <el-input v-model.number="scope.row.assAddFreight" size="mini" />
            </template>
          </el-table-column>
          <el-table-column label="免运费金额" width="80" align="center">
            <template slot-scope="scope">
              <el-input v-model.number="scope.row.assFullPostage" size="mini" />
            </template>
          </el-table-column>
          <el-table-column label="免运费数量" width="80" align="center">
            <template slot-scope="scope">
              <el-input v-model.number="scope.row.assFullNumPostage" size="mini" />
            </template>
          </el-table-column>
          <!-- <el-table-column label="起订量" width="80" align="center">
            <template slot-scope="scope">
              <el-input v-model.number="scope.row.assInitialNum" size="mini" />
            </template>
          </el-table-column> -->

          <el-table-column label="操作" width="80" align="center">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-row class="btn-area" type="flex" justify="center">
          <el-button type="primary" size="mini" @click="onSubmit('freightObjFrom')">保存</el-button>
          <el-button size="mini" @click="close">取消</el-button>
        </el-row>
      </el-form>
    </el-card>
    <AreaDialog v-if="selectDialogVisible" :select-dialog-visible="selectDialogVisible" :current-resv1="currentResv1" :current-resv2="currentResv2" @closeAreaDialog="closeAreaDialog" @addAreaList="addAreaList" />
  </el-dialog>
</template>
<script>
import AreaDialog from './AreaDialog'
const defaultFreight = {
  tmpName: '', // 模板名称
  tmpInitialWeight: '', // 首重
  tmpInitialFreight: '', // 首费
  tmpEveryWeight: '', // 续重
  tmpAddFreight: '', // 续费
  tmpFullPostage: '', // 金额
  tmpFullNumPostage: '', // 数量,
  freeChecked: false, // 非边远地区包邮
  tmpInitialNum: 1
}
export default {
  name: 'BrandDetail',
  components: { AreaDialog },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: null
    }
  },
  watch: {
    'freightObj.freeChecked'(val) {
      if (val) {
        this.freightObj.tmpInitialWeight = '1'
        this.freightObj.tmpInitialFreight = '0'
        this.freightObj.tmpEveryWeight = '1'
        this.freightObj.tmpAddFreight = '0'
        this.freightObj.tmpFullNumPostage = '1'
        this.freightObj.tmpFullPostage = '0'
      } else {
        this.freightObj.tmpInitialWeight = ''
        this.freightObj.tmpInitialFreight = ''
        this.freightObj.tmpEveryWeight = ''
        this.freightObj.tmpAddFreight = ''
        this.freightObj.tmpFullNumPostage = ''
        this.freightObj.tmpFullPostage = ''
      }
    }
  },
  data() {
    return {
      freightObj: Object.assign({}, defaultFreight),
      createTime: '',
      lstUpdTime: '',
      selectDialogVisible: false,
      areaOptions: [],
      selectedIds: [],
      repeatArea: true,
      freightRules: {
        tmpName: [{ required: true, message: '请输入模板名称', trigger: 'blur' }],
        tmpInitialWeight: [{ required: true, message: '请输入首重', trigger: 'blur' }],
        tmpInitialFreight: [{ required: true, message: '请输入首费', trigger: 'blur' }],
        tmpEveryWeight: [{ required: true, message: '请输入续重', trigger: 'blur' }],
        tmpAddFreight: [{ required: true, message: '请输入续费', trigger: 'blur' }],
        tmpFullPostage: [{ required: true, message: '请输入免运费金额', trigger: 'blur' }],
        tmpFullNumPostage: [{ required: true, message: '请输入免运费数量', trigger: 'blur' }]
        // tmpInitialNum: [{ required: true, message: '请输入起订量', trigger: 'blur' }]
      },
      tableData: [],
      currentIndex: null, // 当前选中的行
      currentResv1: null, // 城市名称
      currentResv2: null // 编号--回显
    }
  },
  created() {
    if (this.isEdit) {
      this.$api.suit.getFreightById(this.id).then(response => {
        this.freightObj.tmpName = response.data.tmpName
        this.freightObj.tmpInitialWeight = response.data.tmpInitialWeight
        this.freightObj.tmpInitialFreight = response.data.tmpInitialFreight
        this.freightObj.tmpEveryWeight = response.data.tmpEveryWeight
        this.freightObj.tmpAddFreight = response.data.tmpAddFreight
        this.freightObj.tmpFullPostage = response.data.tmpFullPostage
        this.freightObj.tmpFullNumPostage = response.data.tmpFullNumPostage
        this.freightObj.tmpInitialNum = response.data.tmpInitialNum
        this.tableData = response.data.templateFerighassignsList
      })
    } else {
      this.freightObj = Object.assign({}, defaultFreight)
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    formatTime(time) {
      if (time == null || time === '') {
        return ''
      }
      const date = new Date(time)
      return this.$utils.parseDate(date)
    },
    onSubmit(formName) {
      const that = this
      if (this.tableData.length) {
        this.tableData.forEach(item => {
          if (item.assInitialFreight === '') {
            this.$message.error('首费不能为空！')
            return
          }
          if (item.assInitialWeight === '') {
            this.$message.error('首重不能为空！')
            return
          }
          if (item.assAddFreight === '') {
            this.$message.error('续费不能为空！')
            return
          }
          if (item.assEveryWeight === '') {
            this.$message.error('续重不能为空！')
            return
          }
          if (item.assFullPostage === '') {
            this.$message.error('免运费金额不能为空！')
            return
          }
          if (item.assFullNumPostage === '') {
            this.$message.error('免运费数量不能为空！')
            return
          }
          // if (item.assInitialNum === '') {
          //   this.$message.error('起订量不能为空！')
          //   return
          // }
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.$confirm('是否提交数据', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(async () => {
                if (this.isEdit) {
                  const data = {
                    ...this.freightObj,
                    id: this.id,
                    templateFerighassignsList: this.tableData.map(t => {
                      t.freightId = that.id
                      return t
                    }),
                    isExemption: this.freightObj.freeChecked ? 0 : 1
                  }
                  this.$api.suit.updateFreight(data).then(res => {
                    if (res.code === 200) {
                      this.$refs[formName].resetFields()
                      this.$message({
                        message: '修改成功',
                        type: 'success',
                        duration: 1000
                      })
                      this.close()
                    }
                  })
                } else {
                  const data = {
                    ...this.freightObj,
                    templateFerighassignsList: this.tableData
                  }
                  this.$api.suit.addFreight(data).then(res => {
                    if (res.code === 200) {
                      this.$refs[formName].resetFields()
                      this.freightObj = Object.assign({}, defaultFreight)
                      this.tableData = []
                      this.$message({
                        message: '提交成功',
                        type: 'success',
                        duration: 1000
                      })
                      this.close()
                    }
                  })
                }
              })
            } else {
              this.$message({
                message: '验证失败',
                type: 'error',
                duration: 1000
              })
              return false
            }
          })
        })
      } else {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.$confirm('是否提交数据', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(async () => {
              if (this.isEdit) {
                const data = {
                  ...this.freightObj,
                  id: this.id,
                  templateFerighassignsList: this.tableData
                }
                this.$api.suit.updateFreight(data).then(res => {
                  if (res.code === 200) {
                    this.$refs[formName].resetFields()
                    this.$message({
                      message: '修改成功',
                      type: 'success',
                      duration: 1000
                    })
                    this.$router.back()
                  }
                })
              } else {
                const data = {
                  ...this.freightObj,
                  templateFerighassignsList: this.tableData
                }
                this.$api.suit.addFreight(data).then(res => {
                  if (res.code === 200) {
                    this.$refs[formName].resetFields()
                    this.freightObj = Object.assign({}, defaultFreight)
                    this.tableData = []
                    this.$message({
                      message: '提交成功',
                      type: 'success',
                      duration: 1000
                    })
                  }
                })
              }
            })
          } else {
            this.$message({
              message: '验证失败',
              type: 'error',
              duration: 1000
            })
            return false
          }
        })
      }
    },
    addFreightInfo() {
      this.initTable()
    },
    initTable() {
      this.tableData.push({
        shippingRegion: '',
        assInitialWeight: '',
        assInitialFreight: '',
        assEveryWeight: '',
        assAddFreight: '',
        assFullPostage: '',
        tmpFullNumPostage: '',
        tmpFullPostage: '',
        resv1: '',
        resv2: ''
      })
    },
    handleClick(tab, event) {
      console.log(tab, event)
    },
    pageSizeChange(val) {
      this.size = val
    },
    pageNumberChange(val) {
      this.page = val
    },
    goodsPageSizeChange(val) {
      this.goodsSize = val
    },
    goodsPageNumberChange(val) {
      this.goodsPage = val
    },
    closeAreaDialog() {
      this.selectDialogVisible = false
    },
    selectArea(index, row) {
      this.currentIndex = index
      this.currentResv1 = row.resv1
      this.currentResv2 = row.resv2
      this.selectDialogVisible = true
    },
    addAreaList(val) {
      this.tableData.forEach((el, index) => {
        if (index === this.currentIndex) {
          el.shippingRegion = val.selectedChildrenIds.join(',')
          el.resv1 = val.selectedChildrenNames.join(',')
          el.resv2 = JSON.stringify(val.selectedInfo)
        }
      })
      this.tableData = JSON.parse(JSON.stringify(this.tableData))
    },
    handleDelete(index, row) {
      if (!row.id) {
        this.tableData.splice(index, 1)
        return
      }
      const params = {
        id: row.id
      }
      this.$api.suit.removeFreightAssign(params).then(res => {
        if (res.code === 200) {
          this.$message.success('删除成功')
          this.tableData.splice(index, 1)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.btn-area {
  margin-bottom: 20px;
  button {
    width: 100px;
  }
}
.btn-area1 {
  span {
    font-size: 14px;
    color: crimson;
  }
}
::v-deep .el-table {
  margin: 20px 0;
}
.overflow-hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>

<template>
  <el-dialog title="缴纳保证金" :visible="showPayVisible" width="700px" @close="closePayDialog">
    <el-row class="money-area">
      <p>
        缴纳金额：<span>￥{{ shop.bond | intFilter }}</span>
      </p>
    </el-row>
    <el-row class="pay-area">
      <el-col :span="12" v-for="(item, index) in payList" :key="index">
        <div class="pay-list">
          <el-radio v-model="ruleForm.payType" :label="item.label">
            <div>
              <img :src="item.img" alt="" />
              <span>{{ item.desc }}</span>
            </div>
          </el-radio>
        </div>
      </el-col>
    </el-row>
    <div style="clear: both"></div>
    <div class="btn-area">
      <el-button @click="closePayDialog">取消</el-button>
      <el-button type="primary" @click="pay">确定</el-button>
    </div>

    <wxpayDialog v-if="dialogWxPayVisible" :wx-list-info="wxListInfo" :dialog-wx-pay-visible="dialogWxPayVisible" :source="source" @changeWxDialog="changeWxDialog" @closePayDialog="closePayDialog" />
  </el-dialog>
</template>

<script>
import aliIcon from '@/assets/images/merchants/ali-pay.png'
import wxIcon from '@/assets/images/merchants/wx-pay.png'
import wxpayDialog from './pay/wxpayDialog'

export default {
  props: {
    showPayVisible: {
      type: Boolean,
      default: false
    },
    shop: {
      type: Object,
      default: null
    }
  },
  filters: {
    intFilter(val) {
      if (val === null || val === '') {
        return 0
      }
      if (typeof val === 'undefined') {
        return 0
      }
      return val
    }
  },
  components: {
    wxpayDialog
  },
  data() {
    return {
      aliIcon,
      wxIcon,
      isSelect: 1,
      ruleForm: {
        payType: '',
        totalAmount: '',
        businessType: '0203'
      },
      source: 'chargeMoney',
      wxListInfo: null,
      dialogWxPayVisible: false,
      payList: [
        {
          id: 1,
          desc: '使用支付宝支付',
          img: aliIcon,
          label: 1
        },
        {
          id: 2,
          desc: '使用微信支付',
          img: wxIcon,
          label: 2
        }
      ]
    }
  },
  methods: {
    pay() {
      if (typeof this.shop.bond === 'undefined') {
        this.$message.warning('商户保证金信息错误，请联系管理员查看')
        return
      }
      if (this.ruleForm.payType === null || this.ruleForm.payType === '') {
        this.$message.warning('请选择支付方式')
        return
      }
      let params = {}
      if (this.ruleForm.payType === 1) {
        // 支付宝
        params = {
          subject: '新印网商城',
          totalAmount: this.shop.bond,
          businessType: this.ruleForm.businessType
        }
        this.alipay(params)
      }
      if (this.ruleForm.payType === 2) {
        // 微信
        params = {
          body: '新印网商城',
          totalAmount: this.shop.bond,
          businessType: this.ruleForm.businessType
        }
        this.wxListInfo = params
        this.dialogWxPayVisible = true
      }
    },
    // 调取支付宝支付
    alipay(params) {
      this.$api.pay
        .aliRechargePay(params)
        .then(res => {
          if (res.code === 200) {
            // const divForm = document.getElementsByTagName('divform')
            // if (divForm.length) {
            //   document.body.removeChild(divForm[0])
            // }
            // const div = document.createElement('divform')
            // div.innerHTML = res.data // data就是接口返回的form 表单字符串
            // document.body.appendChild(div)
            // document.forms[0].setAttribute('target', '_blank') // 新开窗口跳转
            // document.forms[0].submit()
            document.querySelector('body').innerHTML = res.data
            const div = document.createElement('div') // 创建div
            div.innerHTML = res.data // 将返回的form 放入div
            document.body.appendChild(div)
            // document.forms[0].setAttribute('target', '_blank')
            document.forms[0].submit()
          }
        })
        .catch(() => {
          this.closePayDialog()
        })
    },
    changeWxDialog(val) {
      this.dialogWxPayVisible = val
    },
    closePayDialog() {
      this.$api.storeAudit
        .getShopById(this.shop.id)
        .then(res => {
          this.shop.bondStatus = res.data.bondStatus
          this.$emit('closePayDialog', this.shop.bondStatus)
        })
        .catch(() => {
          this.$emit('closePayDialog', this.shop.bondStatus)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.money-area {
  margin: 20px 0 50px;
  text-align: center;
  p {
    color: #333333;
    font-size: 14px;
    span {
      color: #ff1a00;
      font-size: 30px;
    }
  }
}
.pay-area {
  padding: 0 50px;
  margin-bottom: 50px;
  .pay-list {
    width: 200px;
    margin: 0 auto;
    border: 1px solid #dcdfe6;
    .el-radio {
      display: flex;
      align-items: center;
      .el-radio__label {
        > div {
          display: flex;
          align-items: center;
          img {
            width: 70px;
            height: 70px;
          }
        }
      }
    }
  }
}
.btn-area {
  text-align: right;
}
</style>

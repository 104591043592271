import commonApi from '@/api/common/api'
import { Message } from 'element-ui'

const state = {
  // 商城优惠券类型
  couponType: [],
  // 商城广告位置
  advertiseType: [],
  // 商城订单状态
  orderStatus: [],
  // 商城订单操作记录状态
  orderOperationStatus: [],
  // 商城订单分类
  orderType: [],
  // 商城退货申请状态
  applyStatus: [],
  // 商城退款申请状态
  refundStatus: [],
  // 商城换货申请状态
  changeStatus: [],
  // 订单来源
  orderSource: [],
  // 商品类型
  shopProductType: [],
  companyList: [], // 所有商家列表
  businessTypeList: [], // 业务类型
  downloadList: [],
  memberLevelList: []
}

const mutations = {
  CHANGE_COUPONTYPE: (state, couponType) => {
    state.couponType = couponType
  },
  BUSINESS_TYPE: (state, businessTypeList) => {
    state.businessTypeList = businessTypeList
  },
  CHANGE_ADVERTISETYPE: (state, advertiseType) => {
    state.advertiseType = advertiseType
  },
  CHANGE_ORDERSTATUS: (state, orderStatus) => {
    state.orderStatus = orderStatus
  },
  CHANGE_ORDEROPERATIONSTATUS: (state, orderOperationStatus) => {
    state.orderOperationStatus = orderOperationStatus
  },
  CHANGE_ORDERTYPE: (state, orderType) => {
    state.orderType = orderType
  },
  CHANGE_APPLYSTATUS: (state, applyStatus) => {
    state.applyStatus = applyStatus
  },
  CHANGE_REFUNDSTATUS: (state, refundStatus) => {
    state.refundStatus = refundStatus
  },
  CHANGE_CHANGESTATUS: (state, changeStatus) => {
    state.changeStatus = changeStatus
  },
  CHANGE_ORDERSOURCE: (state, orderSource) => {
    state.orderSource = orderSource
  },
  CHANGE_SHOPPRODCTTYPE: (state, shopProductType) => {
    state.shopProductType = shopProductType
  },
  CHANGE_COMPANYLIST: (state, companyList) => {
    state.companyList = companyList
  },
  DOWNLOAD_LIST(state, downloadList) {
    state.downloadList = downloadList
  },
  CHANGE_MEMBERLEVEL: (state, memberLevelList) => {
    state.memberLevelList = memberLevelList
  }
}

const actions = {
  async change_coupontype({ commit }) {
    const data = await getDicts('shopweb_couponType')
    commit('CHANGE_COUPONTYPE', data)
  },
  async BUSINESS_TYPE({ commit }) {
    const data = await getDicts('BUSINESS_TYPE')
    commit('BUSINESS_TYPE', data)
  },
  async change_advertisetype({ commit }) {
    const data = await getDicts('SHOP_ADVERTISE_LOCATION')
    commit('CHANGE_ADVERTISETYPE', data)
  },
  async change_orderstatus({ commit }) {
    const data = await getDicts('SHOP_ORDER_STATUS')
    commit('CHANGE_ORDERSTATUS', data)
  },
  async change_orderOperationStatus({ commit }) {
    const data = await getDicts('SHOP_ORDER_OPERATION_STATUS')
    commit('CHANGE_ORDEROPERATIONSTATUS', data)
  },
  async change_ordertype({ commit }) {
    const data = await getDicts('SHOP_ORDER_TYPE')
    commit('CHANGE_ORDERTYPE', data)
  },
  async change_applystatus({ commit }) {
    const data = await getDicts('SHOP_APPLY_STATUS')
    commit('CHANGE_APPLYSTATUS', data)
  },
  async change_refundstatus({ commit }) {
    const data = await getDicts('SHOP_REFUND_STATUS')
    commit('CHANGE_REFUNDSTATUS', data)
  },
  async change_changestatus({ commit }) {
    const data = await getDicts('SHOP_CHANGE_STATUS')
    commit('CHANGE_CHANGESTATUS', data)
  },
  async change_ordersource({ commit }) {
    const data = await getDicts('SHOP_ORDER_SOURCE')
    commit('CHANGE_ORDERSOURCE', data)
  },
  async change_shopProductType({ commit }) {
    const data = await getDicts('SHOP_PRODUCT_TYPE')
    commit('CHANGE_SHOPPRODCTTYPE', data)
  },
  async change_companyList({ commit }) {
    const data = await commonApi.getCompanyList()
    console.log('data:', data)
    commit('CHANGE_COMPANYLIST', data)
  },
  async DOWNLOAD_LIST({ commit }) {
    const data = await getDicts('DOWNLOAD_STATUS')
    commit('DOWNLOAD_LIST', data)
  },
  async change_memberLevelList({ commit }) {
    const data = await getDicts('SYSTEM_VIP_LEVEL')
    commit('CHANGE_MEMBERLEVEL', data)
  }
}

// eslint-disable-next-line no-unused-vars
function getDicts(dictKey) {
  return new Promise((resolve, reject) => {
    commonApi
      .getDict({ dictKey: dictKey })
      .then(response => {
        if (response.code === 200) {
          response.data.map(item => {
            item.value = parseInt(item.dictValue)
            item.label = item.dictLabel
          })
          resolve(response.data)
        } else {
          Message.error(response.msg)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

export default {
  state,
  mutations,
  actions
}

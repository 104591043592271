<template>
  <div class="rankBox">
    <div class="headBox">
      <div class="navBox">
        <p v-for="(item,index) in navTtile" :key="index" :class="item.active === active ? 'active' : ''" @click="handleTab(item.active)">{{item.title}}</p>
      </div>
      <div class="dropBox">
        <el-dropdown  @command="handleCommand">
          <span class="el-dropdown-link">
            {{timeInit}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in timeSelectArry" :key="index" :command="item.command">{{item.title}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="tableBox">
      <div v-if="active === 0">
        <el-table ref="enterTable" :data="browserEnterList" style="width: 100%;height:397px;" v-loading="listLoading" border>
          <!--        <el-table-column type="selection" width="60" align="center" />-->
          <el-table-column label="序号" width="100" align="center">
            <template slot-scope="scope">{{ (pageNumber-1) * 10 + scope.$index+1 }}</template>
          </el-table-column>
          <el-table-column label="企业名称" width="330" align="center">
            <template slot-scope="scope">{{ scope.row.companyName }}</template>
          </el-table-column>
          <el-table-column label="数量（人）" width="102" align="center">
            <template slot-scope="scope">{{ scope.row.shopCount }}</template>
          </el-table-column>
          <el-table-column label="印刷分类" width="262" align="center">
            <template slot-scope="scope">
              <el-popover trigger="hover" placement="top">
                <p>{{ handleFormat(scope.row.companyCategory) }}</p>
                <div slot="reference" class="name-wrapper">{{ handleFormat(scope.row.companyCategory) }}</div>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="active === 1">
        <el-table ref="enterTable" :data="browserProductList" style="width: 100%;height:397px;" v-loading="listLoading" border>
          <!--        <el-table-column type="selection" width="60" align="center" />-->
          <el-table-column label="序号" width="100" align="center">
            <template slot-scope="scope">{{ (pageNumber-1) * 10 + scope.$index+1 }}</template>
          </el-table-column>
          <el-table-column label="产品名称" width="330" align="center">
            <template slot-scope="scope">
              <el-popover trigger="hover" placement="top">
                <p>{{scope.row.companyName}}</p>
                <div slot="reference" class="name-wrapper">{{ scope.row.companyName }}</div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="数量（人）" width="102" align="center">
            <template slot-scope="scope">{{ scope.row.shopCount }}</template>
          </el-table-column>
          <el-table-column label="印刷分类" width="262" align="center">
            <template slot-scope="scope">{{ scope.row.companyCategory }}</template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-container">
        <el-pagination background layout="prev, pager, next" :page-size="pageSize" :page-sizes="[5,10, 20, 30, 50, 100]" :current-page.sync="pageNumber" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      active: 0,
      navTtile:[{
        title:'浏览过的企业排行',
        id:1,
        active:0
      },{
        title:'浏览过的产品排行',
        id:2,
        active:1
      }],
      listLoading: false,
      browserEnterList:[],
      browserProductList:[],
      startTime: '',
      endTime: '',
      total:0,
      pageNumber: 1,
      pageSize: 10,
      timeSelectArry:[{
        title: '近7日',
        command: 'a'
      },{
        title: '近1月',
        command: 'b'
      },{
        title: '近6个月',
        command: 'c'
      },{
        title: '近1年',
        command: 'd'
      }],
      timeInit: '近7日',
    }
  },
  mounted() {
    const now = new Date()
    this.startTime = this.$utils.parseDate(now.getTime() - 3600 * 1000 * 24 * 7, '{y}-{m}-{d}')
    // this.endTime = this.$utils.parseDate(now, '{y}-{m}-{d}')
    this.browserEnterQuery()
  },
  methods:{
    handleFormat(arr){
      let strArr = arr ? JSON.parse(arr) : []
      let str = ''
      str = strArr.map(item => {
        return item.categoryName
      })
      return str.join(',')
    },
    handleTab (item) {
      this.pageNumber =  1
      this.pageSize = 10
      this.active = item
      if(this.active === 0){
        this.browserEnterQuery()
      } else {
        this.browserProductQuery()
      }
    },
    handleCommand(commond){
      this.pageNumber =  1
      this.pageSize = 10
      switch (commond) {
        case 'a' :
          this.timeInit =  this.timeSelectArry[0].title
          var now = new Date()
          this.startTime = this.$utils.parseDate(now.getTime() - 3600 * 1000 * 24 * 7, '{y}-{m}-{d}')
          this.endTime = this.$utils.parseDate(now, '{y}-{m}-{d}')
          break;
        case 'b' :
          this.timeInit =  this.timeSelectArry[1].title
          var now2 = new Date()
          this.startTime = this.$utils.parseDate(new Date().setMonth(now2.getMonth() - 1), '{y}-{m}-{d}')
          this.endTime = this.$utils.parseDate(now2, '{y}-{m}-{d}')
          break;
        case 'c' :
          this.timeInit =  this.timeSelectArry[2].title
          var now3 = new Date()
          this.startTime = this.$utils.parseDate(new Date().setMonth(now3.getMonth() - 6), '{y}-{m}-{d}')
          this.endTime = this.$utils.parseDate(now3, '{y}-{m}-{d}')
          break;
        case 'd' :
          this.timeInit =  this.timeSelectArry[3].title
          var now4 = new Date()
          this.startTime = this.$utils.parseDate(new Date().setMonth(now4.getMonth() - 12), '{y}-{m}-{d}')
          this.endTime = this.$utils.parseDate(now4, '{y}-{m}-{d}')
          break;
      }
      if(this.active === 0){
        this.browserEnterQuery()
      } else {
        this.browserProductQuery()
      }
    },
    handleSizeChange(val){
      this.pageSize = val
      if(this.active === 0){
        this.browserEnterQuery()
      } else {
        this.browserProductQuery()
      }
    },
    handleCurrentChange(val){
      this.pageNumber = val
      if(this.active === 0){
        this.browserEnterQuery()
      } else {
        this.browserProductQuery()
      }

    },
    browserProductQuery(){
      let params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        startTime:this.startTime,
        endTime: this.endTime
      }
      this.$api.home.browserProductReq(params).then(res => {
        this.browserProductList = res.data.data
        this.total = res.data.size > 20 ? 20 : res.data.size
      })
    },
    browserEnterQuery(){
      let params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        startTime:this.startTime
        // endTime: this.endTime
      }
      this.$api.home.browserEnterReq(params).then(res => {
        if(res.code === 200){
          this.browserEnterList = res.data.data
          console.log(this.browserEnterList,"哈哈哈哈浏览过的企业排行")
          this.total = res.data.size > 20 ? 20 : res.data.size
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.rankBox{
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  width: 49.5%;
  padding: 18px 15px;
}
.navBox{
  display:flex;
  p{
    margin-bottom: 0;
    margin-top: 0;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333;
    margin-right: 16px;
    cursor: pointer;
  }
  p.active{
    color: #4766C1;
  }
}
.tableBox{
  margin-top: 20px;
}
.headBox{
  display:flex;
  justify-content: space-between;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.name-wrapper{
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
</style>
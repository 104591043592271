<template>
  <div class="app-container">
    <el-card class="header-card" shadow="never">
      <!-- <div>
        <i class="el-icon-search"></i>
        <span>筛选搜索</span>
        <el-button style="float: right" type="primary" size="small" @click="handleSearchList()"> 查询搜索 </el-button>
        <el-button style="float: right; margin-right: 15px" size="small" @click="handleResetSearch()"> 重置 </el-button>
      </div> -->
      <div style="margin-top: 15px">
        <el-form :inline="true" :model="listQuery" size="small" label-width="140px">
          <el-form-item label="评价内容：">
            <el-input v-model="listQuery.evalContent" class="input-width" placeholder="评价内容" />
          </el-form-item>
          <el-form-item label="评分：">
            <el-select v-model="listQuery.evalLevel" placeholder="全部" clearable class="input-width">
              <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="评价用户：">
            <el-input v-model="listQuery.userName" class="input-width" placeholder="评价用户" />
          </el-form-item>
          <el-form-item v-if="isManageMentRole" label="店铺名称：">
            <el-select v-model="listQuery.shopId" class="input-width" placeholder="全部" clearable>
              <el-option v-for="item in ShopNameOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-form>
        <el-row class="search-area-btn">
          <div class="left">
            <el-button type="primary" class="btn-add" size="mini" @click="handleAddComment()">添加商品评价</el-button>
            <el-select v-model="operateType" size="small" placeholder="批量操作">
              <el-option v-for="item in operates" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <el-button style="margin-left: 20px" class="search-button" type="primary" size="small" @click="handleBatchOperate()"> 确定 </el-button>
          </div>
          <div class="center">
            <el-button type="primary" size="small" @click="handleSearchList()">查询</el-button>
            <el-button size="small" @click="handleResetSearch()">重置</el-button>
          </div>
        </el-row>
      </div>
    </el-card>
    <!-- <el-card class="operate-container" shadow="never">
      <i class="el-icon-tickets"></i>
      <span>数据列表</span>
    </el-card> -->
    <div class="table-container">
      <el-table ref="homeAdvertiseTable" v-loading="listLoading" :data="list" style="width: 100%" border @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="60" align="center" />
        <el-table-column label="编号" width="120" align="center">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column label="商品" width="200" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.productName }}</template>
        </el-table-column>
        <el-table-column label="所属店铺" width="120" align="center">
          <template slot-scope="scope">{{ scope.row.shopName }}</template>
        </el-table-column>
        <el-table-column label="评价内容" width="300" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.evalContent }}</template>
        </el-table-column>
        <el-table-column label="评价图片" align="center">
          <template slot-scope="scope"><img style="height: 80px" :src="scope.row.img" /></template>
        </el-table-column>
        <el-table-column label="评分" align="center">
          <template slot-scope="scope">{{ scope.row.evalLevel }}星</template>
        </el-table-column>
        <el-table-column label="用户" align="center">
          <template slot-scope="scope">{{ scope.row.userName }}</template>
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <!-- <el-button
              size="mini"
              type="text"
              @click="handleUpdate(scope.$index, scope.row)"
              >编辑
            </el-button> -->
            <el-button size="mini" type="text" @click="handleDelete(scope.$index, scope.row)">删除 </el-button>
            <el-button size="mini" type="text" :disabled="scope.row.isEvaluate === 1" @click="handleReply(scope.$index, scope.row)">回复 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination background layout="total, sizes,prev, pager, next,jumper" :page-size="listQuery.pageSize" @size-change="handleSizeChange" :page-sizes="[5, 10, 20, 30, 50, 100]" @current-change="handleCurrentChange" :current-page.sync="listQuery.pageNumber" :total="total" />
    </div>

    <component :is="CommentsDetail" v-if="CommentsDetailShow" :is-edit="false" @close="close" />
  </div>
</template>
<script>
import { mapState } from 'vuex'

const defaultListQuery = {
  pageNumber: 1,
  pageSize: 10,
  evalContent: null,
  evalLevel: null,
  userName: null,
  shopId: null
}
const defaultTypeOptions = [
  {
    label: '1星',
    value: 1
  },
  {
    label: '2星',
    value: 2
  },
  {
    label: '3星',
    value: 3
  },
  {
    label: '4星',
    value: 4
  },
  {
    label: '5星',
    value: 5
  }
]
export default {
  name: 'HomeAdvertiseList',
  components: {
    CommentsDetail: () => import('./components/CommentsDetail')
  },
  data() {
    return {
      CommentsDetail: 'CommentsDetail',
      CommentsDetailShow: false,
      listQuery: Object.assign({}, defaultListQuery),
      typeOptions: Object.assign({}, defaultTypeOptions),
      list: null,
      total: null,
      listLoading: false,
      multipleSelection: [],
      ShopNameOptions: [],
      operates: [
        {
          label: '删除',
          value: 0
        }
      ],
      operateType: null
    }
  },
  created() {
    this.getList()
    this.getShopNameList()
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState({
      isManageMentRole: state => state.user.userRole
    })
  },
  // eslint-disable-next-line vue/order-in-components

  methods: {
    formatTime(time) {
      if (time == null || time === '') {
        return 'N/A'
      }
      const date = new Date(time)
      return this.$utils.parseDate(date, 'yyyy-MM-dd hh:mm:ss')
    },
    close() {
      this.CommentsDetailShow = false
    },
    // 回复
    handleReply(index, row) {
      this.$prompt('请输入回复内容', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        const params = {
          evalContent: value,
          evalFrom: 2,
          parentId: row.id,
          userIs: 1,
          productId: row.productId,
          // eslint-disable-next-line no-dupe-keys
          parentId: row.id,
          orderId: row.orderId,
          img: row.img,
          evalLevel: 5
        }
        this.$api.comment.saveComment(params).then(res => {
          if (res.code === 200) {
            this.getList()
            this.$message({
              type: 'success',
              message: '回复成功!'
            })
          } else {
            this.$message({
              type: 'success',
              message: res.msg
            })
          }
        })
      })
    },
    handleResetSearch() {
      this.listQuery = Object.assign({}, defaultListQuery)
    },
    handleSearchList() {
      this.listQuery.pageNumber = 1
      this.getList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleSizeChange(val) {
      this.listQuery.pageNumber = 1
      this.listQuery.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.pageNumber = val
      this.getList()
    },
    // handleUpdateStatus(index, row) {
    //   this.$confirm("是否要修改上线/下线状态?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning"
    //   })
    //     .then(() => {
    //       updateStatus(row.id, { status: row.status }).then(response => {
    //         this.getList();
    //         this.$message({
    //           type: "success",
    //           message: "修改成功!"
    //         });
    //       });
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: "success",
    //         message: "已取消操作!"
    //       });
    //       this.getList();
    //     });
    // },
    handleDelete(index, row) {
      this.removeComment(row.id)
    },
    handleBatchOperate() {
      if (this.multipleSelection < 1) {
        this.$message({
          message: '请选择一条记录',
          type: 'warning',
          duration: 1000
        })
        return
      }
      const ids = []
      for (let i = 0; i < this.multipleSelection.length; i++) {
        ids.push(this.multipleSelection[i].id)
      }
      if (this.operateType === 0) {
        // 删除
        this.removeComment(ids)
      } else {
        this.$message({
          message: '请选择批量操作类型',
          type: 'warning',
          duration: 1000
        })
      }
    },
    handleAddComment() {
      this.CommentsDetailShow = true
    },
    // handleAdd() {
    //   this.$router.push({ path: "/sms/addAdvertise" });
    // },
    handleUpdate(index, row) {
      this.$router.push({
        path: '/sms/updateAdvertise',
        query: { id: row.id }
      })
    },
    getList() {
      this.listLoading = true
      this.$api.comment.commentList(this.listQuery).then(response => {
        this.listLoading = false
        response.data.list.map(item => {
          item.img = item.img ? item.img.split(',')[0] : ''
        })
        this.list = response.data.list
        this.total = response.data.total
      })
    },
    getShopNameList() {
      this.$api.common.getShopList({ pageNum: 1, pageSize: 100 }).then(response => {
        this.ShopNameOptions = []
        const ShopList = response.data
        for (let i = 0; i < ShopList.length; i++) {
          this.ShopNameOptions.push({
            label: ShopList[i].shopName,
            value: ShopList[i].id
          })
        }
      })
    },
    removeComment(ids) {
      this.$confirm('是否要删除该评论?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = new URLSearchParams()
        params.append('id', ids)
        let data = {
          id: ids
        }
        this.$api.comment.removeComment(data).then(() => {
          this.getList()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
      })
    }
  }
}
</script>
<style scoped>
.input-width {
  width: 203px;
}
</style>

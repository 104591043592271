<template>
  <div class="app-container">
    <el-card class="header-card">
      <el-row class="search-area-btn">
        <div class="left"><el-button type="primary" class="btn-add" size="mini" @click="addArea()">添加</el-button></div>
      </el-row>
    </el-card>
    <div class="table-container">
      <el-row v-if="list && list.length">
        <el-row v-for="(item, index) in list" :key="index" class="top">
          <el-row class="top-area">
            <el-row class="top-area-l"> 模板名称：{{ item.tmpName }} </el-row>
            <el-row class="top-area-r">
              <el-row class="top-area-r-l">最后修改时间：{{ formatTime(item.lstUpdTime) }}</el-row>
              <el-row class="top-area-r-r">
                <el-link type="primary" :underline="false" @click="handleViewDetail(index, item.id)">修改</el-link>|
                <el-link type="danger" :underline="false" @click="handleDelete(index, item.id)">删除</el-link>
              </el-row>
            </el-row>
          </el-row>
          <el-table :data="item.templateFerighassignsList" border>
            <el-table-column label="送货" align="center">
              <template slot-scope="scope">
                <el-popover placement="top-start" title="送货" trigger="hover">
                  <div v-html="scope.row.resv1"></div>
                  <div slot="reference" class="drap-column overflow-hidden" v-html="scope.row.resv1"></div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="首重（KG）" align="center" width="100">
              <template slot-scope="scope">{{ scope.row.assInitialWeight }}</template>
            </el-table-column>
            <el-table-column label="首费（元）" align="center" width="100">
              <template slot-scope="scope">{{ scope.row.assInitialFreight }}</template>
            </el-table-column>
            <el-table-column label="续重（KG）" align="center" width="100">
              <template slot-scope="scope">{{ scope.row.assEveryWeight }}</template>
            </el-table-column>
            <el-table-column label="续费（元）" align="center" width="100">
              <template slot-scope="scope">{{ scope.row.assAddFreight }}</template>
            </el-table-column>
            <el-table-column label="免运费数量" align="center" width="100">
              <template slot-scope="scope">{{ scope.row.assFullNumPostage }}</template>
            </el-table-column>
            <el-table-column label="免运费金额" align="center" width="100">
              <template slot-scope="scope">{{ scope.row.assFullPostage }}</template>
            </el-table-column>

            <!-- <el-table-column label="起订量" align="center" width="100">
              <template slot-scope="scope">{{ scope.row.assInitialNum }}</template>
            </el-table-column> -->
          </el-table>
        </el-row>
      </el-row>
      <el-row v-else> 暂无数据 </el-row>
    </div>
    <div v-if="false" class="batch-operate-container">
      <el-select v-model="operateType" size="small" placeholder="批量操作">
        <el-option v-for="item in operateOptions" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <el-button style="margin-left: 20px" class="search-button" type="primary" size="small" @click="handleBatchOperate()"> 确定 </el-button>
    </div>
    <div class="pagination-container">
      <el-pagination background layout="total, sizes,prev, pager, next,jumper" :current-page.sync="listQuery.pageNumber" :page-size="listQuery.pageSize" :page-sizes="[5, 10, 20, 30, 50, 100]" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
    <freightDetail v-if="freightDetailShow" :id="freightDetailId" :is-edit="freightDetailEdit" @close="close" />
  </div>
</template>
<script>
import freightDetail from './components/FreightDetail'
const defaultListQuery = {
  pageNumber: 1,
  pageSize: 5,
  packageName: ''
}
export default {
  name: 'ReturnApplyList',
  components: {
    freightDetail
  },
  data() {
    return {
      freightDetailShow: false,
      freightDetailEdit: false,
      freightDetailId: null,
      listQuery: Object.assign({}, defaultListQuery),
      list: null,
      total: null,
      listLoading: false,
      multipleSelection: [],
      operateType: 1,
      operateOptions: [
        {
          label: '批量删除',
          value: 1
        }
      ]
    }
  },
  created() {
    this.getList()
  },
  methods: {
    formatTime(time) {
      if (time == null || time === '') {
        return ''
      }
      const date = new Date(time)
      return this.$utils.parseDate(date)
    },
    close() {
      this.freightDetailShow = false
      this.freightDetailEdit = false
      this.freightDetailId = ''
      this.getList()
    },
    handleDelete(index, id) {
      this.$confirm('是否要进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          id
        }
        this.$api.suit.removeFreight(params).then(() => {
          this.getList()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleResetSearch() {
      this.listQuery = Object.assign({}, defaultListQuery)
    },
    handleSearchList() {
      this.listQuery.pageNumber = 1
      this.getList()
    },
    handleViewDetail(index, id) {
      this.freightDetailEdit = true
      this.freightDetailId = id
      this.freightDetailShow = true
    },
    handleBatchOperate() {
      if (this.multipleSelection == null || this.multipleSelection.length < 1) {
        this.$message({
          message: '请选择要操作的申请',
          type: 'warning',
          duration: 1000
        })
        return
      }
      if (this.operateType === 1) {
        // 批量删除
        this.$confirm('是否要进行删除操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // const params = new URLSearchParams()
          // const ids = []
          // for (let i = 0; i < this.multipleSelection.length; i++) {
          //   ids.push(this.multipleSelection[i].id)
          // }
          // params.append('ids', ids)
        })
      }
    },
    handleSizeChange(val) {
      this.listQuery.pageNumber = 1
      this.listQuery.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.pageNumber = val
      this.getList()
    },
    getList() {
      this.listLoading = true
      this.$api.suit.getFreightList(this.listQuery).then(response => {
        this.listLoading = false
        this.list = response.data.list
        this.total = response.data.total
        if (this.list && this.list.length) {
          this.list.forEach(el => {
            el.templateFerighassignsList.unshift({
              resv1: '未被指定的区域将归于该模板的快递默认运费',
              assAddFreight: el.tmpAddFreight,
              assEveryWeight: el.tmpEveryWeight,
              assInitialFreight: el.tmpInitialFreight,

              assInitialWeight: el.tmpInitialWeight,
              assFullNumPostage: el.tmpFullNumPostage,
              assFullPostage: el.tmpFullPostage,
              assInitialNum: el.tmpInitialNum
            })
          })
        }
      })
    },
    addArea() {
      this.freightDetailShow = true
      this.freightDetailId = ''
      this.freightDetailEdit = false
    }
  }
}
</script>
<style lang="scss" scoped>
.input-width {
  width: 203px;
}
::v-deep .table-container {
  color: #333333;
  .top {
    margin-bottom: 50px;
    font-size: 14px;
    .top-area {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      .top-area-r {
        display: flex;
        justify-content: space-between;
        .top-area-r-l {
          margin-right: 20px;
          span {
            color: #0094ff;
            cursor: pointer;
          }
        }
        .top-area-r-r {
          .el-link {
            cursor: pointer;
            margin-left: 10px;
            &:first-child {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

.overflow-hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>

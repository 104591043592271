import request from '@/api/request'

const comment = {
  commentList(params) {
    return request({
      url: '/expositionShop/evaluate/list',
      method: 'get',
      params: params
    })
  },

  // 商品评价-删除
  removeComment(params) {
    return request({
      url: '/expositionShop/evaluate/remove',
      method: 'get',
      params: params
    })
  },

  // 商品评价-修改
  updateComment(params) {
    return request({
      url: '/expositionShop/evaluate/update',
      method: 'POST',
      params: params
    })
  },

  // 商品评价-新增
  saveComment(params) {
    return request({
      url: '/expositionShop/evaluate/save',
      method: 'POST',
      params: params
    })
  },

  // 新增虚拟评价
  saveVirtualComment(data) {
    return request({
      url: '/expositionShop/evaluate/saveVirtual',
      method: 'POST',
      data
    })
  },
  // 获取机器人列表
  fetchRobotList(params) {
    return request({
      url: '/expositionShop/rear/group/robot/get/list',
      method: 'get',
      params: params
    })
  }
}

export default comment

<template>
  <div>
    <el-dialog v-dialogDrag append-to-body :close-on-click-modal="false" :visible="true" :title="(this.formData.id ? '编辑' : '新增') + '分类'" top="10vh" width="35%" @close="close">
      <div class="main-content" />
      <el-form ref="formData" :inline="true" :model="formData" :rules="rules" label-width="80px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="分类名称" prop="categoryName">
              <el-input v-model="formData.categoryName" style="width: 200px" size="mini" maxlength="50" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排序">
              <el-input v-model="formData.sort" style="width: 200px" v-enter-number maxlength="9" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="分类描述" prop="description">
              <el-input v-model="formData.description" type="textarea" style="width: 515px" maxlength="300" rows="4" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveClick()">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    obj: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      loading: false,
      formData: {
        categoryName: '',
        description: '',
        sort: 0
      },
      rules: {
        categoryName: [{ required: true, message: '请输入分类名称', trigger: 'blur' }]
      }
    }
  },
  computed: {},
  created() {
    if (this.obj && Object.keys(this.obj).length > 0) {
      this.formData = {
        ...this.obj
      }
    }
  },
  methods: {
    saveClick() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          if (this.formData.id) {
            if (!this.formData.categoryName.replace(/\s+/g, '')) {
              this.$message.warning('分类名称格式不正确！')
              return
            }
            this.$api.newsClass.categoryUpdate(this.formData).then(res => {
              if (res.code === 200) {
                this.$message.success('修改成功')
                this.$emit('refreshData')
              }
            })
          } else {
            if (!this.formData.categoryName.replace(/\s+/g, '')) {
              this.$message.warning('分类名称格式不正确！')
              return
            }
            this.$api.newsClass.categorySave(this.formData).then(res => {
              if (res.code === 200) {
                this.$message.success('新增成功')
                this.$emit('refreshData')
              }
            })
          }
        }
      })
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
}
::v-deep.el-form {
}
</style>

<template>
  <div style="margin-top: 50px">
    <el-form :model="value" ref="productSaleForm" label-width="120px" style="width: 60%; margin-left: 20%" size="small">
      <el-form-item label="赠送积分：">
        <el-input v-model="value.giftPoint" :disabled="(isEdit && !copy && isVerify) || !value.beBuy" />
      </el-form-item>
      <el-form-item label="赠送成长值：">
        <el-input v-model="value.giftGrowth" :disabled="(isEdit && !copy && isVerify) || !value.beBuy" />
      </el-form-item>
      <el-form-item label="积分购买限制：">
        <el-input v-model="value.usePointLimit" :disabled="(isEdit && !copy && isVerify) || !value.beBuy" />
      </el-form-item>
      <el-form-item v-if="false" label="商品上架：">
        <el-switch v-model="value.publishStatus" :active-value="1" :inactive-value="0" :disabled="(isEdit && !copy && isVerify) || !value.beBuy" />
      </el-form-item>
      <el-form-item label="商品推荐：">
        <span style="margin-right: 10px">新品</span>
        <el-switch v-model="value.newStatus" :active-value="1" :disabled="(isEdit && !copy && isVerify && isPublish) || !value.beBuy" :inactive-value="0" />
        <span style="margin-left: 10px; margin-right: 10px">推荐</span>
        <el-switch v-model="value.recommandStatus" :active-value="1" :disabled="(isEdit && !copy && isVerify && isPublish) || !value.beBuy" :inactive-value="0" />
      </el-form-item>
      <el-form-item label="商品备注：">
        <el-input v-model="value.note" type="textarea" :autoSize="true" :disabled="(isEdit && !copy && isVerify && isPublish) || !value.beBuy" />
      </el-form-item>
      <div style="text-align: center">
        <el-button size="medium" @click="handlePrev">上一步，填写商品信息</el-button>
        <el-button type="primary" size="medium" @click="handleNext">下一步，填写商品属性</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'ProductSaleDetail',
  props: {
    value: Object,
    isEdit: {
      type: Boolean,
      default: false
    },
    copy: {
      type: Boolean,
      default: false
    },
    isPublish: {
      type: Boolean,
      default: false
    },
    isVerify: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      //日期选择器配置
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        }
      }
    }
  },
  created() {
    if (this.isEdit) {
      // this.handleEditCreated();
    } else {
      this.$api.product.memberLevelList({ defaultStatus: 0 }).then(response => {
        let memberPriceList = []
        for (let i = 0; i < response.listData.length; i++) {
          let item = response.listData[i]
          memberPriceList.push({
            memberLevelId: item.id,
            memberLevelName: item.name
          })
        }
        this.value.memberPriceList = memberPriceList
      })
    }
  },
  computed: {
    //选中的服务保证
    selectServiceList: {
      get() {
        let list = []
        if (this.value.serviceIds === undefined || this.value.serviceIds == null || this.value.serviceIds === '') return list
        let ids = this.value.serviceIds.split(',')
        for (let i = 0; i < ids.length; i++) {
          list.push(Number(ids[i]))
        }
        return list
      },
      set(newValue) {
        let serviceIds = ''
        if (newValue != null && newValue.length > 0) {
          for (let i = 0; i < newValue.length; i++) {
            serviceIds += newValue[i] + ','
          }
          if (serviceIds.endsWith(',')) {
            serviceIds = serviceIds.substr(0, serviceIds.length - 1)
          }
          this.value.serviceIds = serviceIds
        } else {
          this.value.serviceIds = null
        }
      }
    }
  },
  methods: {
    handleEditCreated() {
      let ids = this.value.serviceIds.split(',')
      console.log('handleEditCreated', ids)
      for (let i = 0; i < ids.length; i++) {
        this.selectServiceList.push(Number(ids[i]))
      }
    },
    handleRemoveProductLadder(index) {
      let productLadderList = this.value.productLadderList
      if (productLadderList.length === 1) {
        productLadderList.pop()
        productLadderList.push({
          count: 0,
          discount: 0,
          price: 0
        })
      } else {
        productLadderList.splice(index, 1)
      }
    },
    handleAddProductLadder() {
      let productLadderList = this.value.productLadderList
      if (productLadderList.length < 3) {
        productLadderList.push({
          count: 0,
          discount: 0,
          price: 0
        })
      } else {
        this.$message({
          message: '最多只能添加三条',
          type: 'warning'
        })
      }
    },
    handleRemoveFullReduction(index) {
      let fullReductionList = this.value.productFullReductionList
      if (fullReductionList.length === 1) {
        fullReductionList.pop()
        fullReductionList.push({
          fullPrice: 0,
          reducePrice: 0
        })
      } else {
        fullReductionList.splice(index, 1)
      }
    },
    handleAddFullReduction() {
      let fullReductionList = this.value.productFullReductionList
      if (fullReductionList.length < 3) {
        fullReductionList.push({
          fullPrice: 0,
          reducePrice: 0
        })
      } else {
        this.$message({
          message: '最多只能添加三条',
          type: 'warning'
        })
      }
    },
    handlePrev() {
      this.$emit('prevStep')
    },
    handleNext() {
      this.$emit('nextStep')
    }
  }
}
</script>

<style scoped>
.littleMargin {
  margin-top: 10px;
}
</style>

<template>
  <el-dialog title="修改密码" append-to-body :visible.sync="showDialog" width="25%" center :close-on-click-modal="closeOnClickModal" :before-close="handleClose">
    <!-- 修改密码 -->
    <div class="box-content telchange">
      <el-form ref="passwordForm" class="telchange-telform" :model="passwordForm" label-width="95px" size="small" :rules="passwordRules">
        <el-form-item label="原密码：" prop="oldPassword">
          <el-input v-model="passwordForm.oldPassword" type="password" class="tel-input" placeholder="请输入原登录密码" />
        </el-form-item>
        <el-form-item label="新密码：" prop="newPassword">
          <el-input v-model="passwordForm.newPassword" type="password" class="tel-input" placeholder="字母、数字和特殊字符，6~18位字符" />
        </el-form-item>
        <el-form-item label="确认密码：" prop="rePassword">
          <el-input v-model="passwordForm.rePassword" type="password" class="tel-input" placeholder="请输入确认密码" />
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer">
      <el-button type="primary" :disabled="submitBtn" @click="submitPassword('passwordForm')">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { removeToken, removePlatform } from '@/utils/storage'
export default {
  props: {},
  data() {
    var validateRePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.passwordForm.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      submitBtn: false,
      closeOnClickModal: false,
      showDialog: true,
      passwordForm: {
        oldPassword: '',
        newPassword: '',
        rePassword: ''
      },
      passwordRules: {
        oldPassword: [{ required: true, message: '请输入原登录密码', trigger: 'blur' }],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, max: 18, message: '长度在 6 到 20 个字符', trigger: 'blur' },
          { pattern: /^[\u0021-\u007E]{6,18}$/, message: '请输入正确的密码格式' }
        ],
        rePassword: [{ required: true, validator: validateRePass, trigger: 'blur' }]
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('closeDialog', false)
    },
    // 修改密码提交
    submitPassword(formName) {
      const sha256 = require('js-sha256').sha256 // 这里用的是require方法，所以没用import
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true
          const data = {
            oldPassword: sha256(this.passwordForm.oldPassword),
            password: sha256(this.passwordForm.newPassword)
          }
          this.submitBtn = true
          this.$api.common
            .changeUserPwd(data)
            .then(res => {
              if (res.code === 200) {
                this.$message({
                  type: 'success',
                  message: '密码修改成功,请重新登录',
                  duration: 1000,
                  onClose: () => {
                    this.submitBtn = false
                    removeToken()
                    removePlatform()
                    location.href = process.env.VUE_APP_WEBSITE_URL + 'login.html#/'
                  }
                })
              } else {
                this.submitBtn = false
              }
              this.loading = false
            })
            .catch(err => {
              console.log(err)
              this.loading = false
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped></style>

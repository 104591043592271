import request from '@/api/request'
import qs from 'qs'

const excellent = {
  boutiqueList(params) {
    return request({
      url: '/expositionShop/home/recommendBoutique/list',
      method: 'get',
      params: params
    })
  },
  updateExcellentStatus(data) {
    return request({
      url: '/expositionShop/home/recommendBoutique/update/recommendStatus',
      method: 'post',
      data: qs.stringify(data)
    })
  },
  deleteExcellentProduct(data) {
    return request({
      url: '/expositionShop/home/recommendBoutique/delete',
      method: 'post',
      data: qs.stringify(data)
    })
  },
  createExcellentProduct(data) {
    return request({
      url: '/expositionShop/home/recommendBoutique/create',
      method: 'post',
      data: data
    })
  },
  updateExcellentProductSort(params) {
    return request({
      url: '/expositionShop/home/recommendBoutique/update/sort/' + params.id,
      method: 'post',
      params: params
    })
  }
}

export default excellent

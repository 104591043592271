import request from '@/api/request'
import qs from 'qs'

const storeAudit = {
  shopList(params) {
    return request({
      url: '/expositionShop/shop/list',
      method: 'get',
      params
    })
  },
  getShopDetail(id) {
    return request({
      url: '/expositionShop/shop/get/' + id,
      method: 'get'
    })
  },
  /**
   * 店铺审核
   * @param {*} param
   * @returns
   */
  updateWithDrawal(param) {
    return request({
      url: '/expositionShop/shop/updateWithDrawal',
      method: 'put',
      params: param
    })
  },
  // 获取省市区-全部
  getAreaInfoList() {
    return request({
      url: '/system/api/link',
      method: 'get'
    })
  },
  /**
   * 审核通过后修改保存
   * @param {} params
   * @returns
   */
  updateContent(params) {
    return request({
      url: '/expositionShop/shop/updateContent',
      method: 'POST',
      data: qs.stringify(params)
    })
  },
  /**
   * 修改店铺数据
   * @param {*} params
   * @returns
   */
  updateShop(params) {
    return request({
      url: '/expositionShop/shop/update/ybh',
      method: 'POST',
      data: params
    })
  },
  /**
   * 店铺启用/禁用
   * @param {*} params
   * @returns
   */
  disabledShop(params) {
    return request({
      url: '/expositionShop/shop/disabledShop',
      method: 'POST',
      data: qs.stringify(params)
    })
  },
  // 获取企业经营类目
  getCompanyCategory() {
    return request({
      url: 'expositionShop/productCategory/365list',
      method: 'get'
    })
  },
  mustUpdateProduct(params) {
    return request({
      url: `/expositionShop/shop/updateSort`,
      method: 'POST',
      data: qs.stringify(params)
    })
  },
  registerSelect(params) {
    return request({
      url: `/expositionShopweb/register/select`,
      method: 'get',
      params
    })
  },
  registerContact(data) {
    return request({
      url: `/expositionShopweb/register/contact`,
      method: 'POST',
      data: qs.stringify(data)
    })
  },
  importUser() {
    return `${process.env.VUE_APP_BASE_API}/export/api/system/import/user`
  }
}
export default storeAudit

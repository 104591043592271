import { render, staticRenderFns } from "./LimitAreaDetail.vue?vue&type=template&id=aaf274a2&scoped=true&"
import script from "./LimitAreaDetail.vue?vue&type=script&lang=js&"
export * from "./LimitAreaDetail.vue?vue&type=script&lang=js&"
import style0 from "./LimitAreaDetail.vue?vue&type=style&index=0&id=aaf274a2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaf274a2",
  null
  
)

export default component.exports
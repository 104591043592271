<template>
  <div class="app-container">
    <el-card class="header-card">
      <el-form :inline="true" :model="listQuery" size="small" label-width="110px">
        <el-form-item label="输入搜索">
          <el-input v-model="listQuery.keyword" style="width: 203px" placeholder="品牌名称/关键字" />
        </el-form-item>
      </el-form>
      <el-row class="search-area-btn">
        <div class="left">
          <el-button type="primary" class="btn-add" size="mini" @click="addBrand()">添加</el-button>
          <el-select v-model="operateType" size="small" placeholder="批量操作">
            <el-option v-for="item in operates" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
          <el-button style="margin-left: 20px" class="search-button" type="primary" size="small" @click="handleBatchOperate()"> 确定 </el-button>
        </div>
        <div class="center">
          <el-button type="primary" size="small" @click="searchBrandList()">查询</el-button>
        </div>
      </el-row>
    </el-card>
    <div class="table-container">
      <el-table ref="brandTable" :data="list" style="width: 100%" @selection-change="handleSelectionChange" v-loading="listLoading" border>
        <el-table-column type="selection" width="60" align="center" />
        <el-table-column label="编号" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column label="品牌名称" align="center">
          <template slot-scope="scope">{{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column label="品牌首字母" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.firstLetter }}</template>
        </el-table-column>
        <el-table-column label="排序" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.sort }}</template>
        </el-table-column>
        <el-table-column label="品牌制造商" width="100" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.factoryStatus" :active-value="1" :inactive-value="0" @change="handleFactoryStatusChange(scope.$index, scope.row)" />
          </template>
        </el-table-column>
        <el-table-column label="是否显示" width="100" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.showStatus" :active-value="1" :inactive-value="0" @change="handleShowStatusChange(scope.$index, scope.row)" />
          </template>
        </el-table-column>
        <el-table-column label="相关" width="220" align="center">
          <template slot-scope="scope">
            <span>商品：</span>
            <el-button size="mini" type="text" @click="getProductList(scope.$index, scope.row)">
              {{ scope.row.productCount ? scope.row.productCount : '0' }}
            </el-button>
            <span>评价：</span>
            <el-button size="mini" type="text" @click="getProductCommentList(scope.$index, scope.row)">
              {{ scope.row.productCommentCount ? scope.row.productCommentCount : '0' }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="handleUpdate(scope.row)">编辑 </el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination background layout="total, sizes,prev, pager, next,jumper" :page-size="listQuery.pageSize" :page-sizes="[5, 10, 15]" :current-page.sync="listQuery.pageNum" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
    <brandDetail v-if="brandDetailShow" :id="brandDetailId" :is-edit="brandDetailEdit" @close="close" />
  </div>
</template>
<script>
import brandDetail from './components/BrandDetail'

export default {
  name: 'BrandList',
  components: {
    brandDetail
  },
  data() {
    return {
      operates: [
        {
          label: '显示品牌',
          value: 'showBrand'
        },
        {
          label: '隐藏品牌',
          value: 'hideBrand'
        }
      ],
      operateType: null,
      listQuery: {
        keyword: null,
        pageNum: 1,
        pageSize: 10
      },
      list: null,
      total: null,
      listLoading: true,
      multipleSelection: [],
      brandDetailShow: false,
      brandDetailEdit: false,
      brandDetailId: null
    }
  },
  created() {
    this.getList()
  },
  methods: {
    close() {
      this.brandDetailShow = false
      this.brandDetailEdit = false
      this.brandDetailId = null
      this.getList()
    },
    getList() {
      this.listLoading = true
      this.$api.brand.brandList(this.listQuery).then(response => {
        this.listLoading = false
        this.list = response.listData
        this.total = response.count
        // this.totalPage = response.data.totalPage;
        // this.pageSize = response.data.pageSize;
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleUpdate(row) {
      this.brandDetailEdit = true
      this.brandDetailId = parseInt(row.id)
      this.brandDetailShow = true
    },
    handleDelete(index, row) {
      this.$confirm('是否要删除该品牌', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.brand.deleteBrand(row.id).then(() => {
          this.$message({
            message: '删除成功',
            type: 'success',
            duration: 1000
          })
          this.getList()
        })
      })
    },
    getProductList(index, row) {
      console.log(index, row)
    },
    getProductCommentList(index, row) {
      console.log(index, row)
    },
    handleFactoryStatusChange(index, row) {
      let data = {
        ids: row.id,
        factoryStatus: row.factoryStatus
      }
      this.$api.brand
        .updateFactoryStatus(data)
        .then(() => {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000
          })
        })
        .catch(() => {
          if (row.factoryStatus === 0) {
            row.factoryStatus = 1
          } else {
            row.factoryStatus = 0
          }
        })
    },
    handleShowStatusChange(index, row) {
      let data = {
        ids: row.id,
        showStatus: row.showStatus
      }
      this.$api.brand
        .updateShowStatus(data)
        .then(() => {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000
          })
        })
        .catch(() => {
          if (row.showStatus === 0) {
            row.showStatus = 1
          } else {
            row.showStatus = 0
          }
        })
    },
    handleSizeChange(val) {
      this.listQuery.pageNum = 1
      this.listQuery.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.pageNum = val
      this.getList()
    },
    searchBrandList() {
      this.listQuery.pageNum = 1
      this.getList()
    },
    handleBatchOperate() {
      console.log(this.multipleSelection)
      if (this.multipleSelection < 1) {
        this.$message({
          message: '请选择一条记录',
          type: 'warning',
          duration: 1000
        })
        return
      }
      let showStatus = 0
      if (this.operateType === 'showBrand') {
        showStatus = 1
      } else if (this.operateType === 'hideBrand') {
        showStatus = 0
      } else {
        this.$message({
          message: '请选择批量操作类型',
          type: 'warning',
          duration: 1000
        })
        return
      }
      const ids = []
      for (let i = 0; i < this.multipleSelection.length; i++) {
        ids.push(this.multipleSelection[i].id)
      }
      let data = {
        ids: ids,
        showStatus: showStatus
      }
      this.$api.brand.updateShowStatus(data).then(() => {
        this.getList()
        this.$message({
          message: '修改成功',
          type: 'success',
          duration: 1000
        })
      })
    },
    addBrand() {
      this.brandDetailEdit = false
      this.brandDetailId = null
      this.brandDetailShow = true
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped></style>

<template>
  <div class="upload-container">
    <el-button size="mini" :style="{ background: color, borderColor: color }" type="primary" @click="dialogVisible = true">上传文件 </el-button>
    <el-dialog append-to-body :visible.sync="dialogVisible">
      <el-upload class="editor-slide-upload" action="" :multiple="true" :file-list="fileList" :show-file-list="true" :file-type="fileTypeArry" list-type="picture-card" :on-remove="handleRemove" :http-request="uploadFile" :before-upload="beforeUpload">
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </el-dialog>
  </div>
</template>

<script>
import OSS from 'ali-oss'

export default {
  name: 'EditorSlideUpload',
  props: {
    color: {
      type: String,
      default: '#1890ff'
    }
  },
  data() {
    return {
      dialogVisible: false,
      listObj: {},
      fileList: [],
      dataObj: {
        policy: '',
        signature: '',
        key: '',
        ossaccessKeyId: '',
        dir: '',
        host: ''
      },
      fileTypeArry:['mp4','mp3','jpg', 'png', 'jpeg']
    }
  },
  methods: {
     ossClient(conf) {
      return new OSS(conf)
    },
    checkAllSuccess() {
      return Object.keys(this.listObj).every(item => this.listObj[item].hasSuccess)
    },
    handleSubmit() {
      console.log('this.listObj:', this.listObj)
      const arr = Object.keys(this.listObj).map(v => this.listObj[v])
      if (!this.checkAllSuccess()) {
        this.$message('请等待所有文件上传成功 或 出现了网络问题，请刷新页面重新上传！')
        return
      }
      console.log(arr)
      this.$emit('successCBK', arr)
      this.listObj = {}
      this.fileList = []
      this.dialogVisible = false
    },
    handleSuccess(response, file) {
      const uid = file.uid
      const objKeyArr = Object.keys(this.listObj)
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          this.listObj[objKeyArr[i]].url = this.dataObj.host + '/' + this.dataObj.dir + '/' + file.name
          this.listObj[objKeyArr[i]].hasSuccess = true
          return
        }
      }
    },
    handleRemove(file) {
      const uid = file.uid
      const objKeyArr = Object.keys(this.listObj)
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          delete this.listObj[objKeyArr[i]]
          return
        }
      }
    },
    beforeUpload(file) {
      const fileName = file.uid
      this.listObj[fileName] = { hasSuccess: false, uid: file.uid }
    },
    async uploadFile(file) {
      console.log("file:", file)
      const config = await this.$api.common.policy()
      const region = config.endpoint.split('.')[0].slice(7)

      const uploadConf = {
        region,
        accessKeyId: config.accessKeyId,
        accessKeySecret: config.accessKeySecret,
        bucket: config.bucketName
      }
      const fileName = `goods/${new Date().getTime() + Math.round(Math.random() * 10000)}/${file.file.type}` // 定义唯一的文件名
      console.log(file.file.size, "文件大小11111")
      if (file.file.size > 10000000) {
        this.ossClient(uploadConf)
            .multipartUpload(fileName, file.file, { ContentType: 'application/octet-stream' })
            .then(( result ) => {
              // console.log(res,'嘻嘻嘻嘻2233344')
              const { bucket } = result
              const url = `https://${bucket}.${region}.aliyuncs.com/${fileName}`
              // this.emitInput({ name: file.file.name, url: url })
              const objKeyArr = Object.keys(this.listObj)
              this.listObj[objKeyArr[0]].url = url
              this.listObj[objKeyArr[0]].hasSuccess = true
              file.onSuccess()
              // if (res && res.status === 200) {
              //   const objKeyArr = Object.keys(this.listObj)
              //   for (let i = 0, len = objKeyArr.length; i < len; i++) {
              //       this.listObj[objKeyArr[i]].url = res.requestUrls[i]
              //       this.listObj[objKeyArr[i]].hasSuccess = true
              //       return
              //   }
              // }
            })
            .catch(() => {
              this.$message({
                type: 'error',
                message: '上传失败，请重新上传'
              })
           })
      } else {
        this.ossClient(uploadConf)
            .put(fileName, file.file, {
              ContentType: 'image/jpeg/mp4/mp3/rmvb'
            })
            .then(({ res, url }) => {
              console.log('res:', res)
              if (res && res.status === 200) {
                const uid = file.file.uid
                const objKeyArr = Object.keys(this.listObj)
                for (let i = 0, len = objKeyArr.length; i < len; i++) {
                  if (this.listObj[objKeyArr[i]].uid === uid) {
                    this.listObj[objKeyArr[i]].url = url
                    this.listObj[objKeyArr[i]].hasSuccess = true
                    return
                  }
                }
                console.log('listObj:', this.listObj)
                // this.fileList.push({name: file.file.name, url: url})
                // this.emitInput(this.fileList)
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: '上传失败'
              })
            })
        }
     }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.upload-container .editor-slide-upload {
  margin-bottom: 20px;
}
</style>

<template>
  <el-dialog title="设置运费模板" append-to-body :visible="selectFreightDialogVisible" width="50%" @close="closeDialog">
    <el-form ref="objFrom" :model="dataObj" :rules="objRules" label-width="100px">
      <el-form-item label="模板名称" prop="freightId">
        <el-select v-model="dataObj.freightId" placeholder="请选择" clearable>
          <el-option v-for="item in freightOptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <div v-if="freightId === '' || userStatus === 0" class="btn-area">
        <el-button type="primary" size="mini" @click="addFreightModlues">添加运费模板</el-button>
      </div>
      <div class="btn-area">
        <el-button size="small" @click="closeDialog">取 消</el-button>
        <el-button size="small" type="primary" @click="handleSelectDialogConfirm('objFrom')">确 定</el-button>
      </div>
    </el-form>
    <freightDetail v-if="freightDetailShow" @close="close" />
  </el-dialog>
</template>

<script>
import freightDetail from '../../freight/components/FreightDetail'
export default {
  components: {
    freightDetail
  },
  props: {
    selectFreightDialogVisible: {
      type: Boolean,
      default: false
    },
    userStatus: {
      type: Number,
      default: null
    },
    freightId: {
      type: Number,
      default: () => {
        return null
      }
    }
  },
  data() {
    return {
      freightOptions: [],
      dataObj: {
        freightId: this.freightId
      },
      objRules: {
        freightId: [{ required: true, message: '请选择模板', trigger: 'change' }]
      },
      freightDetailShow: false
    }
  },
  created() {
    this.getList()
  },
  methods: {
    close() {
      this.freightDetailShow = false
      this.getList()
    },
    async getList() {
      await this.$api.suit.getFreightListAll().then(res => {
        if (res.data && res.data.length) {
          this.freightOptions = []
          res.data.forEach(el => {
            this.freightOptions.push({
              value: el.id,
              label: el.tmpName
            })
          })
        }
      })
    },
    closeDialog() {
      this.$emit('closeFreightDialog')
    },
    handleSelectDialogConfirm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const data = {}
          this.freightOptions.map(item => {
            if (item.value === this.dataObj.freightId) {
              data.freightId = this.dataObj.freightId
              data.freightName = item.label
            }
          })
          this.$emit('addFreightInfo', data)
        } else {
          this.$message({
            message: '验证失败',
            type: 'error',
            duration: 1000
          })
          return false
        }
      })
    },
    addFreightModlues() {
      this.freightDetailShow = true
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-area {
  margin: 20px;
}
</style>

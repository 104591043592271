<template>
  <div class="flowTableBox">
    <div class="headBox">
      <div class="navBox">
        <p>积分排行</p>
      </div>
<!--      <div class="dropBox">-->
<!--        <el-dropdown  @command="handleCommand">-->
<!--          <span class="el-dropdown-link">-->
<!--            {{timeInit}}<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--          </span>-->
<!--          <el-dropdown-menu slot="dropdown">-->
<!--            <el-dropdown-item v-for="(item, index) in timeSelectArry" :key="index" :command="item.command">{{item.title}}</el-dropdown-item>-->
<!--          </el-dropdown-menu>-->
<!--        </el-dropdown>-->
<!--      </div>-->
    </div>
    <div class="tableBox">
      <el-table ref="enterTable" :data="pageViewList" style="width: 98%;height:397px;" v-loading="listLoading" border>
        <!--        <el-table-column type="selection" width="60" align="center" />-->
        <el-table-column label="序号" width="120" align="center">
          <template slot-scope="scope">{{  (pageNumber-1) * 10 + scope.$index+1 }}</template>
        </el-table-column>
        <el-table-column label="账号" width="237" align="center">
          <template slot-scope="scope">{{ scope.row.userName }}</template>
        </el-table-column>
        <el-table-column label="现有积分" width="135" align="center">
          <template slot-scope="scope">{{ scope.row.integral }}</template>
        </el-table-column>
        <el-table-column label="邀请人数" width="135" align="center">
          <template slot-scope="scope">{{ scope.row.inviteNumber }}</template>
        </el-table-column>
        <el-table-column label="累计签到次数" width="135" align="center">
          <template slot-scope="scope">{{ scope.row.signIn }}</template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination background layout="prev, pager, next" :page-size="pageSize" :page-sizes="[5,10, 20, 30, 50, 100]" :current-page.sync="pageNumber" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {

  },
  data() {
    return {
      pageViewList: [],
      timeSelectArry:[{
        title: '近7日',
        command: 'a'
      },{
        title: '近1月',
        command: 'b'
      },{
        title: '近6个月',
        command: 'c'
      },{
        title: '近1年',
        command: 'd'
      }],
      timeInit:'近7日',
      startTime: '',
      endTime: '',
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      listLoading:false
    }
  },
  mounted() {
    const now = new Date()
    this.startTime = this.$utils.parseDate(now.getTime() - 3600 * 1000 * 24 * 7, '{y}-{m}-{d}')
    this.endTime = this.$utils.parseDate(now, '{y}-{m}-{d}')
    this.pointsSummaryReq()
  },
  methods:{
    handleCommand(commond){
      switch (commond) {
        case 'a' :
          this.timeInit =  this.timeSelectArry[0].title
          var now = new Date()
          this.startTime = this.$utils.parseDate(now.getTime() - 3600 * 1000 * 24 * 7, '{y}-{m}-{d}')
          this.endTime = this.$utils.parseDate(now, '{y}-{m}-{d}')
          break;
        case 'b' :
          this.timeInit =  this.timeSelectArry[1].title
          var now2 = new Date()
          this.startTime = this.$utils.parseDate(new Date().setMonth(now2.getMonth() - 1), '{y}-{m}-{d}')
          this.endTime = this.$utils.parseDate(now2, '{y}-{m}-{d}')
          break;
        case 'c' :
          this.timeInit =  this.timeSelectArry[2].title
          var now3 = new Date()
          this.startTime = this.$utils.parseDate(new Date().setMonth(now3.getMonth() - 6), '{y}-{m}-{d}')
          this.endTime = this.$utils.parseDate(now3, '{y}-{m}-{d}')
          break;
        case 'd' :
          this.timeInit =  this.timeSelectArry[3].title
          var now4 = new Date()
          this.startTime = this.$utils.parseDate(new Date().setMonth(now4.getMonth() - 12), '{y}-{m}-{d}')
          this.endTime = this.$utils.parseDate(now4, '{y}-{m}-{d}')
          break;
      }
      this.pointsSummaryReq()
    },
    pointsSummaryReq(){
      let params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        startTime:this.startTime,
        endTime: this.endTime
      }
      this.$api.home.pointsSummaryList(params).then(res => {
        if(res.code === 200){
          this.pageViewList = res.data.list
          this.total = res.data.total > 20 ? 20  : res.data.total
         console.log(res,"哈哈哈112233日3345435")
        }
      })
    },
    handleSizeChange(val){
      this.pageSize = val
      this.pointsSummaryReq()
    },
    handleCurrentChange(val){
      this.pageNumber = val
      this.pointsSummaryReq()
    },
  }
}
</script>

<style lang="scss" scoped>
.flowTableBox{
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  width: 49.5%;
  margin-right: 16px;
  padding: 18px 15px;
}
.navBox{
  p{
    margin-bottom: 0;
    margin-top: 0;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333;
    margin-right: 16px;
    cursor: pointer;
  }
}
.headBox{
  display:flex;
  justify-content: space-between;
}
.tableBox{
  margin-top: 20px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
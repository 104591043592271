<template>
  <div class="app-container">
    <el-card class="header-card">
      <el-row class="search-area-btn">
        <div class="left">
          <el-button type="primary" class="btn-add" size="mini" @click="addProductAttrCate()">添加</el-button>
        </div>
      </el-row>
    </el-card>
    <div class="table-container">
      <el-table ref="productAttrCateTable" v-loading="listLoading" style="width: 100%" :data="list" border>
        <el-table-column label="编号" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column label="类型名称" align="center">
          <template slot-scope="scope">{{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column label="属性数量" width="200" align="center">
          <template slot-scope="scope">{{ scope.row.attributeCount == null ? 0 : scope.row.attributeCount }}</template>
        </el-table-column>
        <el-table-column label="参数数量" width="200" align="center">
          <template slot-scope="scope">{{ scope.row.paramCount == null ? 0 : scope.row.paramCount }}</template>
        </el-table-column>
        <el-table-column label="操作" width="280" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="getAttrList(scope.$index, scope.row)">属性列表</el-button>
            <el-button type="primary" size="mini" @click="handleUpdate(scope.$index, scope.row)">编辑 </el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination background layout="total, sizes,prev, pager, next,jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size="listQuery.pageSize" :page-sizes="[5, 10, 20, 30, 50, 100]" :current-page.sync="listQuery.pageNum" :total="total" />
    </div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
      <el-form ref="productAttrCatForm" :model="productAttrCate" :rules="rules" label-width="120px">
        <el-form-item label="类型名称" prop="name">
          <el-input v-model="productAttrCate.name" auto-complete="off" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm('productAttrCatForm')">确 定</el-button>
      </span>
    </el-dialog>
    <productAttrList v-if="productAttrListShow" :obj="productAttrListObj" @close="close" />
  </div>
</template>
<script>
import productAttrList from './productAttrList'

export default {
  name: 'ProductAttrCateList',
  components: {
    productAttrList
  },
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 5
      },
      dialogVisible: false,
      dialogTitle: '',
      productAttrCate: {
        name: '',
        id: null
      },
      rules: {
        name: [
          { required: true, message: '请输入类型名称', trigger: 'blur' },
          { max: 30, message: '字符长度不能超过30', trigger: 'blur' }
        ]
      },
      productAttrListShow: false,
      productAttrListObj: {}
    }
  },
  created() {
    this.getList()
  },
  methods: {
    close() {
      this.productAttrListShow = false
      this.productAttrListObj = {}
      this.getList()
    },
    getList() {
      this.listLoading = true
      this.$api.product.categoryList(this.listQuery).then(response => {
        this.listLoading = false
        this.list = response.listData
        this.total = response.count
      })
    },
    addProductAttrCate() {
      this.dialogVisible = true
      this.dialogTitle = '添加类型'
    },
    handleSizeChange(val) {
      this.listQuery.pageNum = 1
      this.listQuery.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.pageNum = val
      this.getList()
    },
    handleDelete(index, row) {
      this.$api.product.cannotRepetitionType({ id: row.id }).then(res => {
        if (res.code === 200) {
          this._deleteProductAttrCate(row.id)
        }
      })
    },
    _deleteProductAttrCate(id) {
      this.$confirm('是否要删除该类型', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.product.deleteProductAttrCate(id).then(() => {
          this.$message({
            message: '删除成功',
            type: 'success',
            duration: 1000
          })
          this.getList()
        })
      })
    },
    handleUpdate(index, row) {
      this.dialogVisible = true
      this.dialogTitle = '编辑类型'
      this.productAttrCate.name = row.name
      this.productAttrCate.id = row.id
    },
    getAttrList(index, row) {
      this.productAttrListObj = { cid: row.id, cname: row.name, type: 0 }
      this.productAttrListShow = true
    },
    getParamList(index, row) {
      this.$router.push({
        path: '/pms/productAttrList',
        query: { cid: row.id, cname: row.name, type: 1 }
      })
    },
    handleConfirm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const data = { name: this.productAttrCate.name }
          if (this.dialogTitle === '添加类型') {
            this.$api.product.createProductAttrCate(data).then(() => {
              this.$message({
                message: '添加成功',
                type: 'success',
                duration: 1000
              })
              this.dialogVisible = false
              this.getList()
            })
          } else {
            this.$api.product.updateProductAttrCate(this.productAttrCate.id, data).then(() => {
              this.$message({
                message: '修改成功',
                type: 'success',
                duration: 1000
              })
              this.dialogVisible = false
              this.getList()
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped></style>

<template>
  <div class="app-container">
    <el-card class="header-card">
      <el-form :inline="true" :model="listQuery" size="small" label-width="110px">
        <el-form-item label="输入搜索">
          <el-input v-model="listQuery.packageName" class="input-width" placeholder="套餐名称" />
        </el-form-item>
      </el-form>
      <el-row class="search-area-btn">
        <div class="left">
          <el-button type="primary" class="btn-add" size="mini" @click="addArea()"> 添加 </el-button>
        </div>
        <div class="center">
          <el-button type="primary" size="small" @click="handleSearchList()">查询</el-button>
          <el-button size="small" @click="handleResetSearch()">重置 </el-button>
        </div>
      </el-row>
    </el-card>
    <div class="table-container">
      <el-table ref="returnApplyTable" :data="list" style="width: 100%" @selection-change="handleSelectionChange" v-loading="listLoading" border>
        <el-table-column label="套餐名称" align="center">
          <template slot-scope="scope">{{ scope.row.packageName }}</template>
        </el-table-column>
        <el-table-column label="创建时间" align="center">
          <template slot-scope="scope">{{ formatTime(scope.row.createTime) }}</template>
        </el-table-column>
        <el-table-column label="最后修改时间" align="center">
          <template slot-scope="scope">{{ formatTime(scope.row.lstUpdTime) }}</template>
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleViewDetail(scope.$index, scope.row)">查看详情</el-button>
            <el-button type="danger" size="mini" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="false" class="batch-operate-container">
      <el-select v-model="operateType" size="small" placeholder="批量操作">
        <el-option v-for="item in operateOptions" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <el-button style="margin-left: 20px" class="search-button" type="primary" size="small" @click="handleBatchOperate()"> 确定 </el-button>
    </div>
    <div class="pagination-container">
      <el-pagination background layout="total, sizes,prev, pager, next,jumper" :current-page.sync="listQuery.pageNumber" :page-size="listQuery.pageSize" :page-sizes="[5, 10, 20, 30, 50, 100]" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
    <suitDetail v-if="suitDetailShow" :id="suitDetailId" :is-edit="suitDetailEdit" @close="close" />
  </div>
</template>
<script>
import suitDetail from './components/SuitDetail'

const defaultListQuery = {
  // orderSn:'',
  pageNumber: 1,
  pageSize: 10,
  packageName: ''
}
// const defaultStatusOptions = [
//   {
//     label: '待处理',
//     value: 0
//   },
//   {
//     label: '退货中',
//     value: 1
//   },
//   {
//     label: '买家退货',
//     value: 2
//   },
//   {
//     label: '已完成',
//     value: 4
//   },
//   {
//     label: '已拒绝',
//     value: 5
//   }
// ]
export default {
  name: 'ReturnApplyList',
  components: {
    suitDetail
  },
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      list: null,
      total: null,
      listLoading: false,
      multipleSelection: [],
      operateType: 1,
      operateOptions: [
        {
          label: '批量删除',
          value: 1
        }
      ],
      suitDetailShow: false,
      suitDetailId: null,
      suitDetailEdit: false
    }
  },
  created() {
    this.getList()
  },
  methods: {
    formatTime(time) {
      if (time == null || time === '') {
        return ''
      }
      const date = new Date(time)
      return this.$utils.parseDate(date)
    },
    close() {
      this.suitDetailId = null
      this.suitDetailEdit = false
      this.suitDetailShow = false
      this.getList()
    },
    handleDelete(index, row) {
      this.$confirm('是否要进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const data = {
          packageId: row.packageId
        }
        this.$api.suit.removeSuitInfo(data).then(() => {
          this.getList()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleResetSearch() {
      this.listQuery = Object.assign({}, defaultListQuery)
    },
    handleSearchList() {
      this.listQuery.pageNumber = 1
      this.getList()
    },
    handleViewDetail(index, row) {
      this.suitDetailId = parseInt(row.packageId)
      this.suitDetailEdit = true
      this.suitDetailShow = true
    },
    handleBatchOperate() {
      if (this.multipleSelection == null || this.multipleSelection.length < 1) {
        this.$message({
          message: '请选择要操作的申请',
          type: 'warning',
          duration: 1000
        })
        return
      }
      if (this.operateType === 1) {
        // 批量删除
        this.$confirm('是否要进行删除操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // const params = new URLSearchParams()
          // const ids = []
          // for (let i = 0; i < this.multipleSelection.length; i++) {
          //   ids.push(this.multipleSelection[i].id)
          // }
          // params.append('ids', ids)
        })
      }
    },
    handleSizeChange(val) {
      this.listQuery.pageNumber = 1
      this.listQuery.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.pageNumber = val
      this.getList()
    },
    getList() {
      this.listLoading = true
      this.$api.suit.getSuitList(this.listQuery).then(response => {
        this.listLoading = false
        this.list = response.data.list
        this.total = response.data.total
      })
    },
    addArea() {
      this.suitDetailId = null
      this.suitDetailEdit = false
      this.suitDetailShow = true
    }
  }
}
</script>
<style scoped>
.input-width {
  width: 203px;
}
</style>

<template>
  <div class="app-container">
    <el-card class="header-card">
      <!--      <el-form :inline="true" :model="listQuery" size="small" label-width="110px">-->
      <!--        <el-row>-->
      <!--          <el-col :span="6">-->
      <!--            <el-form-item label="输入搜索">-->
      <!--              <el-input v-model="listQuery.title" placeholder="请输入标题搜索" />-->
      <!--            </el-form-item>-->
      <!--          </el-col>-->
      <!--        </el-row>-->
      <!--      </el-form>-->
      <el-row class="search-area-btn">
        <div class="left">
          <el-button type="primary" class="btn-add" @click="addNoticeInfo()" size="mini"> 添加 </el-button>
        </div>
      </el-row>
    </el-card>
    <div class="table-container">
      <el-table ref="orderTable" :data="list" style="width: 100%" @selection-change="handleSelectionChange" border>
        <el-table-column label="编号" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column label="消息内容" align="center">
          <template slot-scope="scope">{{ scope.row.news }}</template>
        </el-table-column>
        <el-table-column label="创建时间" align="center">
          <template slot-scope="scope">{{ handleTimeFormat(scope.row.createTime) }}</template>
        </el-table-column>
        <el-table-column label="操作" width="240px" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes,prev, pager, next,jumper" :page-size="listQuery.pageSize" :page-sizes="[5, 10, 15]" :total="total"> </el-pagination>
    </div>
    <addNotice v-if="isShowNotice" :is-edit="isEdit" @refreshData="refreshData" @close="close" />
  </div>
</template>
<script>
import addNotice from './components/addNotice'
// import notice from "@/api/information/notice";
export default {
  name: 'orderList',
  data() {
    return {
      list: [],
      listQuery: {
        pageNum: 1,
        pageSize: 10,
        platformId: 2,
        type: 1
      },
      total: 0,
      isShowNotice: false,
      isEdit: false,
      listLoading: true
    }
  },
  components: {
    addNotice
  },
  created() {
    this.getList()
  },
  filters: {
    // formatCreateTime(time) {
    //   let date = new Date(time)
    //   // return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    // }
  },
  methods: {
    handleTimeFormat(val) {
      return this.$utils.parseDate(val, '{y}-{m}-{d} {h}:{m}:{s}')
    },
    handleUpdate() {},
    addNoticeInfo() {
      this.isShowNotice = true
    },
    close() {
      this.isShowNotice = false
    },
    refreshData() {
      this.close()
      setTimeout(() => {
        this.getList()
      }, 500)
    },
    handleSizeChange(val) {
      this.listQuery.pageNum = 1
      this.listQuery.pageSize = val
    },
    handleCurrentChange(val) {
      this.listQuery.pageNum = val
    },
    handleDelete(row) {
      this.$confirm('确定要删除该条消息吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let ids = []
        ids.push(row.id)
        this.removeNotice(ids)
      })
    },
    handleResetSearch() {},
    handleSearchList() {},
    handleSelectionChange() {},
    //消息列表删除操作
    removeNotice(params) {
      this.$api.notice.removeNotic(params).then(res => {
        if (res.code === 200) {
          this.$message.success('消息删除成功！')
          this.getList()
        }
      })
    },
    getList() {
      this.listLoading = true
      this.$api.news.noticeList(this.listQuery).then(response => {
        if (response.code === 200) {
          this.listLoading = false
          this.list = response.data.list
          this.total = response.data.total
        }
      })
    }
  }
}
</script>
<style scoped>
.input-width {
  width: 203px;
}
</style>

<template>
  <div>
    <el-menu class="navbar" mode="horizontal">
      <hamburger class="hamburger-container" :toggleClick="toggleSideBar" :isActive="sidebar.opened"></hamburger>
      <breadcrumb></breadcrumb>
      <el-dropdown class="avatar-container" placement="bottom-end" trigger="hover">
        <div class="avatar-wrapper">
          <img class="user-avatar" src="@/assets/img/common/user_icon.png" />
          <span class="user-name-text">{{ $store.state.user.name }}</span>
          <i class="el-icon-caret-bottom"></i>
        </div>
        <el-dropdown-menu class="user-dropdown" slot="dropdown">
          <!-- <router-link class="inlineBlock" to="/">
            
          </router-link> -->
          <el-dropdown-item divided>
            <span style="display: block" @click="updatePwd">修改密码</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="avatar-container">
        <div class="avatar-wrapper">
          <span class="item" @click="homeClick">回到印博会</span>
        </div>
      </div>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'

export default {
  components: {
    Breadcrumb,
    Hamburger
  },
  data() {
    return {
      showDialog: false
    }
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar'])
  },
  methods: {
    homeClick() {
      location.href = process.env.VUE_APP_WEBSITE_URL
    },
    toggleSideBar() {
      this.$store.dispatch('ToggleSideBar')
    },
    logout() {
      this.$store.dispatch('LogOut').then(() => {
        location.reload() // 为了重新实例化vue-router对象 避免bug
      })
    },
    updatePwd() {
      this.$emit('updatePwd')
    },
    closeDialog(val) {
      this.showDialog = val
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.navbar {
  height: 50px;
  line-height: 50px;
  border-radius: 0px !important;
  .hamburger-container {
    line-height: 58px;
    height: 50px;
    float: left;
    padding: 0 10px;
  }
  .screenfull {
    position: absolute;
    right: 90px;
    top: 16px;
    color: red;
  }
  .avatar-container {
    height: 50px;
    display: inline-block;
    float: right;
    margin-right: 30px;
    & + .avatar-container {
      margin-right: 0px;
    }
    .avatar-wrapper {
      width: 100%;
      height: 100%;
      cursor: pointer;
      padding: 5px 0 15px 0;
      position: relative;
      display: flex;
      align-items: center;
      .item {
        font-size: 15px;
        margin-right: 20px;
        cursor: pointer;
        &:hover {
          color: #1890ff;
        }
      }
      .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 10px;
      }
      .user-name-text {
        font-size: 20px;
        padding: 0 5px;
      }
      .el-icon-caret-bottom {
        position: absolute;
        right: -10px;
        top: 15px;
        font-size: 12px;
      }
    }
  }
}
.el-dropdown-menu__item {
  padding: 10px 20px !important;
}
</style>

import { constantRouterMap, asyncRoutes } from '@/router'
import commonApi from '@/api/common/api'
import Layout from '@/views/layout/Layout'

const menuList = [
  '添加商品',
  '修改商品',
  '修改商品分类',
  '商品属性列表',
  '修改商品属性',
  '编辑品牌',
  '订单详情',
  '退货原因详情',
  '秒杀时间段选择',
  '秒杀商品列表',
  '修改优惠券',
  '优惠券领取详情',
  '编辑广告',
  '编辑专题',
  '退款原因详情',
  '发货列表',
  '换货原因详情',
  '收货地址修改',
  '发票详情',
  '编辑服务问题',
  '编辑线下门店',
  '团购商品列表',
  '添加积分商品',
  '修改积分商品',
  '编辑套餐',
  '编辑地区套餐',
  '编辑区域限购模板',
  '编辑运费模板',
  '充值消费规则修改',
  '推广商品列表',
  '佣金排行榜列表',
  '编辑装订方式',
  '龙头企业编辑',
  '商品分类编辑'
]

export function generaMenu(routers, data) {
  if (data && data.length > 0) {
    data.forEach(item => {
      let itemUrl = item.url
      itemUrl = itemUrl.replace('/', '')
      const arr = itemUrl.split('-')
      let itemName = ''
      arr.forEach(e => {
        let name = e.toLowerCase()
        name = name.charAt(0).toUpperCase() + name.slice(1)
        itemName += name
      })
      var menu = {
        path: item.url,
        component: item.menuType === 0 ? Layout : resolve => require([`@/views/${item.urlParam}`], resolve),
        children: [],
        name: itemName,
        meta: { title: item.menuName, icon: item.menuIcon }
      }
      if (item.menuType === 1) {
        // 菜单
        var flag = []
        item.children.forEach(i => {
          var a = i.menuPermitted.split(':')
          flag.push(a[a.length - 1])
        })
        if (menuList.includes(item.menuName)) {
          menu.hidden = true
        }
      }
      if (item.menuType === 0) {
        // 目录
        if (item.children && item.children.length > 0) {
          generaMenu(menu.children, item.children)
        }
      }
      if (item.url === '/home') {
        menu.path = '/'
        menu.redirect = '/home'
        menu.children = [
          {
            path: '/home',
            component: resolve => require([`@/views/${item.urlParam}`], resolve),
            name: 'home',
            meta: { title: item.menuName, icon: item.menuIcon, affix: true }
          }
        ]
      }
      routers.push(menu)
    })
  }
}

const state = {
  routes: [],
  addRoutes: [],
  btnPermitted: {}
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRouterMap.concat(routes)
  },
  SET_BTN_PERMITTED: (state, btnPermitted) => {
    state.btnPermitted = btnPermitted
  }
}

const actions = {
  generateRoutes({ commit }) {
    return new Promise(resolve => {
      const routers = []
      commonApi.getMenuByUserId().then(res => {
        if (res.code === 200) {
          const menList = []
          res.data.forEach(el => {
            console.log('路由', el)
            if (el.menuType === 3) {
              el.children.forEach(item => {
                menList.push(item)
              })
            } else {
              menList.push(el)
            }
          })
          generaMenu(routers, menList)
          commit('SET_ROUTES', [...routers, ...asyncRoutes])
          commit('SET_BTN_PERMITTED', getBtnPermitted(menList))
          resolve([...routers, ...asyncRoutes])
        } else {
          resolve(asyncRoutes)
        }
      })
    })
  }
}

export function getBtnPermitted(data) {
  const btnPermitted = {}
  if (data.length) {
    data.forEach(item => {
      if (item.children) {
        const parentUrl = item.url === '/' ? '' : item.url
        item.children.forEach(chi => {
          if (chi.url && chi.url === '#') {
            btnPermitted[parentUrl + '/' + chi.url] = [chi]
          } else if (chi.url) {
            btnPermitted[parentUrl + '/' + chi.url] = chi.children
          }
        })
      }
    })
  }
  console.log('权限：', btnPermitted)
  return btnPermitted
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

import request from '@/api/request'
import qs from 'qs'

const subject = {
  createHomeSubject(data) {
    return request({
      url: '/expositionShop/home/recommendSubject/create',
      method: 'post',
      data: data
    })
  },
  updateHomeSubjectSort(params) {
    return request({
      url: '/expositionShop/home/recommendSubject/update/sort/' + params.id,
      method: 'post',
      params: params
    })
  },
  subjectList(params) {
    return request({
      url: '/expositionShop/subject/list',
      method: 'get',
      params: params
    })
  },
  updateRecommendStatus(data) {
    return request({
      url: '/expositionShop/subject/updateRecommendStatus',
      method: 'POST',
      data: qs.stringify(data)
    })
  },
  // 批量删除
  deleteSubject(data) {
    return request({
      url: '/expositionShop/subject/delete',
      method: 'POST',
      data: qs.stringify(data)
    })
  },
  createSubject(data) {
    return request({
      url: '/expositionShop/subject/save',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      data: data
    })
  },
  updateSubject(data) {
    return request({
      url: '/expositionShop/subject/updateSub',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      data: data
    })
  },
  getSubjectDetail(id) {
    return request({
      url: '/expositionShop/subject/' + id,
      method: 'get'
    })
  }
}

export default subject

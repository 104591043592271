import router from './router'
import store from './store'
import NProgress from 'nprogress'

import { getToken } from '@/utils/storage'
import getPageTitle from '@/utils/get-page-title'

const whiteList = ['/login']
router.beforeEach(async (to, from, next) => {
  NProgress.start()
  // set page title
  document.title = getPageTitle(to.meta.title)

  if (getToken()) {
    localStorage.setItem(process.env.VUE_APP_TOKENKEY, getToken())
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      const hasGetter = store.getters
      const hasRoles = store.getters.roles
      if (hasGetter && hasRoles) {
        next()
      } else {
        try {
          await store.dispatch('GetInfo')
          const accessRoutes = await store.dispatch('permission/generateRoutes')
          console.log('accessRoutes:', accessRoutes)
          router.addRoutes(accessRoutes)
          next({ ...to, replace: true })
        } catch (error) {
          console.log('error:', error)
          next({ path: '/home' })
          location.href = process.env.VUE_APP_WEBSITE_URL
          NProgress.done()
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      // next('/login')
      location.href = process.env.VUE_APP_WEBSITE_URL
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})

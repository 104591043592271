<template>
  <el-dialog title="提现" :visible="withdrawVisible" width="750px" @close="closeDialog">
    <el-row class="pay-area">
      <!--      <el-col :span="8" v-for="(item, index) in payList" :key="index">-->
      <div class="pay-list">
        <el-radio v-model="payList[user.acountType].label" :label="payList[user.acountType].label">
          <div>
            <img :src="payList[user.acountType].img" alt="" />
            <span>{{ payList[user.acountType].desc }}</span>
          </div>
        </el-radio>
      </div>
      <!--      </el-col>-->
    </el-row>
    <el-row class="form-area">
      <el-form :model="moneyForm" :rules="moneyRules" ref="moneyForm">
        <el-form-item prop="bank">
          <div class="btn-area">
            <el-input v-model="money" v-enter-money placeholder="请输入金额" @blur="moneys"></el-input>
            <el-button type="primary" @click="allMoney" :disabled="this.user.amountValid <= 0">全部</el-button>
          </div>
          <!-- <p>
            每次提现将收取<span>{{ shop.withdrawalFee }}%手续费</span>
          </p>
          <p>
            本次提现手续费<span>{{ amount }}元</span>
          </p>
          <p>
            单日提现额度<span>{{ shop.withdrawalLimit }}元</span>
          </p> -->
        </el-form-item>
      </el-form>
    </el-row>
    <div style="clear: both"></div>
    <div class="btn-area">
      <el-button @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="save" :loading="buttonLoading" :disabled="this.user.amountValid <= 0">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import aliIcon from '@/assets/images/merchants/ali-pay.png'
import wxIcon from '@/assets/images/merchants/wx-pay.png'
import cardIcon from '@/assets/images/merchants/card-pay.png'

let moneyForm = {
  name: '',
  account: '',
  bank: ''
}
const initMoneyForm = {
  ...moneyForm
}
export default {
  props: {
    withdrawVisible: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: null
    },
    shop: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      aliIcon,
      wxIcon,
      cardIcon,
      isSelect: 1,
      buttonLoading: false,
      payList: [
        {
          id: 1,
          desc: '提现到支付宝',
          img: aliIcon,
          label: 1
        },
        {
          id: 2,
          desc: '提现到微信',
          img: wxIcon,
          label: 2
        },
        {
          id: 3,
          desc: '提现到银行卡',
          img: cardIcon,
          label: 3
        }
      ],
      moneyForm: {
        ...initMoneyForm
      },
      moneyRules: {
        money: [{ required: true, message: '请输入提现金额', trigger: 'blur' }]
      },
      money: '',
      amount: '0'
    }
  },
  methods: {
    save() {
      if (this.money === null || this.money === '') {
        return
      }
      this.buttonLoading = true
      let param = {
        platformId: this.user.platformId,
        userId: this.user.userId,
        transferBigDecimal: this.money
      }
      this.$api.common.apply(param).then(res => {
        if (res.code === 200) {
          this.$message.success('提现申请成功')
          this.closeDialog()
        } else {
          this.$message.error('提现申请失败')
        }
      })
      this.buttonLoading = false
    },
    moneys() {
      // 处理数据值，如果存在中文，将money设置为0
      if (String(this.money).indexOf('.') !== -1) {
        let moneys = String(this.money).split('.')
        let s = String(moneys[1])
        if (s.length === 1) {
          this.money = moneys[0] + '.' + s + 0
        } else if (s.length > 2) {
          this.money = moneys[0] + '.' + s.substring(0, 2)
        }
      }
      // 判断是否大于日提现额度
      // if (this.money > this.shop.withdrawalLimit) {
      //   this.money = this.shop.withdrawalLimit
      // }
      // 判断是否超过可提现金额
      if (Number(this.money) >= Number(this.user.amountValid)) {
        this.money = this.user.amountValid
      }

      // 计算手续费
      //  this.amount = (this.shop.withdrawalFee * this.money) / 100

      // 长度限制3位小数
      // let stringAmount = String(this.amount)
      // if (stringAmount.indexOf('.') !== -1) {
      //   let strings = String(stringAmount).split('.')
      //   if (String(strings[1]).length > 3) {
      //     this.amount = stringAmount[0] + '.' + String(strings[1]).substring(0, 3)
      //   }
      // }
    },
    allMoney() {
      this.money = this.user.amountValid
      this.moneys()
    },
    closeDialog() {
      this.$emit('closeWithdrawDialog')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.form-area {
  width: 300px;
  margin: 0 auto;
  .btn-area {
    display: flex;
    align-items: center;
    height: 40px;
    margin-top: 8px;
    .el-input {
      width: 230px;
    }
    .el-button {
      width: 70px;
      height: 40px;
    }
  }
  .tips-text {
    font-size: 14px;
    color: #333333;
    i {
      color: #f2383e;
    }
  }

  p {
    text-align: right;
    color: #cbcbcb;
    font-size: 12px;
    margin: 0;
    span {
      color: #ff2c00;
    }
  }
}
.pay-area {
  padding: 0 30px;
  margin-bottom: 50px;
  .pay-list {
    width: 200px;
    margin: 0 auto;
    border: 1px solid #dcdfe6;
    padding: 2px 15px;
    .el-radio {
      display: flex;
      align-items: center;
      .el-radio__label {
        > div {
          display: flex;
          align-items: center;
          img {
            width: 70px;
            height: 70px;
          }
        }
      }
    }
  }
}
.btn-area {
  text-align: right;
}
</style>

<template>
  <div class="app-container">
    <!-- <el-card class="header-card">
      <el-form :inline="true" :model="formData" class="demo-form-inline">
        <el-form-item label="姓名">
          <el-input v-model="formData.shopName" placeholder="请填写姓名" size="mini" />
        </el-form-item>
      </el-form>
      <el-row class="search-area-btn">
        <div class="left" />
        <div class="center">
          <el-button :loading="loading" type="primary" size="mini" @click="searchFun">查询</el-button>
          <el-button :loading="loading" size="mini" @click="resetFun">重置</el-button>
        </div>
      </el-row>
    </el-card> -->

    <el-card class="table-card">
      <template>
        <el-table v-loading="loading" :data="tableData" style="width: 100%" class="m-t-12" border :header-cell-style="{ background: '#eef1f6', color: '#606266', textAlign: 'center' }">
          <el-table-column label="序号" type="index" align="center" width="50" />
          <el-table-column prop="userName" label="姓名" align="center" width="200" />
          <el-table-column prop="phone" label="电话" align="center" width="200" />
          <el-table-column prop="settledDemand" label="描述" align="center" />
          <el-table-column prop="createTime" label="时间" align="center" width="200" />
        </el-table>
      </template>
      <div class="m-w cen-box m-t-12">
        <el-pagination :current-page="page" :page-sizes="pageSize" :page-size="size" :total="count" :layout="'total, sizes, prev, pager, next, jumper'" @size-change="pageSizeChange" @current-change="pageNumberChange" />
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      tableData: [],
      page: 1,
      size: 10,
      count: 0,
      pageSize: [10, 20, 30, 40, 50]
    }
  },
  created() {
    this.getCallMeList()
  },
  methods: {
    getCallMeList() {
      const params = {
        pageNum: this.page,
        pageSize: this.size
      }
      this.$api.callMe.getCallMeList(params).then(res => {
        this.tableData = res.data.data
        this.count = res.data.size
        console.log('联系我们：', res)
      })
    },
    pageSizeChange(val) {
      this.size = val
      this.getCallMeList()
    },
    pageNumberChange(val) {
      this.page = val
      this.getCallMeList()
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep.el-pagination {
  text-align: right;
}
</style>

<template>
  <div class="app-container">
    <el-card class="header-card">
      <el-form :inline="true" :model="formData" class="form-inline">
        <el-row>
          <el-col :span="6">
            <el-form-item label="公司名称">
              <el-input v-model="formData.shopName" placeholder="请输入公司名称" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="search-area-btn">
        <div class="left">&nbsp;</div>
        <div class="center">
          <el-button :loading="loading" type="primary" @click="handleSearch"> 查询 </el-button>
          <el-button :loading="loading" @click="handleReset"> 重置 </el-button>
        </div>
      </div>
    </el-card>
    <el-card class="table-container">
      <el-table ref="table" :data="tableData" style="width: 100%" border :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
        <el-table-column type="index" fixed="left" label="序号" width="100" align="center" />
        <el-table-column label="公司名称" prop="name" width="240" align="center" />
        <el-table-column label="注册地址" prop="address" width="300" align="center" />
        <el-table-column label="法人姓名" prop="operName" width="140" align="center" />
        <el-table-column label="法人身份证号" prop="identityCard" width="180" align="center" />
        <el-table-column label="法人籍贯" prop="nativePlace" width="200" align="center" />
        <el-table-column label="公司类型" prop="companyType" width="140" align="center" />
        <el-table-column label="公司年营业额" prop="annualTurnover" width="140" align="center" />
        <el-table-column label="公司员工数量" prop="employeeNumber" width="140" align="center" />
        <el-table-column label="门店数量" prop="storeNumber" width="140" align="center" />
        <el-table-column label="实际经营地址" prop="businessAddress" width="140" align="center" />
        <el-table-column label="店铺位置商圈" prop="storeTrading" width="140" align="center" />
        <el-table-column label="建联需求" prop="constructionUnion" width="140" align="center" />
        <el-table-column label="希望得到" prop="wishFor" width="250" align="center" />
        <el-table-column label="更多建议" prop="moreAdvice" width="250" align="center">
          <template slot-scope="scope">
            <el-popover placement="top-center" title="更多建议" width="500" trigger="hover" :content="scope.row.moreAdvice">
              <div slot="reference" class="text-hidden">
                {{ scope.row.moreAdvice }}
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime" width="110" align="center">
          <template slot-scope="scope">
            {{ $utils.parseDate(new Date(scope.row.createTime)) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button @click.stop="handleDtl(scope.row)"> 工商信息 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination :current-page="pages.page" :page-sizes="pages.pageSize" :page-size="pages.size" :total="pages.total" :layout="'total, sizes, prev, pager, next, jumper'" @size-change="pageSizeChange" @current-change="pageNumberChange" />
      </div>
    </el-card>
    <detailDialog v-if="detailDialogVisible" :obj="nowObj" @close="dialogClose()"></detailDialog>
  </div>
</template>
<script>
import detailDialog from './components/detailDialog'
import pagesMixin from '@/mixin/pages-mixin'
const initSearchParams = {
  shopName: ''
}
export default {
  name: '',
  components: { detailDialog },
  mixins: [pagesMixin],
  filters: {},
  data() {
    return {
      loading: false,
      detailDialogVisible: false,
      nowObj: null,
      formData: {
        ...initSearchParams
      },
      dateValue: [],
      tableData: []
    }
  },
  computed: {},
  created() {
    this.getList()
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    dateChange(date) {
      if (date) {
        this.formData.startTime = date[0]
        this.formData.endTime = date[1]
      } else {
        this.formData.startTime = ''
        this.formData.endTime = ''
      }
    },
    getList() {
      const param = {
        pageNumber: this.pages.page,
        pageSize: this.pages.size,
        ...this.formData
      }
      this.$api.shopweb.qccList(param).then(res => {
        if (res.code === 200) {
          this.tableData = res.data.list
          this.pages.total = Number(res.data.total || 0)
        }
      })
    },
    handleSearch() {
      this.pages.page = 1
      this.getList()
    },
    handleReset() {
      this.formData = {
        ...initSearchParams
      }
      this.pages.page = 1
      this.dateValue = []
      this.getList()
    },
    handleAdd() {},
    handleDtl(obj) {
      this.nowObj = obj
      this.detailDialogVisible = true
    },
    dialogClose() {
      this.detailDialogVisible = false
    },
    refreshData() {}
  }
}
</script>
<style lang="scss" scoped>
// .tooltip {
//   width: 300px;
// }
.text-hidden {
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>

<template>
  <div>
    <el-dialog v-dialogDrag append-to-body :close-on-click-modal="false" :visible="true" title="商户工商详情" top="10vh" width="80%" @close="close">
      <div class="main-content">
        <el-descriptions title="工商信息" direction="vertical" border>
          <el-descriptions-item label="企业名称">{{ obj.name }}</el-descriptions-item>
          <el-descriptions-item label="工商注册号">{{ obj.no }}</el-descriptions-item>
          <el-descriptions-item label="登记机关">{{ obj.belongOrg }}</el-descriptions-item>
          <el-descriptions-item label="法定代表人ID">{{ obj.operId }} </el-descriptions-item>
          <el-descriptions-item label="法定代表人名称">{{ obj.operName }} </el-descriptions-item>
          <el-descriptions-item label="成立日期">{{ obj.startDate }} </el-descriptions-item>
          <el-descriptions-item label="吊销日期">{{ obj.endDate || '无' }} </el-descriptions-item>
          <el-descriptions-item label="登记状态">{{ obj.status }} </el-descriptions-item>
          <el-descriptions-item label="更新日期">{{ obj.updatedDate }} </el-descriptions-item>
          <el-descriptions-item label="统一社会信用代码">{{ obj.creditCode }} </el-descriptions-item>
          <el-descriptions-item label="注册资本">{{ obj.registCapi }} </el-descriptions-item>
          <el-descriptions-item label="企业类型">{{ obj.econKind }} </el-descriptions-item>
          <el-descriptions-item label="注册地址">{{ obj.address }} </el-descriptions-item>
          <el-descriptions-item label="经营范围">
            <div class="text-area">
              {{ obj.scope }}
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="营业期限始">{{ obj.termStart }} </el-descriptions-item>
          <el-descriptions-item label="营业期限至">{{ obj.termEnd || '至今' }} </el-descriptions-item>
          <el-descriptions-item label="核准日期">{{ obj.checkDate }} </el-descriptions-item>
          <el-descriptions-item label="组织机构代码">{{ obj.orgNo }} </el-descriptions-item>
          <el-descriptions-item label="是否上市">{{ obj.isOnStock === '1' ? '上市' : '未上市' }} </el-descriptions-item>
          <el-descriptions-item label="股票代码">{{ obj.stockNumber || '无' }} </el-descriptions-item>
          <el-descriptions-item label="上市类型">{{ obj.stockType || '无' }} </el-descriptions-item>
          <el-descriptions-item label="曾用名">{{ getOriginalName(obj.originalName) }} </el-descriptions-item>
          <el-descriptions-item label="企业Logo地址">
            <el-image :src="obj.imageUrl" style="width: 120px"></el-image>
          </el-descriptions-item>
          <el-descriptions-item label="企业性质">{{ obj.entType | filterEntType }} </el-descriptions-item>
          <el-descriptions-item label="实缴资本">{{ obj.recCap }} </el-descriptions-item>
          <el-descriptions-item label="注销吊销信息">{{ obj.revokeInfo || '无' }} </el-descriptions-item>
          <el-descriptions-item label="行政区域">{{ getArea(obj.area) }} </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    obj: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  filters: {
    filterEntType(type) {
      let str = ''
      switch (Number(type)) {
        case 0:
          str = '大陆企业'
          break
        case 1:
          str = '社会组织'
          break
        case 3:
          str = '中国香港公司'
          break
        case 4:
          str = '事业单位'
          break
        case 5:
          str = '中国台湾公司'
          break

        case 6:
          str = '基金会'
          break
        case 7:
          str = '医院'
          break
        case 8:
          str = '海外公司'
          break
        case 9:
          str = '律师事务所'
          break
        case 10:
          str = '学校'
          break
        case 11:
          str = '机关单位'
          break
        case -1:
          str = '其他'
          break
      }
      return str
    }
  },
  data() {
    return {
      loading: false
    }
  },
  created() {},
  mounted() {},
  methods: {
    getArea(area) {
      if (!area) {
        return '无'
      }
      let _obj = JSON.parse(area)
      return _obj.Province + _obj.City + _obj.County
    },
    getOriginalName(name) {
      if (!name) {
        return '无'
      }
      let arr = JSON.parse(name).map(t => t.Name)
      console.log(1, arr)
      return arr.join(',')
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.text-area {
}

::v-deep .el-descriptions-item__cell {
  width: 33%;
}
</style>

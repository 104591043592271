<template>
  <suit-detail :is-edit="false" />
</template>
<script>
import SuitDetail from './components/SuitDetail'
export default {
  name: 'AddSuit',
  components: { SuitDetail }
}
</script>
<style></style>

import request from '@/api/request'
import qs from 'qs'

const coupon = {
  couponList(params) {
    return request({
      url: '/expositionShop/coupon/list',
      method: 'get',
      params: params
    })
  },
  deleteCoupon(id, data = {}) {
    return request({
      url: '/expositionShop/coupon/delete/' + id,
      method: 'post',
      data
    })
  },
  addUserCoupon(data) {
    return request({
      url: '/expositionShop/couponHistory/create',
      method: 'post',
      data: qs.stringify(data)
    })
  },
  couponHistoryList(params) {
    return request({
      url: '/expositionShop/couponHistory/list',
      method: 'get',
      params: params
    })
  },
  getCoupon(id, params = {}) {
    return request({
      url: '/expositionShop/coupon/get/' + id,
      method: 'get',
      params
    })
  },
  createCoupon(data) {
    return request({
      url: '/expositionShop/coupon/create',
      method: 'post',
      data: data
    })
  },
  updateCoupon(id, data = {}) {
    return request({
      url: '/expositionShop/coupon/update/' + id,
      method: 'post',
      data: data
    })
  }
}

export default coupon

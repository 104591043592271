<template>
  <div class="invoicereBox">
    <div class="option">
      <el-radio v-model="selectValue" label="1">通过</el-radio>
      <el-radio v-model="selectValue" label="2">拒绝</el-radio>
    </div>
    <div class="title" v-if="selectValue == 2">拒绝原因</div>
    <div class="input" v-if="selectValue == 2">
      <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="textArea"></el-input>
    </div>
    <div class="butList">
      <el-button type="primary" size="mini">确定</el-button>
      <el-button type="danger" size="mini">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 选项
      selectValue: '',
      // 拒绝原因
      textArea: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.invoicereBox {
  > .title {
    margin-top: 10px;
    height: 30px;
    line-height: 30px;
  }
}
</style>

<template>
  <div class="max-w">
    <el-dialog :visible="goodsDialog" :close-on-click-modal="closeOnClickModal" append-to-body title="货品信息" top="10vh" width="60%" @close="close">
      <el-form :inline="true" :model="keyword" class="demo-form-inline">
        <el-form-item label="名称/编号">
          <el-input v-model="keyword.codeOrName" size="mini" />
        </el-form-item>
        <el-form-item label="颜色">
          <el-input v-model="keyword.color" size="mini" />
        </el-form-item>
        <el-form-item label="尺寸">
          <el-input v-model="keyword.size" size="mini" />
        </el-form-item>
        <el-form-item class="search-area-btn">
          <el-button type="primary" size="mini" @click="handleSearch">查询</el-button>
          <el-button type="primary" size="mini" @click="resetData">重置</el-button>
        </el-form-item>
      </el-form>

      <template>
        <el-table ref="multipleTable" :data="tableData" style="width: 100%" class="m-t-12" border height="500" @row-dblclick="handleRowClick" @selection-change="handleSelectionChange">
          <el-table-column v-if="mutiple" type="selection" width="55" align="center" />
          <el-table-column prop="goodsCode" label="货品编号" width="150" align="center" />
          <el-table-column prop="goodsName" label="货品名称" align="center" />
          <el-table-column prop="color" label="颜色" align="center" width="100" />
          <el-table-column prop="size" label="尺寸" align="center" width="100" />
        </el-table>
      </template>
      <div class="m-w cen-box m-t-12">
        <el-pagination :current-page="page" :page-sizes="pageSize" :page-size="size" :total="count" :layout="'total, sizes, prev, pager, next, jumper'" @size-change="pageSizeChange" @current-change="pageNumberChange" />
      </div>
      <span v-if="mutiple" slot="footer" class="dialog-footer">
        <el-button size="mini" @click="close">取 消</el-button>
        <el-button type="primary" size="mini" @click="confirm">确定选择</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

const initSearchParams = {
  codeOrName: '',
  color: '',
  size: ''
}
export default {
  props: {
    goodsDialog: {
      type: Boolean,
      default: false
    },
    mutiple: {
      type: Boolean,
      default: false
    },
    baseGoods: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      isShow: false,
      closeOnClickModal: false,
      keyword: {
        ...initSearchParams
      },
      page: 1,
      size: 10,
      count: 0,
      pageSize: [10, 20, 30, 40, 50],
      tableData: [],
      multipleSelectionGoods: []
    }
  },
  watch: {
    loading(val) {
      this.$emit('loadingChange', val)
    }
  },
  created() {
    console.log(this.baseGoods)
    this.getList()
  },
  beforeDestroy() {
    this.$refs.multipleTable.clearSelection()
  },
  methods: {
    toggleRowSelection() {
      this.tableData.forEach(item => {
        this.baseGoods.forEach(id => {
          if (item.id === id) {
            this.$nextTick(() => {
              this.$refs.multipleTable.toggleRowSelection(item, true)
            })
          }
        })
      })
    },
    close() {
      this.$refs.multipleTable.clearSelection()
      this.$emit('closeGoodsDialog')
    },
    async getList() {
      const params = {
        pageNumber: this.page,
        pageSize: this.size,
        type: 1,
        ...this.keyword
      }
      this.$api.product.LikeSelectBase(params).then(res => {
        this.count = Number(res.data.total)
        this.tableData = res.data.list
        this.toggleRowSelection()
      })
    },
    handleSearch() {
      this.page = 1
      this.getList()
    },
    resetData() {
      this.keyword = {
        ...initSearchParams
      }
      this.handleSearch()
    },
    pageSizeChange(val) {
      this.size = val
      this.getList()
    },
    pageNumberChange(val) {
      this.page = val
      this.getList()
    },
    handleRowClick(row) {
      this.$emit('addGoodsInfo', row)
    },
    handleSelectionChange(val) {
      this.multipleSelectionGoods = val
    },
    confirm() {
      if (!this.multipleSelectionGoods.length) {
        this.$message.warning('请选择货品信息')
        return
      }
      this.$emit('selectionChange', this.multipleSelectionGoods)
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss"></style>

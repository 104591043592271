import request from '@/api/request'
import qs from 'qs'

const suit = {
  // 根据id获取商品sku信息
  getPrductSkuById(params) {
    return request({
      url: '/expositionShop/package/queryPrductIdBySku',
      method: 'get',
      params
    })
  },
  // 查询所有分类信息
  getCategoryInfo(params) {
    return request({
      url: '/expositionShop/package/queryCategoryInfo',
      method: 'get',
      params
    })
  },
  // 根据id获取商品sku信息
  getPrductInfoById(params) {
    return request({
      url: '/expositionShop/package/selectPrductInfoById',
      method: 'get',
      params
    })
  },

  //套餐-添加套餐
  addSuitInfo(data) {
    return request({
      url: '/expositionShop/package/addPackageInfo',
      method: 'post',
      data
    })
  },
  // 验证套餐名称是否存在-验证
  verifyPackageName(params) {
    return request({
      url: `/expositionShop/package/verifyPackageName`,
      method: 'get',
      params
    })
  },

  //套餐-根据参数查找
  getSuitListById(params) {
    return request({
      url: '/expositionShop/package/getPackageById',
      method: 'get',
      params
    })
  },
  //套餐-添加套餐
  updateSuitInfo(data) {
    return request({
      url: '/expositionShop/package/alterPackageInfo',
      method: 'post',
      data
    })
  },
  //套餐-查询
  getSuitList(params) {
    return request({
      url: '/expositionShop/package/list',
      method: 'get',
      params
    })
  },
  //套餐-删除套餐
  removeSuitInfo(params) {
    return request({
      url: '/expositionShop/package/deletePackageInfo',
      method: 'get',
      params
    })
  },
  // 获取省份
  getProvinceList(params) {
    return request({
      url: '/system/province/list',
      method: 'get',
      params
    })
  },
  // 获取城市
  getCityList(params) {
    return request({
      url: '/system/api/province/link',
      method: 'get',
      params
    })
  },
  // 获取区
  getAreaList(params) {
    return request({
      url: '/system/api/province/link',
      method: 'get',
      params
    })
  },
  // 会员优惠套餐地区-添加
  addAreaSuitInfo(data) {
    return request({
      url: `/expositionShop/packagePcd/save`,
      method: 'post',
      data
    })
  },

  // 会员优惠套餐地区-查找
  getAreaSuitListById(id) {
    return request({
      url: `/expositionShop/packagePcd/get/${id}`,
      method: 'get'
    })
  },

  // 会员优惠套餐地区-修改
  updateAreaSuitInfo(data) {
    return request({
      url: `/expositionShop/packagePcd/update`,
      method: 'post',
      data
    })
  },
  // 验证当前地区是否存在套餐-验证
  verifySuit(params) {
    return request({
      url: `/expositionShop/packagePcd/verifyPcd`,
      method: 'get',
      params
    })
  },
  // 会员优惠套餐地区-列表
  getAreaSuitList(params) {
    return request({
      url: '/expositionShop/packagePcd/list',
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      },
      params
    })
  },
  // 会员优惠套餐地区-删除
  removeAreaSuitById(params) {
    return request({
      url: `/expositionShop/packagePcd/remove`,
      method: 'get',
      params
    })
  },
  // 不限购区域--列表
  getLimitAreaList(params) {
    return request({
      url: `/expositionShop/area/list`,
      method: 'get',
      params
    })
  },
  // 不限购区域--删除
  removeLimitArea(params) {
    return request({
      url: `/expositionShop/area/remove`,
      method: 'get',
      params
    })
  },

  // 不限购区域--新增
  addLimitArea(data) {
    return request({
      url: '/expositionShop/area/save',
      method: 'post',
      data: qs.stringify(data)
    })
  },

  // 不限购区域--查找
  getLimitAreaById(params) {
    return request({
      url: `/expositionShop/area/get/${params}`,
      method: 'get'
    })
  },
  // 不限购区域--修改
  updateLimitArea(data) {
    return request({
      url: '/expositionShop/area/update',
      method: 'post',
      data: qs.stringify(data)
    })
  },
  // 获取省市
  getProvinceAreaList(params) {
    return request({
      url: '/system/api/link',
      method: 'get',
      params
    })
  },
  // 获取省份-根据id查找
  getCityListByProvinceId(params) {
    return request({
      url: `/system/city/list`,
      method: 'get',
      params
    })
  },
  // 不限购区域--新增
  addFreight(data) {
    return request({
      url: '/expositionShop/template/freight/save',
      method: 'post',
      data
    })
  },

  // 不限购区域--修改
  updateFreight(data) {
    return request({
      url: '/expositionShop/template/freight/update',
      method: 'post',
      data
    })
  },

  // 不限购区域--查找
  getFreightById(params) {
    return request({
      url: `/expositionShop/template/freight/get/${params}`,
      method: 'get'
    })
  },

  // 查询全部
  getFreightListAll(params = {}) {
    return request({
      url: `/expositionShop/template/freight/pullDownFreight`,
      method: 'get',
      params
    })
  },

  // 查询全部
  getLimitAreaAllList(params = {}) {
    return request({
      url: `/expositionShop/area/pullDownFlashArea`,
      method: 'get',
      params
    })
  },

  // 不限购区域--列表
  getFreightList(params) {
    return request({
      url: `/expositionShop/template/freight/list`,
      method: 'get',
      params
    })
  },

  // 不限购区域--删除
  removeFreight(params) {
    return request({
      url: `/expositionShop/template/freight/remove`,
      method: 'get',
      params
    })
  },

  // 不限购区域--城市删除
  removeFreightAssign(params) {
    return request({
      url: `/expositionShop/template/freight/assign/remove`,
      method: 'get',
      params
    })
  }
}

export default suit

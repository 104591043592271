<template>
  <div class="app-container">
    <el-card class="header-card" shadow="never">
      <div style="margin-top: 15px">
        <el-form :inline="true" :model="listQuery" size="small" label-width="110px">
          <el-row>
            <el-col :span="6">
              <el-form-item label="输入搜索">
                <el-input v-model="listQuery.keyword" style="width: 203px" placeholder="商品名称" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="店铺搜索">
                <el-select v-model="listQuery.shopId" placeholder="请选择店铺" clearable>
                  <el-option v-for="item in ShopNameOptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="商品货号">
                <el-input v-model="listQuery.productSn" style="width: 203px" placeholder="商品货号" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="商品分类">
                <el-cascader v-model="selectProductCateValue" clearable :options="productCateOptions" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <el-row class="search-area-btn">
          <div class="left">&nbsp;</div>
          <div class="center">
            <el-button type="primary" size="small" @click="handleSearchList()">查询 </el-button>
            <el-button size="small" @click="handleResetSearch()">重置</el-button>
          </div>
        </el-row>
      </div>
    </el-card>
    <!-- <el-card class="operate-container" shadow="never">
      <el-button type="primary" size="mini" @click="handleBatchArea">批量设置不限购区域</el-button>
      <el-button type="primary" size="mini" @click="handleBatchFreight">批量设置运费</el-button>
      <el-button type="danger" size="mini" @click="removeBatchArea">批量解除不限购区域</el-button>
      <el-button type="danger" size="mini" @click="removeBatchFreight">批量解除运费</el-button>
    </el-card> -->
    <!-- <el-card class="operate-container" shadow="never">
      <i class="el-icon-tickets"></i>
      <span>数据列表</span>
    </el-card> -->
    <div class="table-container">
      <el-table ref="productTable" v-loading="listLoading" :data="list" style="width: 100%" border @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="60" align="center" />
        <el-table-column label="编号" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column label="商品图片" width="120" align="center">
          <template slot-scope="scope">
            <div class="image__preview">
              <el-image style="height: 100px; width: 100px" :src="scope.row.pic" :preview-src-list="srcList" @click="showViewer(scope.row.pic)" />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商品名称" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.name }}</p>
            <p>品牌：{{ scope.row.brandName }}</p>
          </template>
        </el-table-column>
        <el-table-column label="所属店铺" align="center" width="100">
          <template slot-scope="scope">
            <p>{{ scope.row.shopName }}</p>
          </template>
        </el-table-column>
        <el-table-column label="价格/货号" width="120" align="center">
          <template slot-scope="scope">
            <p>价格：￥{{ scope.row.price }}</p>
            <p>货号：{{ scope.row.productSn }}</p>
          </template>
        </el-table-column>
        <!--        <el-table-column label="SKU库存" width="100" align="center">-->
        <!--          <template slot-scope="scope">-->
        <!--            <el-button type="primary" icon="el-icon-edit" @click="handleShowSkuEditDialog(scope.$index, scope.row)" circle></el-button>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <!--        <el-table-column label="销量" width="100" align="center">-->
        <!--          <template slot-scope="scope">{{scope.row.sale}}</template>-->
        <!--        </el-table-column>-->
        <el-table-column label="不限购区域" width="200" align="center">
          <template slot-scope="scope">
            <div v-if="!scope.row.isExpand" class="title-area">
              {{ scope.row.areaName }}
            </div>
            <div v-if="scope.row.isExpand">
              {{ scope.row.areaName }}
            </div>
            <el-button v-if="scope.row.areaName && !scope.row.isExpand" type="primary" size="mini" @click="expandCity(scope.$index, scope.row)">展开</el-button>
            <el-button v-if="scope.row.isExpand" type="primary" size="mini" @click="retractCity(scope.$index, scope.row)">收起</el-button>
          </template>
        </el-table-column>
        <el-table-column label="设置运费" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.tmpName }}</template>
        </el-table-column>
        <el-table-column label="申请时间" width="180" align="center">
          <template slot-scope="scope">{{ $utils.parseDate(new Date(scope.row.createTime)) }}</template>
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleUpdateProduct(scope.$index, scope.row, 0)">详情 </el-button>
            <el-button type="primary" size="mini" @click="handleResetProduct(scope.row.id, 0)"> 商品还原 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination background layout="total, sizes,prev, pager, next,jumper" :page-size="listQuery.pageSize" @current-change="handleCurrentChange" :page-sizes="[5, 10, 20, 30, 50, 100]" :current-page.sync="listQuery.pageNum" :total="total" @size-change="handleSizeChange" />
    </div>
    <el-dialog title="货品信息" append-to-body :visible.sync="editSkuInfo.dialogVisible" width="800px">
      <span>商品货号：</span>
      <span>{{ editSkuInfo.productSn }}</span>
      <el-table style="width: 100%; margin-top: 20px" :data="editSkuInfo.stockList" border>
        <el-table-column v-for="(item, index) in editSkuInfo.productAttr" :key="item.id" :label="item.name" align="center">
          <template slot-scope="scope">
            {{ getProductSkuSp(scope.row, index, item.name) }}
          </template>
        </el-table-column>
        <el-table-column label="销售价格" width="120" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.price" :disabled="true" />
          </template>
        </el-table-column>
        <el-table-column label="商品库存" width="120" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.stock" min="1" :disabled="true" />
          </template>
        </el-table-column>
        <el-table-column label="库存预警值" width="100" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.lowStock" :disabled="true" />
          </template>
        </el-table-column>
      </el-table>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="editSkuInfo.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleEditSkuConfirm">确 定</el-button>
      </span> -->
    </el-dialog>
    <productDetail v-if="productDetailShow" :id="productDetailId" :copy="productDetailCopy" :is-edit="productDetailEdit" @close="productDetailClose" />
  </div>
</template>
<script>
import productDetail from '../product/components/ProductDetail'

const defaultListQuery = {
  keyword: null,
  pageNum: 1,
  pageSize: 5,
  publishStatus: null,
  shopId: null,
  verifyStatus: null,
  productSn: null,
  productCategoryId: null,
  brandId: null,
  flag: null,
  isExemption: null,
  deleteStatus: 1,
  expositionStatus: 0
}
export default {
  name: 'ProductList',
  components: {
    productDetail
  },
  filters: {
    verifyStatusFilter(value) {
      if (value === 1) {
        return '审核通过'
      } else {
        return '未审核'
      }
    }
  },
  data() {
    return {
      productDetailShow: false,
      productDetailId: null,
      productDetailCopy: false,
      productDetailEdit: false,
      srcList: [],
      editSkuInfo: {
        dialogVisible: false,
        productId: null,
        productSn: '',
        productAttributeCategoryId: null,
        stockList: [],
        productAttr: [],
        keyword: null
      },
      ShopNameOptions: [],
      operates: [
        {
          label: '商品上架',
          value: 'publishOn'
        },
        {
          label: '商品下架',
          value: 'publishOff'
        },
        {
          label: '设为推荐',
          value: 'recommendOn'
        },
        {
          label: '取消推荐',
          value: 'recommendOff'
        },
        {
          label: '设为新品',
          value: 'newOn'
        },
        {
          label: '取消新品',
          value: 'newOff'
        },
        // {
        //   label: "转移到分类",
        //   value: "transferCategory"
        // },
        {
          label: '移入回收站',
          value: 'recycle'
        }
      ],
      operateType: null,
      listQuery: Object.assign({}, defaultListQuery),
      list: null,
      total: null,
      listLoading: true,
      selectProductCateValue: null,
      multipleSelection: [],
      productCateOptions: [],
      brandOptions: [],
      publishStatusOptions: [
        {
          value: 1,
          label: '上架'
        },
        {
          value: 0,
          label: '下架'
        }
      ],
      verifyStatusOptions: [
        {
          value: 1,
          label: '审核通过'
        },
        {
          value: 0,
          label: '未审核'
        }
      ],
      areaOptions: [
        {
          value: 0,
          label: '否'
        },
        {
          value: 1,
          label: '是'
        }
      ],
      currentSelectdProductId: null, // 当前选中的商品id
      selectAreaDialogVisible: false, // 是否展示不限购区域弹框
      selectFreightDialogVisible: false, // 是否展示运费弹框
      currentSelectedAreaInfo: null, // 当前选中的不限购地区信息
      currentSelectedFreightInfo: null // 当前选中的运费模板
    }
  },
  computed: {
    shopProductTypeOptions() {
      if (this.$store.state.dictionary.shopProductType.length === 0) {
        this.$store.dispatch('change_shopProductType')
      }
      return this.$store.state.dictionary.shopProductType
    }
  },
  watch: {
    selectProductCateValue(newValue) {
      if (newValue != null && newValue.length == 2) {
        this.listQuery.productCategoryId = newValue[1]
      } else {
        this.listQuery.productCategoryId = null
      }
    }
  },
  created() {
    this.getList()
    this.getShopNameList()
    this.getBrandList()
    this.getProductCateList()
  },
  methods: {
    productDetailClose() {
      this.productDetailId = null
      this.productDetailCopy = false
      this.productDetailEdit = false
      this.productDetailShow = false
    },
    showViewer(val) {
      this.srcList = []
      this.srcList.push(val)
    },
    handleResetProduct(id, status) {
      this.$confirm('是否要进行还原', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          deleteStatus: status,
          ids: id
        }
        this.$api.product.productReset(params).then(val => {
          console.log(val, '查看是否返回成功')
          if (val.code === 200) {
            this.$message({
              message: '修改成功',
              type: 'success',
              duration: 1000
            })
            this.getList()
          }
        })
      })
      // console.log(id,'查看单个商品信息')
    },
    getProductSkuSp(row, index, name) {
      // if (index === 0) {
      //   return row.sp1;
      // } else if (index === 1) {
      //   return row.sp2;
      // } else {
      //   return row.sp3;
      // }
      const arr = JSON.parse(row.spData)
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].key === name) {
          return arr[i].value
        }
      }
      // return arr[index].value
    },
    getList() {
      this.listLoading = true
      // fetchList
      this.$api.product.getProductList(this.listQuery).then(response => {
        this.listLoading = false
        this.list = response.data.list
        this.total = response.data.total
        this.list.forEach(el => {
          el.isExpand = false
        })
        // this.list = response.listData
        // this.total = response.count
      })
    },
    getShopNameList() {
      this.$api.common.getShopList({ pageNum: 1, pageSize: 100 }).then(response => {
        this.ShopNameOptions = []
        const ShopList = response.data
        for (let i = 0; i < ShopList.length; i++) {
          this.ShopNameOptions.push({ label: ShopList[i].shopName, value: ShopList[i].id })
        }
      })
    },
    getBrandList() {
      this.$api.brand.brandList({ pageNum: 1, pageSize: 100 }).then(response => {
        this.brandOptions = []
        const brandList = response.listData
        for (let i = 0; i < brandList.length; i++) {
          this.brandOptions.push({ label: brandList[i].name, value: brandList[i].id })
        }
      })
    },
    getProductCateList() {
      this.$api.product.fetchListWithChildren().then(response => {
        const list = response.data
        this.productCateOptions = []
        for (let i = 0; i < list.length; i++) {
          const children = []
          if (list[i].children != null && list[i].children.length > 0) {
            for (let j = 0; j < list[i].children.length; j++) {
              children.push({ label: list[i].children[j].name, value: list[i].children[j].id })
            }
          }
          this.productCateOptions.push({ label: list[i].name, value: list[i].id, children: children })
        }
      })
    },
    handleShowSkuEditDialog(index, row) {
      this.editSkuInfo.dialogVisible = true
      this.editSkuInfo.productId = row.id
      this.editSkuInfo.productSn = row.productSn
      this.editSkuInfo.productAttributeCategoryId = row.productAttributeCategoryId
      this.editSkuInfo.keyword = null
      this.$api.product.skuList(row.id, { keyword: this.editSkuInfo.keyword }).then(response => {
        this.editSkuInfo.stockList = response.listData
      })
      if (row.productAttributeCategoryId != null) {
        this.$api.product.productAttrList(row.productAttributeCategoryId, { type: 0 }).then(response => {
          this.editSkuInfo.productAttr = response.listData
        })
      }
    },
    handleSearchEditSku() {
      this.$api.product.skuList(this.editSkuInfo.productId, { keyword: this.editSkuInfo.keyword }).then(response => {
        this.editSkuInfo.stockList = response.listData
      })
    },
    handleEditSkuConfirm() {
      if (this.editSkuInfo.stockList == null || this.editSkuInfo.stockList.length <= 0) {
        this.$message({
          message: '暂无sku信息',
          type: 'warning',
          duration: 1000
        })
        return
      }
      let flag = false
      const reg = /^\d+$/
      this.editSkuInfo.stockList.forEach(item => {
        if (item.stock < 0 || !reg.test(item.stock)) {
          flag = true
        }
      })
      if (flag) {
        this.$message({
          message: '商品库存不能小于0且为整数',
          type: 'warning',
          duration: 1000
        })
        return
      }
      this.$confirm('是否要进行修改', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.product.skuUpdate(this.editSkuInfo.productId, this.editSkuInfo.stockList).then(() => {
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000
          })
          this.editSkuInfo.dialogVisible = false
        })
      })
    },
    handleSearchList() {
      this.listQuery.pageNum = 1
      this.getList()
    },
    handleAddProduct() {
      this.$router.push({ path: '/pms/addProduct' })
    },
    handleSizeChange(val) {
      this.listQuery.pageNum = 1
      this.listQuery.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.pageNum = val
      this.getList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handlePublishStatusChange(index, row) {
      const ids = []
      ids.push(row.id)
      this.updatePublishStatus(row.publishStatus, ids, index)
    },
    handleNewStatusChange(index, row) {
      if (row.publishStatus == 1) {
        this.$message({
          message: '上架商品不能操作'
        })
        return
      }
      const ids = []
      ids.push(row.id)
      this.updateNewStatus(row.newStatus, ids)
    },
    handleRecommendStatusChange(index, row) {
      if (row.publishStatus == 1) {
        this.$message({
          message: '上架商品不能操作'
        })
        return
      }
      const ids = []
      ids.push(row.id)
      this.updateRecommendStatus(row.recommandStatus, ids)
    },
    handleResetSearch() {
      this.selectProductCateValue = []
      this.listQuery = Object.assign({}, defaultListQuery)
    },
    handleUpdateProduct(index, row, type) {
      console.log(11111)
      this.productDetailId = row.id
      this.productDetailCopy = type
      this.productDetailEdit = true
      this.productDetailShow = true
    },
    handleShowProduct(index, row) {
      console.log('handleShowProduct', row)
    },
    handleShowVerifyDetail(index, row) {
      console.log('handleShowVerifyDetail', row)
    },
    handleShowLog(index, row) {
      console.log('handleShowLog', row)
    },
    getAuditStatus(ids, auditStatus) {
      const params = {
        ids: ids,
        auditStatus: auditStatus
      }
      this.$api.product.getAuditStatus(params).then(response => {
        console.log(response)
        this.getList()
        this.$message({
          message: '修改成功',
          type: 'success',
          duration: 1000
        })
      })
    },
    updatePublishStatus(publishStatus, ids, index) {
      let data = {
        ids: ids,
        publishStatus: publishStatus
      }
      this.$api.product
        .updatePublishStatus(data)
        .then(() => {
          this.getList()
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000
          })
        })
        .catch(() => {
          this.list[index].publishStatus = publishStatus === 1 ? 0 : 1
        })
    },
    updateNewStatus(newStatus, ids) {
      let data = {
        ids: ids,
        newStatus: newStatus
      }
      this.$api.product.updateNewStatus(data).then(() => {
        this.getList()
        this.$message({
          message: '修改成功',
          type: 'success',
          duration: 1000
        })
      })
    },
    updateRecommendStatus(recommendStatus, ids) {
      let data = {
        ids: ids,
        recommendStatus: recommendStatus
      }
      this.$api.product.updateRecommendStatus(data).then(() => {
        this.getList()
        this.$message({
          message: '修改成功',
          type: 'success',
          duration: 1000
        })
      })
    },
    updateDeleteStatus(deleteStatus, ids) {
      let data = {
        ids: ids,
        deleteStatus: deleteStatus
      }
      this.$api.product.updateDeleteStatus(data).then(() => {
        this.$message({
          message: '删除成功',
          type: 'success',
          duration: 1000
        })
        this.getList()
      })
    },
    // 设置不限购区域
    handleSetArea(ndex, row) {
      this.currentSelectdProductId = row.id
      this.selectAreaDialogVisible = true
    },
    // 设置不限购区域
    handleSetFreight(index, row) {
      this.currentSelectdProductId = row.id
      this.selectFreightDialogVisible = true
    },
    closeAreaDialog() {
      this.selectAreaDialogVisible = false
    },
    closeFreightDialog() {
      this.selectFreightDialogVisible = false
    },
    addAreaInfo(value) {
      this.currentSelectedAreaInfo = value
      this.updateGoodsArea()
    },
    addFreightInfo(value) {
      this.currentSelectedFreightInfo = value
      this.updateGoodsFreight()
    },
    // 批量
    handleBatchArea() {
      if (this.multipleSelection == null || this.multipleSelection.length < 1) {
        this.$message({
          message: '请选择要操作的商品',
          type: 'warning',
          duration: 1000
        })
        return
      }
      this.selectAreaDialogVisible = true
    },
    // 批量
    handleBatchFreight() {
      if (this.multipleSelection == null || this.multipleSelection.length < 1) {
        this.$message({
          message: '请选择要操作的商品',
          type: 'warning',
          duration: 1000
        })
        return
      }
      this.selectFreightDialogVisible = true
    },
    // 批量设置不限购区域
    updateGoodsArea() {
      let data = {}
      if (this.currentSelectdProductId) {
        data = {
          flashAreaId: this.currentSelectedAreaInfo.tmpName,
          flashAreaName: this.currentSelectedAreaInfo.areaList,
          ids: this.currentSelectdProductId
        }
      } else {
        const ids = []
        if (this.multipleSelection && this.multipleSelection.length) {
          this.multipleSelection.forEach(el => {
            ids.push(el.id)
          })
        }
        data = {
          flashAreaId: this.currentSelectedAreaInfo.tmpName,
          flashAreaName: this.currentSelectedAreaInfo.areaList,
          ids: ids.join(',')
        }
      }
      this.$api.product.setLimitArea(data).then(res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '设置成功'
          })
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    // 批量设置运费模板
    updateGoodsFreight() {
      let data = {}
      if (this.currentSelectdProductId) {
        data = {
          freightId: this.currentSelectedFreightInfo.freightId,
          freightName: this.currentSelectedFreightInfo.freightName,
          ids: this.currentSelectdProductId,
          isExemption: 1
        }
      } else {
        const ids = []
        if (this.multipleSelection && this.multipleSelection.length) {
          this.multipleSelection.forEach(el => {
            ids.push(el.id)
          })
        }
        data = {
          freightId: this.currentSelectedFreightInfo.freightId,
          freightName: this.currentSelectedFreightInfo.freightName,
          ids: ids.join(','),
          isExemption: 1
        }
      }
      this.$api.product.setFreight(data).then(res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '设置成功'
          })
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    // 解除不限购区域模板
    removeBatchArea() {
      if (this.multipleSelection == null || this.multipleSelection.length < 1) {
        this.$message({
          message: '请选择要操作的商品',
          type: 'warning',
          duration: 1000
        })
        return
      }
      const ids = []
      if (this.multipleSelection && this.multipleSelection.length) {
        this.multipleSelection.forEach(el => {
          ids.push(el.id)
        })
      }
      const data = {
        ids: ids.join(',')
      }
      this.$api.product.relieveFlashArea(data).then(res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '解除不限购区域成功'
          })
          this.getList()
        }
      })
    },
    // 解除运费模板
    removeBatchFreight() {
      if (this.multipleSelection == null || this.multipleSelection.length < 1) {
        this.$message({
          message: '请选择要操作的商品',
          type: 'warning',
          duration: 1000
        })
        return
      }
      const ids = []
      if (this.multipleSelection && this.multipleSelection.length) {
        this.multipleSelection.forEach(el => {
          ids.push(el.id)
        })
      }
      const data = {
        ids: ids.join(',')
      }
      this.$api.product.relieveFreight(data).then(res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '解除运费区域成功'
          })
          this.getList()
        }
      })
    },
    // 收起
    retractCity(index, row) {
      this.list.forEach(el => {
        if (el.id == row.id) {
          el.isExpand = false
        }
      })
      this.list = JSON.parse(JSON.stringify(this.list))
    },
    // 展开
    expandCity(index, row) {
      this.list.forEach(el => {
        if (el.id == row.id) {
          el.isExpand = true
        }
      })
      this.list = JSON.parse(JSON.stringify(this.list))
    }
  }
}
</script>

<style lang="scss" scoped>
.title-area {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pagination-container {
  padding-bottom: 20px;
}
</style>

<template>
  <div class="detail-container" style="left: 50px">
    <el-card shadow="never">
      <span class="font-title-medium">商品信息</span>
      <el-table border class="standard-margin" ref="productTable" :data="productList">
        <el-table-column label="商品图片" width="160" align="center">
          <template slot-scope="scope">
            <img style="height: 80px" :src="scope.row.productPic" />
          </template>
        </el-table-column>
        <el-table-column label="商品名称" align="center">
          <template slot-scope="scope">
            <span class="font-small">{{ scope.row.productName }}</span
            ><br />
            <span class="font-small">品牌：{{ scope.row.productBrand }}</span>
          </template>
        </el-table-column>
        <el-table-column label="价格/货号" width="180" align="center">
          <template slot-scope="scope">
            <span class="font-small">价格：￥{{ scope.row.productPrice }}</span
            ><br />
            <span class="font-small">货号：NO.{{ scope.row.productId }}</span>
          </template>
        </el-table-column>
        <el-table-column label="属性" width="180" align="center">
          <template slot-scope="scope">
            <span v-for="col in scope.row.productAttr" :key="col.key">{{ col.key + ':' + col.value }}</span>
          </template>
        </el-table-column>
        <el-table-column label="数量" width="100" align="center">
          <template slot-scope="scope">{{ scope.row.productQuantity }}</template>
        </el-table-column>
        <el-table-column label="小计" width="100" align="center">
          <template slot-scope="scope">￥{{ scope.row.productPrice }}</template>
        </el-table-column>
      </el-table>
      <div style="float: right; margin-top: 15px; margin-bottom: 15px">
        <span class="font-title-medium">合计：</span>
        <span class="font-title-medium color-danger">￥{{ order ? order.totalAmount : 0 }}</span>
      </div>
    </el-card>
    <el-card v-if="invoiceDetail" shadow="never" class="standard-margin">
      <span class="font-title-medium">服务单信息</span>
      <div class="form-container-border">
        <el-row>
          <el-col :span="6" class="form-border form-left-bg font-small">服务单号</el-col>
          <el-col class="form-border font-small" :span="18">{{ invoiceDetail.platformTransId }}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">申请状态</el-col>
          <el-col class="form-border font-small" :span="18">{{ invoiceDetail.status | invoiceStatus }}</el-col>
        </el-row>
        <el-row>
          <el-col :span="6" class="form-border form-left-bg font-small" style="height: 50px; line-height: 30px">订单编号 </el-col>
          <el-col class="form-border font-small" :span="18" style="height: 50px">
            {{ invoiceDetail.platformTransId }}
            <el-button type="text" size="small" @click="handleViewOrder">查看</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">申请时间</el-col>
          <el-col class="form-border font-small" :span="18">{{ formatTime(invoiceDetail.createTime) }}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">用户账号</el-col>
          <el-col class="form-border font-small" :span="18">{{ invoiceDetail.memberUsername || '暂无' }}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">联系人</el-col>
          <el-col class="form-border font-small" :span="18">{{ order ? order.memberUsername : '' }}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">联系电话</el-col>
          <el-col class="form-border font-small" :span="18">{{ order ? order.receiverPhone : '' }}</el-col>
        </el-row>
        <el-row v-if="order">
          <el-col class="form-border form-left-bg font-small" :span="6">收货地址</el-col>
          <el-col class="form-border font-small" :span="18">{{ order.receiverProvince + order.receiverCity + order.receiverRegion + order.receiverDetailAddress }}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">发票类型</el-col>
          <el-col class="form-border font-small" :span="18">{{ invoiceDetail.invoiceNameType | invoiceType }}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">发票抬头</el-col>
          <el-col class="form-border font-small" :span="18">{{ invoiceDetail.invoiceName || '暂无' }}</el-col>
        </el-row>
        <el-row v-if="order">
          <el-col class="form-border form-left-bg font-small" :span="6">开票金额(￥)</el-col>
          <el-col class="form-border font-small" :span="18">{{ order.payAmount || '暂无' }}</el-col>
        </el-row>
      </div>
      <div v-if="invoiceDetail.invoiceNameType == 0 || invoiceDetail.invoiceNameType == null" class="form-container-border">
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">个人姓名</el-col>
          <el-col class="form-border font-small" :span="18">{{ invoiceDetail.invoiceName }}</el-col>
        </el-row>
        <!-- <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">接受发票的电子邮箱</el-col>
          <el-col class="form-border font-small" :span="18">{{ orderReturnApply.returnAmount }}</el-col>
        </!-->
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6" style="height: 100px; line-height: 80px"> 电子发票 </el-col>
          <el-col class="form-border font-small" :span="18" style="height: 100px">
            <!-- <el-image
              style="width:80px;height:80px"
              :src="invoiceDetail.invoiceUrl"
            >
            </el-image> -->
            <el-link v-if="invoiceDetail.invoiceUrl" :href="invoiceDetail.invoiceUrl" target="_blank">
              <el-button type="primary" size="mini">查看发票</el-button>
            </el-link>
            <span v-else>暂无</span>
          </el-col>
        </el-row>
      </div>
      <div v-if="invoiceDetail.invoiceNameType == 1 || invoiceDetail.invoiceNameType == 2" class="form-container-border">
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">公司名称</el-col>
          <el-col class="form-border font-small" :span="18">{{ invoiceDetail.invoiceName }}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">纳税识别号</el-col>
          <el-col class="form-border font-small" :span="18">{{ invoiceDetail.ein }}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">单位地址</el-col>
          <el-col class="form-border font-small" :span="18">{{ '暂无' }}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">电话号码</el-col>
          <el-col class="form-border font-small" :span="18">{{ invoiceDetail.billReceiverPhone }}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">开户行</el-col>
          <el-col class="form-border font-small" :span="18">{{ '暂无' }}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">银行账号</el-col>
          <el-col class="form-border font-small" :span="18">{{ '暂无' }}</el-col>
        </el-row>
        <!-- <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">接受发票的电子邮箱</el-col>
          <el-col class="form-border font-small" :span="18">{{ orderReturnApply.returnAmount }}</el-col>
        </el-row> -->
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6" style="height: 100px; line-height: 80px">电子发票 </el-col>
          <el-col class="form-border font-small" :span="18" style="height: 100px">
            <!-- <img
              style="width:80px;height:80px"
              :src="invoiceDetail.invoiceUrl"
            /> -->
            <el-link v-if="invoiceDetail.invoiceUrl" :href="invoiceDetail.invoiceUrl" target="_blank">
              <el-button type="primary" size="mini">下载发票</el-button>
            </el-link>
            <span v-else>暂无</span>
          </el-col>
        </el-row>
      </div>
      <div style="margin-top: 15px; text-align: center">
        <!-- <el-button type="primary" size="small" @click="handleUpdateStatus(1)">开票完成</el-button> -->
        <el-button v-if="false" type="danger" size="small" @click="handleUpdateStatus(2)">取消开票完成</el-button>
        <!-- invoiceDetail.status === 0 ||  -->
        <el-button v-if="invoiceDetail.status === 1" type="primary" size="small" @click="cancellationInvoice()">发票作废</el-button>
      </div>
    </el-card>
  </div>
</template>
<script>
const defaultUpdateStatusParam = {
  companyAddressId: null,
  handleMan: null,
  handleNote: null,
  receiveMan: '',
  receiveNote: null,
  returnAmount: 0,
  status: 0,
  logisticalCompany: '',
  logisticalNumber: '',
  phoneNumber: ''
}
const defaultOrderReturnApply = {
  id: null,
  orderId: null,
  companyAddressId: null,
  productId: null,
  orderSn: null,
  createTime: null,
  memberUsername: null,
  returnAmount: null,
  returnName: null,
  returnPhone: null,
  status: null,
  handleTime: null,
  productPic: null,
  productName: null,
  productBrand: null,
  productAttr: null,
  productCount: null,
  productPrice: null,
  productRealPrice: null,
  reason: null,
  description: null,
  proofPics: null,
  handleNote: null,
  handleMan: null,
  receiveMan: null,
  receiveTime: null,
  receiveNote: null
}
export default {
  name: 'invoiceDetail',
  data() {
    return {
      id: null,
      orderReturnApply: Object.assign({}, defaultOrderReturnApply),
      productList: null,
      proofPics: null,
      updateStatusParam: Object.assign({}, defaultUpdateStatusParam),
      companyAddressList: null,

      invoiceDetail: null,
      order: null
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getDetail()
    this.getOrderDetailByCode()
  },
  filters: {
    invoiceStatus(val) {
      if (val === 0) {
        return '待开票'
      } else if (val === 1) {
        return '已开票'
      } else {
        return '已废除'
      }
    },
    invoiceType(val) {
      if (val === null) return '个人'
      if (val === 0) {
        return '个人'
      } else if (val === 1) {
        return '企业'
      }
    }
  },
  methods: {
    formatTime(time) {
      if (time == null || time === '') {
        return ''
      }
      let date = new Date(time)
      return this.$utils.parseDate(date)
    },
    // 发票作废
    cancellationInvoice() {
      this.$confirm('是否要进行此操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          transId: this.$route.query.code
        }
        this.$api.invoice.cancellationInvoice(params).then(res => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '操作成功!',
              duration: 1000
            })
            this.$router.back()
          }
        })
      })
    },
    handleViewOrder() {
      this.$router.push({
        path: '/oms/orderDetail',
        query: { id: this.order.id }
      })
    },
    getDetail() {
      this.$api.invoice.getInvoiceByTd(this.id).then(response => {
        this.invoiceDetail = response.data
        if (response.data.platformTransId) {
          this.getOrderInfo(response.data.platformTransId)
        }
      })
    },
    getOrderInfo(code) {
      const params = {
        code: code
      }
      this.$api.invoice.getOrderInfo(params).then(res => {
        if (res.code === 200) {
          this.order = res.data
          res.data.orderItemList.map(item => {
            item.productAttr = item.productAttr && JSON.parse(item.productAttr)
          })
          this.productList = res.data.orderItemList
        }
      })
    },
    handleUpdateStatus(status) {
      this.$confirm('是否要进行此操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          id: this.id,
          status: status
        }
        this.$api.invoice.updateInvoice(params).then(() => {
          this.$message({
            type: 'success',
            message: '操作成功!',
            duration: 1000
          })
          this.$router.back()
        })
      })
    },
    getOrderDetailByCode() {
      const params = {
        code: this.$route.query.code
      }
      this.$api.order.getOrderDetailByCode(params).then(res => {
        console.log(res)
      })
    }
  }
}
</script>
<style scoped>
.detail-container {
  position: absolute;
  left: 0;
  right: 0;
  width: 1080px;
  padding: 35px 35px 15px 35px;
  margin: 20px auto;
}

.standard-margin {
  margin-top: 15px;
}
.form-border {
  border-right: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  padding: 10px;
}

.form-container-border {
  border-left: 1px solid #dcdfe6;
  border-top: 1px solid #dcdfe6;
  margin-top: 15px;
}

.form-left-bg {
  background: #f2f6fc;
  text-align: center;
}
</style>

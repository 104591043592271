import request from '@/api/request'
// import qs from 'qs'

const news = {
  addNews(params) {
    return request({
      url: `expositionShop/content/save`,
      method: 'POST',
      data: params
    })
  },
  newsQuery(params) {
    return request({
      url: `expositionShop/content/list`,
      method: 'GET',
      params
    })
  },
  newsRemove(params) {
    return request({
      url: `expositionShop/content/remove`,
      method: 'POST',
      data: params
    })
  },
  newsContentQuery(id) {
    return request({
      url: `expositionShop/content/get/${id}`,
      method: 'GET'
    })
  },
  newsContentUpadte(params) {
    return request({
      url: `expositionShop/content/update`,
      method: 'POST',
      data: params
    })
  },
  newsOverHead(params) {
    return request({
      url: `expositionShop/content/overhead`,
      method: 'POST',
      data: params
    })
  },
  mulitRemoveNews(data) {
    return request({
      url: `expositionShop/content/remove`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      noUse: true,
      data: data
    })
  },
  noticeList(params) {
    return request({
      url: `expositionShop/news/list`,
      method: 'GET',
      params
    })
  },
  addNoticeList(params) {
    return request({
      url: `expositionShop/news/create`,
      method: 'POST',
      data: params
    })
  }
}

export default news

<template>
  <el-dialog v-dialogDrag :close-on-click-modal="false" :visible="true" :title="(isEdit ? '编辑' : '新增') + '品牌'" top="10vh" width="60%" @close="close">
    <el-card class="container" shadow="never">
      <el-form ref="brandFrom" :model="brand" :rules="rules" label-width="150px">
        <el-form-item label="品牌名称：" prop="name">
          <el-input v-model="brand.name" />
        </el-form-item>
        <el-form-item label="品牌首字母：">
          <el-input v-model="brand.firstLetter" />
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="品牌LOGO：" prop="logo">
              <single-upload v-model="brand.logo" :name="fileName" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="品牌专区大图：">
              <single-upload v-model="brand.bigPic" :name="fileName" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="品牌故事：">
          <el-input v-model="brand.brandStory" placeholder="请输入内容" type="textarea" :autosize="true" />
        </el-form-item>
        <el-form-item label="排序：" prop="sort">
          <el-input v-model.number="brand.sort" />
        </el-form-item>
        <el-form-item label="是否显示：">
          <el-radio-group v-model="brand.showStatus">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="品牌制造商：">
          <el-radio-group v-model="brand.factoryStatus">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <el-row type="flex" justify="center">
        <el-button type="primary" @click="onSubmit('brandFrom')">提交</el-button>
        <el-button @click="close">取消</el-button>
      </el-row>
    </el-card>
  </el-dialog>
</template>
<script>

const defaultBrand = {
  bigPic: '',
  brandStory: '',
  factoryStatus: 0,
  firstLetter: '',
  logo: '',
  name: '',
  showStatus: 0,
  sort: 0
}
export default {
  name: 'BrandDetail',
  components: { },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      fileName: 'static',
      brand: Object.assign({}, defaultBrand),
      rules: {
        name: [
          { required: true, message: '请输入品牌名称', trigger: 'blur' },
          {
            min: 2,
            max: 140,
            message: '长度在 2 到 140 个字符',
            trigger: 'blur'
          }
        ],
        logo: [{ required: true, message: '请输入品牌logo', trigger: 'blur' }],
        sort: [{ type: 'number', message: '排序必须为数字' }]
      }
    }
  },
  created() {
    if (this.isEdit) {
      this.$api.brand.getBrand(this.id).then(response => {
        this.brand = response.data
      })
    } else {
      this.brand = Object.assign({}, defaultBrand)
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if (this.isEdit) {
              this.$api.brand.updateBrand(this.id, this.brand).then(() => {
                this.$refs[formName].resetFields()
                this.$message({
                  message: '修改成功',
                  type: 'success',
                  duration: 1000
                })
                this.close()
              })
            } else {
              this.$api.brand.createBrand(this.brand).then(() => {
                this.$refs[formName].resetFields()
                this.brand = Object.assign({}, defaultBrand)
                this.$message({
                  message: '提交成功',
                  type: 'success',
                  duration: 1000
                })
                this.close()
              })
            }
          })
        } else {
          this.$message({
            message: '验证失败',
            type: 'error',
            duration: 1000
          })
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.brand = Object.assign({}, defaultBrand)
    }
  }
}
</script>
<style></style>

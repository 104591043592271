import request from '@/api/request'
// import qs from 'qs'

const goodsAudit = {
  getAuditList(params) {
    return request({
      url: '/expositionShop/product/selectExpositionStatusProductList',
      method: 'get',
      params: params
    })
  }
}

export default goodsAudit

<template>
  <div>
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible="true" :title="dialogTitle" top="10vh" width="60%" @close="close">
      <el-card class="container" shadow="never">
        <el-form ref="productInfoForm" :model="newsData" :rules="rules" label-width="120px" style="width: 100%" size="small">
          <el-row>
            <el-col :span="12">
              <el-form-item label="新闻标题：" prop="title">
                <el-input v-model="newsData.title" :disabled="nwesStatus === 2"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="新闻分类：" prop="categoryId">
                <el-select v-model="newsData.categoryId" placeholder="请选择新闻分类" :disabled="nwesStatus === 2" @change="newsDataSelect">
                  <el-option v-for="item in productCateOptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12" v-if="categoryName !== '小程序新闻'">
              <el-form-item label="新闻摘要：" prop="description">
                <el-input
                    type="textarea"
                    :rows="4"
                    v-model="newsData.description"
                    :disabled="nwesStatus === 2"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="新闻附图：" prop="cover">
                <upload-file v-if="nwesStatus !== 2"  :limit="1" :file-size="fileSizePicture" :file-type="fileTypePicture" :list-type="listTypePicture" :init-file-list="fileList" :name="'file-name'" @removeFile="removeFile" @uploadSuccess="uploadSuccess" />
                <img v-if="fileList.length && nwesStatus === 2 && newsData.cover.indexOf('mp4') < 0" :src="fileList[0].url" style="width: 100px;height: 100px;" alt="" />
                <video v-if="fileList.length && nwesStatus === 2 && newsData.cover.indexOf('mp4') > 0" :src="fileList[0].url" style="width: 200px;height: 110px;" controls="controls" ></video>
                <span v-if="nwesStatus === 2 && !fileList.length">暂无</span>
<!--                <single-upload v-model="newsData.cover" name="file.png" />-->
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item label="新闻内容：" prop="content">
                <tinymce v-model="newsData.content" :width="800" :height="300" :disabled="nwesStatus === 2"/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- <el-form-item label="展示图标：" prop="showIcon">
                <single-upload v-model="value.showIcon" name="showIcon" />
              </el-form-item> -->
<!--              <el-form-item label="新闻状态：" prop="status">-->
<!--                <el-switch v-model="newsData.status" :active-value="1" :inactive-value="0"/>-->
<!--              </el-form-item>-->
            </el-col>
            <el-col :span="12">
              <el-form-item label="生效时间：" prop="publishDate">
                <el-date-picker
                    v-model="newsData.publishDate"
                    type="date"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="newsData.authorQuote">
              <el-form-item label="链接：">
                <el-input v-for="(item,index) in newsData.authorQuote" v-model="newsData.authorQuote[index]" :key="item" :disabled="true"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="新闻排序：" prop="sort"> <el-input type="number" v-model="newsData.sort" :disabled="nwesStatus === 2"/> </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="浏览次数：" prop="clicks">
                <el-input type="number" v-model="newsData.clicks" :disabled="nwesStatus === 2"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="nwesStatus !== 2" @click="handlePrview">预览</el-button>
        <el-button v-if="nwesStatus == 2" type="primary" size="mini" @click="handlePassNews()">通过</el-button>
        <el-button v-if="nwesStatus == 2" class="btn-add" type="danger" size="mini" @click="handleRefuseNews()">不通过</el-button>
        <el-button v-if="nwesStatus == 2" @click="close">关闭</el-button>
        <el-button v-if="nwesStatus !== 2" @click="handleSaveNews" type="primary">保存</el-button>
        <el-button v-if="nwesStatus !== 2" @click="handleReviewNews" type="primary">提审</el-button>
      </span>
      <newsPreview v-if="showPrview" :newsData="newsData" @handlePreviewColse="handlePreviewColse"></newsPreview>
    </el-dialog>
  </div>
</template>

<script>
import Tinymce from '@/components/Tinymce'
import newsPreview from './newsPreview'
const defaultProductParam = {
  sort:'',
  publishDate:'',
  categoryId: '',
  cover:'',
  content: '',
  expiryDate: '',
  clicks: '',
  status: '',
  description: '',
  title: '',
  authorQuote:''
}
export default {
  components: {
    Tinymce,
    newsPreview
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    isCopy: {
      type: Boolean,
      default: false
    },
    nwesId: {
      type:[String,Number]
    },
    nwesStatus:{
      type:[String,Number]
    },
    productCateOptions:{
      type: Array,
      default: () => []
    }
  },
  watch: {
  },
  computed: {

  },
  created() {
    console.log(this.isEdit, this.nwesStatus, '哈哈哈哈')
    if(this.isEdit){
      this.dialogTitle = "新闻编辑"
    }  else if (this.nwesStatus == 2) {
      this.dialogTitle = "新闻审核"
    } else {
      this.dialogTitle = "新闻新增"
    }
    if(this.nwesId) {
      this.getNewsForm()
    }
  },
  data () {
    return {
      newsData:{
       ...defaultProductParam
      },
      rules:{
        title:[{ required: true, message: '请填写新闻标题', trigger: 'blur' }],
        categoryId: [{ required: true, message: '请填写新闻分类', trigger: 'blur' }],
        description: [{ required: true, message: '请填写新闻摘要', trigger: 'blur' }],
        cover: [{ required: true, message: '请上传新闻附图', trigger: 'blur' }],
        content: [{ required: true, message: '请填写新闻内容', trigger: 'blur' }]
      },
      timeValue:[],
      selectProductCateValue:[],
      showPrview:false,
      fileSizePicture:[150,'mb'],
      fileTypePicture:['png' , 'jpeg', 'jpg', 'eml', 'mp4'],
      btnTextArray: ['选取文件', '上传'],
      listTypePicture:'picture-card',
      fileList: [],
      categoryName: '',
      dialogTitle:'新闻新增'
    }
  },
  methods: {
    newsDataSelect(data){
      if(data === 22){
        this.categoryName = '小程序新闻'
      } else {
        this.categoryName = ''
      }
      console.log(data,"11223345666789")
    },
    removeFile(){
      if(this.nwesStatus == 2){
        this.$message.warning("审核状态，无法修改！")
        return false
      }
     this.fileList = []
    },
    handlePassNews(){
      if(this.categoryName === "小程序新闻"){
        this.rules.description[0].required = false
        this.rules.cover[0].required = false
      }
      this.$refs.productInfoForm.validate(valid => {
        if (valid) {
          this.$emit('handlePassNews',this.newsData)
        }
      })
    },
    handleRefuseNews(){
      this.$emit('handleRefuseNews',this.newsData)
    },
    uploadSuccess(file){
      this.newsData.cover = file[0].url
      const type = this.newsData.cover.indexOf('mp4') > 0 ? 'mp4' : 'png'
      if(type === 'mp4'){
        this.listTypePicture = 'text'
      }
       console.log(file,"文件上传成功回调")
    },
    close (){
      this.$emit('closeEidtNews')
    },
    //新闻预览
    handlePrview(){
      this.$refs.productInfoForm.validate(valid => {
        if (valid) {
          this.showPrview = true
        }
      })
    },
    //关闭新闻预览
    handlePreviewColse(){
      this.showPrview = false
    },
    //新闻填充内容获取
    getNewsForm(){
      this.$api.news.newsContentQuery(this.nwesId).then(res => {
        if(res.code === 200){
          this.newsData = res.data
          if(this.newsData.authorQuote){
            let authorQu = this.newsData.authorQuote.split(",")
            this.newsData.authorQuote = authorQu
          }
          console.log(this.newsData.authorQuote,'哈哈哈哈哈哈哈 222333')
          this.productCateOptions.forEach(v => {
            if(v.value ===  this.newsData.categoryId){
              this.categoryName = v.label
            }
          })
          if(this.newsData.cover){
            const type = this.newsData.cover.indexOf('mp4') > 0 ? 'mp4' : 'png'
            if(type === 'mp4'){
              this.listTypePicture = 'text'
            }
            console.log(type,"哈哈哈哈显示视频是否正常")
            this.fileList.push({
              url:this.newsData.cover,
              name: '新闻附图' + '.' + type,
              uid:1,
              type: type
            })
            console.log(this.fileList,"哈哈哈哈显示视频是否正常")
          }
        }
      })
    },
    //新闻编辑接口请求
    handleNewUpdata(params){
      this.$api.news.newsContentUpadte(params).then(res => {
         if(res.code === 200){
           this.$message.success('修改成功！')
           this.$emit("handleConfirm")
         }
      })
    },
    //新闻保存接口请求
    handleNewsSave(params){
      this.$api.news.addNews(params).then(res => {
        if (res.code === 200) {
          this.$message.success('添加成功！')
          this.$emit("handleConfirm")
        }
      })
    },
    //新闻提审
    handleReviewNews(){
      let title = ''
      if(this.nwesId){
        title = '确定要修改条新闻并提交审核吗？'
      } else {
        title = '确定要添加该条新闻并提交审核吗？'
      }
      if(this.categoryName === "小程序新闻"){
        this.rules.description[0].required = false
        this.rules.cover[0].required = false
      }
      this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            if(this.newsData.authorQuote){
              this.newsData.authorQuote = this.newsData.authorQuote.join(',')
            }
            this.newsData.status = 2
            this.$refs.productInfoForm.validate(valid => {
              if (valid) {
                if(!this.newsData.description.replace(/\s+/g, "") && this.categoryName !== "小程序新闻"){
                  this.$message.warning("摘要格式不正确！")
                  return
                }
                if(!this.newsData.title.replace(/\s+/g, "")){
                  this.$message.warning("标题格式不正确！")
                  return
                }
                if(Number(this.newsData.clicks) < 0){
                  this.$message.warning("浏览数不能为负数！")
                  return
                }
                if(Number(this.newsData.sort) < 0){
                  this.$message.warning("排序不能为负数！")
                  return
                }
                if(this.nwesId && !this.isCopy){
                  this.handleNewUpdata(this.newsData)
                } else if(this.nwesId && this.isCopy){
                  this.handleNewsSave(this.newsData)
                } else {
                  this.handleNewsSave(this.newsData)
                }
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消提审修改'
            })
          })
    },
    //新闻保存草稿
    handleSaveNews(){
      let title = ''
      if(this.nwesId){
        title = '确定要修改条新闻保存为草稿状态吗？'
      } else {
        title = '确定要添加该条新闻保存为草稿状态吗？'
      }
      if(this.categoryName === "小程序新闻"){
        this.rules.description[0].required = false
        this.rules.cover[0].required = false
      }
        this.$confirm(title, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        .then(() => {
          this.newsData.status = 0
          if(this.newsData.authorQuote){
            this.newsData.authorQuote = this.newsData.authorQuote.join(',')
          }
          this.$refs.productInfoForm.validate(valid => {
            if (valid) {
              if(!this.newsData.description.replace(/\s+/g, "") && this.categoryName !== "小程序新闻"){
                this.$message.warning("摘要格式不正确！")
                return
              }
              if(!this.newsData.title.replace(/\s+/g, "")){
                this.$message.warning("标题格式不正确！")
                return
              }
              if(Number(this.newsData.clicks) < 0){
                this.$message.warning("浏览数不能为负数！")
                return
              }
              if(Number(this.newsData.sort) < 0){
                this.$message.warning("排序不能为负数！")
                return
              }
              if(this.nwesId && !this.isCopy){
                this.handleNewUpdata(this.newsData)
              } else if(this.nwesId && this.isCopy){
                this.handleNewsSave(this.newsData)
              } else {
                this.handleNewsSave(this.newsData)
              }
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消保存为草稿'
          })
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.form-container {
  position: relative;
  width: 100%;
}
</style>
<template>
  <div>
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible="true" :title="isEdit ? '编辑商品评论' : '新增商品评论'" top="10vh" width="80%" @close="close">
      <el-card class="form-container" shadow="never">
        <el-table border :data="tableData">
          <el-table-column label="用户名称" align="center" width="170px">
            <template slot-scope="scope">
              <el-input v-model="scope.row.userName" size="mini" />
              <el-button type="primary" size="mini" @click="addUserInfo(scope.$index)">添加</el-button>
            </template>
          </el-table-column>
          <el-table-column label="商品编号" align="center" width="170px">
            <template slot-scope="scope">
              <el-input v-model="scope.row.productId" size="mini" />
              <el-button type="primary" size="mini" @click="addProductInfo(scope.$index)">添加</el-button>
            </template>
          </el-table-column>
          <el-table-column label="评分" align="center" width="171px">
            <template slot-scope="scope">
              <el-rate v-model="scope.row.star" show-text />
            </template>
          </el-table-column>
          <el-table-column prop="content" align="center" label="评价内容">
            <template slot-scope="scope">
              <el-input v-model="scope.row.content" type="textarea" size="mini" />
            </template>
          </el-table-column>
          <el-table-column label="评价图片">
            <template slot-scope="scope">
              <multi-upload-eval v-model="scope.row.imgs" :name="multiFileName" />
            </template>
          </el-table-column>
          <el-table-column label="评价时间" width="200px">
            <template slot-scope="scope">
              <el-date-picker v-model="scope.row.createTime" type="datetime" size="mini" placeholder="选择日期时间" />
            </template>
          </el-table-column>
        </el-table>
        <ProductDialog v-if="selectDialogVisible" :select-dialog-visible="selectDialogVisible" :selected-goods-ids="selectedGoodsIds" :selected-user-ids="selectedUserIds" :shop-id="shopId" @closeProductDialog="closeProductDialog" @addProductList="addProductList" />
        <UserDialog v-if="selectUserDialogVisible" :select-user-dialog-visible="selectUserDialogVisible" @closeProductDialog="closeUserDialog" @addUserList="addUserList" />
      </el-card>
      <el-row type="flex" justify="center" align="middle">
        <el-button type="primary" size="mini" @click="onSubmit">保存</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import SingleUpload from '@/components/Upload/singleUpload'
import ProductDialog from './productDialog'
import UserDialog from './userDialog'
import MultiUploadEval from '@/components/Upload/multiUploadEval'
export default {
  name: 'HomeAdvertiseDetail',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SingleUpload,
    ProductDialog,
    UserDialog,
    MultiUploadEval
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      multiFileName: 'evaluation-img',
      selectDialogVisible: false,
      selectUserDialogVisible: false,
      homeAdvertise: null,
      tableData: [],
      selectedGoodsIds: [],
      selectedUserIds: [],
      currentProductIndex: null,
      currentUserIndex: null,
      shopId: ''
    }
  },
  created() {
    this.initData()
    this.getShopIdList()
    if (this.isEdit) {
      // getHomeAdvertise(this.$route.query.id).then(response => {
      //   this.homeAdvertise = response.data;
      // });
    } else {
      // this.homeAdvertise = Object.assign({},defaultHomeAdvertise);
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    initData() {
      for (let i = 0; i < 5; i++) {
        this.tableData.push({
          userName: '',
          userHead: '',
          productId: '',
          star: 5,
          content: '',
          imgs: [],
          createTime: ''
        })
      }
    },
    onSubmit() {
      this.$confirm('是否提交数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // eslint-disable-next-line no-empty
        if (this.isEdit) {
        } else {
          const evaluateList = []
          const productIds = []
          let timeLength = 0
          if (this.tableData && this.tableData.length) {
            this.tableData.forEach(el => {
              if (el.productId) {
                if (el.createTime) {
                  timeLength++
                }
                productIds.push(el.productId)
                evaluateList.push({
                  evalContent: el.content ? el.content : '此用户没有填写评价',
                  evalLevel: el.star,
                  img: el.imgs.length ? el.imgs.join(',') : '',
                  productId: el.productId,
                  userName: el.userName,
                  userHead: el.userHead,
                  userIs: el.userName ? 0 : 1,
                  createTime: el.createTime
                })
              }
            })
          }
          for (let i = 0; i < productIds.length; i++) {
            if (!/^[0-9]+$/.test(productIds[i])) {
              this.$message({
                type: 'error',
                message: '输入的商品编号格式有误'
              })
              return
            }
          }
          if (timeLength < evaluateList.length) {
            this.$message({
              type: 'error',
              message: '您还有未选择的评价时间'
            })
            return
          }
          const data = {
            evaluateList: [...evaluateList]
          }
          if (evaluateList && evaluateList.length) {
            console.log('evaluateList:', evaluateList)
            this.$api.comment.saveVirtualComment(data).then(res => {
              if (res.code === 200) {
                this.tableData = []
                this.initData()
                this.$message({
                  type: 'success',
                  message: '提交成功'
                })
                this.close()
              }
            })
          }

          // createHomeAdvertise(this.homeAdvertise).then(response => {
          //   this.$refs[formName].resetFields();
          //   this.homeAdvertise = Object.assign({},defaultHomeAdvertise);
          //   this.$message({
          //     message: '提交成功',
          //     type: 'success',
          //     duration:1000
          //   });
          // });
        }
      })
    },
    getShopIdList() {
      this.$api.product.shopIdList({}).then(response => {
        this.shopId = response.listData[0].shopId
        console.log(response)
      })
    },
    addProductInfo(val) {
      this.currentProductIndex = val
      this.selectDialogVisible = true
    },
    closeProductDialog() {
      this.selectDialogVisible = false
    },
    addProductList(val) {
      if (val && val.length) {
        this.tableData[this.currentProductIndex].productId = val[0].id
      }
    },
    addUserInfo(val) {
      this.currentUserIndex = val
      this.selectUserDialogVisible = true
    },
    closeUserDialog() {
      this.selectUserDialogVisible = false
    },
    addUserList(val) {
      if (val && val.length) {
        this.tableData[this.currentUserIndex].userName = val[0].name
        this.tableData[this.currentUserIndex].userHead = val[0].url
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.form-container {
  position: relative;
  width: 100% !important;
  padding: 0;
  // margin-right: 20px;
}
::v-deep .el-table {
  margin-top: 20px;
}
::v-deep .el-table__row {
  td {
    &:nth-child(1),
    &:nth-child(2) {
      .cell {
        display: flex;
      }
    }
  }
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 180px;
}
.area-input {
  display: flex;
}
.input-width {
  width: 70%;
}
</style>

import Cookies from 'js-cookie'
const TOKENKEY = process.env.VUE_APP_TOKENKEY
const PLATFORM = 'ybh_platform'

export function getToken() {
  return Cookies.get(TOKENKEY)
}

export function setToken(token) {
  localStorage.setItem(TOKENKEY, token)
  if (process.env.VUE_APP_CURRENTMODE === 'development') {
    return Cookies.set(TOKENKEY, token)
  } else if (process.env.VUE_APP_CURRENTMODE === 'test') {
    return Cookies.set(TOKENKEY, token, { path: '/', domain: 'xykj.com' })
  } else {
    return Cookies.set(TOKENKEY, token, { path: '/', domain: '365ttybh.com' })
  }
}
export function getPlatform() {
  return Cookies.get(PLATFORM)
}

export function setPlatform(platformId) {
  if (process.env.VUE_APP_CURRENTMODE === 'development') {
    return Cookies.set(PLATFORM, platformId)
  } else if (process.env.VUE_APP_CURRENTMODE === 'test') {
    return Cookies.set(PLATFORM, platformId, { path: '/', domain: 'xykj.com' })
  } else {
    return Cookies.set(PLATFORM, platformId, { path: '/', domain: '365ttybh.com' })
  }
}

export function removePlatform() {
  if (process.env.VUE_APP_CURRENTMODE === 'development') {
    return Cookies.remove(PLATFORM)
  } else if (process.env.VUE_APP_CURRENTMODE === 'test') {
    return Cookies.remove(PLATFORM, { path: '/', domain: 'xykj.com' })
  } else {
    return Cookies.remove(PLATFORM, { path: '/', domain: '365ttybh.com' })
  }
}

export function removeToken() {
  if (process.env.VUE_APP_CURRENTMODE === 'development') {
    return Cookies.remove(TOKENKEY)
  } else if (process.env.VUE_APP_CURRENTMODE === 'test') {
    return Cookies.remove(TOKENKEY, { path: '/', domain: 'xykj.com' })
  } else {
    return Cookies.remove(TOKENKEY, { path: '/', domain: '365ttybh.com' })
  }
}

const targetSourceKey = 'design_target_source'
export function setTargetSource(url) {
  return localStorage.setItem(targetSourceKey, url)
}

export function getTargetSource() {
  return localStorage.getItem(targetSourceKey)
}

export function removeTargetSource() {
  return localStorage.removeItem(targetSourceKey)
}

export function setStorage(key, val) {
  if (process.env.VUE_APP_CURRENTMODE === 'development') {
    return Cookies.set(key, val)
  } else if (process.env.VUE_APP_CURRENTMODE === 'test') {
    return Cookies.set(key, val, { path: '/', domain: 'xykj.com' })
  } else {
    return Cookies.set(key, val, { path: '/', domain: '365ttybh.com' })
  }
}

export function getStorage(key) {
  return Cookies.get(key)
}

<template>
  <div class="app-container">
    <el-card class="header-card">
      <el-form :inline="true" :model="listQuery" size="small" label-width="110px">
        <el-form-item label="输入搜索">
          <el-input v-model="listQuery.orderSn" class="input-width" placeholder="订单编号" />
        </el-form-item>
        <el-form-item label="收货人">
          <el-input v-model="listQuery.receiverKeyword" class="input-width" placeholder="收货人姓名/手机号码" />
        </el-form-item>
        <el-form-item label="提交时间">
          <el-date-picker v-model="dateValue" type="daterange" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']" :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" @change="dateChange" />

          <!-- <el-date-picker v-model="listQuery.createTime" class="input-width" value-format="yyyy-MM-dd" type="date" placeholder="请选择时间" /> -->
        </el-form-item>

        <el-form-item label="订单分类">
          <el-select v-model="listQuery.orderType" class="input-width" placeholder="全部" clearable>
            <el-option v-for="item in orderTypeOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="订单来源">
          <el-select
            v-model="listQuery.sourceType"
            class="input-width"
            placeholder="全部"
            clearable
          >
            <el-option
              v-for="item in orderSourceOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item> -->
        <el-form-item v-if="isManageMentRole" label="店铺搜索">
          <el-select v-model="listQuery.shopId" class="input-width" placeholder="全部" filterable clearable>
            <el-option v-for="item in shopNameOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="商品分类">
          <el-cascader v-model="selectProductCateValue" clearable :show-all-levels="false" :options="goodsCateList" />
        </el-form-item>
      </el-form>
      <el-row class="search-area-btn">
        <div class="left">
          <el-button type="primary" size="small" @click="handlebatchExportOrder"> 订单导出 </el-button>
          <el-select v-model="operateType" size="small" placeholder="批量操作">
            <el-option v-for="item in operateOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
          <el-button style="margin-left: 20px" class="search-button" type="primary" size="small" @click="handleBatchOperate()"> 确定 </el-button>
        </div>
        <div class="center">
          <el-button type="primary" size="small" @click="handleSearchList()"> 查询 </el-button>
          <el-button size="small" @click="handleResetSearch()">重置</el-button>
        </div>
      </el-row>
    </el-card>
    <el-menu style="margin-top: 15px" :default-active="activeIndex" class="el-menu-demo" mode="horizontal" background-color="#ffffff" text-color="#303133" active-text-color="#409EFF" @select="handleSelect">
      <el-menu-item index="-1">全部</el-menu-item>
      <el-menu-item index="1">待发货</el-menu-item>
      <el-menu-item index="2">已发货</el-menu-item>
      <el-menu-item index="3">已完成</el-menu-item>
      <el-menu-item index="4">已关闭</el-menu-item>
      <el-submenu index="5">
        <template slot="title">其它选项</template>
        <el-menu-item index="10">其他全部</el-menu-item>
        <el-menu-item index="5">
          <i class="el-icon-warning" style="color: #909399"></i>
          无效订单
        </el-menu-item>
        <el-menu-item index="6">
          <i class="el-icon-error" style="color: #909399"></i>
          已取消
        </el-menu-item>
        <el-menu-item index="7">
          <i class="el-icon-warning" style="color: #e6a23c"></i>
          待分享
        </el-menu-item>
        <el-menu-item index="8">
          <i class="el-icon-remove" style="color: #f56c6c"></i>
          拼团失败
        </el-menu-item>
        <el-menu-item index="9">
          <i class="el-icon-remove" style="color: #909399"></i>
          售后关闭
        </el-menu-item>
        <el-menu-item index="0">
          <i class="el-icon-warning" style="color: #e6a23c"></i>
          待付款
        </el-menu-item>
      </el-submenu>
    </el-menu>
    <div class="table-container">
      <el-table ref="orderTable" v-loading="listLoading" :data="list" style="width: 100%" border @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="60" align="center" />
        <el-table-column label="编号" width="80" align="center">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column prop="orderSn" label="订单编号" align="center" width="220px">
          <template slot-scope="scope">
            <el-popover trigger="click" placement="top">
              <p>收货人: {{ scope.row.receiverName }}</p>
              <p>
                收货地址:
                {{ scope.row.receiverProvince + scope.row.receiverCity + scope.row.receiverRegion + scope.row.receiverDetailAddress }}
              </p>
              <div v-for="(item, index) in orderItemList" :key="index + 'sn'">
                <p>商品名称: {{ item.productName || '暂无' }}</p>
                <p>
                  物流公司 / 单号:
                  {{ item.deliveryCompany || '暂无' + ' / ' + item.deliverySn || '暂无' }}
                </p>
                <p>
                  {{ '商品规格:' + item.productAttr + '  ' + '商品数量：' + item.productQuantity + '  ' + '商品金额：' + item.productPrice }}
                </p>
              </div>
              <div slot="reference" class="name-wrapper" @mouseenter="OrderSnenter(scope.row.id)">
                {{ scope.row.orderSn }}
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="所属店铺" width="200" align="center">
          <template slot-scope="scope">{{ scope.row.shopName }}</template>
        </el-table-column>
        <el-table-column label="提交时间" width="180" align="center">
          <template slot-scope="scope">
            {{ $utils.parseDate(new Date(scope.row.createTime)) }}
          </template>
        </el-table-column>
        <el-table-column label="用户账号" align="center">
          <template slot-scope="scope">{{ scope.row.memberUsername }}</template>
        </el-table-column>
        <el-table-column label="订单金额" width="120" align="center">
          <template slot-scope="scope">￥{{ scope.row.totalAmount }}</template>
        </el-table-column>
        <el-table-column label="支付方式" width="120" align="center">
          <template slot-scope="scope">
            {{ scope.row.payType | formatPayType }}
          </template>
        </el-table-column>
        <el-table-column label="订单来源" width="120" align="center">
          <template slot-scope="scope">
            {{ $utils.handleOptions(scope.row.sourceType, orderSourceOptions) }}
          </template>
        </el-table-column>
        <el-table-column label="配送方式" width="120" align="center">
          <template slot-scope="scope">
            {{ scope.row.distributionType === 0 ? '快递' : '自提' }}
          </template>
        </el-table-column>
        <el-table-column label="订单状态" width="120" align="center">
          <template slot-scope="scope">
            <el-button
              :type="scope.row.status == 2 || scope.row.status == 3 ? 'primary' : scope.row.status == 0 || scope.row.status == 1 || scope.row.status == 7 || scope.row.status == 11 ? 'warning' : scope.row.status == 4 || scope.row.status == 5 || scope.row.status == 6 || scope.row.status == 9 || scope.row.status == 12 ? 'info' : 'danger'"
              :icon="scope.row.status == 1 || scope.row.status == 2 || scope.row.status == 10 ? (scope.row.distributionType === 0 ? 'el-icon-truck' : 'el-icon-shopping-bag-1') : ''"
              size="mini"
            >
              {{ $utils.handleOptions(scope.row.status, orderStatusOptions) }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleViewOrder(scope.$index, scope.row)"> 查看订单 </el-button>
            <el-button v-show="scope.row.status === 0" size="mini" @click="handleCloseOrder(scope.$index, scope.row)"> 关闭订单 </el-button>
            <el-button v-show="scope.row.status === 1 || scope.row.status === 10" size="mini" @click="handleDeliveryOrder(scope.$index, scope.row)"> 订单发货 </el-button>
            <el-button v-show="scope.row.status === 4" size="mini" type="danger" @click="handleDeleteOrder(scope.$index, scope.row)"> 删除订单 </el-button>
            <el-button v-show="isFastProde(scope.row)" size="mini" type="danger" @click="confirmFast(scope.row)"> 确认发货 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination background layout="total, sizes,prev, pager, next,jumper" :current-page.sync="listQuery.pageNum" :page-size="listQuery.pageSize" :page-sizes="[5, 10, 20, 30, 50, 100]" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
    <el-dialog title="关闭订单" :visible.sync="closeOrder.dialogVisible" width="30%">
      <span style="vertical-align: top">操作备注：</span>
      <el-input v-model="closeOrder.content" style="width: 80%" type="textarea" :rows="5" placeholder="请输入内容" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeOrder.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleCloseOrderConfirm"> 确 定 </el-button>
      </span>
    </el-dialog>
    <logistics-dialog v-model="logisticsDialogVisible" :logistics-list="logisticsList" />
  </div>
</template>
<script>
import LogisticsDialog from '@/views/oms/order/components/logisticsDialog'
import { mapState } from 'vuex'
import store from '@/store'

const defaultListQuery = {
  pageNum: 1,
  pageSize: 10,
  orderSn: null,
  shopId: null,
  receiverKeyword: null,
  status: null,
  orderType: null,
  productCategoryId: null,
  sourceType: '0,1',
  createTime: null,
  endTime: null
}
export default {
  name: 'OrderList',
  components: { LogisticsDialog },
  data() {
    var that = this
    return {
      shopDetailVisible: false,
      listQuery: Object.assign({}, defaultListQuery),
      listLoading: true,
      dateValue: [],
      list: null,
      total: null,
      operateType: null,
      multipleSelection: [],
      shopNameOptions: [],
      closeOrder: {
        dialogVisible: false,
        content: null,
        orderIds: []
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              picker.$emit('pick', [that.$utils.parseDate(start, '{y}-{m}-{d} 00:00:00'), that.$utils.parseDate(end, '{y}-{m}-{d} 23:59:59')])
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
              end.setTime(end.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', [that.$utils.parseDate(start, '{y}-{m}-{d} 00:00:00'), that.$utils.parseDate(end, '{y}-{m}-{d} 23:59:59')])
            }
          },
          {
            text: '近7天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [that.$utils.parseDate(start, '{y}-{m}-{d} 00:00:00'), that.$utils.parseDate(end, '{y}-{m}-{d} 23:59:59')])
            }
          },
          {
            text: '近30天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [that.$utils.parseDate(start, '{y}-{m}-{d} 00:00:00'), that.$utils.parseDate(end, '{y}-{m}-{d} 23:59:59')])
            }
          }
        ]
      },
      operateOptions: [
        {
          label: '批量发货',
          value: 1
        },
        {
          label: '关闭订单',
          value: 2
        },
        {
          label: '删除订单',
          value: 3
        }
      ],
      logisticsDialogVisible: false,
      logisticsList: [],
      activeIndex: '-1',
      goodsCateList: [], // 商品分类列表
      selectProductCateValue: null,
      UserStatus: '',
      orderItemList: ''
    }
  },
  computed: {
    ...mapState({
      orderStatusOptions: state => state.dictionary.orderStatus,
      orderTypeOptions: state => state.dictionary.orderType,
      orderSourceOptions: state => state.dictionary.orderSource,
      companyList: state => state.dictionary.companyList,
      isManageMentRole: state => state.user.userRole
    })
  },
  watch: {
    selectProductCateValue(newValue) {
      if (newValue != null && newValue.length === 2) {
        this.listQuery.productCategoryId = newValue[1]
      } else {
        this.listQuery.productCategoryId = null
      }
    }
  },
  created() {
    if (this.$route.query.createTime) {
      if (this.$route.query.model === 'first') {
        this.listQuery.createTime = this.$route.query.createTime + ' ' + '00:00:00'
        this.listQuery.endTime = this.$route.query.createTime + ' ' + '23:59:59'
        this.dateValue = [this.listQuery.createTime, this.listQuery.endTime]
        this.listQuery.status = 31
      } else {
        this.listQuery.createTime = this.$route.query.createTime + '-' + '01' + ' ' + '00:00:00'
        this.listQuery.endTime = this.$route.query.createTime + '-' + '31' + ' ' + '23:59:59'
        this.dateValue = [this.listQuery.createTime, this.listQuery.endTime]
        this.listQuery.status = 31
      }
    } else {
      const now = new Date()
      // this.listQuery.createTime = this.$utils.parseDate(new Date().setMonth(now.getMonth() - 3), '{y}-{m}-{d}')
      // this.listQuery.endTime = this.$utils.parseDate(now, '{y}-{m}-{d}')
      this.listQuery.createTime = this.$utils.parseDate(new Date().setMonth(now.getMonth() - 3), '{y}-{m}-{d} 00:00:00')
      this.listQuery.endTime = this.$utils.parseDate(now, '{y}-{m}-{d} 23:59:59')
      this.dateValue = [this.listQuery.createTime, this.listQuery.endTime]
    }
    this.getDictionaryList()
    this.getShopNameList()
    this.getGoodsCate()
    setTimeout(() => {
      this.getList()
    }, 500)
  },
  // eslint-disable-next-line vue/order-in-components
  filters: {
    formatPayType(value) {
      let str = ''
      switch (value) {
        case 1:
          str = '支付宝'
          break
        case 2:
          str = '微信'
          break
        case 3:
          str = '余额'
          break
      }
      return str
    }
  },
  methods: {
    // 确认发货按钮操作
    confirmFast(value) {
      this.listLoading = true
      var _this = this
      var params = {
        id: value.id
      }
      this.$api.order.confirmFast(params).then(() => {
        this.listLoading = false
        _this.getList()
      })
    },

    // 判断是否能够发货
    isFastProde(value) {
      if (value.status === 3 && value.distributionType === 1 && (!value.deliveryStatus || value.deliveryStatus != 1)) {
        return true
      } else {
        return false
      }
    },

    dateChange(date) {
      this.listQuery.createTime = date[0]
      this.listQuery.endTime = date[1]
    },
    async getDictionaryList() {
      await store.dispatch('change_orderstatus')
      await store.dispatch('change_ordertype')
      await store.dispatch('change_ordersource')
      await store.dispatch('change_companyList')
    },
    handleProductCategoryChange(value) {
      console.log(value)
    },
    OrderSnenter(id) {
      this.$api.order.getOrderDetail(id).then(response => {
        const dataList = response.data.orderItemList
        dataList.forEach(table => {
          let b
          if (table.productAttr) {
            const goodsAttributeList = table.productAttr ? JSON.parse(table.productAttr) : []
            if (goodsAttributeList && goodsAttributeList.length) {
              b = ''
              goodsAttributeList.forEach(el => {
                let a = el.key
                a = a + ':' + el.value
                b = b + a + ';'
              })
              table.productAttr = b
            }
          }
        })
        this.orderItemList = dataList
      })
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath, '哈哈哈哈哈22222')
      this.listQuery = Object.assign(this.listQuery, {
        pageNum: 1,
        pageSize: 10
      })
      if (key >= 0) {
        this.listQuery.status = key
      } else if (key === '-2') {
        this.listQuery.status = '5,6,7,0'
      } else if (key === '-1') {
        delete this.listQuery.status
      }
      this.getList()
    },
    handleResetSearch() {
      this.selectProductCateValue = null
      this.listQuery = Object.assign({}, defaultListQuery)
    },
    handleSearchList() {
      this.listQuery.pageNum = 1
      this.getList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleViewOrder(index, row) {
      this.$router.push({ path: '/oms/orderDetail', query: { id: row.id } })
    },
    handleCloseOrder(index, row) {
      this.closeOrder.dialogVisible = true
      this.closeOrder.orderIds = [row.id]
    },
    handleDeliveryOrder(index, row) {
      // let listItem = this.covertOrder(row);
      // this.$router.push({
      //   path: "/oms/deliverOrderList",
      //   query: { list: [listItem] },
      // });
      this.$router.push({ path: '/oms/orderDetail', query: { id: row.id } })
    },
    handleViewLogistics(index, row) {
      this.$api.order.listByFeign({ logisticalNumber: row.logisticalNumber }).then(res => {
        if (res.code === 200) {
          const list = res.data.list.map(item => {
            return {
              id: item.id,
              name: item.logisticalContext,
              time: item.logisticalTime
            }
          })
          this.logisticsList = list
          this.logisticsDialogVisible = true
        }
      })
    },
    handleDeleteOrder(index, row) {
      const ids = []
      ids.push(row.id)
      this.deleteOrder(ids)
    },
    handleBatchOperate() {
      if (this.multipleSelection == null || this.multipleSelection.length < 1) {
        this.$message({
          message: '请选择要操作的订单',
          type: 'warning',
          duration: 1000
        })
        return
      }
      if (this.operateType === 1) {
        // 批量发货
        const list = []
        for (let i = 0; i < this.multipleSelection.length; i++) {
          if (this.multipleSelection[i].status === 1) {
            list.push(this.covertOrder(this.multipleSelection[i]))
          }
        }
        if (list.length === 0) {
          this.$message({
            message: '选中订单中没有可以发货的订单',
            type: 'warning',
            duration: 1000
          })
          return
        }
        this.$router.push({
          path: '/oms/deliverOrderList',
          query: { list: list }
        })
      } else if (this.operateType === 2) {
        // 关闭订单
        this.closeOrder.orderIds = []
        for (let i = 0; i < this.multipleSelection.length; i++) {
          this.closeOrder.orderIds.push(this.multipleSelection[i].id)
        }
        this.closeOrder.dialogVisible = true
      } else if (this.operateType === 3) {
        // 删除订单
        const ids = []
        for (let i = 0; i < this.multipleSelection.length; i++) {
          ids.push(this.multipleSelection[i].id)
        }
        this.deleteOrder(ids)
      }
    },
    handleSizeChange(val) {
      this.listQuery.pageNum = 1
      this.listQuery.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.pageNum = val
      this.getList()
    },
    handleCloseOrderConfirm() {
      if (this.closeOrder.content == null || this.closeOrder.content === '') {
        this.$message({
          message: '操作备注不能为空',
          type: 'warning',
          duration: 1000
        })
        return
      }

      let data = {
        ids: this.closeOrder.orderIds,
        note: this.closeOrder.content
      }
      this.$api.order.closeOrder(data).then(() => {
        this.closeOrder.orderIds = []
        this.closeOrder.dialogVisible = false
        this.getList()
        this.$message({
          message: '修改成功',
          type: 'success',
          duration: 1000
        })
      })
    },
    // 打印
    printExcel(data, name = new Date()) {
      const blob = new Blob([data], { type: 'application/vnd.ms-excel' })
      const objectUrl = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = objectUrl
      link.download = name
      link.click()
      URL.revokeObjectURL(objectUrl)
    },
    handlebatchExportOrder() {
      this.$api.order.batchExportOrderList(this.listQuery).then(res => {
        console.log(res)
        this.printExcel(res, '订单列表')
      })
      // eslint-disable-next-line handle-callback-err
      // .catch(err => {
      //   this.$message({
      //     message: '订单未付款，导出失败！',
      //     type: 'error',
      //     duration: 1000
      //   })
      // })
    },
    getGoodsCate() {
      this.$api.product.fetchListWithChildren().then(response => {
        const list = response.data
        this.goodsCateList = []
        for (let i = 0; i < list.length; i++) {
          const children = []
          if (list[i].children != null && list[i].children.length > 0) {
            for (let j = 0; j < list[i].children.length; j++) {
              children.push({
                label: list[i].children[j].name,
                value: list[i].children[j].id
              })
            }
          }
          this.goodsCateList.push({
            label: list[i].name,
            value: list[i].id,
            children: children
          })
        }
      })
    },
    getList() {
      this.listLoading = true
      this.$api.order.orderList(this.listQuery).then(response => {
        this.listLoading = false
        this.list = response.data.list
        this.total = response.data.total
        if (this.list.length > 0) {
          this.UserStatus = this.list[0].status
        }
      })
    },
    async getShopNameList() {
      await this.$api.product.getShopname({ pageNum: 1, pageSize: 100 }).then(response => {
        this.shopNameOptions = []
        const ShopList = response.data.list
        for (let i = 0; i < ShopList.length; i++) {
          this.shopNameOptions.push({
            label: ShopList[i].shopName,
            value: ShopList[i].id
          })
        }
      })
    },
    deleteOrder(ids) {
      this.$confirm('是否要进行该删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          ids: ids
        }
        this.$api.order.deleteOrder(data).then(() => {
          this.$message({
            message: '删除成功！',
            type: 'success',
            duration: 1000
          })
          this.getList()
        })
      })
    },
    covertOrder(order) {
      const address = order.receiverProvince + order.receiverCity + order.receiverRegion + order.receiverDetailAddress
      const listItem = {
        orderId: order.id,
        orderSn: order.orderSn,
        receiverName: order.receiverName,
        receiverPhone: order.receiverPhone,
        receiverPostCode: order.receiverPostCode,
        address: address,
        deliveryCompany: null,
        deliverySn: null
      }
      return listItem
    }
  }
}
</script>

<style lang="scss" scoped>
.input-width {
  width: 203px;
}
.btnGroup {
  padding-bottom: 30px;
}
</style>
